<template>
  <div id="content" class="pt-20" role="main">
    <div class="panel-blue-block pt-10 pl-30 pr-30 pb-5 tx-c mt-10">
      <div class="mb-10 mt-10">
        <div class="spr-treasures-favicon m-auto"></div>
      </div>
      <h2 class="byline tx-c">Welcome {{ this.user.first_name }}</h2>
      <p class="akr-gen ta-l">HQ is your information headquarters</p>
    </div>
    <div class="panel-blue-block pt-20 pl-30 pr-30 pb-5 tx-c mt-10">
      <h2 class="byline">Your Mission</h2>
      <p>
        Vote your Posts or your favourite Posts into the top 10 on the
        <strong>
          <router-link to="/leaderboard">Leaderboard</router-link> </strong
        >, before the end of this week, to claim a permanent place on the
        <strong> <router-link to="/winners">Winners' Wall</router-link> </strong
        >.
      </p>
      <h2 class="byline mt-30">Your Rewards</h2>
      <p>
        Top 10 Winners every week receive free
        <strong><router-link to="/tokens">Tokens</router-link></strong>,  
        <strong>Custom <router-link to="/themes">Themes</router-link></strong
        > & <strong>Boost Rewards</strong> PLUS free promotion of their Winning Posts,
        <strong>FOREVER!</strong>
      </p>
    </div>
    <div class="sub-panel">
      <p class="tx-l">
        <span class="akr-gen icon-calendar"> Competition Week: </span>
        <strong>
          <router-link to="/leaderboard">
            {{ currentWeek }}
          </router-link>
        </strong>
        <span class="fs-7">: {{ currentYear }}</span>
      </p>
      <p class="tx-l">
        <span class="akr-gen icon-right-hand"> Your Votes left today:  </span>
        <strong class="red"> {{ votes }}</strong>
      </p>
    </div>
    <div class="mb-20 tx-c">
      <p class="gen-akr fs-9">We always need air - Please share us on...</p>
      <div class="social-share-links center">
        <a @click="shareFacebook()" target="_blank" class="icon-box fb">
          <i class="icon-facebook"></i>
        </a>
        <a @click="shareTwitter()" target="_blank" class="icon-box tw">
          <i class="icon-twitter"></i>
        </a>
        <a @click="sharePinterest()" target="_blank" class="icon-box pi">
          <i class="icon-pinterest-circled"></i>
        </a>
        <a @click="shareLinkedin()" target="_blank" class="icon-box li">
          <i class="icon-linkedin"></i>
        </a>
        <a @click="modalShow = !modalShow" target="_blank" class="icon-box mi">
          <i class="icon-mail-alt"></i>
        </a>
        <modal
          :show="modalShow"
          @toggle-modal="modalShow = !modalShow"
          @handle-submit="handleSubmit"
        />
      </div>
    </div>

    <div class="mb-30 tx-c">
      <div ref="settings">
        <div class="stroke-container pt-0 tx-l">
          <h4
            class="icon-award p-head read-more"
            :class="{ opened: isOpen === 7 }"
            @click="opening(7)"
            :ref="'settings-1'"
          >
            Rewards
            <i
              class="right icon-down-open"
              :class="{ 'icon-up-open': isOpen === 7 }"
            ></i>
          </h4>
          <WebsiteThemes :isOpen="isOpen" />
          <!-- Token -->
          <h4
            class="p-head read-more token-H4"
            :class="{ opened: isOpen === 9 }"
            @click="opening(9)"
            :ref="'settings-1'"
          >
            <span class=""
              ><img src="../assets/images/tkn-o2-29x29.png" alt=""
            /></span>
            Tokens
            <i
              class="right icon-down-open"
              :class="{ 'icon-up-open': isOpen === 9 }"
            ></i>
          </h4>
          <TokenDescription :isOpen="isOpen" />
          <!-- end-token -->
          <!-- Notifications -- sent from O2 Admin -->
          <h4
            class="p-head read-more o2-notifs p-r"
            :class="{ opened: isOpen === 1 }"
            :ref="'settings-2'"
            @click="opening(1)"
          >
            <span v-if="isOpen === 1" class="icon-badge static opened">
              {{ totalNewsCount }}
            </span>
            <span
              v-else
              class="icon-badge static"
              :class="{ zero: unread_number === 0 }"
              >{{ unread_number }}</span
            >
            News from O2
            <i
              class="right icon-down-open"
              :class="{ 'icon-up-open': isOpen === 1 }"
            ></i>
          </h4>
          <NewsComponent v-if="isOpen === 1" :isOpen="isOpen" />
          <!-- end of O2 sent Notifications -->
          <!-- Start newsletter sign up form --->
          <h4
            id="newsletterForm"
            class="icon-mail p-head read-more"
            :class="{ opened: isOpen === 2 }"
            :ref="'settings-3'"
            @click="opening(2)"
          >
            Newsletter
            <i
              class="right icon-down-open"
              :class="{ 'icon-up-open': isOpen === 2 }"
            ></i>
          </h4>
          <NewsletterComponent :isOpen="isOpen" />
          <!-- end of email newsletter signup form -->
          <!-- Refer a friend -->
          <h4
            id="referFrd"
            class="icon-share p-head read-more"
            :class="{ opened: isOpen === 8 }"
            :ref="'settings-4'"
            @click="opening(8)"
          >
            Refer a friend
            <i
              class="right icon-down-open"
              :class="{ 'icon-up-open': isOpen === 8 }"
            ></i>
          </h4>
          <ReferFriendComponent :isOpen="isOpen" />
          <!-- end of refer a friend -->
          <h4
            id="reportPost"
            class="icon-frown p-head read-more"
            :class="{ opened: isOpen === 3 }"
            :ref="'settings-5'"
            @click="opening(3)"
          >
            Bugs & suggestions
            <i
              class="right icon-down-open"
              :class="{ 'icon-up-open': isOpen === 3 }"
            ></i>
          </h4>
          <ReportPostComponent :isOpen="isOpen" ref="report_post" />
          <!-- end of report post-->
          <h4
            class="icon-doc-text p-head read-more"
            :ref="'settings-6'"
            :class="{ opened: isOpen === 4 }"
            @click="opening(4)"
          >
            Community rules
            <i
              class="right icon-down-open"
              :class="{ 'icon-up-open': isOpen === 4 }"
            ></i>
          </h4>
          <CommunityRulesComponent :isOpen="isOpen" ref="community_role" />
          <!-- end of community rules-->
          <h4
            class="icon-doc-text p-head read-more"
            :class="{ opened: isOpen === 5 }"
            :ref="'settings-7'"
            @click="opening(5)"
          >
            Terms of service
            <i
              class="right icon-down-open"
              :class="{ 'icon-up-open': isOpen === 5 }"
            ></i>
          </h4>
          <TermsOfServiceComponent :isOpen="isOpen" />
          <!-- end of terms of service-->
          <h4
            class="icon-doc-text p-head read-more"
            :class="{ opened: isOpen === 6 }"
            :ref="'settings-8'"
            @click="opening(6)"
          >
            Privacy policy
            <i
              class="right icon-down-open"
              :class="{ 'icon-up-open': isOpen === 6 }"
            ></i>
          </h4>
          <PrivacyPolicyComponent :isOpen="isOpen" />
        </div>
        <div class="panel-blue-block p-15 pr-20 pl-20 mb-30 mt-30 tx-c">
          <h2 class="byline">O2 Social</h2>
          <p>
            <span class="akr-gen">SHARE THE LOVE. FOLLOW US ON...</span>
          </p>
          <div class="o2-socials">
            <span data-title="O2 Facebook" data-title-top
              ><a
                href="https://www.facebook.com/O2Unlimited"
                target="_blank"
                class="icon-facebook"
              ></a
            ></span>
            <span data-title="O2 Instagram" data-title-top
              ><a
                href="https://www.instagram.com/o2positive_com"
                target="_blank"
                class="icon-instagram"
              ></a
            ></span>
            <span data-title="O2 Pinterest" data-title-top>
              <a
                href="https://www.pinterest.nz/o2positive_com"
                target="_blank"
                class="icon-pinterest"
              ></a
            ></span>
            <span data-title="O2 Twitter" data-title-top
              ><a
                href="https://www.twitter.com/O2Unlimited"
                target="_blank"
                class="icon-twitter"
              ></a
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GetCurrentWeekMixin from "@/mixins/GetCurrentWeekMixin";
import NewsComponent from "@/components/hq/NewsComponent";
import NewsletterComponent from "@/components/hq/NewsletterComponent";
import CommunityRulesComponent from "@/components/hq/CommunityRulesComponent";
import ReportPostComponent from "@/components/hq/ReportPostComponent";
import TermsOfServiceComponent from "@/components/hq/TermsOfServiceComponent";
import PrivacyPolicyComponent from "@/components/hq/PrivacyPolicyComponent";
import modal from "../components/shared/modal/modal.vue";
import WebsiteThemes from "@/components/hq/WebsiteThemes";
import $ from "jquery";
import ReferFriendComponent from "@/components/hq/ReferFriendComponent.vue";
import TokenDescription from "@/components/hq/TokenDescription.vue";

export default {
  name: "O2hq",
  components: {
    NewsComponent,
    NewsletterComponent,
    CommunityRulesComponent,
    ReportPostComponent,
    TermsOfServiceComponent,
    PrivacyPolicyComponent,
    ReferFriendComponent,
    modal,
    WebsiteThemes,
    TokenDescription,
  },

  mixins: [GetCurrentWeekMixin],

  computed: {
    ...mapState({
      votes: (state) => state.auth.user.votes,
      unread_number: (state) => state.auth.unread_number,
      user: (state) => state.auth.user,
      isLogin: (state) => state.auth.isLogin,
      totalNewsCount: (state) => state.auth.totalNewsCount,
    }),
    postURL() {
      let $this = this;
      if ($this.$route.params.postURL) {
        this.reortPost();
      }
      return this.$route.params.postURL;
    },
  },

  data() {
    return {
      isOpen: 0,
      modalShow: false,
    };
  },

  mounted() {
    if (this.$route.name === "O2hq") {
      $(".custom-screen").addClass("full-screen");
    }
    this.$store.commit("set_shared_overlay_class", "");
  },

  destroyed() {
    $(".custom-screen").removeClass("full-screen");
  },

  methods: {
    shareFacebook() {
      window.open(
        "https://www.facebook.com/sharer.php?u=" + process.env.VUE_APP_PREFIX,
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
    },
    shareTwitter() {
      window.open(
        "https://twitter.com/intent/tweet?url=" + process.env.VUE_APP_PREFIX,
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
    },
    shareLinkedin() {
      window.open(
        "https://www.linkedin.com/shareArticle?mini=true&url=" +
          process.env.VUE_APP_PREFIX,
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
    },
    sharePinterest() {
      window.open(
        "http://pinterest.com/pin/create/button/?url=" +
          process.env.VUE_APP_PREFIX,
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
    },
    handleSubmit(data) {
      if (this.isLogin) {
        data.user = "yes";
      } else {
        data.user = "no";
      }
      this.modalShow = false;
      data.route = this.$route.path;
      this.$store.dispatch("overlayModule/o2SiteSharing", data);
    },
    opening(num) {
      if (this.isOpen === num) {
        this.isOpen = 0;
      } else {
        this.isOpen = num;
        document.getElementById("app").scrollTop =
          this.$refs.settings.offsetTop;
        if (num === 1) {
          this.$store.commit("set_unreads", 0);
        }
      }
    },
    reortPost() {
      this.opening(3);
      this.$nextTick(() => {
        this.$refs.report_post.$refs.report_post_url.focus();
      });
    },
  },
};
</script>