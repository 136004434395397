import * as api from "../Services/api.js";

export const state = {
  posts: [],
  pageNumber: 0,
  totalPosts: 0,
  toggleShow: false,
};

export const mutations = {
  SET_TOGGLE(state, data) {
    state.toggleShow = data;
  },
  SET_ORDERBY_SORT(state, orderBy) {
    state.orderBySort = orderBy;
  },
  SET_FAVORITE(state, payload) {
    state.posts = payload.post_data;
    state.pageNumber = 1;
    state.totalPosts = payload.post_count;
  },
  SET_LOADED_FAVORITE(state, payload) {
    state.posts = state.posts.concat(payload.post_data);
    state.totalPosts = payload.post_count;
    state.pageNumber += 1;
  },
  CHANGE_PRESSURE(state, payload) {
    const index = state.posts.findIndex(
      ({ post_id }) => post_id === payload.post_id
    );
    state.posts[index].votes += payload.amount;
  },
  EDIT_POST(state, payload) {
    const index = state.posts.findIndex(
      ({ post_id }) => post_id === payload.post_id
    );
    state.posts.splice(index, 1, { ...payload });
  },
  DELETE_POST(state, payload) {
    const index = state.posts.findIndex(({ post_id }) => post_id === payload);
    state.posts.splice(index, 1);
  },
};

export const actions = {
  async getFavorites({ commit }, data) {
    return await api
      .get(
        "/posts/favorites/?page_num=" +
          data.pageNumber +
          "&active_tab=" +
          data.activeTab +
          "&show=" +
          data.show
      )
      .then((response) => {
        commit("SET_FAVORITE", response);
        commit("SET_TOGGLE", data.show);
        commit("SET_ORDERBY_SORT", response.order_by);
        return response;
      });
  },
  async loadMorePosts({ dispatch, commit }, data) {
    dispatch("setStatus", true, { root: true });
    const tmp = await api
      .get(
        "/posts/favorites/?page_num=" +
          data.pageNumber +
          "&active_tab=" +
          data.activeTab +
          "&show=" +
          state.toggleShow
      )
      .then((response) => {
        dispatch("setStatus", false, { root: true });
        commit("SET_LOADED_FAVORITE", response);
        return response;
      });
    return tmp;
  },
  async changePressure({ commit }, data) {
    return await api
      .get(
        "/posts/change-vote/?post_id=" + data.post_id + "&amount=" + data.amount
      )
      .then((response) => {
        commit("CHANGE_PRESSURE", data);
        return response;
      });
  },
  async editPost({ commit }, data) {
    return await api.post("/posts/update/", data).then((response) => {
      if (response.status === 200) {
        commit("EDIT_POST", data);
      }
    });
  },
  async deletePost({ commit }, data) {
    return await api
      .get("/follower/remove-favorite/?post_id=" + data)
      .then((response) => {
        if (response.status === 200) {
          commit("DELETE_POST", data);
        }
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
