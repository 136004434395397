<template>
  <div class="read-panel" :class="{ open: isOpen === 9 }">
    <h4 class="p-head blue">
    Tokens are O2's virtual currency.</h4>
    <div class="token-des-wrap">
      <img src="../../assets/images/tkn-tokens.png" alt="" />
      <div class="token-des">
        <p>
          They can be earned, bought, gifted or recieved and can use for many
          cool, fun or strategic purpose across the site.
        </p>
        <router-link to="/tokens">Find out more about Tokens</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TokenDescription",
  props: ["isOpen"],
};
</script>