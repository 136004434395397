<template>
  <div>
    <img
      v-if="!idx"
      class="crown"
      data-title="TOTAL LEGEND!"
      data-title-right
    />
    <div
      v-else
      class="top10-circle tippy"
      :data-title="`Pos: ${idx}, Week 7`"
      data-title-right
    >
      <span data-title="" data-title-right>
        <span>{{ idx }}</span>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    idx: {
      type: Number,
    },
  },
};
</script>