import axios from "axios";
import * as api from "../Services/api.js";

export const state = {
  editedPost: {},
  comments: [],
  subComments: [],
  selectedPostId: 0,
  totalComments: 0,
  totalCommentsCount: 0,
  pageNumber: 0,
  countData: {},
  userData: [],
  totalStar: 0,
  LovePost: [],
  LikePost: [],
  loveCount: 0,
  likeCount: 0,
  loveComment: [],
  likeComment: [],
  LoveLikeCommentCount: [],
  lifeSupport: "",
};

export const mutations = {
  set_love_post(state, data) {
    state.LovePost = data;
  },
  set_like_post(state, data) {
    state.LikePost = data;
  },
  set_love_like_comment(state, data) {
    state.likeComment = data.likedComments;
    state.loveComment = data.lovedComments;
    state.LoveLikeCommentCount = data.data;
  },
  set_love_like_count(state, data) {
    state.loveCount = data.loveCount;
    state.likeCount = data.likeCount;
  },
  set_total_star(state, data) {
    state.totalStar = data;
  },
  set_star_count(state, data) {
    state.countData = data.count;
    state.userData = data.userData;
  },
  SET_SELECTED_POSTID(state, postID) {
    state.selectedPostId = postID;
  },
  SET_COMMENTS(state, data) {
    state.comments = data.comments;
    state.totalComments = data.comment_count;
    state.totalCommentsCount = data.totalCommentsCount;
    state.pageNumber = 1;
  },
  SET_COMMENTS_COUNT(state, data) {
    state.totalComments = state.totalComments + data;
  },
  SET_TOTAL_COMMENTS_COUNT(state, data) {
    state.totalCommentsCount = state.totalCommentsCount + data;
  },
  SET_LOADED_SUB_COMENTS(state, data) {
    const index = state.comments.findIndex(
      (item) => item.comm_id === data.comm_id
    );
    state.comments[index].sub_comm = state.comments[index].sub_comm.concat(
      data.sub_comments
    );
    state.comments[index].curr_page = state.comments[index].curr_page + 1;
  },
  SET_SUB_COMMENT_CURR_PAGE(state, data) {
    const index = state.comments.findIndex((item) => item.comm_id === data);
    state.comments[index].curr_page = 0;
  },
  SET_LOADED_COMMENT(state, data) {
    state.comments = state.comments.concat(data.comments);
    state.pageNumber += 1;
  },
  SET_PAGE_NUMBER(state, data) {
    state.pageNumber = data;
  },
  REMOVE_COMMENT(state, data) {
    state.comments.splice(data.index, 1);
    if (state.comments.length === 0) {
      state.comments.splice(data.index, 1);
    }
  },

  REMOVE_SUB_COMMENT(state, data) {
    state.comments[data.index].sub_comm.splice(data.idx, 1);
    state.comments[data.index].total_sub_comm =
      state.comments[data.index].total_sub_comm - 1;
  },
  SET_EDITED_POST(state, payload) {
    state.editedPost = { ...payload };
  },
};

export const getters = {
  getpostId(state) {
    return state.selectedPostId;
  },
};

export const actions = {
  async devicetoken(_, data) {
    await axios({
      url: "/devices/",
      method: "POST",
      data: { type: data.type, registration_id: data.registration_id },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async emailSharing(_, data) {
    const tmp = await axios({
      url: "posts/email-sharing/",
      method: "POST",
      data: {
        postId: data.postId,
        toMail: data.toMail,
        user: data.user,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return tmp;
  },
  async contactAdmin(_, data) {
    const tmp = await axios({
      url: "posts/contact-admin/",
      method: "POST",
      data: {
        postURL: data.postURL,
        postID: data.postID,
        userEmail: data.userEmail,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return tmp;
  },
  async o2SiteSharing(_, data) {
    await axios({
      url: "posts/o2site-sharing/",
      method: "POST",
      data: {
        toMail: data.toMail,
        route: data.route,
        user: data.user,
      },
    })
      .then((response) => {
        console.log(response);
      })
  },
  async disableReportButton(_, data) {
    const tmp = await axios({
      url: "posts/disable-report-button/",
      data: {
        post_id: data.post_id,
      },
      method: "POST",
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return tmp;
  },
  async getComments({ commit }, post_id) {
    await api
      .get("/posts/comments/?post_id=" + post_id + "&page_num=0")
      .then((response) => {
        commit("SET_COMMENTS", response);
      });
  },
  async loadMoreSubComments({ commit }, data) {
    await api
      .get(
        "/posts/get-subcomments/?comm_id=" +
          data.comm_id +
          "&page_num=" +
          data.subPageNum
      )
      .then((response) => {
        commit("SET_LOADED_SUB_COMENTS", response);
      });
  },
  async loadMoreComments({ commit }, post_id) {
    await api
      .get(
        "/posts/comments/?post_id=" + post_id + "&page_num=" + state.pageNumber
      )
      .then((response) => {
        commit("SET_LOADED_COMMENT", response);
      });
  },
  async removeComment({ commit }, data) {
    const res = await axios({
      url: "posts/remove-comment/",
      method: "POST",
      data: { comment_id: data.comment_id },
    })
      .then((response) => {
        if (response.status === 200) {
          commit("REMOVE_COMMENT", data);
        }
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  },
  async removeSubComment({ commit }, data) {
    const res = await axios({
      url: "posts/remove-sub-comment/",
      method: "POST",
      data: {
        sub_comment_id: data.sub_comment_id,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          commit("REMOVE_SUB_COMMENT", data);
        }
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  },
  setEditedPost({ commit }, data) {
    commit("SET_EDITED_POST", data);
  },
  async updatePost({ commit }, data) {
    await api.post("/posts/update/", data).then((response) => {
      if (response.status === 200) {
        commit("SET_EDITED_POST", data);
      }
    });
  },
  async banPost(_, postId) {
    const tmp = await axios({
      url: "/posts/ban-post/",
      method: "post",
      data: {
        post_id: postId,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return tmp;
  },
  async StarCount({ commit }, data) {
    await axios({
      url: "user/token/star-count/?post=" + data.post,
      method: "GET",
    })
      .then((response) => {
        commit("set_star_count", response.data);
        commit("set_total_star", response.data.totalStar);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async PostLoveLike(_, data) {
    await axios({
      url: "posts/love-like/",
      method: "POST",
      data: data,
    })
      .then((response) => {
        console.log(response);
      })
  },
  async GetLoveLikeData({ commit }, data) {
    await axios({
      url: "posts/love-like/?post=" + data.post,
      method: "GET",
      data: data,
    })
      .then((response) => {
        commit("set_love_post", response.data.LovePosts);
        commit("set_like_post", response.data.LikePosts);
        commit("set_love_like_count", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async removeLoveLike(_, data) {
    await axios({
      url: "posts/remove-love-like/",
      method: "POST",
      data: data,
    })
      .then((response) => {
        console.log(response);
      })
  },
  async CommentLoveLike(_, data) {
    await axios({
      url: "posts/comment-love-like/",
      method: "POST",
      data: data,
    })
      .then((response) => {
        console.log(response);
      })
  },
  async GetCommentLoveLikeData({ commit }, data) {
    await axios({
      url: "posts/comment-love-like/?post=" + data.post,
      method: "GET",
    })
      .then((response) => {
        commit("set_love_like_comment", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async removeCommentLoveLike(_, data) {
    await axios({
      url: "posts/remove-comment-love-like/",
      method: "POST",
      data: data,
    })
      .then((response) => {
        console.log(response);
      })
  },
  async PostTrack(_, data) {
    await axios({
      url: "posts/post-track/",
      method: "POST",
      data: data,
    })
      .then((response) => {
        console.log(response);
      })
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
