<template>
  <div>
    <div id="content" class="pt-20" role="main">
      <PostCreateOverlay />
      <div class="right relative mt--5 no-ws show-for-small">
        <div data-title="New Posts" data-title-left>
          <router-link to="/" class="button tertiary ml-10 pl-5 pr-5">
            <span class="pr-5 pl-5">Pressure Pit</span>
          </router-link>
        </div>
      </div>
      <h2 class="inline-block akrobat m-0 mt-2">What we found ...</h2>
      <div v-if="filter === 0">
        <div v-if="searchedPeoples.length === 0" class="nothing-found">
          <h2 class="inline-block akrobat noCap m-0 mt-2 nothing-found-title">
            OOPS! <span>NOTHING FOUND.</span>
          </h2>
          <p>Please search for another name, title, word, phrase or #tag</p>
        </div>
        <div v-else>
          <div v-if="isLogin">
            <SearchPeople />
          </div>
          <div v-else class="nothing-found">
            <h2 class="inline-block akrobat m-0 mt-2 nothing-found-title">
              OOPS. <span>NOTHING!</span>
            </h2>
            <p>Please try another Name, Post Title, Word, Phrase or #Tag</p>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="posts.length === 0" class="nothing-found">
          <h2 class="inline-block akrobat m-0 mt-2 nothing-found-title">
            OOPS. <span>NOTHING!</span>
          </h2>
          <p>Please try another Name, Post Title, Word, Phrase or #Tag</p>
        </div>

        <div v-else>
          <TileView
            :moduleName="`searchModule`"
            @increment="increment"
            @decrement="decrement"
            @showOverlay="showOverlay"
            :paginate="true"
            :limit="50"
          />
        </div>
      </div>
      <LoaderOverlay v-if="isLoading" />
      <Overlay
        v-if="isOverlay"
        :post="selectedPost"
        :isOverlay="isOverlay"
        :hasNext="hasNext"
        :hasPrev="hasPrev"
        @increment="increment"
        @decrement="decrement"
        @removePost="removePost"
        @close="closeOveraly"
        @showOtherPost="showOtherPost"
      />
      <EditPostOverlay
        v-if="editingPost"
        :post="selectedPost"
        @editPost="editPost"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TileView from "@/components/shared/TileView";
import Overlay from "@/components/shared/overlay/Overlay";
import EditPostOverlay from "../components/shared/overlay/EditPostOverlay.vue";
import SearchPeople from "@/components/SearchPeople";
import LoaderOverlay from "../components/shared/overlay/LoaderOverlay.vue";
import CreateOverlayMixin from "@/mixins/CreateOverlayMixin";
import $ from "jquery";

export default {
  name: "LeaderBoard",
  components: {
    TileView,
    Overlay,
    EditPostOverlay,
    SearchPeople,
    LoaderOverlay,
  },

  mixins: [CreateOverlayMixin],

  data: () => {
    return {
      selectedPostId: 0,
      isOverlay: false,
    };
  },

  computed: {
    ...mapState({
      isLogin: (state) => state.auth.isLogin,
      posts: (state) => state.searchModule.posts,
      filter: (state) => state.searchModule.filter,
      editingPost: (state) => state.uxModule.editingPost,
      searchedPeoples: (state) => state.searchModule.people,
      isLoading: (state) => state.auth.isLoading,
    }),
    hasNext() {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPost.post_id
      );
      if (index + 1 === this.posts.length) return false;
      return true;
    },
    hasPrev() {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPost.post_id
      );
      if (index === 0) return false;
      return true;
    },
    selectedPost() {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPostId
      );
      if (index === -1) return { ...this.posts[0] };
      return { ...this.posts[index] };
    },
  },

  methods: {
    async initialProfileData() {
      const postData = await this.$store.dispatch("searchModule/search");
      this.$store.dispatch("votingModule/setVotes", postData);
    },
    editPost(postData) {
      this.$store.dispatch("searchModule/editPost", { ...postData });
    },
    async increment(postID) {
      const res = await this.$store.dispatch("searchModule/changePressure", {
        post_id: postID,
        amount: 1,
      });
      await this.$store.dispatch("homeModule/voteRecord", postID);
      await this.$store.dispatch("voteCount", {
        post: postID,
        type: "plus",
      });
      if (res.status === 200) {
        this.$store.dispatch("votingModule/setVotes", res.data);
        this.$store.commit("reduce_my_votes");
      }
    },
    async decrement(postID) {
      const res = await this.$store.dispatch("searchModule/changePressure", {
        post_id: postID,
        amount: -1,
      });
      this.$store.dispatch("voteCount", {
        post: postID,
        type: "minus",
      });
      if (res.status === 200) {
        this.$store.dispatch("votingModule/setVotes", res.data);
        this.$store.commit("reduce_my_votes");
      }
    },
    async showOverlay(postId) {
      await this.$store.dispatch("overlayModule/getComments", postId);
      this.selectedPostId = postId;
      this.isOverlay = true;
    },
    async showOtherPost(num) {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPostId
      );
      this.selectedPostId = this.posts[index + num].post_id;
      await this.$store.dispatch(
        "overlayModule/getComments",
        this.posts[index + num].post_id
      );
      await this.$store.dispatch("overlayModule/StarCount", {
        post: this.posts[index + num].post_id,
      });
      if (this.isLogin) {
        await this.$store.dispatch("overlayModule/GetCommentLoveLikeData", {
          post: this.posts[index + num].post_id,
        });
        await this.$store.dispatch("overlayModule/GetLoveLikeData", {
          post: this.posts[index + num].post_id,
        });
      }
      await this.$store.dispatch("overlayModule/PostTrack", {
        post: this.posts[index + num].post_id,
        type: "post",
      });
    },
    closeOveraly() {
      this.isOverlay = false;
      this.$store.dispatch("changeState", ["overlayClass", ""]);
      $("body").removeClass("hero-overlay");
    },
    removePost() {
      this.isOverlay = false;
      this.$store.dispatch("searchModule/removePost", this.selectedPost.uuid);
    },
  },
};
</script>
