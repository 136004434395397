<template>
  <!----------------------------------------TAB 1 ----------------------------------->
  <div id="tabs-1">
    <div
      class="cate-filter mb-30"
      v-b-hover="handleHover"
      v-if="availableCate.length > 0"
    >
      <b-button :class="isHovered ? 'not-collapsed' : 'collapsed'"
        >Categories <span class="icon-angle-down"></span>
      </b-button>
      <div class="selected-cate" v-if="selectedItem">
        <span><span class="icon-filter"></span> {{ selectedItem }} </span>
        <a @click="removeCategory" v-if="selectedItem">
          <i class="icon-cancel-circle"></i
        ></a>
      </div>
      <div v-else class="selected-cate">
        <span><span class="icon-picture"></span> All Posts </span>
      </div>
      <b-collapse id="collapse-1" class="show-cate" v-model="isHovered">
        <b-card>
          <div
            v-for="(cate, index) in availableCate"
            :key="index"
            class="single-cate"
            :class="{ active: selectedItem === cate }"
          >
            <label @click="selectCate(cate)">{{ cate }}</label>
          </div>
        </b-card>
      </b-collapse>
    </div>
    <div class="tabWrap">
      <div class="tx-c">
        <div v-if="posts.length">
          <div>
            <WallTabsWrap @changeTab="changeTab" @getByTitle="getByTitle" />
          </div>
        </div>
        <div v-else>
          <h2 class="akrobat c-999 noCaps">
            <i class="icon-picture"></i> You have no Posts yet.
          </h2>
        </div>
      </div>
      <LoaderOverlay v-if="isLoading" />
      <p v-if="banePostCount > 0">
        <a
          v-if="postToggle === true"
          class="
            show-panel
            ban_post_btn
            button
            secondary
            pr-7
            pl-7
            pt-3
            pb-5
            fs-7
          "
          title="All Posts"
          @click="banPostData"
          >All Posts</a
        >
        <a
          v-else
          class="
            show-panel
            ban_post_btn
            button
            tertiary
            pr-7
            pl-7
            pt-5
            pb-5
            fs-7
          "
          title="Banned Posts"
          @click="banPostData"
          >Banned</a
        >
      </p>
      <ul class="feed list-grid">
        <li v-for="(post, index) in posts" :key="index">
          <div
            class="voter"
            v-if="
              isLogin &&
              showPressure &&
              post.life_support &&
              post.bane_post === false
            "
          >
            <div>
              <div
                class="votes only_plus pp"
                :class="[
                  myVotes && max_votes !== post.post_id ? '' : 'count_up',
                ]"
              >
                <div data-title="Pressure Up" data-title-right>
                  <div
                    v-if="myVotes && max_votes !== post.post_id"
                    class="icon-plus votes-button votes-up tippy"
                    @click="increment(post.post_id)"
                  ></div>
                </div>

                <div class="cst_input" v-if="post.votes > 0">
                  <div
                    data-title="Votes Received"
                    v-if="post.votes > 0"
                    data-title-right
                  >
                    <input
                      type="number"
                      id="post-02-votes"
                      :value="pressures[index]"
                      min="0"
                    />
                    <span>%</span>
                  </div>
                </div>
                <div class="no_count" v-else>
                  <div data-title="Help Me!" data-title-right>NO VOTES YET</div>
                </div>
              </div>
            </div>
          </div>
          <div class="imgWrap no-shadow">
            <div class="post-info-block">
              <div class="trash-Wrp" @click="removeUserPost(post.uuid)">
                <div data-title="Remove" data-title-left>
                  <div class="trash icon-trash"></div>
                </div>
              </div>
            </div>

            <LifeRemainingBarComponent
              v-if="post.life_support"
              :expiration="post.expiration"
              :life_remaining="post.life_support_remaining"
            />
            <div
              v-if="post.mature_content"
              class="mature-audience"
              style="display: block"
              @click="checkIfLogin(post.post_id)"
            >
              <div>
                <div>
                  <i class="icon-eye-off"></i>
                </div>
                <p class="mature">MATURE AUDIENCE</p>
                <span>Content may be sensitive</span>
              </div>
            </div>
            <div
              class="thank_you"
              :class="[post.post_id === post_id ? 'show' : '']"
              :id="`msgID${post.post_id}`"
            >
              <canvas class="confetti" :id="`canvas${post.post_id}`"></canvas>

              <div class="message">
                <p>THANK YOU!</p>
                <span>This Post is now on the Leaderboard</span>
              </div>
            </div>
            <div @click="showOverlay(post.post_id)">
              <div class="prevent-vid-click"></div>
              <div v-if="post.is_video && post.videos" class="vid-arrow">
                <div data-title="Video" data-title-top>
                  <span>{{ post.videos.length }}</span>
                </div>
              </div>
            </div>
            <div class="daynamic-img-warp">
              <img v-if="!post.is_video" :src="post.images[0].url" alt />
              <iframe
                v-else
                width="560"
                height="315"
                :src="post.videos[0].url"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <div class="self-count-info">
                <p v-if="post.open_count">Opens: {{ post.open_count }}</p>
                <p v-if="post.vote_count">
                  <span data-title="Up Votes" data-title-top>
                    <i class="icon-right-hand">{{ post.vote_count }}</i>
                  </span>
                </p>
                <p v-if="post.star_count">
                  <span data-title="Star Received" data-title-top>
                    <i class="icon-star">{{ post.star_count }}</i>
                  </span>
                </p>
                <p v-if="post.like_count">
                  <span data-title="Likes" data-title-top>
                    <i class="icon-thumbs-up">{{ post.like_count }}</i>
                  </span>
                </p>
                <p v-if="post.love_count">
                  <span data-title="Love" data-title-top>
                    <i class="icon-fire">{{ post.love_count }}</i>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="author with-tag">
            <span class="asign-cate">
              <span
                v-for="(cat, index) in post.category"
                :key="index"
                :title="cat"
                @click="selectCate(cat)"
                >{{ cat }}</span
              >
            </span>
            <span>
              <span class="user mr-5">
                <i class="icon-user"></i>
                <a
                  class="anti-a mr-0"
                  :title="`View ${post.author}`"
                  @click="goToProfile(index)"
                  >{{ post.author }}</a
                >
                <span>
                  <a
                    :href="link"
                    :data-title="toCapital(post.author_theme)"
                    data-title-left
                    class="user-trophy"
                    :class="trophyClass(post.author_theme)"
                  >
                    <i class="icon-award tiny"></i>
                  </a>
                </span>
              </span>
              <span class="date">
                <i class="icon-calendar"></i>
                {{ postTimeAgo(post.post_id) }}
              </span>
            </span>
            <span :data-title="post.author" data-title-left class="tip-user">
              <a class="avatar">
                <!-- <div
                  v-if="isLogin"
                  class="on-off"
                  :class="[post.author_status === true ? 'on-now' : '']"
                  :data-title="
                    post.author_status === true ? 'Online' : 'Offline'
                  "
                  data-title-right
                ></div> -->
                <img
                  :src="post.author_img"
                  :alt="post.author"
                  :class="
                    post.author_class === 'no-border' ? 'avatar-no-border' : ''
                  "
                />
              </a>
            </span>
          </div>
          <h3>
            <a
              v-if="post.title && post.title.length < 45"
              class="show-panel"
              :title="post.title"
              @click="showOverlay(post.post_id)"
              >{{ post.title }}</a
            >
            <a
              v-else
              class="show-panel"
              :title="post.title"
              @click="showOverlay(post.post_id)"
              >{{ post.title.substring(0, 45) + ".." }}</a
            >
          </h3>
          <p v-if="post.summary && post.summary.length < 100" class="summary">
            {{ post.summary }}
          </p>
          <p v-else class="summary">
            {{ post.summary.substring(0, 100) + "..." }}
          </p>
          <b-modal
            :id="'bv-modal-example' + post.post_id"
            hide-footer
            v-model="postBanModal[post.post_id]"
            dialog-class="bane-modal"
          >
            <div class="d-block text-center">
              <h3 class="akrobat">POST REMOVED</h3>
            </div>
            <p class="fs-8 tx-c">
              Your Post has received multiple complaints and breaches O2 Rules
              and Terms of Service.
            </p>
            <p class="fs-8 tx-c">
              If you disagree with this ruling you can ask for a review from O2
              Admin
            </p>
            <b-button
              class="mt-3"
              block
              @click="contactAdmin(post.post_id, post.author_email)"
              >Contact Admin</b-button
            >
            <p class="fs-7 tx-c">
              Post reviews may take several days. The issue is then closed.
            </p>
          </b-modal>
        </li>
        <div class="btn_center_sec">
          <a class="load_more_btn" v-if="loadMoreToggle" @click="loadMorePosts"
            >Show more <span class="icon-down-open"></span></a
          >
        </div>
      </ul>
    </div>

    <Overlay
      v-if="isOverlay"
      :post="selectedPost"
      :isOverlay="isOverlay"
      :hasNext="hasNext"
      :hasPrev="hasPrev"
      @increment="increment"
      @close="closeOveraly"
      @removePost="removePost"
      @showOtherPost="showOtherPost"
    />
    <EditPostOverlay
      v-if="editingPost"
      :post="selectedPost"
      @editPost="editPost"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Overlay from "@/components/shared/overlay/Overlay";
import EditPostOverlay from "@/components/shared/overlay/EditPostOverlay.vue";
import LoaderOverlay from "@/components/shared/overlay/LoaderOverlay.vue";
import LifeRemainingBarComponent from "../shared/LifeRemainingBarComponent.vue";
import * as moment from "moment";
import WallTabsWrap from "../../components/shared/WallTabsWrap.vue";
import showLeaderMessageMixin from "@/mixins/showLeaderMessageMixin";
import { BButton, BModal } from "bootstrap-vue";
import $ from "jquery";

export default {
  name: "Posts",
  components: {
    Overlay,
    EditPostOverlay,
    LoaderOverlay,
    LifeRemainingBarComponent,
    WallTabsWrap,
    BButton,
    BModal,
  },

  data: () => {
    return {
      selectedPostId: 0,
      isOverlay: false,
      post_id: "",
      banPosts: false,
      ban_post_toggle: false,
      postBanModal: {},
      isHovered: false,
    };
  },

  mixins: [showLeaderMessageMixin],

  computed: {
    ...mapState({
      isLogin: (state) => state.auth.isLogin,
      isLoading: (state) => state.auth.isLoading,
      totalVotes: (state) => state.votingModule.totalVotes,
      min_votes: (state) => state.votingModule.min_votes,
      max_votes: (state) => state.votingModule.max_votes,
      isListView: (state) => state.common.isListView,
      myVotes: (state) => state.auth.user.votes,
      showPressure: (state) => state.common.showPressure,
      posts: (state) => state.userModule.posts,
      editingPost: (state) => state.uxModule.editingPost,
      pageNumber: (state) => state.userModule.pageNumber,
      totalPosts: (state) => state.userModule.totalPosts,
      favoriteTab: (state) => state.staticLabels.favoriteTab,
      activeTab: (state) => state.base.activeTab,
      toggleShow: (state) => state.userModule.toggleShow,
      postToggle: (state) => state.userModule.postToggle,
      banePostCount: (state) => state.userModule.banePostCount,
      banPageNumber: (state) => state.userModule.banPageNumber,
      userEmail: (state) => state.auth.user.email,
      selectedItem: (state) => state.common.selectedItem,
      categoryList: (state) => state.common.categoryList,
      availableCate: (state) => state.common.availableCate,
    }),
    pressures() {
      let pressures = [];
      this.posts.map((post) => {
        switch (post.post_id) {
          case this.max_votes:
            pressures.push(99.99);
            break;
          case this.min_votes:
            pressures.push(0.01);
            break;
          default:
            if (this.totalVotes) {
              pressures.push(
                (
                  (post.votes / this.totalVotes) * 100 +
                  post.post_id * 0.01
                ).toFixed(2)
              );
            } else {
              pressures.push(0.0);
            }
            break;
        }
      });
      return pressures;
    },
    hasNext() {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPost.post_id
      );
      if (index + 1 === this.posts.length) return false;
      return true;
    },
    hasPrev() {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPost.post_id
      );
      if (index === 0) return false;
      return true;
    },
    selectedPost() {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPostId
      );
      if (index === -1) return { ...this.posts[0] };
      return { ...this.posts[index] };
    },
    loadMoreToggle() {
      const limit = 10;
      if (this.postToggle === true) {
        return Math.ceil(this.totalPosts / limit) > this.banPageNumber + 1
          ? true
          : false;
      } else {
        return Math.ceil(this.totalPosts / limit) > this.pageNumber + 1
          ? true
          : false;
      }
    },
    link() {
      return process.env.VUE_APP_PREFIX + "/themes";
    },
  },

  async mounted() {
    await this.initialPostData();
    this.$store.dispatch("allCategory", { module: "User" });
    this.$store.commit("set_selected_cate", localStorage.getItem("category"));
  },

  methods: {
    handleHover(hovered) {
      this.isHovered = hovered;
    },
    async selectCate(cate) {
      this.isHovered = false;
      localStorage.setItem("category", cate);
      this.$store.commit("set_selected_cate", cate);
      await this.initialPostData();
      await this.$store.dispatch("getLeaders");
    },
    async removeCategory() {
      localStorage.removeItem("category");
      this.$store.commit("set_selected_cate", "");
      await this.initialPostData();
      await this.$store.dispatch("getLeaders");
    },
    imgCount(imgLen, videoLen) {
      return imgLen - 1 + videoLen;
    },
    async increment(postID) {
      const res = await this.$store.dispatch("userModule/changePressure", {
        post_id: postID,
        amount: 1,
      });
      const index = this.posts.findIndex((post) => post.post_id === postID);
      this.showLeaderMsg(postID, this.posts[index].votes, "myprofile");
      this.conffetti(postID);
      await this.$store.dispatch("getLeaders");
      await this.$store.dispatch("homeModule/voteRecord", postID);
      this.$store.dispatch("votingModule/setVotes", res.data);
      this.$store.commit("reduce_my_votes");
    },
    async initialPostData() {
      this.$store.dispatch("resetActiveTab");
      const postData = await this.$store.dispatch("userModule/getUserPost", {
        activeTab: 0,
        pageNumber: 0,
        selectedCategory: localStorage.getItem("category"),
      });
      this.$store.commit("userModule/set_count", {
        winner_count: postData.winner_count,
        top10_count: postData.top10_count,
        my_profile_theme: postData.user_themes,
        element_theme: postData.elementTheme,
      });
      this.$store.dispatch("votingModule/setVotes", postData);
      await this.$store.dispatch("getMyDraft", 0);
      await this.$store.dispatch("userModule/getUnreadCount");
    },
    async banPostData() {
      this.ban_post_toggle = !this.ban_post_toggle;
      await this.$store.dispatch("userModule/banPostData", {
        postToggle: this.ban_post_toggle,
        pageNumber: 0,
      });
    },
    async loadMoreBanPosts() {
      await this.$store.dispatch("userModule/banPostData", {
        postToggle: this.toggleShow,
        pageNumber: this.pageNumber,
      });
    },
    async changeTab(index) {
      const postData = await this.$store.dispatch("userModule/getUserPost", {
        activeTab: index,
        pageNumber: 0,
        selectedCategory: localStorage.getItem("category"),
      });
      this.$store.dispatch("votingModule/setVotes", postData);
      this.$store.dispatch("setActiveTab", index);
    },
    async getByTitle(isAtoZ) {
      await this.$store.dispatch("userModule/getUserPost", {
        show: isAtoZ,
        activeTab: 2,
        pageNumber: 0,
      });
    },
    async contactAdmin(post_id, email) {
      const data = {
        postID: post_id,
        userEmail: email,
      };
      const response = await this.$store.dispatch(
        "overlayModule/contactAdmin",
        data
      );
      this.postBanModal = {
        ...this.postBanModal,
        [post_id]: false,
      };
      await this.$store.dispatch("showToast", {
        message: response.data.message,
      });
    },
    postTimeAgo(postId) {
      const post = this.posts.find((post) => post.post_id === postId);
      if (!post) {
        return;
      }
      const now = moment.utc()._d;
      const creat_at =
        moment(post.post_date).format("DD/MM/YYYY") +
        " " +
        moment(post.post_date).format("HH:mm:ss");
      const curr_time =
        moment(now).format("DD/MM/YYYY") + " " + moment(now).format("HH:mm:ss");
      var ms = moment(curr_time, "DD/MM/YYYY HH:mm:ss").diff(
        moment(creat_at, "DD/MM/YYYY HH:mm:ss")
      );
      var d = moment.duration(ms);
      var days = Math.floor(d.asDays());
      var seconds = Math.floor(d.asSeconds());
      if (days === 0 && seconds >= 0 && seconds < 60) {
        if (seconds === 0) {
          return "few second ago";
        } else {
          return seconds + "s";
        }
      }

      if (days === 0 && seconds >= 60 && seconds < 3600) {
        let minutes = Math.floor(seconds / 60);
        if (minutes === 0 && seconds === 60) {
          return " minute ago";
        } else {
          return minutes + "m";
        }
      }

      if (days === 0 && seconds >= 3600 && seconds < 86400) {
        let hours = Math.floor(seconds / 3600);
        if (hours === 0) {
          return "hour ago";
        } else {
          return hours + "h";
        }
      }

      //  1 day to 30 days
      if (days >= 1 && days < 30) {
        return days + "d";
      }

      if (days >= 30 && days < 35) {
        let weeks = Math.floor(days / 7);
        return weeks + "w";
      }

      if (days >= 30 && days < 365) {
        let months = Math.floor(days / 30);
        return months + "mo";
      }

      if (days >= 365) {
        let years = Math.floor(days / 365);
        return years + "y";
      }
    },
    editPost(postData) {
      this.$store.dispatch("userModule/editPost", { ...postData });
    },
    async showOverlay(post_id) {
      const index = this.posts.findIndex((post) => post.post_id === post_id);
      if (this.posts[index].bane_post === true) {
        this.postBanModal = {
          ...this.postBanModal,
          [post_id]: true,
        };
        await this.$store.dispatch("changeState", ["overlayClass", ""]);
      } else {
        await this.$store.dispatch("overlayModule/getComments", post_id);
        await this.$store.dispatch("changeState", ["overlayClass", "opened"]);
        this.selectedPostId = post_id;
        this.isOverlay = true;
      }
    },
    async showOtherPost(num) {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPostId
      );
      this.selectedPostId = this.posts[index + num].post_id;
      await this.$store.dispatch(
        "overlayModule/getComments",
        this.posts[index + num].post_id
      );
      await this.$store.dispatch("overlayModule/StarCount", {
        post: this.posts[index + num].post_id,
      });
      if (this.isLogin) {
        await this.$store.dispatch("overlayModule/GetCommentLoveLikeData", {
          post: this.posts[index + num].post_id,
        });
        await this.$store.dispatch("overlayModule/GetLoveLikeData", {
          post: this.posts[index + num].post_id,
        });
      }
      await this.$store.dispatch("overlayModule/PostTrack", {
        post: this.posts[index + num].post_id,
        type: "post",
      });
    },
    closeOveraly() {
      this.isOverlay = false;
      this.$store.dispatch("changeState", ["overlayClass", ""]);
      $("body").removeClass("hero-overlay");
    },
    async removeUserPost(uuid) {
      await this.$store.dispatch("userModule/removePost", uuid);
      await this.$store.dispatch("userModule/getUnreadCount");
    },
    async removePost() {
      this.isOverlay = false;
      await this.$store.dispatch(
        "userModule/removePost",
        this.selectedPost.uuid
      );
      await this.$store.dispatch("userModule/getUnreadCount");
    },
    checkIfLogin(post_id) {
      if (this.isLogin) this.showOverlay(post_id);
    },
    goToProfile(index) {
      let $this = this;
      let post = $this.posts[index];
      if ($this.userEmail === post.author_email) {
        $this.$router.push({ name: "User" });
      } else {
        $this.$router.push({
          name: "Profile",
          params: {
            uuid: post.author_uuid,
          },
        });
      }
    },
    async loadMorePosts() {
      if (this.postToggle === true) {
        await this.$store.dispatch("userModule/loadMoreBanPosts", {
          postToggle: this.postToggle,
          pageNumber: this.banPageNumber,
        });
      } else {
        await this.$store.dispatch("userModule/getLoadedUserPost", {
          show: this.toggleShow,
          pageNumber: this.pageNumber,
          activeTab: this.activeTab,
          selectedCategory: localStorage.getItem("category"),
        });
      }
    },
    trophyClass(theme) {
      if (theme === "gold") {
        return "gold-trophy";
      } else if (theme === "platinum") {
        return "platinum-trophy";
      } else if (theme === "diamond") {
        return "diamond-trophy";
      } else {
        return "";
      }
    },
    toCapital(author_theme) {
      return (
        author_theme[0].toUpperCase() + author_theme.slice(1) + " " + "Member"
      );
    },
  },
};
</script>
