<template>
  <div class="read-panel site-bug" :class="{ open: isOpen === 3 }">
    <h4 class="p-head blue">Earn 250 Tokens</h4>
    <p>
      <strong>Help us to improve O2</strong>
      <br />If you find any site bugs, or have any site improvement suggestions,
      please let us know. If it's legit we'll give you 250 Tokens!
      <em>(Nice!)</em>
    </p>
    <div>
      <form
        id="reportPostForm"
        action
        method="post"
        onsubmit="return send_request()"
      >
        <p>
          <strong>URL or webpage</strong>
        </p>
        <div
          class="gen-inputs"
          :class="this.linkValidationText ? 'is-invalid' : ''"
        >
          <div class="block">
            <label for="add-title" class="offset">https://</label>
            <input
              v-model="site_url"
              ref="report_post_url"
              type="text"
              placeholder="https://"
              @blur="validateURL(site_url)"
            />
          </div>
        </div>

        <p class="error-message" v-if="linkValidationText">
          <i class="icon-attention-circled">{{ linkValidationText }}</i>
        </p>
        <p>
          <strong>Describe the problem or idea</strong>
        </p>
        <div class="gen-inputs cst-textarea">
          <div class="block">
            <label for="add-content" class="offset"
              >What is the issue or how does it happens ...</label
            >
            <textarea
              v-model="report_content"
              id="add-content"
              placeholder="Details ..."
              rows="5"
            ></textarea>
          </div>
          <div class="footer-btn-wrap">
            <div id="browse-files-1" class="cst-file-btn">
              <div class="new-img-wrap" v-if="urls.length">
                <img class="new-img" :src="urls[0]" alt />
              </div>
              <a
                class="button reverse"
                title="Browse for images"
                v-if="!urls.length"
              >
                Attach Image
                <input
                  title="Browse for image"
                  type="file"
                  accept="image/*"
                  multiple="multiple"
                  ref="imageFiles1"
                  @change="browseImages"
                  :disabled="urls.length === 1"
                />
              </a>
            </div>
            <div class="submit-btn">
              <a
                class="post button secondary"
                @click="submit"
                :disabled="disableSubmit"
                >Submit</a
              >
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="promo1 mt-20 mb-10" v-if="successToggle === true">
      <p>
        <strong
          >Your Report has been sent.<br />Thank you for your support.</strong
        >
      </p>
    </div>
    <div class="fs-8 note-text">
      <span class="icon-heart red"></span
      ><strong
        >We're just a small team of passionate working-class heroes,</strong
      >
      so your support is really appreciated in helping us to
      improve the O2 experience. Thanks!
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ReportPostComponent",

  props: ["isOpen"],

  computed: {
    disableSubmit() {
      if (this.report_content !== "") {
        return false;
      }
      return true;
    },
    validateLink() {
      const expression =
        /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

      if (this.site_url) {
        if (expression.test(this.site_url)) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
  },

  data() {
    return {
      site_url: "",
      report_content: "",
      errorMessage: "",
      successToggle: false,
      files: [],
      urls: [],
      linkValidationText: "",
      validation: true,
    };
  },

  watch: {
    isOpen() {
      this.$nextTick(() => {
        if (this.isOpen !== 3) this.errorMessage = "";
      });
    },
  },

  methods: {
    browseImages() {
      let bugImages = [];
      bugImages = this.$refs.imageFiles1.files;
      for (let i = 0; i < bugImages.length; i++) {
        let file = bugImages[i];
        this.urls.push(URL.createObjectURL(file));
        this.files.push(file);
      }
    },
    validateURL(value) {
      if (value === "") {
        this.validation = true;
      } else {
        this.validation = this.validateAdditionalLink(value);
      }
      if (this.validation === true) {
        this.linkValidationText = "";
      } else {
        this.linkValidationText = "Please enter valid Link";
      }
    },
    validateAdditionalLink(data) {
      const expression =
        "^(http://www.|http://|https://www.|https://|ftp://www.|ftp://|www.){1}([0-9A-Za-z]+.)([A-Za-z]){2,3}(/)?";

      var regex = new RegExp(expression);
      var t = data;
      if (data) {
        if (t.match(regex)) {
          return true;
        } else {
          return false;
        }
      }
    },
    submit() {
      if (!this.disableSubmit && this.linkValidationText === "") {
        let data = new FormData();
        data.append("text", this.report_content);
        data.append("site_url", this.site_url);
        data.append("file[" + 0 + "]", this.files[0]);
        axios({
          url: "auth/user/report-site-bug/",
          data: data,
          method: "POST",
        })
          .then((response) => {
            if (response.status === 200) {
              this.site_url = "";
              this.report_content = "";
              this.urls = [];
              this.files = [];
              this.successToggle = true;
              setTimeout(() => {
                this.successToggle = false;
              }, 3000);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    removeFile(index) {
      this.files.splice(index, 1);
      this.urls.splice(index, 1);
    },
  },
};
</script>