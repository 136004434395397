<template>
  <div>
    {{ $route.params.uid }}
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Activation",
  created() {
    let $this = this;
    axios({
      url: "auth/user/activate/",
      data: {
        uid: $this.$route.params.uid,
        token: $this.$route.params.token,
      },
      method: "POST",
    })
      .then((response) => {
        if (response.status === 200) {
          let user = {
            email: localStorage.getItem("email"),
            password: localStorage.getItem("password"),
          };
          $this.$store.dispatch("login", user);

          $this.$router.push({ name: "Home" });
          $this.$store.dispatch("setMyOzone", 4);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>