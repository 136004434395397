<template>
  <div class="post-life">
    <div
      class="bar"
      :class="calExpiration"
      :data-title="`${limit2numbers} of O2 remains`"
      :style="`top:${expiration}%`"
      data-title-right
    ></div>
  </div>
</template>

<script>
import * as moment from "moment";

export default {

  props: {
    expiration: {
      type: Number,
    },
    life_remaining: {
      type: Number,
    },
  },

  computed: {
    calExpiration() {
      let color = this.expiration < 70.0 ? "blue" : "red";
      return color;
    },
    limit2numbers() {
      var duration = moment.duration(this.life_remaining, "hours");
      const d = moment.duration(duration);
      const days = Math.abs(d._data.days);
      const hours = Math.abs(d._data.hours);
      const minutes = Math.abs(d._data.minutes);
      return days + "d" + ":" + hours + "h" + ":" + minutes + "m";
    },
  },
};
</script>