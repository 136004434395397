<template>
  <div></div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "OpenedOverlay",
  computed: {
    ...mapState({
      sharedOverlayClass: (state) => state.common.sharedOverlayClass,
      overlayPost: (state) => state.common.overlayPost,
    }),
  },

  data() {
    return {
      metaTags: ["og:title", "og:description"],
    };
  },

  created() {
    this.overlayBYUUID();
  },

  watch: {
    overlayClass() {
      if (this.overlayClass === "") {
        this.$router.push({ name: "Home" });
      }
    },
  },
  methods: {
    async overlayBYUUID() {
      const response = await this.$store.dispatch("openOverlayByUUID", {
        uuid: this.$route.params.uuid,
      });
      this.$store.dispatch("setOverlayPostByUrl",  response.post_data[0]);
      const $body = document.body;
      $body.classList.add("overlay-open");
      this.$store.dispatch("setSharedOverlayClass", "opened");
      if (localStorage.getItem("accessToken")) {
        this.$store.dispatch("setMyOzone", 1);
      }
      const $metas = document.getElementsByTagName("meta");
      $metas.forEach((element) => {
        let property = element.getAttribute("property");
        if (this.metaTags.includes(property)) {
          element.setAttribute("content", response.post_data.title);
        }
      });
    },
  },
};
</script>