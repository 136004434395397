export const state = {
  editingPost: false,
};

export const mutations = {
  EDITING_POST(state, payload) {
    state.editingPost = payload;
  },
};

export const getters = {};

export const actions = {
  editingPost({ commit }, data) {
    commit("EDITING_POST", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
