<template>
  <div id="tokenWrap" ref="boost">
    <div class="tok-btnWrap" v-if="!isLogin">
      <a
        data-title="Send Post to Top of Leaderboard"
        data-title-top
        class="button filter tertiary pl-5 pr-5 fullwidth"
        @click="notMember()"
      >
        <i class="icon-rocket"></i>BOOST TO TOP<span class="arrow"></span>
      </a>
    </div>
    <div class="tok-btnWrap clr" v-if="isLogin">
      <a
        v-if="user.email === author"
        data-title="Extend this Post's Life"
        data-title-top
        class="button filter tertiary pl-5 pr-5 tw-l"
        :class="{ open: this.addMoreTime }"
        @click="openAddMoreDayData"
        :disabled="disableAddMore || disable"
      >
        <i class="icon-calendar"></i>ADD MORE TIME<span
          class="arrow hide380"
        ></span>
      </a>
      <a
        data-title="Send Post to Top of Leaderboard"
        data-title-top
        class="button filter tertiary pl-5 pr-5 tw-r"
        :class="{ fullwidth: this.user.email !== author, open: this.boostTop }"
        :disabled="disableBoostTop"
        @click="openBoostTopData"
      >
        <i class="icon-rocket"></i>BOOST TO TOP<span
          class="arrow hide380"
        ></span>
      </a>
    </div>
    <div class="tok-notloggedin" v-if="notLogin">
      <div class="alert-tokens red">
        <h2 class="akrobat">OOPs! You need to Login first.</h2>
      </div>
      <div class="tok-notloggedin-wrap">
        <a
          title="Login"
          class="show-panel button primary icon-lock-open"
          data-mobile="true"
          @click="hideSidebar(0)"
        >
          Login
        </a>
        <a
          title="Sign up"
          class="show-panel button primary icon-lock"
          @click="hideSidebar(3)"
        >
          Sign up
        </a>
      </div>
    </div>

    <div id="time-wrapper">
      <div class="more-time" v-if="user.email === author && addMoreTime">
        <div class="tok-day-header">
          <h5 class="tx-c">ADD MORE TIME</h5>
          <label class="akrobat">Make your Post live longer - Add oxygen</label>
          <span class="fs-8">You can add more time only once per Post</span>
        </div>

        <div
          class="tok-day-wrap"
          v-for="(item, index) in addMoreDays"
          :key="index"
        >
          <div class="tok-days">
            <div class="one">
              <i class="icon-plus"></i>{{ item.days }} <span>Day</span>
            </div>
            <div class="two">
              <span class="tok-icon-gen-white ml-10">
                <span>O<sup>2</sup></span>
              </span>
              {{ item.token_cost }}
              <span>Tokens</span>
            </div>
            <div class="three" v-b-toggle.collapse-1>
              <a
                class="button primary"
                :title="`Add: ${item.days} Extra Day of Time to this Post`"
                @click="addDays(index)"
                >Add</a
              >
            </div>
          </div>
          <b-collapse
            id="collapse-1"
            v-if="confirmDiv === index"
            class="add-time-confirm"
          >
            <h3>Are you sure to add more time?</h3>
            <div class="t-confirm-wrap">
              <a
                title="Confirm"
                class="button primary"
                @click="confirmDay(index)"
              >
                Confirm
              </a>
              <a title="Cancel" class="button tertiary" @click="cancel">
                Cancel
              </a>
            </div>
          </b-collapse>
          <b-collapse
            id="collapse-2"
            v-if="addMoreErorr === index"
            class="add-time-confirm"
          >
            <div class="alert-tokens">
              <h4>Oops, you need more tokens.</h4>
              <div class="tx-c">
                <h3>
                  You have<span>{{ user.token }} Tokens</span>available
                </h3>
                <!-- <router-link
                  to="/tokens"
                  class="button primary mt--5 pr-15 pl-10"
                  title="Button"
                  ><span class="tok-icon-gen-white"
                    ><span>O<sup>2</sup></span></span
                  >Get More Tokens</router-link
                > -->
              </div>
            </div>
          </b-collapse>
        </div>
        <div class="benefits">
          <label>Benefits of adding more time to your Posts.</label>
          <ul>
            <li>It allows more time for your Post to be voted on</li>
            <li>
              It allows more time to get into the Top 10 (Win Rewards & Tokens)
            </li>
            <li>More time for people to see and share your Post</li>
            <li>More promotion before your Post disappears</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="tok-notloggedin tx-c" v-if="addMoreSuccess">
      <h2 class="akrobat">CONGRATULATIONS!</h2>
      <strong
        >Your Post now has
        <span class="mid-color">{{ extraDay }} more day(s)</span> of Life
        Support.</strong
      >
    </div>

    <!---BOOST TO TOP ---->
    <div id="pressure-wrapper" v-if="boostTop">
      <div class="more-pressure pb-15">
        <h5>BOOST TO TOP</h5>
        <p class="akrobat">
          One click sends this post to the top of the leaderboard
        </p>
        <a
          class="button primary mt--5 pr-15 pl-10"
          title="Send it Now"
          @click="sendPostTop"
          ><span class="icon-rocket"> Send it now</span></a
        >
        <div class="tok-cost mt-5">
          <span class="tok-icon-gen-white">
            <span>O<sup>2</sup></span></span
          >{{ tokenAmount }} Tokens
        </div>
      </div>
      <!-- failure div -->
      <div class="alert-tokens" v-if="errorDiv">
        <h2>Oops, you need more tokens.</h2>
      </div>
      <div class="current-tkns tx-c" v-if="errorDiv">
        <h2>You have {{ user.token }} Tokens available</h2>
        <!-- <a class="button primary mt--5 pr-15 pl-10" title="Button">
          <span class="tok-icon-gen-white"
            ><span>O<sup>2</sup></span></span
          >
          <router-link to="/tokens"> Get More Tokens</router-link>
        </a> -->
      </div>
      <!-- success div -->
      <div class="tok-notloggedin tx-c mb-40" v-if="successDiv">
        <h2 class="akrobat">CONGRATULATIONS!</h2>
        <p>
          <strong
            >This Post is now in TOP position on the
            <a href="/leaderboard">Leaderboard</a></strong
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import $ from "jquery";
import changeMyOzoneMixin from "@/mixins/ChangeMyOzoneMixin";
import axios from "axios";
import moment from "moment-timezone";

export default {
  name: "BoostToTop",

  props: ["post_id", "author", "add_time", "life"],

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      isLogin: (state) => state.auth.isLogin,
      max_votes: (state) => state.votingModule.max_votes,
    }),
    disableBoostTop() {
      let saturday = moment().isoWeekday(6).format("DD-MM-YYYY 23:59:59");
      let sunday = moment().endOf("week").format("DD-MM-YYYY 23:59:59");
      const now = moment.utc()._d;
      let current = moment(now).format("DD-MM-YYYY HH:mm:ss");
      if (this.life) {
        if (current >= saturday && current <= sunday) {
          return true;
        } else {
          if (
            this.max_votes === this.post_id ||
            this.leader_id === this.post_id
          ) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return true;
      }
    },
    disableAddMore() {
      if (this.life) {
        if (this.add_time || this.disable) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },

  mixins: [changeMyOzoneMixin],

  data() {
    return {
      notLogin: false,
      successDiv: false,
      errorDiv: false,
      boostTop: false,
      tokenAmount: 0,
      leader_id: "",
      addMoreTime: false,
      confirmDiv: "",
      addMoreErorr: "",
      addMoreSuccess: false,
      addMoreDays: [],
      disable: false,
      extraDay: 0,
    };
  },

  mounted() {
    this.tokenCost();
    this.addMoreDayData();
  },

  methods: {
    openAddMoreDayData() {
      this.boostTop = false;
      this.addMoreTime = !this.addMoreTime;
    },
    openBoostTopData() {
      this.addMoreTime = false;
      this.boostTop = !this.boostTop;
    },
    async notMember() {
      if (this.isLogin) {
        return "";
      } else {
        this.notLogin = true;
        setTimeout(() => {
          this.notLogin = false;
        }, 20000);
      }
    },
    async tokenCost() {
      await axios({
        url: "user/token/boost-post/",
        method: "GET",
      })
        .then((response) => {
          this.tokenAmount = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async sendPostTop() {
      const data = {
        post: this.post_id,
      };
      await axios({
        url: "user/token/boost-post/",
        method: "POST",
        data: data,
      })
        .then((response) => {
          if (response.data.status === 200) {
            this.successDiv = true;
            setTimeout(() => {
              this.successDiv = false;
              this.boostTop = false;
            }, 6000);
            this.$store.dispatch("getLeaders");
            let set_max_vote = response.data.p_data;
            set_max_vote["max_votes"] = response.data.post_id;
            this.$store.dispatch("votingModule/setVotes", set_max_vote);
            if (response.data.type === "boost") {
              this.$store.dispatch("showToast", {
                message:
                  "you have used 1 boost. Now, you have" +
                  " " +
                  (this.user.rewarded_boost - 1) +
                  " available boost.",
              });
              this.$store.commit(
                "set_user_boost",
                this.user.rewarded_boost - 1
              );
            } else {
              this.$store.commit(
                "set_user_token",
                this.user.token - this.tokenAmount
              );
            }
            this.leader_id = response.data.post_id;
          } else {
            this.errorDiv = true;
            setTimeout(() => {
              this.successDiv = false;
            }, 5000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async addMoreDayData() {
      await axios({
        url: "user/token/add-post-days/?post_id=" + this.post_id,
        method: "GET",
      })
        .then((response) => {
          this.addMoreDays = response.data.data;
          this.disable = response.data.post;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async addDays(index) {
      this.addMoreErorr = "";
      this.confirmDiv = index;
    },
    async cancel() {
      this.confirmDiv = "";
      this.addMoreTime = false;
    },
    async confirmDay(index) {
      const data = {
        post: this.post_id,
        scheme: this.addMoreDays[index].id,
      };
      await axios({
        url: "user/token/add-post-days/",
        method: "POST",
        data: data,
      })
        .then((response) => {
          const index = this.addMoreDays.findIndex(
            (item) => item.id === response.data.data.scheme
          );
          if (response.data.status === 200) {
            this.cancel();
            this.disable = true;
            this.extraDay = response.data.data.days;

            this.$store.commit(
              "set_user_token",
              this.user.token - this.addMoreDays[index].token_cost
            );
            this.$store.dispatch("homeModule/getAllAvailablePosts", {
              activeTab: 0,
              pageNumber: 0,
              selectedCategory: localStorage.getItem("category"),
            });
            this.$store.dispatch("showToast", {
              message: response.data.message,
            });
            this.addMoreSuccess = true;
            setTimeout(() => {
              this.addMoreSuccess = false;
            }, 5000);
          } else {
            this.confirmDiv = "";
            this.addMoreErorr = index;
            setTimeout(() => {
              this.addMoreErorr = "";
            }, 5000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    hideSidebar(num) {
      // this.$emit("close");
      let $this = this;
      $this.changeMyOzone(num);
      $("body").addClass("mpanel-open hidesb");
      $("body").removeClass("hero-overlay");
      $(".logged-out").addClass("hidesb");
      $(".ozone-column").addClass("moved");
      $(".my-ozone").addClass("moved");
      $this.$store.commit("change_state", ["mnav_class", "move"]);
      setTimeout(function () {
        $this.$store.commit("change_state", ["mnav_close_class", "slidem"]);
      }, 350);
    },
  },
};
</script>