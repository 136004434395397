<template>
  <div
    class="fearless-leaders no-ws"
    :class="{ listview: isListView, 'display-flex': !isListView }"
  >
    <div class="tx-ctr" v-if="this.$route.path === '/leaderboard'">
      <div v-if="posts.length === 0 && this.activeTab === 0">
        <h2 class="akrobat c-999 noCaps">
          <i class="icon-chart-bar"></i>No Leaders yet.<br /><br />Vote on a
          Post in the Pressure Pit to put it on the Leaderboard.
        </h2>
      </div>
      <div v-else-if="posts.length === 0 && this.activeTab === 1">
        <h2 class="akrobat c-999 noCaps">
          <i class="icon-chart-line"></i> Nothing is Trending yet today.<br /><br />Vote
          on a Post to give it a push!
        </h2>
      </div>
      <div v-else-if="posts.length === 0 && this.activeTab === 2">
        <h2 class="akrobat c-999 noCaps">
          <i class="icon-chart-line"></i> Nothing is Trending yet today.<br /><br />Vote
          on a Post to give it a push!
        </h2>
      </div>
      <div v-else-if="posts.length === 0 && this.activeTab === 3">
        <h2 class="akrobat c-999 noCaps">
          <i class="icon-chart-line"></i> Nothing is Trending yet today.<br /><br />Vote
          on a Post to give it a push!
        </h2>
      </div>
    </div>
    <div class="tx-ctr" v-if="this.$route.path === '/favoriteposts'">
      <div v-if="posts.length === 0 && this.activeTab === 0">
        <h2 class="akrobat c-999 noCaps">
          You have no Favourites yet.<br /><br />Make someone happy and
          <i class="icon-heart"></i> their Post!
        </h2>
      </div>
      <div v-else-if="posts.length === 0 && this.activeTab === 1">
        <h2 class="akrobat c-999 noCaps">
          You have no Favourites yet.<br /><br />Make someone happy and
          <i class="icon-heart"></i> their Post!
        </h2>
      </div>
      <div v-else-if="posts.length === 0 && this.activeTab === 2">
        <h2 class="akrobat c-999 noCaps">
          You have no Favourites yet.<br /><br />Make someone happy and
          <i class="icon-heart"></i> their Post!
        </h2>
      </div>
    </div>
    <div
      class="tx-ctr"
      v-if="
        this.$route.path !== 'Home' &&
        this.$route.name !== 'Leaderboard' &&
        this.$route.name !== 'FavoritePosts' &&
        this.$route.name !== 'User'
      "
    >
      <div v-if="posts.length === 0 && this.activeTab === 0">
        <h2 class="akrobat c-999 noCaps">
          <i class="icon-user p-r"><span class="plus-inline">+</span></i> Follow
          any members to view their Posts.
        </h2>
      </div>
      <div v-else-if="posts.length === 0 && this.activeTab === 1">
        <h2 class="akrobat c-999 noCaps">
          <i class="icon-user p-r"><span class="plus-inline">+</span></i> Follow
          any members to view their Posts.
        </h2>
      </div>
      <div v-else-if="posts.length === 0 && this.activeTab === 2">
        <h2 class="akrobat c-999 noCaps">
          <i class="icon-user p-r"><span class="plus-inline">+</span></i> Follow
          any members to view their Posts.
        </h2>
      </div>
    </div>
    <div :class="col_class" v-for="(row, idx) in alignedPosts" :key="idx">
      <div
        v-for="(post, index) in row"
        :key="index"
        class="brick"
        :class="{ listview: isListView }"
        style="position: relative"
      >
        <WinnerBadgeComponent
          v-if="$route.name === 'Winners'"
          :idx="index * columns + idx + 1"
        />

        <div class="brick-wrapper">
          <div class="vote-wrapper">
            <div class="imgWrap" :class="noShadow(idx, index)">
              <div class="post-info-block">
                <div class="ext-img">
                  <div v-if="!post.videos">
                    <div v-if="post.images.length > 1">
                      <span
                        class="img-count"
                        data-title="No. of Images"
                        data-title-left
                      >
                        {{ "+" }}{{ post.images.length - 1 }}
                      </span>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      v-if="post.videos.length > 1 && post.images.length === 0"
                    >
                      <span
                        class="img-count"
                        data-title="No. of Images"
                        data-title-left
                      >
                        {{ "+" }}{{ post.videos.length - 1 }}
                      </span>
                    </div>
                    <div v-else-if="post.images.length && post.videos.length">
                      {{ "+"
                      }}{{ imgCount(post.images.length, post.videos.length) }}
                    </div>
                  </div>
                </div>

                <span v-if="$route.name !== 'FavoritePosts'">
                  <span
                    class="tip-icon"
                    :class="
                      (post.images.length === 0 && post.videos.length < 2) ||
                      (post.images.length < 2 && !post.videos)
                        ? 'only-like'
                        : ''
                    "
                    :data-title="`${
                      diableFavorite(post.post_id)
                        ? 'Remove from Faves'
                        : 'Add to Faves'
                    }`"
                    data-title-left
                  >
                    <a
                      v-show="isLogin && userEmail !== post.author_email"
                      class="icon-heart sav2fav"
                      :class="{ liked: diableFavorite(post.post_id) }"
                      @click="clickFavButton(post.post_id)"
                    >
                    </a>
                  </span>
                </span>
              </div>
              <div
                class="trash-Wrp"
                v-if="isDeleteable"
                @click="$emit('deletePost', post.post_id)"
              >
                <div data-title="Remove" data-title-left>
                  <div class="trash icon-trash" title="Delete"></div>
                </div>
              </div>
              <LifeRemainingBarComponent
                v-if="post.life_support"
                :expiration="post.expiration"
                :life_remaining="post.life_support_remaining"
              />
              <div
                v-if="post.mature_content"
                class="mature-audience"
                style="display: block"
                @click="checkIfLogin(post.post_id, post.mature_content)"
              >
                <div>
                  <div><i class="icon-eye-off"></i></div>
                  <p class="mature">MATURE AUDIENCE</p>
                  <span>Content may be sensitive</span>
                  <span class="letmesee">LET ME SEE</span>
                </div>
              </div>
              <MatureAudienceLoginModal
                v-if="showLoginModal"
                @colseModal="colseModal"
              />
              <div
                class="thank_you"
                :class="[post.post_id === post_id ? 'show' : '']"
                :id="`msgID${post.post_id}`"
              >
                <canvas class="confetti" :id="`canvas${post.post_id}`">
                </canvas>

                <div class="message">
                  <p>THANK YOU!</p>
                  <span>This Post is now on the Leaderboard</span>
                </div>
              </div>
              <div @click="showOverlay(post.post_id, post.mature_content)">
                <div class="prevent-vid-click"></div>
                <div v-if="post.is_video && post.videos" class="vid-arrow">
                  <div data-title="Video" data-title-top>
                    <span>{{ post.videos.length }}</span>
                  </div>
                </div>
              </div>
              <div v-if="post.mature_content && !isLogin">
                <img src="../../assets/images/logintoview.png" />
              </div>
              <div v-else>
                <v-lazy-image v-if="!post.is_video" :src="post.images[0].url" />
                <iframe
                  v-else
                  width="560"
                  height="315"
                  :src="post.videos[0].url"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                >
                </iframe>
              </div>
            </div>
            <div
              class="voter"
              v-if="
                isLogin &&
                showPressure &&
                post.life_support &&
                $route.name !== 'Winners'
              "
            >
              <div class="votes block_count">
                <div v-if="post.votes > 0">
                  <div
                    class="mr-30"
                    data-title="Leaderboard Position"
                    data-title-top
                  >
                    <input
                      type="number"
                      id="z2h-05b-votes"
                      :value="pressures[index * columns + idx]"
                      min="0"
                      step="1"
                      onInput="checkLength(5,this)"
                    />
                  </div>
                  <span>%</span>
                </div>
                <div class="no_count" v-else>NO VOTES YET</div>
                <div class="votes-nav" v-if="isLogin">
                  <div class="votes-right-warp">
                    <div
                      v-if="myVotes && post.post_id !== max_votes"
                      class="icon-plus votes-button votes-up tippy"
                      @click="increment(post.post_id)"
                      title="Pressure Up"
                    ></div>
                  </div>
                  <div class="votes-left-warp">
                    <div
                      v-if="post.votes && myVotes && post.post_id !== min_votes"
                      class="icon-minus votes-button votes-down tippy"
                      @click="decrement(post.post_id)"
                      title="Pressure Down"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cst-toggle-view">
            <h3>
              <a
                v-if="post.title && post.title.length < 45"
                class="show-panel"
                :title="post.title"
              >
                {{ post.title }}
              </a>
              <a v-else class="show-panel" :title="post.title">
                {{ post.title.substring(0, 45) + "..." }}
              </a>
            </h3>
            <p
              v-if="post.summary && post.summary.length < 100"
              class="summary"
              :class="{ 'white-spaces-pre': isListView }"
            >
              {{ post.summary }}
            </p>
            <p v-else class="summary">
              {{ post.summary.substring(0, 100) + "..." }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="btn_center_sec">
      <a
        class="load_more_btn"
        v-if="paginate && loadMoreToggle"
        @click="loadMorePosts"
      >
        Show more <span class="icon-down-open"></span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import WinnerBadgeComponent from "@/components/shared/WinnerBadgeComponent";
import LifeRemainingBarComponent from "../shared/LifeRemainingBarComponent.vue";
import showLeaderMessageMixin from "@/mixins/showLeaderMessageMixin";
import MatureAudienceLoginModal from "../../components/modal/MatureAudienceLoginModal.vue";
import $ from "jquery";
import VLazyImage from "v-lazy-image/v2";

export default {
  name: "TileView",
  props: {
    moduleName: String,
    paginate: Boolean,
    limit: Number,
  },

  mixins: [showLeaderMessageMixin],

  components: {
    WinnerBadgeComponent,
    LifeRemainingBarComponent,
    MatureAudienceLoginModal,
    VLazyImage,
  },

  computed: {
    ...mapState({
      isLogin: (state) => state.auth.isLogin,
      totalVotes: (state) => state.votingModule.totalVotes,
      min_votes: (state) => state.votingModule.min_votes,
      max_votes: (state) => state.votingModule.max_votes,
      ListView: (state) => state.common.isListView,
      showPressure: (state) => state.common.showPressure,
      myVotes: (state) => state.auth.user.votes,
      userEmail: (state) => state.auth.user.email,
      myFavorites: (state) => state.common.myFavorites,
      posts(state) {
        return state[this.moduleName].posts;
      },
      activeTab: (state) => state.base.activeTab,
      pageNumber(state) {
        return state[this.moduleName].pageNumber;
      },
      totalPosts(state) {
        return state[this.moduleName].totalPosts;
      },
      toggleShow: (state) => state.favoriteModule.toggleShow,
    }),
    isDeleteable() {
      if (this.$route.name === "FavoritePosts" || this.$route.name === "User")
        return true;
      return false;
    },
    isListView() {
      if (this.$route.name !== "Winners") return this.ListView;
      return false;
    },
    col_class() {
      if (this.isListView) return "";
      if (this.columns === 3) {
        return "col-4";
      } else if (this.columns === 1) {
        return "col-12";
      } else {
        return "col-6";
      }
    },
    alignedPosts() {
      let $this = this;
      if ($this.width < 992 && $this.width > 420) {
        $this.columns = 2;
        var temp = [[], []];
      } else if ($this.width < 421) {
        $this.columns = 1;
        temp = [[]];
      } else {
        $this.columns = 3;
        temp = [[], [], []];
      }

      for (let i = 0; i < $this.posts.length; i++) {
        temp[i % $this.columns].push($this.posts[i]);
      }
      return temp;
    },
    pressures() {
      let pressures = [];
      this.posts.map((post) => {
        switch (post.post_id) {
          case this.max_votes:
            pressures.push(99.99);
            break;
          case this.min_votes:
            pressures.push(0.01);
            break;
          default:
            pressures.push(
              (
                (post.votes / this.totalVotes) * 100 +
                post.post_id * 0.01
              ).toFixed(2)
            );
            break;
        }
      });
      return pressures;
    },
    loadMoreToggle() {
      const limit = this.limit ? this.limit : 10;
      return Math.ceil(this.totalPosts / limit) > this.pageNumber
        ? true
        : false;
    },
    showLoginModal() {
      if (this.modalShow === true && !this.closeLoginModal) {
        return true;
      }
      return false;
    },
  },

  data() {
    return {
      columns: 3,
      width: window.innerWidth,
      post_id: "",
      closeLoginModal: false,
      modalShow: false,
    };
  },

  mounted() {
    window.addEventListener("resize", this.resizeWindow);
  },

  destroyed() {
    window.removeEventListener("resize", this.resizeWindow);
  },

  methods: {
    noShadow(idx, index) {
      if (this.isLogin && this.$route.name !== "FavoritePosts") {
        if (this.userEmail === this.alignedPosts[idx][index].author_email) {
          if (this.alignedPosts[idx][index].images.length) {
            if (this.alignedPosts[idx][index].is_video) {
              return "";
            } else {
              if (this.alignedPosts[idx][index].images.length < 2) {
                return "no-shadow";
              }
            }
          } else {
            if (this.alignedPosts[idx][index].videos.length < 2) {
              return "no-shadow";
            }
          }
        }
      } else {
        return "no-shadow";
      }
    },
    resizeWindow() {
      this.width = window.innerWidth;
    },
    imgCount(imgLen, videoLen) {
      return imgLen - 1 + videoLen;
    },
    async increment(postID) {
      this.$emit("increment", postID);
      const index = this.posts.findIndex((post) => post.post_id === postID);
      this.showLeaderMsg(postID, this.posts[index].votes, "");
      this.conffetti(postID);
    },
    async decrement(postID) {
      this.$emit("decrement", postID);
    },
    showOverlay(post_id, isMature) {
      if (this.isLogin) {
        this.$store.dispatch("changeState", ["overlayClass", "opened"]);
        this.$emit("showOverlay", post_id);
      } else {
        if (isMature === false) {
          this.$store.dispatch("changeState", ["overlayClass", "opened"]);
          this.$emit("showOverlay", post_id);
        }
      }
    },
    checkIfLogin(post_id, isMature) {
      if (this.isLogin) {
        this.modalShow = false;
        this.showOverlay(post_id, isMature);
      } else {
        this.modalShow = true;
        $(".sidebar-right").addClass("sidebar-hide");
        $(".show-mnav").addClass("sidebar-hide");
        this.hideSideBar();
      }
    },
    colseModal() {
      this.closeLoginModal = true;
      this.modalShow = false;
      this.closeLoginModal = false;
      $(".show-mnav").removeClass("sidebar-hide");
      this.hideSideBar();
    },
    hideSideBar() {
      let $this = this;
      $("body").removeClass("mpanel-open hidesb");
      $(".ozone-column").removeClass("moved");
      $(".my-ozone").removeClass("moved");
      $this.$store.commit("change_state", ["mnav_class", ""]);
      setTimeout(function () {
        $this.$store.commit("change_state", ["mnav_close_class", ""]);
      }, 350);
    },
    async loadMorePosts() {
      await this.$store.dispatch(`${this.moduleName}/loadMorePosts`, {
        show: this.toggleShow,
        activeTab: this.activeTab,
        pageNumber: this.pageNumber,
        selectedCategory: localStorage.getItem("category"),
      });
    },
    clickFavButton(postID) {
      if (this.diableFavorite(postID)) {
        return this.removeFavourite(postID);
      } else {
        return this.addToFavorite(postID);
      }
    },
    async addToFavorite(postID) {
      await this.$store.dispatch("getFavorites");
      let data = {
        post_id: postID,
      };
      await this.$store.dispatch("addToFavorites", data);
      await this.$store.dispatch("getFavorites");
    },
    diableFavorite(postID) {
      if (!this.isLogin) return false;
      if (this.myFavorites.find((item) => item === postID)) return true;
      return false;
    },
    async removeFavourite(postID) {
      await this.$store.dispatch("getFavorites");
      await this.$store.dispatch("favoriteModule/deletePost", postID);
      await this.$store.dispatch("getFavorites");
    },
  },
};
</script>
