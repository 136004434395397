<template>
  <div class="read-panel" :class="{ open: isOpen === 8 }">
    <h4 class="p-head blue">Earn 50 Tokens</h4>
    <p>
      Enter a friend's email for them to receive an invitation to join
      O2.
    </p>
    <p>
      If they're not already a member and join, you will earn 50 Tokens.
    </p>
    <form id="refereFriend" action="" method="post">
      <div class="gen-inputs">
        <div class="block refer-friend-block">
          <label for="friend_email" class="offset"
            >Friend's email address</label
          >
          <input
            type="text"
            v-model="friend_email"
            placeholder="Friend's email address"
            @blur="validateEmail"
          />
          <a class="button primary" @click="sendEmail"> Send </a>
        </div>
      </div>
    </form>
    <p class="fs-8">Thank you for helping to grow the O2 community ;-)</p>
    <p class="error-message">{{ errorMsg }}</p>
    <!-- Fail Div -->
    <div class="promo1 mt-20 mb-10" v-if="errorDiv">
      <h2 class="akrobat mb-10 mt-10 wh">OOPS!</h2>
      <p><strong>That person is already a Member.</strong></p>
      <p>Please try another email address.</p>
    </div>
    <!-- Success Div  -->
    <div class="promo1 mt-20 mb-10" v-if="successDiv">
      <h2 class="akrobat mb-10 mt-10 wh">SUCCESS</h2>
      <p>
        <strong>Your friend has been sent an invitation to join.</strong>
      </p>
      <p>Refer another friend?</p>
    </div>
  </div>
</template>

<script >
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "ReferFriendComponent",

  props: ["isOpen"],

  data() {
    return {
      errorDiv: false,
      successDiv: false,
      friend_email: "",
      errorMsg: "",
    };
  },

  computed: {
    ...mapState({}),
  },

  methods: {
    validateEmail() {
      const expression = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
      if (this.friend_email) {
        if (expression.test(this.friend_email)) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    async sendEmail() {
      if (this.validateEmail()) {
        const data = { friend_email: this.friend_email };
        await axios({
          url: "user/token/refer-friend/",
          method: "POST",
          data: data,
        })
          .then((response) => {
            if (response.data.status === 200) {
              this.successDiv = true;
              this.friend_email = "";
              setTimeout(() => {
                this.successDiv = false;
              }, 5000);
            }
          })
          .catch((error) => {
            this.errorDiv = true;
            setTimeout(() => {
              this.errorDiv = false;
            }, 5000);
            console.log(error);
          });
      } else {
        this.errorMsg = "Please enter valid email address";
        setTimeout(() => {
          this.errorMsg = "";
        }, 3000);
      }
    },
  },
};
</script>
