<template>
  <div class="read-panel" :class="{ open: isOpen === 4 }">
    <h4 class="p-head blue">Hi {{ this.user.first_name }}</h4>
    <p class="motto">
      We welcome you to discover and create. 
      Enjoy!
    </p>
    <p class="noellip">
      +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    </p>
    <h4 class="p-head blue">Rules & principles</h4>
    <p>
      <strong>With the help of the O2 Community</strong>, we've created this set
      of rules and principles as a guide for us all, to keep things positive &
      awesome.
    </p>
    <p>
      <strong>These rules apply</strong> to the O2 Community and all Posts<em
        >(title, description, tags, visual content, comments, links, and
        messages)</em
      >. Although 'context' may be taken into account, we take enforcement of
      these rules seriously.
    </p>
    <p class="noellip">
      +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    </p>
    <h4 class="p-head blue">There's a big bad sheriff in this town</h4>
    <p>
      <strong>For most of us that's a good thing,</strong> but for any
      gunslingers out there, take note:
    </p>
    <p>
      <strong>This isn't the Wild West.</strong> There is a Sheriff in this
      town. He packs a 6-gun shooter and doesn't take prisoners.
    </p>
    <p>
      <strong>Infringement of our Community rules</strong> may result in content
      removal, warnings, partial, period or lifetime bans
      <em>(at the Community's, or our, discretion)</em>, along with the
      potential of serious offender's details being sent to national
      authorities.
    </p>
    <p>
      <strong>With all that said, now relax.</strong> Grab yourself a
      <i class="icon-coffee"></i>coffee and have a read of the small print
      below, which we hope will keep us all feeling warm, safe and happy<i
        class="icon-smile"
      ></i>
    </p>
    <p class="noellip">
      +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    </p>

    <h4 class="p-head blue">Sharing practices</h4>
    <p>
      <strong>Consider these tips when sharing</strong> content with the O2
      Community to ensure that you are seen as a responsible member:
    </p>
    <p><strong>Add value</strong></p>
    <ul>
      <li>
        Post content that adds value to the community and the viewing public.
        Content that raises a smile, creates discussion, is new or unique, tells
        a story or has a deep meaning or positive inspirational impact, are
        types of content that is popular on O2<br />
        <br />
        If you're seeking votes, be critical of everything that you Post.
      </li>
    </ul>
    <p><strong>Be transparent & honest</strong></p>
    <ul>
      <li>
        The O2 Community expects total honesty about the origin of any posts
        that you share with them. Do not pretend that you created something if
        you didn't. Content credits are a common feature of great sharing
        practises - and you can add a link for that purpose.
      </li>
    </ul>
    <p><strong>Show respect</strong></p>
    <ul>
      <li>
        Show good sportsmanship within the O2 Community and the voting process.
        Don't take any down-votes personally. It's all relative, and there may
        be some awesome content that you are competing with this week. <br />
        <br />
        <strong>Also, avoid reposting the same content</strong> - That's
        guaranteed to get down-voted into the big black hole of oblivion very
        quickly. Spamming old content really annoys the O2 Community and it will
        not make any of your future posts very popular either.
      </li>
    </ul>
    <p>
      <strong>Repeated violations of these practices</strong> may result in
      warnings, restrictions, account suspension or ban.
    </p>
    <p class="noellip">
      +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    </p>

    <h4 class="p-head blue">No nudity or sexually explicit content</h4>
    <p><strong>No ...</strong></p>
    <ul>
      <li>full or partial nudity</li>
      <li>
        gratuitous or explicit display of breasts, butts, and sexual organs
      </li>
      <li>
        depiction of sexual activity, explicit or implied
        <em>(drawings, print, animated, human, or otherwise)</em>
      </li>
      <li>
        images taken of someone without their knowledge, or consent for the
        purpose of sexualization
      </li>
      <li>
        solicitation
        <em
          >(the uninvited act of directly requesting sexual content from another
          person, or selling/offering explicit content and/or adult
          services)</em
        >
      </li>
    </ul>
    <p>Content that might be removed includes any variants of the above.</p>
    <p>
      <strong>Artistic, scientific or educational</strong> nude images, with
      educational context, may be permitted. We do not judge the artistic merit
      of particular content. We focus on content, context and intent, for the
      general community.
    </p>
    <p>
      <strong>Any content exploiting minors</strong> will be removed and, if
      necessary, reported to national authorities.
    </p>
    <p class="noellip">
      +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    </p>
    <h4 class="p-head blue">Mature audience content</h4>
    <p>
      <strong>O2 is a public-facing browsing experience,</strong> which means
      public posts can be seen by all. To prevent the 'accidental' viewing of
      some 'mature' content by the general public, such content can only be
      viewed by O2 members, as an opt-in/opt-out <em>(by clicking)</em> basis.
    </p>
    <p>
      <strong>Mature or Sensitive audience content</strong> may include but is
      not limited to, suggestive, inflammatory, unsettling, or provocative
      jokes, stories, tags, audio, and visual content.
    </p>
    <p>It may include content containing:</p>
    <ul>
      <li>mild violence, medical work, injuries or blood</li>
      <li>content that antagonizes or directly agitates a group of people</li>
      <li>scantily-clad people</li>
      <li>content that makes an average person squeamish or disgusted</li>
      <li>non-explicit content discussing sexual experiences or fetishes</li>
      <li>content posted to sexualize or ogle</li>
      <li>
        any content intended or considered appropriate for a mature
        <em>(and not underage)</em> audience
      </li>
    </ul>
    <p>
      <strong>Content marked for mature audiences</strong> may include
      surgical/medical procedures or conditions, vehicle collisions, dead or
      injured animals, bikini photoshoots, objectification of public and
      non-public figures, vomit, graphic or upsetting stories, non-explicit
      written descriptions of sexual experiences, etc.
    </p>
    <p>
      <strong>No sexually explicit or abusive content</strong> is allowed, even
      if it's marked as <strong>mature</strong> <em>(see rule above)</em>.
    </p>
    <p class="red fs-600">
      *When you create any Post, if appropriate, ensure that you use the
      <i class="icon-star-empty"></i>Sensitive Audience 'checkbox' before
      Submitting your Post, based upon common sense and these guildeline rules.
    </p>
    <p class="noellip">
      +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    </p>
    <h4 class="p-head blue">No hate speech, abuse, or harassment.</h4>
    <p>
      <strong>O2 is a public-facing browsing experience,</strong> so we will
      remove any hate speech, divisive and abusive content when found. This may
      include content containing:
    </p>
    <ul>
      <li>
        attacks on people based on race, ethnicity, origin, religion, sex,
        gender, sexual orientation, age, disability or medical condition
      </li>
      <li>glorification or endorsement of hateful content or ideologies</li>
      <li>
        depicting or advocating discrimination of a group especially so as to
        distinguish it as inferior or superior to another
      </li>
      <li>
        harassment in the forms of witch-hunting, sexual harassment, repetitive
        unwanted or obsessive attention, aggressive intimidation, suicide
        requests or threats, or inciting the community into support or disdain
        for a person, organization or community
      </li>
      <li>attacks, abuse, revenge, bullying or harassment</li>
    </ul>
    <p>
      The subtle nuances between unintentional and serious offences in the above
      subjects can sometimes be difficult to identify, so we are likely to be
      cautious and take the content down.
    </p>
    <p class="noellip">
      +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    </p>
    <h4 class="p-head blue">Illegal or violent activity.</h4>
    <p>
      <strong>No content</strong> depicting, condoning, inciting, or advocating
      illegal activity or violence will be tolerated. This may include content
      containing:
    </p>
    <ul>
      <li>
        child abuse or exploitation
        <em>(includes photos, video, print, artwork)</em>
      </li>
      <li>rape or domestic abuse</li>
      <li>
        sales of illegal substances or products requiring permits/licenses,
        including pharmaceuticals, narcotics, pets, or firearms
      </li>
      <li>
        fraudulent or deceptive content intended to spread false information or
        exploit
      </li>
      <li>false impersonation</li>
      <li>brutal fights or graphic injuries</li>
      <li>depictions of violence or human death</li>
    </ul>
    <p>
      <strong>No content</strong> that may incite, condone, advocate others to
      commit violence, mocks victims, or include direct or specific threats of
      violence.
    </p>
    <p class="noellip">
      +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    </p>
    <h4 class="p-head blue">No gore or shock content.</h4>
    <p>
      <strong>No content</strong> depicting vivid and realistic acts of violence
      and brutality. Don’t post content intended to disgust or shock the
      community. This may include content containing:
    </p>
    <ul>
      <li>animal abuse</li>
      <li>expulsion of bodily fluids</li>
      <li>dissection of animals</li>
    </ul>
    <p class="noellip">
      +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    </p>
    <h4 class="p-head blue">No posting of personal information.</h4>
    <p>
      <strong>For your protection,</strong> we don't allow anything that reveals
      other people's personally identifiable information, including their phone
      numbers, home or email addresses, employment information, or personal
      social media accounts of non-public figures without their consent.
    </p>
    <h4 class="p-head blue">No spam or prohibited behaviour.</h4>
    <p>
      <strong>We value authentic and genuine behaviour.</strong> We take strong
      action against anyone involved in malicious or illegal behaviour, which
      may include the following, or similar:
    </p>
    <ul>
      <li>
        Spam
        <em
          >(clickbait, affiliate links, rapid-fire posting, unapproved
          commercial advertising of any kind)</em
        >
      </li>
      <li>Entertainment spoilers <em>(for the purpose of trolling).</em></li>
      <li>
        Scripts or bots <em>(that act maliciously or non-transparently)</em>
      </li>
      <li>
        Vote manipulation
        <em
          >(using multiple accounts, voting services, or any software to
          increase or decrease vote scores)</em
        >
      </li>
      <li>
        Interfering in any way with the correct and normal use of O2 services or
        competitions.
      </li>
      <li>
        Repeated multiple account creation to evade punishment, restrictions,
        warnings or bans.
      </li>
    </ul>
    <p class="noellip">
      +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    </p>
    <h4 class="p-head blue">Rule breakers</h4>
    <p>
      <strong>If the O2 Community rules or terms of service</strong> are not
      adhered to: the members(s) involved will be warned about the infraction.
      Their content may be removed or further steps may be taken. If the same
      member(s) continues to receive warnings for multiple infractions, they
      could receive time-based account suspensions or ban.
    </p>
    <p>
      <strong>The O2 Community itself has the ability to Report Posts</strong>
      and there is an automated system to remove Posts that the O2 Community
      find objectionable.
    </p>
    <p>
      <strong>O2 Admin's decision</strong> will be the only and final decision
      on each outcome, and no further correspondence will be entered into. We
      don't have the time or inclination to baby-sit multiple offenders.
    </p>
    <p>
      <strong>If you're unsure about any content, don't post it.</strong> It's
      really that simple.
    </p>
    <p class="noellip">
      +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CommunityRulesComponent",

  props: ["isOpen"],

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
};
</script>