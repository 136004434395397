<template>
  <perfect-scrollbar
    id="app"
    :class="parentClass"
    ref="app"
    @scroll="scrolling"
  >
    <VoteExpiredModal v-if="showExpiredModal" @colseModal="colseModal" />
    <SignUpConfirmationModal v-if="showSignUpConfirmationModal" />
    <ZeroHero v-if="parentClass != 'leaderboard'" />
    <MyOzone
      :isLoginPannelOpen="isLoginPannelOpen"
      @closeLoginPanel="closeLoginPanel"
      v-if="adminClass != 'adminOverlay'"
    />
    <IconMenu
      :isLoginPannelOpen="isLoginPannelOpen"
      @changeIsLoginPannelOpen="changeIsLoginPannel"
      v-if="adminClass != 'adminOverlay'"
    />
    <Toaster
      v-if="showToast"
      :type="toastMsg.type"
      :toastMsg="toastMsg.message"
    />
    <div class="skip-link screen-reader-text">
      <a title="Skip to content" href="#content">Skip to content</a>
    </div>
    <div class="shell no-ws" @click="changeMyOzone">
      <div class="main-column">
        <router-link
          to="/"
          :class="
            this.overlayClass === 'opened' ||
            this.sharedOverlayClass === 'opened' ||
            this.editPostOverlayClass === 'opened'
              ? ''
              : 'homeHeaderLink'
          "
        >
        </router-link>
        <div class="domainName" v-if="selectedItem">{{ selectedItem }}</div>
        <div class="domainName" v-else>Positive</div>
          <div
          v-if="this.selectedTheme !== 'o2' && isLogin"
          class="rewardsText"
          :class="isLogin ? '' : 'not-login'"
        >
          <span></span>
          <div>
          </div>
           
        </div>
        <div
          class="content Hellow custom-screen"
          id="screen"
          :class="{
            active: isSearching,
            abc: sharedOverlayClass || editPostOverlayClass,
          }"
        >
          <div class="overlay-content" @click="toggleSearch"></div>
          <SiteHeader />
          <Overlay v-if="sharedOverlayClass === 'opened'" />
          <router-view />
        </div>
      </div>
    </div>
    <a
      class="icon-up-open goup"
      :class="goUpClass"
      title="Scroll to top"
      @click="scrollToTop"
    ></a>
  </perfect-scrollbar>
</template>

<script>
import { mapState } from "vuex";
import Overlay from "@/components/Overlay";
import ZeroHero from "@/components/sidebar/ZeroHero";
import MyOzone from "@/components/sidebar/MyOzone";
import IconMenu from "@/components/sidebar/IconMenu";
import SiteHeader from "@/components/SiteHeader";
import VoteExpiredModal from "@/components/modal/VoteExpiredModal";
import SignUpConfirmationModal from "@/components/modal/SignUpConfirmationModal";
import Toaster from "./components/shared/Toaster.vue";
import moment from "moment-timezone";
import $ from "jquery";

moment.tz.setDefault("Pacific/Auckland");

export default {
  components: {
    MyOzone,
    ZeroHero,
    IconMenu,
    SiteHeader,
    Overlay,
    VoteExpiredModal,
    SignUpConfirmationModal,
    Toaster,
  },

  computed: {
    ...mapState({
      isLogin: (state) => state.auth.isLogin,
      overlayClass: (state) => state.common.overlayClass,
      sharedOverlayClass: (state) => state.common.sharedOverlayClass,
      editPostOverlayClass: (state) => state.common.editPostOverlayClass,
      myOzone: (state) => state.common.myOzone,
      changedTimeMyOzone: (state) => state.common.changedTimeMyOzone,
      myVotes: (state) => state.auth.user.votes,
      showSignUpConfirmationModal: (state) =>
        state.auth.showSignUpConfirmationModal,
      activeTab: (state) => state.base.activeTab,
      showToast: (state) => state.base.showToast,
      toastMsg: (state) => state.base.toastMsg,
      isSearching: (state) => state.common.isSearching,
      unread_notification_count: (state) => state.auth.unreadNotificationCount,
      userEmail: (state) => state.auth.user.email,
      selectedTheme: (state) => state.auth.selectedTheme,
      selectedItem: (state) => state.common.selectedItem,
    }),
    showExpiredModal() {
      if (this.myVotes === 0 && !this.closeExpiredModal) {
        return true;
      }
      return false;
    },
    parentClass() {
      if (
        this.$route.name === "Leaderboard" ||
        this.$route.name === "Winners" ||
        this.$route.name === "FavoritePosts" ||
        this.$route.name === "Search" ||
        this.$route.name === "Profile" ||
        this.$route.name === "PrivacyPolicy" ||
        this.$route.name === "AdminOverlay" ||
        this.$route.name === "AdminUserView" ||
        this.$route.name === "SuccessPayment"
      ) {
        return "leaderboard";
      } else {
        return "";
      }
    },
    adminClass() {
      if (this.$route.name === "AdminOverlay" || this.$route.name === " ") {
        return "adminOverlay";
      } else {
        return "";
      }
    },
  },

  data() {
    return {
      isLoginPannelOpen: false,
      ip: "",
      closeExpiredModal: false,
      goUpClass: "",
      connection: null,
      notifyByUser: "",
      description: "",
      title: "",
      post_title: "",
      ws: null,
    };
  },

  async created() {
    let $this = this;
    if (localStorage.getItem("accessToken")) {
      await $this.$store.dispatch("getUser"); //
      $this.$store.dispatch("getUnreads");
      $this.$store.dispatch("userModule/getUserData");
      $this.$store.dispatch("getFollowing");
      $this.$store.dispatch("setLogin", true);
      $this.$store.dispatch("setMyOzone", 1);

      // Socket connection
      var endpoint = process.env.VUE_APP_CONNECTION_ENDPOINT;
      var ws = new WebSocket(endpoint);

      ws.addEventListener("open", (event) => {
        console.log(event);
        ws.send(JSON.stringify({ open: this.userEmail }));
      });
      ws.addEventListener("message", (event) => {
        this.$store.commit("set_user_status", event.data);
      });
      ws.addEventListener("error", (event) => {
        console.log("Error", event);
      });

      const user = this.userEmail;
      window.addEventListener("beforeunload", function () {
        ws.send(JSON.stringify({ close: user }));
        setTimeout(function () {
          ws.addEventListener("close", (event) => {
            console.log("Close", event);
          });
        }, 5000);
      });
    }
  },

  watch: {
    selectedTheme() {
      if (this.selectedTheme) {
        $("body").removeClass(function (index, className) {
          return (className.match(/\btheme-\S+/g) || []).join(" ");
        });
        $("body").addClass("theme-" + this.selectedTheme);
      }
    },
  },

  async mounted() {
    await this.$store.dispatch("unreadNotificationCount");
    await this.$store.dispatch("CartData");
    // Set Notification
    if (this.$messaging) {
      this.$messaging
        .getToken({
          vapidKey:
            "BKn7TaKLgCq5X3Gt4yClvAwOEf6Y7lRdN7KmIjucn2ckNoikJ_smMpIeqF9HSSl9V4TwfHv0mA-sdqQTHi2Ryuw",
        })
        .then((res) => {
          this.$store.commit("set_FCMToken", res);
        });

      this.$messaging.onMessage((payload) => {
        this.$store.commit("append_notification", payload.data);
        if (payload.data.notify_by !== this.userEmail) {
          this.$store.commit(
            "unread_notification_count",
            this.unread_notification_count + 1
          );
        }
        this.description = payload.data.description;
        this.title = payload.data.title;
        if (payload.data.type !== "ban" || payload.data.type !== "winner") {
          this.notifyByUser = payload.data.user;
          if (payload.data.notify_by !== this.userEmail) {
            this.makeToast(this.description, this.title, this.notifyByUser);
          }
        } else {
          this.post_title = payload.data.post_title;
          if (payload.data.notify_by !== this.userEmail) {
            this.makeToast(this.description, this.title);
          }
        }
      });
    }
  },

  methods: {
    makeToast(msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    changeIsLoginPannel() {
      this.isLoginPannelOpen = !this.isLoginPannelOpen;
    },
    closeLoginPanel() {
      this.isLoginPannelOpen = false;
    },
    scrolling() {
      const $body = document.body;
      const header = $(".goup");
      if (this.$refs.app.$el.scrollTop > 200) {
        $body.classList.add("stick");
        header.addClass("on jumping");
      } else if (this.$refs.app.$el.scrollTop < 200) {
        $body.classList.remove("stick");
        header.removeClass("on jumping");
      }
    },
    async scrollToTop() {
      this.$store.commit("set_overlay_class", "");
      this.$store.commit("set_shared_overlay_class", "");
      setTimeout(() => {
        this.$refs.app.$el.scrollTop = 0;
      }, 200);
      this.$refs.app.update();
    },
    changeMyOzone() {
      let $this = this;
      let changeTime = moment().format();
      let c_moment = moment(changeTime);
      if (
        $this.myOzone != 1 &&
        $this.isLogin &&
        c_moment.from($this.changedTimeMyOzone) !== "a few seconds ago"
      ) {
        $this.$store.dispatch("setMyOzone", 1);
      }
    },
    colseModal() {
      this.closeExpiredModal = true;
    },
    async toggleSearch() {
      if (this.isSearching === true) {
        await this.$store.dispatch("setSearching", false);
        if (
          this.$route.name === "Home" ||
          this.$route.name === "O2hq" ||
          this.$route.name === "User" ||
          this.$route.name === "Tokens"
        ) {
          $(".custom-screen").addClass("full-screen");
        }
      }
      // hide ozone pnale on outer click
      $(".ozone-column").removeClass("moved");
      $(".my-ozone").removeClass("moved");
    },
  },
};
</script>
