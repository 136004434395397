<template>
  <div class="after-payment-page payment-success">
    <div class="ap-wrap">
      <img src="@/assets/images/true.png" />
      <div class="ap-info">
        <h5>Payment successful!</h5>
        <p>
          Your payment of <span class="token-amoun">${{ totalCoast }}</span> is
          successful.
        </p>
        <p>
          <span class="token-amoun">{{ totalToken }}</span> Tokens have been
          added to your account.
        </p>
        <a class="button primary" href="/"
          ><span class="icon-left-dir"> Back to O2: Positive</span></a
        >
      </div>
      <p>
        We take security of your data! If you have any questions, please email
        <a href="mailto:admin@o2feed.com">admin@o2feed.com</a>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Success",
};
</script>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "SuccessPayment",

  data() {
    return {
      totalCoast: 0,
      totalToken: 0,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },

  created() {
    setTimeout(() => this.$router.push({ name: "Home" }), 10000);
  },

  mounted() {
    this.cartCheckout();
  },

  methods: {
    async cartCheckout() {
      const data = {
        payment_id: this.$route.query.paymentId,
        payment_intent: localStorage.getItem("paymentIntent"),
      };
      await axios({
        url: "user/token/cart-checkout/",
        method: "POST",
        data: data,
      })
        .then((response) => {
          this.totalCoast = response.data.data.total_coast;
          this.totalToken = response.data.data.total_token;
          this.$store.commit(
            "set_user_token",
            this.user.token + response.data.data.total_token
          );
          this.$store.commit("set_cart_data", []);
          this.cartTotal = 0;
          if (response.data.data.total_token > 0) {
            this.$store.dispatch("showToast", {
              message:
                response.data.data.total_token +
                " Token added in your account.",
            });
          }
          localStorage.removeItem("paymentIntent");
          localStorage.removeItem("paymentID");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
