import * as moment from "moment";

export default {
  methods: {
    timeAgo(created_at) {
      const now = moment.utc()._d;
      const creat_at =
        moment(created_at).format("DD/MM/YYYY") +
        " " +
        moment(created_at).format("HH:mm:ss");
      const curr_time =
        moment(now).format("DD/MM/YYYY") + " " + moment(now).format("HH:mm:ss");

      var ms = moment(curr_time, "DD/MM/YYYY HH:mm:ss").diff(
        moment(creat_at, "DD/MM/YYYY HH:mm:ss")
      );
      var d = moment.duration(ms);
      var days = Math.floor(d.asDays());
      var seconds = Math.floor(d.asSeconds());
      if (days === 0 && seconds >= 0 && seconds < 60) {
        if (seconds === 0) {
          return "few seconds ago";
        } else {
          return seconds + "s";
        }
      }

      if (days === 0 && seconds >= 60 && seconds < 3600) {
        let minutes = Math.floor(seconds / 60);
        if (minutes === 0 && seconds === 60) {
          return " minutes ago";
        } else {
          return minutes + "m";
        }
      }

      if (days === 0 && seconds >= 3600 && seconds < 86400) {
        let hours = Math.floor(seconds / 3600);
        if (hours === 0) {
          return "hours ago";
        } else {
          return hours + "h";
        }
      }

      if (days >= 1 && days < 8) {
        return days + "d";
      }

      if (days >= 8 && days < 31) {
        let weeks = Math.floor(days / 7);
        return weeks + "w";
      }

      if (days >= 31 && days < 365) {
        let months = Math.floor(days / 30);
        return months + "mo";
      }

      if (days >= 365) {
        let years = Math.floor(days / 365);
        return years + "y";
      }
    },

    subTimeAgo(sub_comm_at) {
      const now = moment.utc()._d;
      const creat_at =
        moment(sub_comm_at).format("DD/MM/YYYY") +
        " " +
        moment(sub_comm_at).format("HH:mm:ss");
      const curr_time =
        moment(now).format("DD/MM/YYYY") + " " + moment(now).format("HH:mm:ss");

      var ms = moment(curr_time, "DD/MM/YYYY HH:mm:ss").diff(
        moment(creat_at, "DD/MM/YYYY HH:mm:ss")
      );
      var d = moment.duration(ms);
      var days = Math.floor(d.asDays());
      var seconds = Math.floor(d.asSeconds());

      if (days === 0 && seconds >= 0 && seconds < 60) {
        if (seconds === 0) {
          return "few seconds ago";
        } else {
          return seconds + "s";
        }
      }

      if (days === 0 && seconds >= 60 && seconds < 3600) {
        let minutes = Math.floor(seconds / 60);
        if (minutes === 0 && seconds === 60) {
          return " minute ago";
        } else {
          return minutes + "m";
        }
      }

      if (days === 0 && seconds >= 3600 && seconds < 86400) {
        let hours = Math.floor(seconds / 3600);
        if (hours === 0) {
          return "hour ago";
        } else {
          return hours + "h";
        }
      }

      if (days >= 1 && days < 30) {
        return days + "d";
      }

      if (days >= 30 && days < 35) {
        let weeks = Math.floor(days / 7);
        return weeks + "w";
      }

      if (days >= 30 && days < 365) {
        let months = Math.floor(days / 30);
        return months + "mo";
      }

      if (days >= 365) {
        let years = Math.floor(days / 365);
        return years + "y";
      }
    },
    postTimeAgo(post_at) {
      const now = moment.utc()._d;
      const creat_at =
        moment(post_at).format("DD/MM/YYYY") +
        " " +
        moment(post_at).format("HH:mm:ss");
      const curr_time =
        moment(now).format("DD/MM/YYYY") + " " + moment(now).format("HH:mm:ss");

      var ms = moment(curr_time, "DD/MM/YYYY HH:mm:ss").diff(
        moment(creat_at, "DD/MM/YYYY HH:mm:ss")
      );
      var d = moment.duration(ms);
      var days = Math.floor(d.asDays());
      var seconds = Math.floor(d.asSeconds());

      if (days === 0 && seconds >= 0 && seconds < 60) {
        if (seconds === 0) {
          return "second ago";
        } else {
          return seconds + "s";
        }
      }

      if (days === 0 && seconds >= 60 && seconds < 3600) {
        let minutes = Math.floor(seconds / 60);
        if (minutes === 0 && seconds === 60) {
          return "minute ago";
        } else {
          return minutes + "m";
        }
      }

      if (days === 0 && seconds >= 3600 && seconds < 86400) {
        let hours = Math.floor(seconds / 3600);
        if (hours === 0) {
          return "hour ago";
        } else {
          return hours + "h";
        }
      }

      if (days >= 1 && days < 30) {
        return days + "d";
      }

      if (days >= 30 && days < 35) {
        let weeks = Math.floor(days / 7);
        return weeks + "w";
      }

      if (days >= 30 && days < 365) {
        let months = Math.floor(days / 30);
        return months + "mo";
      }

      if (days >= 365) {
        let years = Math.floor(days / 365);
        return years + "y";
      }
    },
  },
};
