<template>
  <div>
    <div class="section-nav sidebar-right" @click="toggleOverlay">
      <ul v-if="isLogin === true">
        <li data-title="Me" data-title-left>
          <a class="show-ozone" style="cursor: pointer" @click="goToMyprofile">
            <img
              class="pho-circs pho-3"
              :title="`${user.first_name} ${user.last_name}`"
              :src="userAvatar"
              alt="My Ozone"
            />
          </a>
        </li>
        <li data-title="Edit Profile" data-title-left>
          <a
            class="icon-edit js-trig-aaa"
            :class="{ active: myOzone === 4 }"
            @click="changeMyOzone(4)"
          >
            <span
              v-if="incompleteProfile"
              class="badge incomplete icon-attention-alt"
              title="Incomplete"
            ></span>
          </a>
        </li>
        <li data-title="Settings" data-title-left>
          <a
            class="icon-cog js-trig-bbb"
            :class="{ active: myOzone === 5 }"
            @click="changeMyOzone(5)"
          >
          </a>
        </li>
        <li data-title="Following Users" data-title-left>
          <a
            class="icon-user js-trig-ccc"
            :class="{ active: myOzone === 6 }"
            @click="followingMember"
          >
            <span class="plus">+</span>
          </a>
        </li>
        <li data-title="Favourite Posts" data-title-left @click="hideSidebar">
          <router-link
            to="/favoriteposts"
            :class="{ active: $route.name === 'FavoritePosts' }"
            class="icon-heart"
          >
          </router-link>
        </li>
        <li data-title="Notifications" data-title-left>
          <a
            class="icon-bell-alt js-trig-ccc"
            :class="{ active: myOzone === 7 }"
            @click="changeMyOzone(7)"
          >
            <span
              v-if="this.unread_notification_count > 0"
              class="badge"
              title="Unseen Notifications"
            >
              <strong v-if="this.unread_notification_count > 0">
                {{ this.unread_notification_count }}</strong
              >
            </span>
          </a>
        </li>
        <!-- <li data-title="Cart Token" data-title-left>
          <a
            class="icon-shopping-basket js-trig-ccc"
            :class="{ active: myOzone === 8 }"
            @click="changeMyOzone(8)"
          >
            <span
              v-if="this.cartItemsCount > 0"
              class="badge"
              title="cart items"
            >
              <strong v-if="this.cartItemsCount > 0">
                {{ this.cartItemsCount }}</strong
              >
            </span>
          </a>
        </li> -->
        <li data-title="O2 Headquarters" data-title-left @click="hideSidebar">
          <router-link
            to="/o2hq"
            :class="{ active: $route.name === 'O2hq' }"
            class="hq icon-badge"
          >
            <span
              data-title="Notifications"
              data-title-left
              v-if="unread_number > 0"
              class="badge"
              >{{ unread_number }}</span
            >HQ
          </router-link>
        </li>
        <li data-title="Main Features" data-title-left @click="hideSidebar">
          <router-link
            to="/howitworks"
            :class="{ active: $route.name === 'HowItWorks' }"
            class="icon-info"
          >
          </router-link>
        </li>
        <li data-title="Log out" data-title-left>
          <!-- Opens LOGIN panel or LOGOUT -->
          <a
            class="icon-logout"
            v-if="loginType !== 'google'"
            @click="logout"
          ></a>
          <a v-if="loginType === 'google'" class="google-out-btn">
            <GoogleLogin
              :params="params"
              :logoutButton="true"
              :onSuccess="logout"
            >
              <i class="icon-logout" title="Log out"></i>
            </GoogleLogin>
          </a>
        </li>
      </ul>
      <ul v-else class="nav-logged-out">
        <li class="hide1200" data-title="Login / Signup" data-title-left>
          <a
            class="icon-login"
            :class="[{ active: isLoginPannelOpen }]"
            data-mobile="true"
            @click="onLoginIconClick"
          >
          </a>
        </li>
        <li data-title="What is O2?" data-title-left>
          <a to="/whatiso2" class="icon-info" @click="whatisO2"> </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapState } from "vuex";
import ChangeMyOzoneMixin from "@/mixins/ChangeMyOzoneMixin";
import GoogleLogin from "vue-google-login";

export default {
  name: "IconMenu",
  mixins: [ChangeMyOzoneMixin],
  components: { GoogleLogin },
  props: {
    isLoginPannelOpen: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      params: {
        client_id:
          "2133277209-a8dn0n9nomkia9c5g7vlr7hnqbdb5opb.apps.googleusercontent.com",
        scope: "email",
        response_type: "code",
      },
      FB: undefined,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      isLogin: (state) => state.auth.isLogin,
      isSearching: (state) => state.common.isSearching,
      overlayClass: (state) => state.common.overlayClass,
      loginType: (state) => state.auth.loginType,
      postCreateOverlayClass: (state) => state.common.postCreateOverlayClass,
      editPostOverlayClass: (state) => state.common.editPostOverlayClass,
      unread_number: (state) => state.auth.unread_number,
      unread_notification_count: (state) => state.auth.unreadNotificationCount,
      cartItemsCount: (state) => state.auth.cartItemsCount,
      FCMToken: (state) => state.auth.FCMToken,
      themeLogo: (state) => state.auth.user.themeLogo,
    }),
    userAvatar() {
      if (this.user.avatar) {
        return this.user.avatar;
      } else {
        if (this.themeLogo) {
          return this.themeLogo;
        } else {
          return process.env.VUE_APP_DEFAULT_IMG_URL;
        }
      }
    },
    incompleteProfile() {
      if (
        this.user.first_name &&
        this.user.last_name &&
        this.user.bio &&
        this.user.avatar &&
        this.user.city &&
        this.user.country
      ) {
        return false;
      }
      return true;
    },
  },

  methods: {
    goToMyprofile() {
      this.$router.push({
        name: "User",
      });
      this.hideSidebar();
    },
    onLoginIconClick() {
      this.$emit("changeIsLoginPannelOpen");
      this.changeMyOzone(0);
      $("body").removeClass("hello");
    },
    logout() {
      this.$store.dispatch("logout", this.FCMToken);
      this.$store.dispatch("setUserStatus", {
        status: false,
        user: this.user.email,
      });
      $("body").removeClass(function (index, className) {
        return (className.match(/\btheme-\S+/g) || []).join(" ");
      });

      this.changeMyOzone(0);
      if (this.$route.name !== "Home") {
        this.$router.push({ name: "Home" });
      }
    },
    sdkLoaded(payload) {
      this.isConnected = payload.isConnected;
      this.FB = payload.FB;
      if (this.isConnected) this.getUserData();
    },
    async followingMember() {
      this.changeMyOzone(6);
      await this.$store.dispatch("getFollowing");
      await this.$store.dispatch("getFollower");
    },
    async toggleOverlay() {
      const $body = document.body;
      if (
        $body.classList.contains("overlay-open") &&
        this.overlayClass === "opened"
      ) {
        this.$store.dispatch("setOverlayClass", "");
        $body.classList.remove("overlay-open");
      }
      if (
        $body.classList.contains("overlay-open") &&
        this.postCreateOverlayClass === "opened"
      ) {
        this.$store.dispatch("setPostCreateOverlayClass", "");
        $body.classList.remove("overlay-open");
      }
      if (
        $body.classList.contains("overlay-open") &&
        this.editPostOverlayClass === "opened"
      ) {
        this.$store.commit("set_edit_post_overlay_class", "");
        $body.classList.remove("overlay-open");
      }
      if (this.isSearching === true) {
        await this.$store.dispatch("setSearching", false);
        if (
          this.$route.name === "Home" ||
          this.$route.name === "O2hq" ||
          this.$route.name === "User"
        ) {
          $(".custom-screen").addClass("full-screen");
        }
      }
    },
    whatisO2() {
      if (this.$route.name !== "WhatIsO2")
        this.$router.push({ name: "WhatIsO2" });
      this.hideSidebar();
    },
    hideSidebar() {
      let $this = this;
      if (this.isLogin) $this.changeMyOzone(1);
      $("body").removeClass("mpanel-open hidesb");
      $("body").removeClass("hello");
      $(".ozone-column").removeClass("moved");
      $(".my-ozone").removeClass("moved");
      $this.$store.commit("change_state", ["mnav_class", ""]);
      setTimeout(function () {
        $this.$store.commit("change_state", ["mnav_close_class", ""]);
      }, 350);
    },
  },
};
</script>