<template>
  <b-modal
    id="admin-modal-prevent-closing"
    ref="admin-modal"
    title="Contact Admin"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
    @cancel="handleCancel"
    @close="handleClose"
    dialog-class="email-share-modal"
    v-model="showModal"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group label="Email:" label-for="to-email" :state="nameState">
        <div :class="errorMsg ? 'is-invalid' : ''">
          <b-form-input
            id="to-email"
            v-model="this.user_email"
            :state="nameState"
            required
          ></b-form-input>
        </div>
      </b-form-group>
      <b-form-group label="Note:" label-for="note" :state="nameState">
        <div :class="errorMsg ? 'is-invalid' : ''">
          <b-form-textarea
            id="note"
            v-model="note"
            :state="nameState"
            required
          ></b-form-textarea>
        </div>
      </b-form-group>
      <p class="error-message" v-if="errorMsg">
        <i class="icon-attention-circled"> {{ errorMsg }}</i>
      </p>
    </form>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";
export default {
  props: ["showModal", "user_email"],
  data: () => ({
    userMail: "",
    note: "",
    nameState: null,
    errorMsg: "",
    errorClass: "",
  }),

  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
  },
  directives: {
    "b-modal": VBModal,
    "b-form-group": VBModal,
    "b-form-input": VBModal,
  },
  methods: {
    resetModal() {
      this.userMail = "";
      this.note = "";
      this.nameState = null;
      this.errorMsg = "";
      this.errorClass = "";
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (this.validateEmail() && this.note !== "") {
        this.errorMsg = "";
        this.$emit("handle-submit", {
          userMail: this.user_email,
          note: this.note,
        });
      } else {
        this.errorClass = "is-invalid";
        this.errorMsg = "Enter Valid Data.";
        setTimeout(() => {
          this.errorMsg = "";
          this.errorClass = "";
        }, 5000);
      }
    },
    validateEmail() {
      const expression =
        /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (expression.test(this.user_email)) {
        return true;
      } else {
        return false;
      }
    },
    handleClose() {
      this.$emit("toggle-modal-user", false);
    },
    handleCancel() {
      this.$emit("toggle-modal-user", false);
    },
  },
};
</script>
