<template>
  <div
    class="read-panel"
    :class="{ open: isOpen === 1, 'no-data': newsData.length < 1 }"
  >
    <div v-if="newsData.length > 0">
      <div v-for="(news, index) in newsData" :key="index">
        <span class="grey-dot" :class="{ 'red-dot': news.unread }"></span>
        <p class="noti-head">
          {{ news.title }}
        </p>
        <pre class="noti-desc">{{ news.description }}</pre>
      </div>
      <div class="btn_center_sec mainPost_loadmore load-more-notification">
        <a class="load_more_btn" v-if="loadMoreToggle" @click="loadMoreNews">
          Show more <span class="icon-down-open"></span>
        </a>
      </div>
    </div>
    <div v-else>
      {{ error }}
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "NewsComponent",
  props: ["isOpen"],

  data() {
    return {
      newsData: [],
      totalNews: 0,
      pageNumber: 0,
      loadedNewsData: [],
      error: "",
    };
  },

  computed: {
    ...mapState({
      isLogin: (state) => state.auth.isLogin,
    }),
    loadMoreToggle() {
      const limit = 10;
      return Math.ceil(this.totalNews / limit) > this.pageNumber ? true : false;
    },
  },

  created() {
    axios({
      url: "notification/get-news/?page_num=" + this.pageNumber,
      method: "GET",
    })
      .then((response) => {
        this.newsData = response.data.data;
        this.pageNumber = 1;
        this.totalNews = response.data.totalNewsCount;
        this.$store.commit("set_total_news_count", this.totalNews);
      })
      .catch((error) => {
        this.error = "There is no news from Admin yet.";
        console.log(error);
      });
  },

  methods: {
    async loadMoreNews() {
      await axios({
        url: "notification/get-news/?page_num=" + this.pageNumber,
        method: "GET",
      })
        .then((response) => {
          this.newsData = this.newsData.concat(response.data.data);
          this.pageNumber = this.pageNumber + 1;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
