import Vue from "vue";
import Vuex from "vuex";
import auth from "./__auth";
import common from "./common";
import base from "./base";
import staticLabels from "./staticLabels";

// Updated version
import homeModule from "./Modules/homeModule";
import leaderModule from "./Modules/leaderModule";
import winnersModule from "./Modules/winnersModule";
import profileModule from "./Modules/profileModule";
import userModule from "./Modules/userModule";
import favoriteModule from "./Modules/favoriteModule";
import votingModule from "./Modules/votingModule";
import overlayModule from "./Modules/overlayModule";
import searchModule from "./Modules/searchModule";
import uxModule from "./Modules/uxModule";

const state = {};
const mutations = {};
const actions = {};

// end
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: auth,
    common: common,
    base: base,
    staticLabels: staticLabels,

    homeModule: homeModule,
    leaderModule: leaderModule,
    winnersModule: winnersModule,
    profileModule: profileModule,
    userModule: userModule,
    favoriteModule: favoriteModule,
    votingModule: votingModule,
    overlayModule: overlayModule,
    searchModule: searchModule,
    uxModule: uxModule,
  },

  state,
  mutations,
  actions,
});
