<template>
  <!----------------------------------------TAB 4 ----------------------------------->
  <div id="tabs-4">
    <div class="tabWrap">
      <div class="tx-c">
        <h2 class="akrobat c-999"><i class="icon-chart-bar"></i> Rankings</h2>
      </div>
      <!------ START COMPLETE WRAP ""RANKINGS"" TAB -------Display the accumulated point of all users for periods etc shown - POINTS = numbe rof + votes they have received on all their posts combined------>
      <div class="tab-users">
        <!-- ********************************************************************************  
                - KEY for color chnages  (add classes) -<br>
                
                A Position NO CHANGE = BLACK 
                                    <span class=""><i class=""></i>42</span><span>change</span>
                                    
                A Position change UP in this leaderboard = GREEN - add span class (.up) and add i class (icon-up-open) into the 2nd cells as shown below
                                    <span class="up"><i class="icon-up-open"></i>42</span><span>change</span>
                                    
                A Position change DOWN in this leaderboard = RED - add span class (.down) and add i class (icon-down-open) into the 2nd cells as shown below
                                    <span class="down"><i class="icon-down-open"></i>42</span><span>change</span> 
                                    
                ******************************************************************************** -->
        <!-- I have shown WEEK data - ie. users with most points this week--
                I use css :before pseudo class to populate the "Week's Points" title on mobile devices, so use the follow depending on if it is today, this week, this month, this year or All time
                eg: if the data i This month change the css to...
                table.power-users td:nth-of-type(4):before { content: "{Month's} Points"; } 
                and also chnage the html 4th cell to {Month) pts
                -->

        <nav class="author-tabs" data-user-id="1374234">
          <ul>
            <li
              v-for="(tab, index) in rankTabs"
              :key="index"
              :class="{ active: activeTab === index }"
              @click="activeTab = index"
            >
              <a>{{ tab }}</a>
            </li>
          </ul>
        </nav>
        <table role="table" class="power-users">
          <thead role="rowgroup">
            <tr role="row"></tr>
          </thead>
          <tbody role="rowgroup">
            <tr role="row"></tr>
            <tr role="row">
              <td role="cell">
                <a
                  ><img
                    id=""
                    class="pho-circs pho-2"
                    title="View {Alexandra Attwood}"
                    src="images/profile-image-square.jpg"
                    alt="{Alexandra Attwood}"
                /></a>
                <h3>Alexandra Attwood</h3>
                <!-- full name -->
                <h5>Member: {3} years, {11} months</h5>
                <!-- member since -->
              </td>
              <td role="cell" title="Leaderboard position">
                <span>#1</span><span>rank</span>
              </td>
              <td role="cell" title="Position change">
                <span class="up"><i class="icon-up-open"></i>42</span
                ><span>change</span>
              </td>
              <td role="cell" title="Points this period">
                <span>674,999</span><span>{week} pts</span>
              </td>
              <td role="cell" title="All time points">
                <span>945,987</span><span>total pts</span>
              </td>
            </tr>
            <tr role="row">
              <td role="cell">
                <a
                  ><img
                    id=""
                    class="pho-circs pho-2"
                    title="View {Alexandra Attwood}"
                    src="images/profile-image-square.jpg"
                    alt="{Alexandra Attwood}"
                /></a>
                <h3>Alexandra Attwood</h3>
                <!-- full name -->
                <h5>Member: {2} years, {6} months</h5>
                <!-- member since -->
              </td>
              <td role="cell" title="Leaderboard position">
                <span>#2</span><span>rank</span>
              </td>
              <td role="cell" title="Position change">
                <span class=""><i class=""></i>0</span><span>change</span>
              </td>
              <td role="cell" title="Points this period">
                <span>474,999</span><span>{week} pts</span>
              </td>
              <td role="cell" title="All time points">
                <span>545,987</span><span>total pts</span>
              </td>
            </tr>
            <tr role="row">
              <td role="cell">
                <a
                  ><img
                    id=""
                    class="pho-circs pho-2"
                    title="View {Alexandra Attwood}"
                    src="images/profile-image-square.jpg"
                    alt="{Alexandra Attwood}"
                /></a>
                <h3>Alexandra Attwood</h3>
                <!-- full name -->
                <h5>Member: {11} months</h5>
                <!-- member since -->
              </td>
              <td role="cell" title="Leaderboard position">
                <span>#3</span><span>rank</span>
              </td>
              <td role="cell" title="Position change">
                <span class="up"><i class="icon-up-open"></i>178</span
                ><span>change</span>
              </td>
              <td role="cell" title="Points this period">
                <span>374,999</span><span>{week} pts</span>
              </td>
              <td role="cell" title="All time points">
                <span>545,987</span><span>total pts</span>
              </td>
            </tr>
            <tr role="row">
              <td role="cell">
                <a
                  ><img
                    id=""
                    class="pho-circs pho-2"
                    title="View {Alexandra Attwood}"
                    src="images/profile-image-square.jpg"
                    alt="{Alexandra Attwood}"
                /></a>
                <h3>Alexandra Attwood</h3>
                <!-- full name -->
                <h5>Member: {3} years, {11} months</h5>
                <!-- member since -->
              </td>
              <td role="cell" title="Leaderboard position">
                <span>#4</span><span>rank</span>
              </td>
              <td role="cell" title="Position change">
                <span class="down"><i class="icon-down-open"></i>224</span
                ><span>change</span>
              </td>
              <td role="cell" title="Points this period">
                <span>274,999</span><span>{week} pts</span>
              </td>
              <td role="cell" title="All time points">
                <span>345,987</span><span>total pts</span>
              </td>
            </tr>
            <tr role="row">
              <td role="cell">
                <a
                  ><img
                    id=""
                    class="pho-circs pho-2"
                    title="View {Alexandra Attwood}"
                    src="images/profile-image-square.jpg"
                    alt="{Alexandra Attwood}"
                /></a>
                <h3>Alexandra Attwood</h3>
                <!-- full name -->
                <h5>Member: {3} years, {11} months</h5>
                <!-- member since -->
              </td>
              <td role="cell" title="Leaderboard position">
                <span>#5</span><span>rank</span>
              </td>
              <td role="cell" title="Position change">
                <span class="up"><i class="icon-up-open"></i>78</span
                ><span>change</span>
              </td>
              <td role="cell" title="Points this period">
                <span>174,999</span><span>{week} pts</span>
              </td>
              <td role="cell" title="All time points">
                <span>645,987</span><span>total pts</span>
              </td>
            </tr>
            <tr role="row">
              <td role="cell">
                <a
                  ><img
                    id=""
                    class="pho-circs pho-2"
                    title="View {Alexandra Attwood}"
                    src="images/profile-image-square.jpg"
                    alt="{Alexandra Attwood}"
                /></a>
                <h3>Alexandra Attwood</h3>
                <!-- full name -->
                <h5>Member: {3} years, {11} months</h5>
                <!-- member since -->
              </td>
              <td role="cell" title="Leaderboard position">
                <span>#6</span><span>rank</span>
              </td>
              <td role="cell" title="Position change">
                <span class="down"><i class="icon-down-open"></i>22,378</span
                ><span>change</span>
              </td>
              <td role="cell" title="Points this period">
                <span>74,999</span><span>{week} pts</span>
              </td>
              <td role="cell" title="All time points">
                <span>345,987</span><span>total pts</span>
              </td>
            </tr>
            <tr role="row">
              <td role="cell">
                <a
                  ><img
                    id=""
                    class="pho-circs pho-2"
                    title="View {Alexandra Attwood}"
                    src="images/profile-image-square.jpg"
                    alt="{Alexandra Attwood}"
                /></a>
                <h3>Alexandra Attwood</h3>
                <!-- full name -->
                <h5>Member: {3} years, {11} months</h5>
                <!-- member since -->
              </td>
              <td role="cell"><span>#7</span><span>rank</span></td>
              <td role="cell">
                <span class=""><i class=""></i>0</span><span>change</span>
              </td>
              <td role="cell"><span>67,999</span><span>{week} pts</span></td>
              <td role="cell"><span>345,987</span><span>total pts</span></td>
            </tr>
            <tr role="row">
              <td role="cell">
                <a
                  ><img
                    id=""
                    class="pho-circs pho-2"
                    title="View {Alexandra Attwood}"
                    src="images/profile-image-square.jpg"
                    alt="{Alexandra Attwood}"
                /></a>
                <h3>Alexandra Attwood</h3>
                <!-- full name -->
                <h5>Member: {3} years, {11} months</h5>
                <!-- member since -->
              </td>
              <td role="cell"><span>#8</span><span>rank</span></td>
              <td role="cell">
                <span class=""><i class=""></i>0</span><span>change</span>
              </td>
              <td role="cell"><span>44,999</span><span>{week} pts</span></td>
              <td role="cell"><span>345,987</span><span>total pts</span></td>
            </tr>
            <tr role="row">
              <td role="cell">
                <a
                  ><img
                    id=""
                    class="pho-circs pho-2"
                    title="View {Alexandra Attwood}"
                    src="images/profile-image-square.jpg"
                    alt="{Alexandra Attwood}"
                /></a>
                <h3>Alexandra Attwood</h3>
                <!-- full name -->
                <h5>Member: {3} years, {11} months</h5>
                <!-- member since -->
              </td>
              <td role="cell"><span>#9</span><span>rank</span></td>
              <td role="cell">
                <span class="down"><i class="icon-down-open"></i>22,378</span
                ><span>change</span>
              </td>
              <td role="cell"><span>42,999</span><span>{week} pts</span></td>
              <td role="cell"><span>345,987</span><span>total pts</span></td>
            </tr>
            <tr role="row">
              <td role="cell">
                <a
                  ><img
                    id=""
                    class="pho-circs pho-2"
                    title="View {Alexandra Attwood}"
                    src="images/profile-image-square.jpg"
                    alt="{Alexandra Attwood}"
                /></a>
                <h3>Alexandra Attwood</h3>
                <!-- full name -->
                <h5>Member: {3} years, {11} months</h5>
                <!-- member since -->
              </td>
              <td role="cell"><span>#10</span><span>rank</span></td>
              <td role="cell">
                <span class=""><i class=""></i>0</span><span>change</span>
              </td>
              <td role="cell"><span>23,999</span><span>{week} pts</span></td>
              <td role="cell"><span>345,987</span><span>total pts</span></td>
            </tr>
            <tr role="row">
              <td role="cell">
                <a
                  ><img
                    id=""
                    class="pho-circs pho-2"
                    title="View {Alexandra Attwood}"
                    src="images/profile-image-square.jpg"
                    alt="{Alexandra Attwood}"
                /></a>
                <h3>Alexandra Attwood</h3>
                <!-- full name -->
                <h5>Member: {3} years, {11} months</h5>
                <!-- member since -->
              </td>
              <td role="cell"><span>#11</span><span>rank</span></td>
              <td role="cell">
                <span class=""><i class=""></i>0</span><span>change</span>
              </td>
              <td role="cell"><span>21,999</span><span>{week} pts</span></td>
              <td role="cell"><span>345,987</span><span>total pts</span></td>
            </tr>
            <tr role="row">
              <td role="cell">
                <a
                  ><img
                    id=""
                    class="pho-circs pho-2"
                    title="View {Alexandra Attwood}"
                    src="images/profile-image-square.jpg"
                    alt="{Alexandra Attwood}"
                /></a>
                <h3>Alexandra Attwood</h3>
                <!-- full name -->
                <h5>Member: {3} years, {11} months</h5>
                <!-- member since -->
              </td>
              <td role="cell"><span>#12</span><span>rank</span></td>
              <td role="cell">
                <span class=""><i class=""></i>0</span><span>change</span>
              </td>
              <td role="cell"><span>17,999</span><span>{week} pts</span></td>
              <td role="cell"><span>345,987</span><span>total pts</span></td>
            </tr>
            <tr role="row">
              <td role="cell">
                <a
                  ><img
                    id=""
                    class="pho-circs pho-2"
                    title="View {Alexandra Attwood}"
                    src="images/profile-image-square.jpg"
                    alt="{Alexandra Attwood}"
                /></a>
                <h3>Alexandra Attwood</h3>
                <!-- full name -->
                <h5>Member: {3} years, {11} months</h5>
                <!-- member since -->
              </td>
              <td role="cell"><span>#13</span><span>rank</span></td>
              <td role="cell">
                <span class=""><i class=""></i>0</span><span>change</span>
              </td>
              <td role="cell"><span>14,999</span><span>{week} pts</span></td>
              <td role="cell"><span>345,987</span><span>total pts</span></td>
            </tr>
            <tr role="row">
              <td role="cell">
                <a
                  ><img
                    id=""
                    class="pho-circs pho-2"
                    title="View {Alexandra Attwood}"
                    src="images/profile-image-square.jpg"
                    alt="{Alexandra Attwood}"
                /></a>
                <h3>Alexandra Attwood</h3>
                <!-- full name -->
                <h5>Member: {3} years, {11} months</h5>
                <!-- member since -->
              </td>
              <td role="cell"><span>#14</span><span>rank</span></td>
              <td role="cell">
                <span class=""><i class=""></i>0</span><span>change</span>
              </td>
              <td role="cell"><span>8,999</span><span>{week} pts</span></td>
              <td role="cell"><span>345,987</span><span>total pts</span></td>
            </tr>
          </tbody>
        </table>
      </div>
      <!------ END COMPLETE WRAP ""RANKINGS"" TAB -------Display the accumulated point of all users for periods etc shown - POINTS = numbe rof + votes they have received on all their posts combined------>
      <div class="tx-c">
        <a class="button tertiary mt-20" title="Load more leaders">
          <span class="show-for-large">Show more</span>
          <span class="arrow"></span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Rank",
  data() {
    return {
      activeTab: 1,
      rankTabs: ["Today", "This Week", "This Month", "This Year", "All Time"],
    };
  },
};
</script>