import axios from "axios";

export default {
  state: {
    user: {
      first_name: "",
      last_name: "",
      email: "",
      votes: "",
      token: "",
      boost: "",
      avatar: "",
      bio: "",
      city: "",
      country: "",
      site_url: "",
      facebook_url: "",
      instagram_url: "",
      twitter_url: "",
      linkedin_url: "",
      pinterest_url: "",
      subscription_email: "",
      user_status: false,
    },
    isLogin: false,
    isLoading: false,
    ErrorMessage: "",
    accessToken: "",
    refreshToken: "",
    following: {},
    follower: {},
    followingCount: 0,
    followerCount: 0,
    unread_number: 0,
    showSignUpConfirmationModal: false,
    loginType: "",
    socialAccessToken: "",
    showUserBaneModal: false,
    admin: false,
    FCMToken: "",
    unreadNotificationCount: 0,
    notification: [],
    // theme data
    selectedTheme: "",
    availableTheme: [],
    usertheme: [],

    totalNewsCount: 0,
    resendEmail: "",
    // token data
    tokenScheme: [],
    cartData: [],
    cartItemsCount: 0,
    // payment
    // stripe_payment_intent: "",
  },

  mutations: {
    // set_payment_intent(state, data) {
    //   state.stripe_payment_intent = data;
    // },
    set_user_token(state, data) {
      state.user.token = data;
    },
    set_user_boost(state, data) {
      state.user.boost = data;
    },
    set_cart_data(state, data) {
      state.cartData = data;
      state.cartItemsCount = data.length;
    },
    set_token_scheme(state, data) {
      state.tokenScheme = data;
    },
    set_resend_email(state, data) {
      state.resendEmail = data;
    },
    set_user_status(state, data) {
      state.user.user_status = data;
    },
    set_available_theme(state, data) {
      state.availableTheme = data;
    },
    set_user_theme(state, data) {
      state.usertheme = data;
    },
    set_selected_theme(state, data) {
      state.selectedTheme = data;
    },
    set_admin_status(state, data) {
      state.admin = data;
    },
    set_FCMToken(state, data) {
      state.FCMToken = data;
    },
    add_following(state, data) {
      state.following.push(data);
    },
    change_password(state, data) {
      state.user.password = data;
    },
    set_total_news_count(state, data) {
      state.totalNewsCount = data;
    },
    set_notofication(state, payload) {
      state.notification = payload;
    },
    append_notification(state, payload) {
      state.notification = [payload, ...state.notification];
    },
    unread_notification_count(state, data) {
      state.unreadNotificationCount = data;
    },
    new_notification(state) {
      state.unread_number++;
    },
    set_following(state, data) {
      state.following = data.data;
      state.followingCount = data.data.length;
    },
    set_follower(state, data) {
      state.follower = data.data;
      state.followerCount = data.data.length;
    },
    set_loginType(state, data) {
      state.loginType = data;
    },
    set_socialAccessToken(state, data) {
      state.socialAccessToken = data;
    },
    removeFollowing(state, email) {
      for (let item in state.following) {
        if (state.following[item].email === email) {
          state.following.splice(item, 1);
          break;
        }
      }
    },
    removeFollower(state, email) {
      for (let item in state.follower) {
        if (state.follower[item].email === email) {
          state.follower.splice(item, 1);
          break;
        }
      }
    },
    removeFollowingCount(state, data) {
      state.followingCount = state.followingCount + data;
    },
    removeFollowerCount(state, data) {
      state.followerCount = state.followerCount + data;
    },
    set_login(state, data) {
      state.isLogin = data;
    },
    set_status(state, data) {
      state.isLoading = data;
    },
    update_error_message(state, message) {
      state.ErrorMessage = message;
    },
    set_user(state, data) {
      state.user = {
        ...state.user,
        ...data,
      };
      for (let key in state.user) {
        if (state.user[key] === "null") state.user[key] = "";
      }
    },
    set_user_notifications(state, data) {
      state.user.new_follower_notification = data.new_follower_notification;
      state.user.new_comment_notification = data.new_comment_notification;
      state.user.friend_post_notification = data.friend_post_notification;
    },
    set_subemail(state, data) {
      state.user.subscription_email = data;
    },
    set_unreads(state, data) {
      state.unread_number = data;
    },
    reduce_my_votes(state) {
      if (state.user.votes > 0) {
        state.user.votes--;
      }
    },
    toggle_signup_modal(state, data) {
      state.showSignUpConfirmationModal = data;
    },
    logout(state) {
      state.user = {
        first_name: "",
        last_name: "",
        email: "",
        votes: "",
        avatar: "",
        bio: "",
        city: "",
        country: "",
        site_url: "",
        facebook_url: "",
        instagram_url: "",
        twitter_url: "",
        linkedin_url: "",
        pinterest_url: "",
        subscription_email: "",
      };
    },
  },

  actions: {
    checkUserIp({ commit }, ip) {
      axios({
        url: "auth/user/check-ip/",
        method: "POST",
        data: {
          ipAddress: ip,
        },
      }).then((response) => {
        commit("cal_pressure");
        return response.data;
      });
    },
    async changePassword({ commit }, password) {
      await axios({
        url: "auth/user/change-password/",
        data: {
          password: password,
        },
        method: "POST",
      })
        .then((response) => {
          if (response.status === 200) {
            commit("change_password", password);
          }
        })
        .catch((error) => {
          console.log(typeof error);
        });
    },
    async resetPassword({ commit, state }, password) {
      await axios({
        url: "auth/user/reset-password/",
        data: {
          email: localStorage.getItem("user_email"),
          password: password,
        },
        method: "POST",
      })
        .then((response) => {
          if (response.status === 200) {
            localStorage.removeItem("user_email");
            commit("set_my_ozone", 0);
            state.toastMsg.message = "Password Reset Suceesfully!";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeUserNotificationSettings({ commit }, notifications) {
      axios({
        url: "auth/user/settings/",
        data: notifications,
        method: "POST",
      })
        .then((response) => {
          if (response.status === 200) {
            commit("set_user_notifications", notifications);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async forgetPassword({ commit }, email) {
      await axios({
        url: "auth/user/forget_password/",
        data: {
          email: email,
        },
        method: "POST",
      })
        .then((response) => {
          if (response.status === 200) {
            commit("set_my_ozone", 0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setLogin({ commit }, isLogin) {
      commit("set_login", isLogin);
    },
    async setUserSubEmail({ commit }, subemail) {
      const tmp = await axios({
        url: "auth/user/subscribe/?subemail=" + subemail,
        method: "get",
      })
        .then((response) => {
          commit("set_subemail", subemail);
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
      return tmp;
    },
    async unSubscribe(_, sub_email) {
      const tmp = await axios({
        url: "auth/user/un-subscribe/",
        data: {
          sub_email: sub_email,
        },
        method: "POST",
      })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
      return tmp;
    },
    async getUser(context) {
      await axios({
        url: "auth/user/get/",
        method: "GET",
      })
        .then((response) => {
          if (response.data.admin === true) {
            context.commit("set_admin_status", response.data.admin);
          }
          context.commit("set_available_theme", response.data.available_themes);
          context.commit("set_selected_theme", response.data.selected_theme[0]);
          context.commit("set_user_theme", response.data.userThemes);
          context.commit("set_user", response.data);
          context.commit("set_user_token", response.data.token);
          context.commit("set_user_boost", response.data.rewarded_boost);
          context.commit("userModule/SET_USER_DATA", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getFollowing({ commit }) {
      const tmp = await axios({
        url: "follower/get-following/",
        method: "GET",
      })
        .then((response) => {
          commit("set_following", response);
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
      return tmp;
    },
    async getFollower({ commit }) {
      const tmp = await axios({
        url: "follower/get-follower/",
        method: "GET",
      })
        .then((response) => {
          commit("set_follower", response);
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
      return tmp;
    },
    getUnreads({ commit }) {
      axios({
        url: "notification/get-unreads/",
        method: "GET",
      })
        .then((response) => {
          commit("set_unreads", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async unreadNotificationCount({ commit }) {
      await axios({
        url: "/notification/unread-notification-count/",
        method: "post",
      })
        .then((response) => {
          commit("unread_notification_count", response.data.unreadCount);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async readNotification(_, data) {
      await axios({
        url: "/notification/read-notification/",
        method: "post",
        data: {
          notificationId: data,
        },
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateErrorMsg({ commit }, msg) {
      commit("update_error_message", msg);
    },
    async checkType(_, data) {
      const tmp = await axios({
        url: "auth/user/social-login/",
        data: data,
        method: "POST",
      })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
      return tmp;
    },
    signUp({ commit }, userData) {
      return new Promise(() => {
        commit("set_login");
        axios({ url: "auth/user/create/", data: userData, method: "POST" })
          .then((response) => {
            localStorage.setItem("email", userData.email);
            localStorage.setItem("password", userData.password);
            if (response.data === "created") {
              commit("update_error_message", "");
              if (
                userData.loginType === "facebook" ||
                userData.loginType === "google"
              ) {
                commit("toggle_signup_modal", false);
              } else {
                commit("toggle_signup_modal", true);
              }
              commit("set_my_ozone", 0);
            }
            switch (response.data) {
              case "created":
                commit("update_error_message", "");
                if (
                  userData.loginType === "facebook" ||
                  userData.loginType === "google"
                ) {
                  commit("toggle_signup_modal", false);
                } else {
                  commit("toggle_signup_modal", true);
                }
                commit("set_my_ozone", 0);
                break;
              case "UNIQUE":
                commit(
                  "update_error_message",
                  "This email address is already activated"
                );
                break;

              case "NO SECURE":
                commit("update_error_message", "Please enter strong password");
                break;
            }
          })
          .catch((error) => {
            commit("update_error_message", "Use another email address!");
            console.log(error);
          });
      });
    },
    async resendConfirmationEmail(_, data) {
      const tmp = await axios({
        url: "auth/user/resend-email/",
        data: { email: data },
        method: "POST",
      })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
      return tmp;
    },
    async login({ commit, dispatch, state }, user) {
      localStorage.clear();
      const tmp = await axios({
        url: "auth/token/obtain/",
        data: {
          email: user.email,
          password: user.password,
          FCM_Token: state.FCMToken,
        },
        method: "POST",
      })
        .then((response) => {
          localStorage.setItem("accessToken", response.data.token);
          localStorage.setItem("refreshToken", "");
          axios.defaults.headers.common["Authorization"] =
            "JWT " + response.data.token;
          dispatch("getUser");
          commit("set_login", true);
          commit("set_my_ozone", 1);
          commit("update_error_message", "");
          commit("set_socialAccessToken", "");
          dispatch("getFollowing");
          dispatch("unreadNotificationCount");
          return response;
        })
        .catch((error) => {
          localStorage.clear();
          if (error.response.status !== 200) {
            commit("update_error_message", "*Please enter correct credentials");
          } else if (
            error.response.status !== 200 &&
            error.response.status === 404
          ) {
            commit("update_error_message", "You are baned");
          }
          return error.response;
        });
      return tmp;
    },
    contactToAdmin(_, data) {
      axios({
        url: "auth/user/user-admin-contact/",
        method: "POST",
        data: { user_email: data.userMail, note: data.note },
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async suspendedTime(_, data) {
      const tmp = await axios({
        url: "/auth/user/suspended-time/",
        method: "POST",
        data: {
          email: data.user_email,
        },
      })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      return tmp;
    },
    GetUserByUUID(_, uuid) {
      const tmp = axios({
        url: "auth/user/get-user/?uuid=" + uuid,
        method: "GET",
      })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      return tmp;
    },
    setStatus({ commit }, status) {
      commit("set_status", status);
    },
    async logout({ commit }, FcmToken) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      commit("logout");
      commit("set_login", false);
      await axios({
        url: "auth/user/remove-fcm-token/",
        method: "post",
        data: { FcmToken: FcmToken },
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async setUserStatus({ commit }, data) {
      await axios({
        url: "auth/user/set-user-status/",
        data: data,
        method: "POST",
      })
        .then((response) => {
          if (response.status === 200) {
            commit("set_user_status", data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async changeTheme(_, data) {
      await axios({
        url: "auth/user/change-theme/",
        method: "POST",
        data: { theme: data },
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async siteView(_, data) {
      await axios({
        url: "auth/user/site-view/",
        method: "POST",
        data: data,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async TokenScheme({ commit }) {
      await axios({
        url: "user/token/buy-token/",
        method: "GET",
      })
        .then((response) => {
          commit("set_token_scheme", response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async AddTokenToCart(_, data) {
      await axios({
        url: "user/token/add-token-to-cart/",
        method: "POST",
        data: data,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async CartData({ commit }) {
      await axios({
        url: "user/token/add-token-to-cart/",
        method: "GET",
      })
        .then((response) => {
          commit("set_cart_data", response.data.cartData);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    AddGasTheme(_, data) {
      const tmp = axios({
        url: "user/token/element-theme/",
        method: "POST",
        data: data,
      })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      return tmp;
    },
    CheckThemeToken(_, data) {
      const tmp = axios({
        url: "user/token/check-theme-token/",
        method: "POST",
        data: data,
      })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      return tmp;
    },
  },
};
