export const state = {
  tabs: [
    {
      title: "New Posts",
      text1: "Latest",
      text2: "",
    },
    {
      title: "Popular Today",
      text1: "Trending",
      text2: "",
    },
    {
      title: "Low on O2",
      text1: "Expiring",
      text2: "",
    },
    {
      title: "Lucky Dip",
      text1: "Random",
      text2: "",
    },
  ],
  leaderTab: [
    {
      title: "Top of Leaderboard",
      text1: "Leaders",
      text2: "",
    },
    {
      title: "Popular Today",
      text1: "Trending",
      text2: "",
    },
    {
      title: "Low on O2",
      text1: "Expiring",
      text2: "",
    },
    {
      title: "Lucky Dip",
      text1: "Random",
      text2: "",
    },
  ],
  favoriteTab: [
    {
      title: "New Posts",
      text1: "Latest",
      text2: "",
    },
    {
      title: "Oldest Posts",
      text1: "Oldest",
      text2: "",
    },
    {
      title: "A-Z : Z-A",
      text1: "Sort A-Z",
      text2: "",
    },
  ],
  profilePostTab: [
    {
      title: "New Posts",
      text1: "Latest",
      text2: "",
    },
    {
      title: "Oldest Posts",
      text1: "Oldest",
      text2: "",
    },
    {
      title: "Lucky Dip",
      text1: "Random",
      text2: "",
    },
    {
      title: "A-Z : Z-A",
      text1: "Sort A-Z",
      text2: "",
    },
  ],
  header_text: {
    Login: {
      h1_text1: "O2.",
      h1_text2: "POSITIVE.",
      h2_text: "",
      p_text1: "Share something great & vote on your faves!",
      p_text2: "",
    },
    Home: {
      h1_text1: "O2.",
      h1_text2: "POSITIVE.",
      h2_text: "",
      p_text1: "Share something great & vote on your faves!",
      p_text2: "Top 10 winners every week.",
    },
    OpenedOverlay: {
      h1_text1: "O2.",
      h1_text2: "POSITIVE.",
      h2_text: "",
      p_text1: "Share something great & vote on your faves!",
      p_text2: "",
    },
    Activation: {
      h1_text1: "O2.",
      h1_text2: "POSITIVE.",
      h2_text: "",
      p_text1: "Share something great & vote on your faves!",
      p_text2: "",
    },
    ForgetPassword: {
      h1_text1: "O2.",
      h1_text2: "POSITIVE.",
      h2_text: "",
      p_text1: "Share something great & vote on your faves!",
      p_text2: "Top 10 winners every week.",
    },
    HowItWorks: {
      h1_text1: "Main.",
      h1_text2: "Features.",
      h2_text: "How it works",
      p_text1: "",
      p_text2: "",
    },
    WhatIsO2: {
      h1_text1: "What is.",
      h1_text2: "O2?",
      h2_text: "A website for good",
      p_text1: "",
      p_text2: "",
    },
    Leaderboard: {
      h1_text1: "Current.",
      h1_text2: "Leaders.",
      h2_text: "Pumped and rising fast",
      p_text1: "",
      p_text2: "",
    },
    Winners: {
      h1_text1: "Weekly.",
      h1_text2: "Winners.",
      h2_text: "Legends of the past",
      p_text1: "",
      p_text2: "",
    },
    FavoritePosts: {
      h1_text1: "Favourite. ",
      h1_text2: "Posts.",
      h2_text: "Things I want to keep",
      p_text1: "",
      p_text2: "",
    },
    O2hq: {
      h1_text1: "O2. ",
      h1_text2: "HQ.",
      h2_text: "Mission control",
      p_text1: "",
      p_text2: "",
    },
    User: {
      h1_text1: "",
      h1_text2: "",
      h2_text: "",
      p_text1: "",
      p_text2: "",
    },
    Profile: {
      h1_text1: "",
      h1_text2: "",
      h2_text: "",
      p_text1: "",
      p_text2: "",
    },
    TermsOfService: {
      h1_text1: "Terms of. ",
      h1_text2: "Service.",
      h2_text: "Rules",
      p_text1: "",
      p_text2: "",
    },
    Search: {
      h1_text1: "Search.",
      h1_text2: "Results.",
      h2_text: "",
      p_text1: "",
      p_text2: "",
    },
    PrivacyPolicy: {
      h1_text1: "Privacy.",
      h1_text2: "Policy.",
      h2_text: "We take your privacy seriously",
      p_text1: "",
      p_text2: "",
    },
    Tokens: {
      h1_text1: "O2.",
      h1_text2: "Tokens.",
      h2_text: "Your currency for good",
      p_text1: "",
      p_text2: "",
    },
    PageNotFound: {
      h1_text1: "OOPS.",
      h1_text2: "ERROR.",
      h2_text: "That page has evaporated",
      p_text1: "",
      p_text2: "",
    },
    Themes: {
      h1_text1: "O2.",
      h1_text2: "Themes.",
      h2_text: "You've earned it!",
      p_text1: "",
      p_text2: "",
    },
  },
  votes_text: [
    {
      mobile_vote_status: "Day 1/7",
      gauge_h2: "All Systems Go!",
      gauge_h3: "7 days & counting.",
      pressure_class: "pressure-280",
    },
    {
      mobile_vote_status: "Day 2/7",
      gauge_h2: "Get Voting!",
      gauge_h3: "6 days. Get voting.",
      pressure_class: "pressure-240",
    },
    {
      mobile_vote_status: "Day 3/7",
      gauge_h2: "Pressure's on!",
      gauge_h3: "5 days left.",
      pressure_class: "pressure-200",
    },
    {
      mobile_vote_status: "Day 4/7",
      gauge_h2: "Time's Ticking",
      gauge_h3: "4 days. Time's ticking.",
      pressure_class: "pressure-160",
    },
    {
      mobile_vote_status: "Day 5/7",
      gauge_h2: "No guts. No glory.",
      gauge_h3: "Pump some gas!",
      pressure_class: "pressure-120",
    },
    {
      mobile_vote_status: "Day 6/7",
      gauge_h2: "Brace yourself",
      gauge_h3: "2 days. Brace yourself!",
      pressure_class: "pressure-80",
    },
    {
      mobile_vote_status: "Day 7/7",
      gauge_h2: "Sucking vapours!",
      gauge_h3: "Send for backup.",
      pressure_class: "pressure-40",
    },
  ],
};
export const leaderTab = {
  leader: [
    {
      title: "New Posts",
      text1: "Latest",
      text2: "",
    },
    {
      title: "Trending",
      text1: "Leaders",
      text2: "",
    },
    {
      title: "Low on O2",
      text1: "Expiring",
      text2: "",
    },
    {
      title: "Lucky Dip",
      text1: "Random",
      text2: "",
    },
  ],
};
export const mutations = {
  SITE_HEADER(state, data) {
    state.header_text.Profile.h1_text1 = data.first_name + ". ";
    state.header_text.Profile.h1_text2 = data.last_name + ". ";
    if (data.country && !(data.country === "null")) {
      state.header_text.Profile.h2_text = data.city + ", " + data.country;
    } else {
      if (data.city !== "null") state.header_text.Profile.h2_text = data.city;
    }
  },
  SITE_HEADER_MINE(state, data) {
    state.header_text.User.h1_text1 = data.first_name + ". ";
    state.header_text.User.h1_text2 = data.last_name + ". ";
    if (data.country && !(data.country === "null")) {
      state.header_text.User.h2_text = data.city + ", " + data.country;
    } else {
      if (data.city !== "null") state.header_text.User.h2_text = data.city;
    }
  },
};
export const getters = {
  tabs: ({ tabs }) => tabs,
};
export const actions = {
  set_siteheader({ commit }, data) {
    commit("SITE_HEADER", data);
  },
  set_profile_header({ commit }, data) {
    commit("SITE_HEADER_MINE", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
