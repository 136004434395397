<template>
  <b-modal
    id="modal-prevent-closing"
    ref="modal"
    title="Invite a Friend"
    ok-title="Submit"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
    @cancel="handleCancel"
    @close="handleClose"
    dialog-class="email-share-modal"
    v-model="show"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group label="To" label-for="to-email" :state="nameState">
        <div :class="errorClass">
          <b-form-input
            id="to-email"
            v-model="to"
            placeholder="Friend's Email"
            :state="nameState"
            required
          ></b-form-input>
          <p class="error-message" v-if="this.errorMsg">
            <i class="icon-attention-circled">{{ this.errorMsg }}</i>
          </p>
        </div>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import { BModal, VBModal, BFormGroup, BFormInput } from "bootstrap-vue";
export default {
  props: ["show"],
  data: () => ({
    to: "",
    nameState: null,
    errorMsg: "",
    errorClass: "",
  }),

  components: {
    BModal,
    BFormGroup,
    BFormInput,
  },
  directives: {
    "b-modal": VBModal,
    "b-form-group": VBModal,
    "b-form-input": VBModal,
  },
  methods: {
    resetModal() {
      this.to = "";
      this.nameState = null;
      this.errorMsg = "";
      this.errorClass = "";
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (this.validateEmail()) {
        this.errorMsg = "";
        this.$emit("handle-submit", {
          toMail: this.to,
        });
      } else {
        this.errorClass = "is-invalid";
        this.errorMsg = "Enter Valid Email Address.";
        setTimeout(() => {
          this.errorMsg = "";
          this.errorClass = "";
        }, 5000);
      }
    },
    validateEmail() {
      const expression =
        /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (expression.test(this.to)) {
        return true;
      } else {
        return false;
      }
    },
    handleClose() {
      this.$emit("toggle-modal", false);
    },
    handleCancel() {
      this.$emit("toggle-modal", false);
    },
  },
};
</script>
