import * as moment from "moment";
export default {
  data() {
    return {
      currentWeek: 0,
    };
  },
  created() {
    let $this = this;
    let now = moment().format();
    $this.currentWeek = moment(now).isoWeek();
    $this.currentYear = moment(now).isoWeekYear();
  },
};
