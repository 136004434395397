<template>
  <div id="content" role="main">
    <WinnersOverlay
      v-if="isOverlay"
      :post_id="selectedPost_id"
      :isOverlay="isOverlay"
      @close="closeOveraly"
      @showOtherPost="showOtherPost"
    />
    <p class="mb-40">
      <i class="icon-calendar"></i>Week :<strong>
        {{ this.selectedWeek === 0 ? 1 : this.selectedWeek }}</strong
      >
    </p>
    <PostCreateOverlay />
    <a
      class="
        show-panel
        button
        primary
        icon-upload-cloud
        right
        mt--5
        pl-15
        pr-20
        hideUnder575
      "
      @click="showPostCreateOverlay"
    >
      Add Post
    </a>
    <h2 class="inline-block akrobat m-0 mt-2 mb-20">walls of Fame</h2>
    <div class="leaderboard-filter">
      <div class="wrapper">
        <div class="fieldset">
          <label for="select-leaders-year" class="offset" title="Select year"
            >Select year</label
          >
          <div class="select-holder">
            <select v-model="selectedYear">
              <option
                v-for="(year, index) in range(startUpYear, currentYear)"
                :key="index"
                :value="year"
              >
                {{ year }}
              </option>
            </select>
          </div>
        </div>
        <div class="weeks-slider-wrap" :class="curr_year_class">
          <div>
            <VueSlickCarousel
              ref="carousel"
              :key="selectedYear"
              v-bind="settings"
            >
              <li
                v-for="index in range(startWeek, lastweek)"
                :key="index"
                class="past"
                :class="[
                  { active: index === selectedWeek },
                  index === lastweek ? 'currentweek' : '',
                ]"
                :title="index === lastweek ? `` : `Past Legends: Week ${index}`"
                @click="getWeeklyPosts(index)"
              >
                <a>
                  <span>{{ index }} </span>
                  <div data-title="Current Leaderboard" data-title-right>
                    <i class="icon-chart-bar"></i></div
                ></a>
              </li>
            </VueSlickCarousel>
          </div>
        </div>
      </div>
    </div>
    <div class="tx-ctr">
      <div v-if="winners.length === 0">
        <h2 class="akrobat c-999 noCaps">
          <i class="icon-picture"></i> There were no winners this week.
        </h2>
      </div>
    </div>
    <div class="fearless-leaders no-ws display-flex">
      <div
        class="cst-6"
        :class="col_class"
        v-for="(row, idx) in alignedPosts"
        :key="idx"
      >
        <div
          v-for="(post, index) in row"
          :key="index"
          class="brick"
          style="position: relative"
        >
          <div
            class="crown"
            :data-title="`WINNER ~ Week ${selectedWeek} : LEGEND!`"
            data-title-right
            v-if="idx === 0 && index === 0"
          ></div>
          <div v-else class="top10-circle">
            <div
              :data-title="`POS : ${
                index * columns + idx + 1
              } ~ Week ${selectedWeek}`"
              data-title-right
            >
              <span>{{ index * columns + idx + 1 }}</span>
            </div>
          </div>

          <div class="brick-wrapper">
            <div class="vote-wrapper" :class="noShadow(idx, index)">
              <div
                v-if="post.mature_content"
                class="mature-audience"
                style="display: block"
                @click="checkIfLogin(post.post_id, post.mature_content)"
              >
                <div>
                  <div><i class="icon-eye-off"></i></div>
                  <p class="mature">MATURE AUDIENCE</p>
                  <span>Content may be sensitive</span>
                  <span class="letmesee">LET ME SEE</span>
                </div>
              </div>
              <MatureAudienceLoginModal
                v-if="showLoginModal"
                @colseModal="colseModal"
              />
              <div @click="showOverlay(post.post_id, post.mature_content)">
                <div class="prevent-vid-click"></div>
                <div v-if="post.is_video && post.videos" class="vid-arrow">
                  <div data-title="Video" data-title-top>
                    <span>{{ post.videos.length }}</span>
                  </div>
                </div>
              </div>
              <div class="post-info-block">
                <div class="ext-img">
                  <div v-if="!post.videos">
                    <div v-if="post.images.length > 1">
                      <span data-title="No. of Images" data-title-left>
                        {{ "+" }}{{ post.images.length - 1 }}
                      </span>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      v-if="post.videos.length > 1 && post.images.length === 0"
                    >
                      <span data-title="No. of Videos" data-title-left
                        >{{ "+" }}{{ post.videos.length - 1 }}</span
                      >
                    </div>
                    <div v-else-if="post.images.length && post.videos.length">
                      {{ "+"
                      }}{{ imgCount(post.images.length, post.videos.length) }}
                    </div>
                  </div>
                </div>
                <span
                  class="tip-icon"
                  :class="
                    (post.images.length === 0 && post.videos.length < 2) ||
                    (post.images.length < 2 && !post.videos)
                      ? 'only-like'
                      : ''
                  "
                  :data-title="`${
                    diableFavorite(post.post_id)
                      ? 'Remove from Faves'
                      : 'Add to Faves'
                  }`"
                  data-title-left
                >
                  <a
                    v-show="isLogin && userEmail !== post.author_email"
                    class="icon-heart sav2fav"
                    :class="{ liked: diableFavorite(post.post_id) }"
                    @click="clickFavButton(post.post_id)"
                  >
                  </a>
                </span>
              </div>
              <div v-if="post.mature_content && !isLogin">
                <img src="../assets/images/logintoview.png" />
              </div>
              <div v-else>
                <v-lazy-image v-if="!post.is_video" :src="post.images[0].url" />
                <iframe
                  v-else
                  width="560"
                  height="315"
                  :src="post.videos[0].url"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                >
                </iframe>
              </div>
            </div>
            <p>{{ post.title }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapState } from "vuex";
import WinnersOverlay from "@/components/winners/WinnersOverlay";
import CreateOverlayMixin from "@/mixins/CreateOverlayMixin";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import MatureAudienceLoginModal from "../components/modal/MatureAudienceLoginModal.vue";
import $ from "jquery";
import VLazyImage from "v-lazy-image/v2";

export default {
  name: "Winners",
  components: {
    WinnersOverlay,
    VueSlickCarousel,
    MatureAudienceLoginModal,
    VLazyImage,
  },

  mixins: [CreateOverlayMixin],

  mounted() {
    window.addEventListener("resize", this.resizeWindow());
    this.settings.initialSlide = this.lastweek - 2;
  },

  destroyed() {
    window.removeEventListener("resize", this.resizeWindow());
  },

  computed: {
    ...mapState({
      winners: (state) => state.winnersModule.winners,
      isLogin: (state) => state.auth.isLogin,
      userEmail: (state) => state.auth.user.email,
      myFavorites: (state) => state.common.myFavorites,
      selectedItem: (state) => state.common.selectedItem,
      categoryList: (state) => state.common.categoryList,
    }),
    currentYear() {
      return moment().year();
    },
    startUpYear() {
      return parseInt(process.env.VUE_APP_STARTUP_YEAR);
    },
    startWeek() {
      if (this.selectedYear == this.startUpYear)
        return parseInt(process.env.VUE_APP_STARTUP_WEEK);
      else return 1;
    },
    lastweek() {
      let now;
      if (parseInt(process.env.VUE_APP_STARTUP_YEAR) === this.currentYear) {
        return moment().isoWeek();
      } else {
        if (this.selectedYear === this.startUpYear) {
          now = moment(this.startUpYear + "-12-30").format();
        } else if (
          this.selectedYear !== this.startUpYear &&
          this.selectedYear !== this.currentYear
        ) {
          now = moment(this.selectedYear + "-12-30").format();
        } else {
          now = moment().format();
        }
        return moment(now).isoWeek();
      }
    },
    query() {
      return this.selectedYear + "-" + this.selectedWeek;
    },
    // isListView() {
    //   if (this.$route.name !== "Winners") return this.ListView;
    //   return false;
    // },
    col_class() {
      if (this.isListView) return "";
      if (this.columns === 3) {
        return "col-4";
      } else if (this.columns === 1) {
        return "col-12";
      } else {
        return "col-6";
      }
    },
    curr_year_class() {
      return this.selectedYear === this.currentYear ? "abc" : "";
    },
    alignedPosts() {
      let $this = this;
      if ($this.width < 992 && $this.width > 420) {
        $this.columns = 2;
        var temp = [[], []];
      } else if ($this.width < 421) {
        $this.columns = 1;
        temp = [[]];
      } else {
        $this.columns = 3;
        temp = [[], [], []];
      }
      for (let i = 0; i < $this.winners.length; i++) {
        temp[i % $this.columns].push($this.winners[i]);
      }
      return temp;
    },
    showLoginModal() {
      if (this.modalShow === true && !this.closeLoginModal) {
        return true;
      }
      return false;
    },
  },

  data() {
    return {
      selectedYear: moment().year(),
      selectedWeek: 0,
      selectedPost_id: 0,
      isOverlay: false,
      closeLoginModal: false,
      modalShow: false,
      columns: 3,
      width: window.innerWidth,
      settings: {
        dots: false,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        speed: 100,
        slidesToShow: 10,
        slidesToScroll: 10,
        initialSlide: this.lastweek,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 8,
              slidesToScroll: 8,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 6,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            },
          },
        ],
      },
    };
  },

  watch: {
    selectedYear() {
      if (this.selectedYear !== this.currentYear) {
        this.settings.initialSlide = 0;
        this.selectedWeek = this.startWeek;
      } else {
        this.selectedWeek = this.lastweek - 1;
      }
      this.getWeeklyWinners();
    },
  },

  created() {
    if (this.selectedYear !== this.currentYear) {
      this.selectedWeek = this.startWeek;
    } else {
      this.selectedWeek = this.lastweek - 1;
    }
    this.getWeeklyWinners();
  },

  methods: {
    noShadow(idx, index) {
      if (this.isLogin) {
        if (this.userEmail === this.alignedPosts[idx][index].author_email) {
          if (this.alignedPosts[idx][index].images.length) {
            if (this.alignedPosts[idx][index].is_video) {
              return "";
            } else {
              if (this.alignedPosts[idx][index].images.length < 2) {
                return "no-shadow";
              }
            }
          } else {
            if (this.alignedPosts[idx][index].videos.length < 2) {
              return "no-shadow";
            }
          }
        }
      } else {
        return "no-shadow";
      }
    },
    resizeWindow() {
      this.width = window.innerWidth;
    },
    imgCount(imgLen, videoLen) {
      return imgLen - 1 + videoLen;
    },
    range: function (start, end) {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    },
    showOtherPost(num) {
      const index = this.winners.findIndex(
        (item) => item.post_id === this.selectedPost_id
      );
      this.selectedPost_id = this.winners[index + num].post_id;
    },
    getWeeklyWinners() {
      this.$store.dispatch("winnersModule/getWinners", {
        query: this.query,
        selectedCategory: localStorage.getItem("category"),
      });
    },
    getWeeklyPosts(index) {
      this.selectedWeek = index;
      if (this.query === this.currentYear + "-" + this.lastweek) {
        this.$router.push({ name: "Leaderboard" });
      }
      this.$store.dispatch("winnersModule/getWinners", {
        query: this.query,
        selectedCategory: localStorage.getItem("category"),
      });
    },
    async showOverlay(post_id, isMature) {
      if (this.isLogin) {
        await this.$store.dispatch("winnersModule/setOverlayPost", post_id);
        await this.$store.dispatch("winnersModule/getComments", post_id);
        await this.$store.dispatch("changeState", ["overlayClass", "opened"]);
        this.selectedPost_id = post_id;
        this.isOverlay = true;
      } else {
        if (isMature === false) {
          await this.$store.dispatch("winnersModule/setOverlayPost", post_id);
          await this.$store.dispatch("winnersModule/getComments", post_id);
          await this.$store.dispatch("changeState", ["overlayClass", "opened"]);
          this.selectedPost_id = post_id;
          this.isOverlay = true;
        }
      }
    },
    closeOveraly() {
      this.isOverlay = false;
      this.$store.dispatch("changeState", ["overlayClass", ""]);
      $("body").removeClass("hero-overlay");
    },
    checkIfLogin(post_id, isMature) {
      if (this.isLogin) {
        this.modalShow = false;
        this.showOverlay(post_id, isMature);
      } else {
        this.modalShow = true;
        $(".sidebar-right").addClass("sidebar-hide");
        $(".show-mnav").addClass("sidebar-hide");
        this.hideSideBar();
      }
    },
    colseModal() {
      this.closeLoginModal = true;
      this.modalShow = false;
      this.closeLoginModal = false;
      $(".show-mnav").removeClass("sidebar-hide");
      this.hideSideBar();
    },
    hideSideBar() {
      let $this = this;
      $("body").removeClass("mpanel-open hidesb");
      $(".ozone-column").removeClass("moved");
      $(".my-ozone").removeClass("moved");
      $this.$store.commit("change_state", ["mnav_class", ""]);
      setTimeout(function () {
        $this.$store.commit("change_state", ["mnav_close_class", ""]);
      }, 350);
    },
    clickFavButton(postID) {
      if (this.diableFavorite(postID)) {
        return this.removeFavourite(postID);
      } else {
        return this.addToFavorite(postID);
      }
    },
    async addToFavorite(postID) {
      await this.$store.dispatch("getFavorites");
      let data = {
        post_id: postID,
      };
      await this.$store.dispatch("addToFavorites", data);
      await this.$store.dispatch("getFavorites");
    },
    diableFavorite(postID) {
      if (!this.isLogin) return false;
      if (this.myFavorites.find((item) => item === postID)) return true;
      return false;
    },
    async removeFavourite(postID) {
      await this.$store.dispatch("getFavorites");
      await this.$store.dispatch("favoriteModule/deletePost", postID);
      await this.$store.dispatch("getFavorites");
    },
  },
};
</script>

<style scoped>
.brick {
  position: relative;
}
@media screen and (max-width: 992px) {
  .cst-6 {
    width: 50% !important;
  }
}
</style>