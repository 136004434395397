<template>
  <div @click="toggleOverlay">
    <div class="zero-hero-column col-trigger" style="overflow: hidden">
      <div class="wrapper">
        <div class="zero-hero-header">
          <h2>From zero</h2>
          <h3>to hero</h3>
        </div>
        <perfect-scrollbar class="high-flyers">
          <h2>
            This week's Leaders
            <router-link title="See all" to="/leaderboard" v-if="isLogin">
              View All
            </router-link>
          </h2>
          <h3>As created & voted by you!</h3>
          <div class="tx-ctr">
            <div v-if="leaders.length === 0">
              <h4 class="akrobat c-999"><i class="icon-picture"></i> NO LEADERS YET</h4>
                <p class="fs-9">Vote on a Post in the Pressure Pit to put it on the
                Leaderboard.</p>
            </div>
          </div>
          <ul>
            <li v-for="(post, index) in leaders" :key="index">
              <div :class="noShadow(index)">
                <div class="post-info-block">
                  <div class="ext-img">
                    <div v-if="!post.videos">
                      <div v-if="post.images.length > 1">
                        <span data-title="No. of Images" data-title-left>
                          {{ "+" }}{{ post.images.length - 1 }}
                        </span>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        v-if="
                          post.videos.length > 1 && post.images.length === 0
                        "
                      >
                        <span data-title="No. of Images" data-title-left>
                          {{ "+" }}{{ post.videos.length - 1 }}
                        </span>
                      </div>
                      <div v-else-if="post.images.length && post.videos.length">
                        {{ "+"
                        }}{{ imgCount(post.images.length, post.videos.length) }}
                      </div>
                    </div>
                  </div>
                  <span
                    class="tip-icon"
                    :class="
                      (post.images.length === 0 && post.videos.length < 2) ||
                      (post.images.length < 2 && !post.videos)
                        ? 'only-like'
                        : ''
                    "
                    :data-title="`${
                      diableFavorite(post.post_id)
                        ? 'Remove from Faves'
                        : 'Add to Faves'
                    }`"
                    data-title-left
                  >
                    <a
                      v-show="isLogin && userEmail !== post.author_email"
                      class="icon-heart sav2fav"
                      :class="{ liked: diableFavorite(post.post_id) }"
                      @click="clickFavButton(post.post_id)"
                    >
                    </a>
                  </span>
                </div>
              </div>
              <ImgWrap
                :post="post"
                :isLeaders="true"
                @click.native="setSelectedPost(post.post_id)"
              />

              <div class="voter" v-if="isLogin">
                <div v-if="isLogin && showPressure" class="votes">
                  <div class="votes-nav">
                    <div data-title="Pressure Up" data-title-top>
                      <div
                        v-if="index > 0 && myVotes"
                        class="icon-plus votes-button votes-up tippy"
                        @click="plus(post.post_id, post.author_email)"
                      ></div>
                    </div>

                    <div data-title="Pressure Down" data-title-top>
                      <div
                        v-if="post.votes && myVotes"
                        class="icon-minus votes-button votes-down tippy"
                        @click="minus(post.post_id, post.author_email)"
                      ></div>
                    </div>
                  </div>
                  <div data-title="Leaderboard Position" data-title-top>
                    <input
                      type="number"
                      id="z2h-01-votes"
                      :value="post.pressure"
                      min="0"
                      step="1"
                    />
                    <span>%</span>
                  </div>
                </div>
              </div>
              <p>{{ post.title }}</p>
            </li>
          </ul>
          <div class="btn_center_sec">
            <a
              class="load_more_btn"
              v-if="loadMoreToggle"
              @click="loadMorePosts"
            >
              Show more <span class="icon-down-open"></span>
            </a>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
    <div class="zero-hero col-trigger"></div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import * as moment from "moment";
import ImgWrap from "@/components/shared/ImgWrap";
import ChangePressureMixin from "@/mixins/ChangePressureMixin";
import $ from "jquery";

export default {
  name: "ZeroHero",
  components: {
    ImgWrap,
  },

  mixins: [ChangePressureMixin],

  computed: {
    ...mapGetters({ getpostId: "overlayModule/getpostId" }),
    ...mapState({
      isLogin: (state) => state.auth.isLogin,
      overlayClass: (state) => state.common.overlayClass,
      postCreateOverlayClass: (state) => state.common.postCreateOverlayClass,
      editPostOverlayClass: (state) => state.common.editPostOverlayClass,
      myOzone: (state) => state.common.myOzone,
      isSearching: (state) => state.common.isSearching,
      leaders: (state) => state.common.leaders,
      pageNumber: (state) => state.common.pageNumber,
      totalLeaders: (state) => state.common.totalLeaders,
      showPressure: (state) => state.common.showPressure,
      myVotes: (state) => state.auth.user.votes,
      userEmail: (state) => state.auth.user.email,
      myFavorites: (state) => state.common.myFavorites,
      givenPlus: (state) => state.userModule.givenPlus,
      givenMinus: (state) => state.userModule.givenMinus,
      receivePlus: (state) => state.userModule.receivePlus,
      receiveMinus: (state) => state.userModule.receiveMinus,
    }),
    loadMoreToggle() {
      const limit = 10;
      return Math.ceil(this.totalLeaders / limit) > this.pageNumber
        ? true
        : false;
    },
  },

  data() {
    return {
      now: 0.0,
      selectedPostId: 0,
    };
  },

  created() {
    this.$store.dispatch("getLeaders");
  },

  methods: {
    ...mapMutations({
      SET_SELECTED_POSTID: "overlayModule/SET_SELECTED_POSTID",
    }),
    async plus(post_id, user) {
      await this.addPressure(post_id);
      if (this.userEmail === user) {
        this.$store.commit("userModule/set_vote_history", {
          givenPlus: this.givenPlus + 1,
        });
        this.$store.commit("userModule/set_vote_history", {
          receivePlus: this.receivePlus + 1,
        });
      } else {
        this.$store.commit("userModule/set_vote_history", {
          givenPlus: this.givenPlus + 1,
        });
      }
    },
    async minus(post_id, user) {
      await this.reducePressure(post_id);
      if (this.userEmail === user) {
        this.$store.commit("userModule/set_vote_history", {
          givenMinus: this.givenMinus + 1,
        });
        this.$store.commit("userModule/set_vote_history", {
          receiveMinus: this.receiveMinus + 1,
        });
      } else {
        this.$store.commit("userModule/set_vote_history", {
          givenMinus: this.givenMinus + 1,
        });
      }
    },
    noShadow(index) {
      if (this.isLogin) {
        if (this.userEmail === this.leaders[index].author_email) {
          if (this.leaders[index].images.length) {
            if (this.leaders[index].is_video) {
              return "";
            } else {
              if (this.leaders[index].images.length < 2) {
                return "no-shadow";
              }
            }
          } else {
            if (this.leaders[index].videos.length < 2) {
              return "no-shadow";
            }
          }
        }
      } else {
        return "no-shadow";
      }
    },
    imgCount(imgLen, videoLen) {
      return imgLen - 1 + videoLen;
    },
    calExpiration(e) {
      let color = e < 70.0 ? "blue" : "red";
      return color;
    },
    setSelectedPost(id) {
      this.$emit("addOverlayClass");
      this.SET_SELECTED_POSTID(id);
      $("body").addClass("hero-overlay");
      $(".sidebar-right").addClass("hide-sidebar");
    },
    async toggleOverlay() {
      let t_now = moment().format();
      this.now = t_now;
      // Change myOzone
      if (this.myOzone != 1 && this.isLogin) {
        await this.$store.dispatch("setMyOzone", 1);
      }
      // Hide Search bar
      if (this.isSearching === true) {
        await this.$store.dispatch("setSearching", false);
        if (
          this.$route.name === "Home" ||
          this.$route.name === "O2hq" ||
          this.$route.name === "User" ||
          this.$route.name === "Tokens"
        ) {
          $(".custom-screen").addClass("full-screen");
        }
      }
    },
    loadMorePosts() {
      this.$store.dispatch("loadMoreLeaders");
    },
    clickFavButton(postID) {
      if (this.diableFavorite(postID)) {
        return this.removeFavourite(postID);
      } else {
        return this.addToFavorite(postID);
      }
    },
    async addToFavorite(postID) {
      await this.$store.dispatch("getFavorites");
      let data = {
        post_id: postID,
      };
      await this.$store.dispatch("addToFavorites", data);
      await this.$store.dispatch("getFavorites");
    },
    diableFavorite(postID) {
      if (!this.isLogin) return false;
      if (this.myFavorites.find((item) => item === postID)) return true;
      return false;
    },
    async removeFavourite(postID) {
      await this.$store.dispatch("getFavorites");
      await this.$store.dispatch("favoriteModule/deletePost", postID);
      await this.$store.dispatch("getFavorites");
    },
  },
};
</script>