import * as api from "../Services/api.js";

export const state = {
  posts: [],
  people: [],
  totalPeople: 0,
  query: "",
  filter: 0,
  pageNumber: 0,
  totalPosts: 0,
};

export const mutations = {
  SET_PEOPLE(state, payload) {
    state.people = payload.peopledata;
    state.pageNumber += 1;
    state.totalPeople = payload.totalpeople;
  },
  SET_LOADED_PEOPLE(state, payload) {
    state.people = state.people.concat(payload.peopledata);
    state.pageNumber += 1;
  },
  SET_POSTS(state, payload) {
    state.posts = payload.post_data;
    state.pageNumber = 1;
    state.totalPosts = payload.post_count;
  },
  SET_LOADED_POST(state, payload) {
    state.posts = state.posts.concat(payload.post_data);
    state.pageNumber += 1;
  },
  SET_QUERY(state, payload) {
    state.query = payload.query;
    state.filter = payload.filter;
  },
  CHANGE_PRESSURE(state, payload) {
    const index = state.posts.findIndex(
      ({ post_id }) => post_id === payload.post_id
    );
    state.posts[index].votes += payload.amount;
  },
  EDIT_POST(state, payload) {
    const index = state.posts.findIndex(
      ({ post_id }) => post_id === payload.post_id
    );
    state.posts.splice(index, 1, { ...payload });
  },
  REMOVE_POST(state, payload) {
    const index = state.posts.findIndex(({ uuid }) => uuid === payload);
    state.posts.splice(index, 1);
  },
};

export const getters = {};

export const actions = {
  async search({ commit }) {
    return await api
      .get(
        "/posts/search/?page_num=" +
          0 +
          "&query=" +
          state.query +
          "&filter=" +
          state.filter
      )
      .then((response) => {
        if (state.filter === 0) commit("SET_PEOPLE", response);
        else commit("SET_POSTS", response);
        return response;
      });
  },
  setQuery({ commit }, data) {
    commit("SET_QUERY", data);
  },
  async changePressure({ commit }, data) {
    return await api
      .get(
        "/posts/change-vote/?post_id=" + data.post_id + "&amount=" + data.amount
      )
      .then((response) => {
        commit("CHANGE_PRESSURE", data);
        return response;
      });
  },
  async loadMorePosts(context) {
    context.dispatch("setStatus", true, { root: true });
    return await api
      .get(
        "/posts/search/?page_num=" +
          state.pageNumber +
          "&query=" +
          state.query +
          "&filter=" +
          state.filter
      )
      .then((response) => {
        context.dispatch("setStatus", false, { root: true });
        if (state.filter === 0) context.commit("SET_LOADED_PEOPLE", response);
        else context.commit("SET_LOADED_POST", response);
        return response;
      });
  },
  async editPost({ commit }, data) {
    return await api.post("/posts/update/", data).then((response) => {
      if (response.status) {
        commit("EDIT_POST", data);
      }
    });
  },
  async removePost({ commit }, data) {
    return await api.get("/posts/remove-post?uuid=" + data).then((response) => {
      if (response.status === 200) {
        commit("REMOVE_POST", data);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
