<template>
  <div>
    <div id="content" class="pt-20" role="main">
      <div class="right relative mt--5 no-ws">
        <div data-title="New Posts" data-title-left>
          <router-link
            to="/"
            class="button tertiary show-for-small ml-10 pl-5 pr-5 small-none"
          >
            <span class="pr-5 pl-5">Pressure Pit</span>
          </router-link>
        </div>
      </div>
      <PostCreateOverlay />
      <WallTabsWrap @changeTab="changeTab" @getByTitle="getByTitle" />

      <LoaderOverlay v-if="isLoading" />

      <TileView
        :moduleName="`favoriteModule`"
        :paginate="true"
        @increment="increment"
        @decrement="decrement"
        @showOverlay="showOverlay"
        @deletePost="deletePost"
      />
      <Overlay
        v-if="isOverlay"
        :post="selectedPost"
        :isOverlay="isOverlay"
        :hasNext="hasNext"
        :hasPrev="hasPrev"
        @increment="increment"
        @decrement="decrement"
        @close="closeOveraly"
        @showOtherPost="showOtherPost"
      />
      <EditPostOverlay
        v-if="editingPost"
        :post="selectedPost"
        @editPost="editPost"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TileView from "@/components/shared/TileView";
import Overlay from "@/components/shared/overlay/Overlay";
import WallTabsWrap from "../components/shared/WallTabsWrap.vue";
import EditPostOverlay from "../components/shared/overlay/EditPostOverlay.vue";
import LoaderOverlay from "../components/shared/overlay/LoaderOverlay.vue";
import CreateOverlayMixin from "@/mixins/CreateOverlayMixin";
import $ from "jquery";

export default {
  name: "User",
  components: {
    TileView,
    WallTabsWrap,
    Overlay,
    EditPostOverlay,
    LoaderOverlay,
  },

  mixins: [CreateOverlayMixin],

  data() {
    return {
      isBioOpening: false,
      selectedPostId: 0,
      isOverlay: false,
      orderBy: null,
    };
  },

  computed: {
    ...mapState({
      following: (state) => state.auth.following,
      isLogin: (state) => state.auth.isLogin,
      isLoading: (state) => state.auth.isLoading,
      activeTab: (state) => state.base.activeTab,
      posts: (state) => state.favoriteModule.posts,
      editingPost: (state) => state.uxModule.editingPost,
    }),
    hasNext() {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPost.post_id
      );
      if (index + 1 === this.posts.length) return false;
      return true;
    },
    hasPrev() {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPost.post_id
      );
      if (index === 0) return false;
      return true;
    },
    selectedPost() {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPostId
      );
      if (index === -1) return { ...this.posts[0] };
      return { ...this.posts[index] };
    },
  },
  mounted() {
    this.intialData();
  },
  methods: {
    async intialData() {
      this.$store.dispatch("resetActiveTab");
      const postData = await this.$store.dispatch(
        "favoriteModule/getFavorites",
        {
          activeTab: 0,
          pageNumber: 0,
          orderBy: this.orderBy,
        }
      );
      this.orderBy = postData.orderBy;
      this.$store.dispatch("votingModule/setVotes", postData);
    },
    async changeTab(index) {
      const postData = await this.$store.dispatch(
        "favoriteModule/getFavorites",
        {
          activeTab: index,
          pageNumber: 0,
        }
      );
      this.$store.dispatch("votingModule/setVotes", postData);
      this.$store.dispatch("setActiveTab", index);
    },
    async getByTitle(isAtoZ) {
      await this.$store.dispatch("favoriteModule/getFavorites", {
        show: isAtoZ,
        activeTab: 2,
        pageNumber: 0,
      });
    },
    async increment(postID) {
      const res = await this.$store.dispatch("favoriteModule/changePressure", {
        post_id: postID,
        amount: 1,
      });
      await this.$store.dispatch("homeModule/voteRecord", postID);
      await this.$store.dispatch("voteCount", {
        post: postID,
        type: "plus",
      });
      if (res.status === 200) {
        this.$store.dispatch("votingModule/setVotes", res.data);
        this.$store.commit("reduce_my_votes");
      }
    },
    async decrement(postID) {
      const res = await this.$store.dispatch("favoriteModule/changePressure", {
        post_id: postID,
        amount: -1,
      });
      await this.$store.dispatch("voteCount", {
        post: postID,
        type: "minus",
      });
      if (res.status === 200) {
        this.$store.dispatch("votingModule/setVotes", res.data);
        this.$store.commit("reduce_my_votes");
      }
    },
    editPost(postData) {
      this.$store.dispatch("favoriteModule/editPost", { ...postData });
    },
    async showOverlay(postId) {
      await this.$store.dispatch("overlayModule/getComments", postId);
      this.selectedPostId = postId;
      this.isOverlay = true;
    },
    async showOtherPost(num) {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPostId
      );
      this.selectedPostId = this.posts[index + num].post_id;
      await this.$store.dispatch(
        "overlayModule/getComments",
        this.posts[index + num].post_id
      );
      await this.$store.dispatch("overlayModule/StarCount", {
        post: this.posts[index + num].post_id,
      });
      if (this.isLogin) {
        await this.$store.dispatch("overlayModule/GetCommentLoveLikeData", {
          post: this.posts[index + num].post_id,
        });
        await this.$store.dispatch("overlayModule/GetLoveLikeData", {
          post: this.posts[index + num].post_id,
        });
      }
      await this.$store.dispatch("overlayModule/PostTrack", {
        post: this.posts[index + num].post_id,
        type: "post",
      });
    },
    closeOveraly() {
      this.isOverlay = false;
      this.$store.dispatch("changeState", ["overlayClass", ""]);
      $("body").removeClass("hero-overlay");
    },
    async deletePost(postID) {
      await this.$store.dispatch("favoriteModule/deletePost", postID);
    },
  },
};
</script>
