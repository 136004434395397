<template>
  <div>
    <PostCreateOverlay />
    <div class="panel-blue-block pt-10 pl-30 pr-30 pb-15 mb-30 tx-c">
      <div class="mb-10 mt-10">
        <div class="spr-treasures-favicon m-auto"></div>
      </div>
      <h2 class="byline">Community Entertainment<br />Powered by You</h2>
    </div>
    <div class="sub-panel">
      <h2 class="byline">O2 is a Weekly Competition</h2>
      <p>
        To find the best images & visual stories -
        <strong>CREATED & VOTED BY YOU!</strong>
      </p>
      <p class="noellip">
        +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
      </p>
      <h2 class="byline">How it works</h2>
      <p>
        Simply
        <a class="show-panel" @click="showPostCreateOverlay()">
          <strong>Add a Post</strong>
        </a>
        for it to display in the
        <router-link to="/"> <strong>Pressure Pit</strong></router-link
        >. Anyone can then vote it onto the
        <router-link to="/leaderboard">
          <strong>Leaderboard</strong></router-link
        >. At the end of every week, the Top 10 Posts on the Leaderboard appear
        on the
        <router-link to="/winners"> <strong>Winners Walls</strong></router-link>
        to receive recognition, rewards and permanent promotion.
      </p>
      <p class="noellip">
        +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
      </p>
      <h2 class="byline">Back Story</h2>
      <p>
        O2 was born in June 2022. (still a baby!)<br />
Being surrounded by negative news and a world of constant 'doom and gloom': we wanted to create a site for a community that enjoys the positives of life.
A site for people to enjoy, create and share inspiring images and stories of the world.
      </p>
      <p class="noellip">
        +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
      </p>
      <h2 class="byline">Important Tips</h2>
      <p>
        <strong class="icon-lightbulb">Be quick to vote:</strong> Posts only have 7
        days of life before they expire.
      </p>
      <p>
        <strong class="icon-lightbulb">Use all your votes:</strong> You have 40
        votes per day.
      </p>
      <p>
        <strong class="icon-lightbulb">Check</strong> the
        <strong><router-link to="/">Pressure Pit</router-link></strong> <em>(Homepage)</em> for new
        posts.
      </p>
      <p>
        <strong class="icon-lightbulb">Post often:</strong> You get rewards and
        awards for your activity.
      </p>
      <p class="noellip">
        +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
      </p>
      <p>Join us ...</p>
      <p>
        <a
          class="
            show-panel
            button
            primary
            icon-lock-open
            pr-15
            pl-15
            mb-10
            mr-10
          "
          data-mobile="true"
          title="Login"
          @click="hideSidebar(0)"
        >
          Login
        </a>
        <a
          class="show-panel button primary icon-lock pr-15 pl-15 mb-10"
          title="Sign up"
          data-mobile="true"
          @click="hideSidebar(3)"
        >
          Sign up
        </a>
      </p>
      <p class="mt-20">
        <router-link
          class="button secondary pl-5 pr-5"
          to="/howitworks"
          title="Main Features"
        >
          <span class="pr-10 pl-10">Main Features</span>
        </router-link>
      </p>
    </div>
    <div class="pt-20 tx-c">
      <p class="gen-akr fs-9">We always need air - Please share us on...</p>
      <div class="social-share-links center">
        <a @click="shareFacebook()" target="_blank" class="icon-box fb">
          <i class="icon-facebook"></i
        ></a>
        <a @click="shareTwitter()" target="_blank" class="icon-box tw">
          <i class="icon-twitter"></i
        ></a>
        <a @click="sharePinterest()" target="_blank" class="icon-box pi">
          <i class="icon-pinterest-circled"></i
        ></a>
        <a @click="shareLinkedin()" target="_blank" class="icon-box li">
          <i class="icon-linkedin"></i
        ></a>
        <a
          @click="emailModalShow = !emailModalShow"
          target="_blank"
          class="icon-box mi"
        >
          <i class="icon-mail-alt"></i
        ></a>
        <modal
          :show="emailModalShow"
          @toggle-modal="emailModalShow = !emailModalShow"
          @handle-submit="handleSubmit"
        />
      </div>
    </div>
    <p>&nbsp;</p>
    <div class="panel-blue-block p-15 pr-20 pl-20 mb-30 mt-30 tx-c">
      <h2 class="byline">O2 Social</h2>
      <p>
        <span class="akr-gen">FOLLOW US ON...</span>
      </p>
      <div class="o2-socials">
        <span data-title="O2 Facebook" data-title-top
          ><a
            href="https://www.facebook.com/O2Unlimited"
            target="_blank"
            class="icon-facebook"
          ></a
        ></span>
        <span data-title="O2 Instagram" data-title-top
          ><a
            href="https://www.instagram.com/o2positive_com"
            target="_blank"
            class="icon-instagram"
          ></a
        ></span>
        <span data-title="O2 Pinterest" data-title-top>
          <a
            href="https://www.pinterest.nz/o2positive_com"
            target="_blank"
            class="icon-pinterest"
          ></a
        ></span>
        <span data-title="O2 Twitter" data-title-top
          ><a
            href="https://www.twitter.com/O2Unlimited"
            target="_blank"
            class="icon-twitter"
          ></a
        ></span>
      </div>
    </div>
    <MatureAudienceLoginModal v-if="showLoginModal" @colseModal="colseModal" />
  </div>
</template>

<script>
import * as moment from "moment";
import ChangeMyOzoneMixin from "@/mixins/ChangeMyOzoneMixin";
import modal from "../../components/shared/modal/modal.vue";
import $ from "jquery";
import CreateOverlayMixin from "@/mixins/CreateOverlayMixin";
import MatureAudienceLoginModal from "../../components/modal/MatureAudienceLoginModal.vue";

export default {
  name: "Public",

  components: {
    modal,
    MatureAudienceLoginModal,
  },

  mixins: [ChangeMyOzoneMixin, CreateOverlayMixin],

  data() {
    return {
      currentWeek: 0,
      emailModalShow: false,
    };
  },

  created() {
    let $this = this;
    let now = moment().format();
    $this.currentWeek = moment(now).isoWeek();
  },

  methods: {
    hideSidebar(num) {
      this.$emit("close");
      let $this = this;
      $this.changeMyOzone(num);
      $("body").addClass("mpanel-open hidesb");
      $(".logged-out").addClass("hidesb");
      $(".ozone-column").addClass("moved");
      $(".my-ozone").addClass("moved");
      $this.$store.commit("change_state", ["mnav_class", "move"]);
      setTimeout(function () {
        $this.$store.commit("change_state", ["mnav_close_class", "slidem"]);
      }, 350);
    },
    shareFacebook() {
      window.open(
        "https://www.facebook.com/sharer.php?u=" + process.env.VUE_APP_PREFIX,
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
    },
    shareTwitter() {
      window.open(
        "https://twitter.com/intent/tweet?url=" + process.env.VUE_APP_PREFIX,
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
    },
    shareLinkedin() {
      window.open(
        "https://www.linkedin.com/shareArticle?mini=true&url=" +
          process.env.VUE_APP_PREFIX,
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
    },
    sharePinterest() {
      window.open(
        "http://pinterest.com/pin/create/button/?url=" +
          process.env.VUE_APP_PREFIX,
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
    },
    handleSubmit(data) {
      if (this.isLogin) {
        data.user = "yes";
      } else {
        data.user = "no";
      }
      data.route = this.$route.path;
      this.emailModalShow = false;
      this.$store.dispatch("overlayModule/o2SiteSharing", data);
    },
  },
};
</script>