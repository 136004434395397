var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"click":_vm.toggleOverlay}},[_c('div',{staticClass:"zero-hero-column col-trigger",staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"wrapper"},[_vm._m(0),_c('perfect-scrollbar',{staticClass:"high-flyers"},[_c('h2',[_vm._v(" This week's Leaders "),(_vm.isLogin)?_c('router-link',{attrs:{"title":"See all","to":"/leaderboard"}},[_vm._v(" View All ")]):_vm._e()],1),_c('h3',[_vm._v("As created & voted by you!")]),_c('div',{staticClass:"tx-ctr"},[(_vm.leaders.length === 0)?_c('div',[_c('h4',{staticClass:"akrobat c-999"},[_c('i',{staticClass:"icon-picture"}),_vm._v(" NO LEADERS YET")]),_c('p',{staticClass:"fs-9"},[_vm._v("Vote on a Post in the Pressure Pit to put it on the Leaderboard.")])]):_vm._e()]),_c('ul',_vm._l((_vm.leaders),function(post,index){return _c('li',{key:index},[_c('div',{class:_vm.noShadow(index)},[_c('div',{staticClass:"post-info-block"},[_c('div',{staticClass:"ext-img"},[(!post.videos)?_c('div',[(post.images.length > 1)?_c('div',[_c('span',{attrs:{"data-title":"No. of Images","data-title-left":""}},[_vm._v(" "+_vm._s("+")+_vm._s(post.images.length - 1)+" ")])]):_vm._e()]):_c('div',[(
                        post.videos.length > 1 && post.images.length === 0
                      )?_c('div',[_c('span',{attrs:{"data-title":"No. of Images","data-title-left":""}},[_vm._v(" "+_vm._s("+")+_vm._s(post.videos.length - 1)+" ")])]):(post.images.length && post.videos.length)?_c('div',[_vm._v(" "+_vm._s("+")+_vm._s(_vm.imgCount(post.images.length, post.videos.length))+" ")]):_vm._e()])]),_c('span',{staticClass:"tip-icon",class:(post.images.length === 0 && post.videos.length < 2) ||
                    (post.images.length < 2 && !post.videos)
                      ? 'only-like'
                      : '',attrs:{"data-title":("" + (_vm.diableFavorite(post.post_id)
                      ? 'Remove from Faves'
                      : 'Add to Faves')),"data-title-left":""}},[_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLogin && _vm.userEmail !== post.author_email),expression:"isLogin && userEmail !== post.author_email"}],staticClass:"icon-heart sav2fav",class:{ liked: _vm.diableFavorite(post.post_id) },on:{"click":function($event){return _vm.clickFavButton(post.post_id)}}})])])]),_c('ImgWrap',{attrs:{"post":post,"isLeaders":true},nativeOn:{"click":function($event){return _vm.setSelectedPost(post.post_id)}}}),(_vm.isLogin)?_c('div',{staticClass:"voter"},[(_vm.isLogin && _vm.showPressure)?_c('div',{staticClass:"votes"},[_c('div',{staticClass:"votes-nav"},[_c('div',{attrs:{"data-title":"Pressure Up","data-title-top":""}},[(index > 0 && _vm.myVotes)?_c('div',{staticClass:"icon-plus votes-button votes-up tippy",on:{"click":function($event){return _vm.plus(post.post_id, post.author_email)}}}):_vm._e()]),_c('div',{attrs:{"data-title":"Pressure Down","data-title-top":""}},[(post.votes && _vm.myVotes)?_c('div',{staticClass:"icon-minus votes-button votes-down tippy",on:{"click":function($event){return _vm.minus(post.post_id, post.author_email)}}}):_vm._e()])]),_c('div',{attrs:{"data-title":"Leaderboard Position","data-title-top":""}},[_c('input',{attrs:{"type":"number","id":"z2h-01-votes","min":"0","step":"1"},domProps:{"value":post.pressure}}),_c('span',[_vm._v("%")])])]):_vm._e()]):_vm._e(),_c('p',[_vm._v(_vm._s(post.title))])],1)}),0),_c('div',{staticClass:"btn_center_sec"},[(_vm.loadMoreToggle)?_c('a',{staticClass:"load_more_btn",on:{"click":_vm.loadMorePosts}},[_vm._v(" Show more "),_c('span',{staticClass:"icon-down-open"})]):_vm._e()])])],1)]),_c('div',{staticClass:"zero-hero col-trigger"})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"zero-hero-header"},[_c('h2',[_vm._v("From zero")]),_c('h3',[_vm._v("to hero")])])}]

export { render, staticRenderFns }