<template>
  <div id="content" class="profile" role="main">
    <PostCreateOverlay />
    <div class="prof-img new-design-me p-r">
      <div class="mob-t-margin new-design-me p-r">
        <img
          v-if="userAvatar.length > 0"
          class="pho-new-design-me"
          :class="user.avatar ? 'round-logo' : ''"
          :title="`${user.first_name} ${user.last_name}`"
          :src="userAvatar"
          :alt="`${user.first_name} ${user.last_name}`"
        />
        <div v-else class="search-prof-img p-r user-defaul-logo"></div>
      </div>

      <div class="bio-edit p-a">
        <a
          data-title="Edit Profile"
          data-title-top
          class="button tertiary p-a bio-edit-me js-trig-aaa"
          data-mobile="true"
          @click="openEditPanel"
        >
          <i class="icon-edit"></i>
        </a>
      </div>
      <div class="my-bio-toggle p-a">
        <a
          data-title="About me"
          data-title-top
          class="button secondary p-a bio-pos"
          @click="isBioOpening = !isBioOpening"
        >
          <i
            class="icon-down-open"
            :class="{ 'icon-up-open': isBioOpening }"
          ></i>
        </a>
      </div>
    </div>

    <!--BIO -->
    <div class="bio new-design-me" :class="{ open: isBioOpening }">
      <div class="m-space">
        <h3
          v-if="
            (user.bio !== '' && user.bio !== null) ||
            (user.site_url !== '' && user.site_url !== null)
          "
        >
          About
        </h3>
        <pre v-if="user.bio !== ''">{{ user.bio }}</pre>
        <div
          v-if="user.site_url !== '' && user.site_url !== null"
          class="url tx-c"
        >
          <i class="icon-link-ext"></i>
          <a :href="user.site_url" target="_blank">{{ user.site_url }}</a>
        </div>
        <h3 class="mb-15">
          Profile Link<span class="fs-8">
            -
            <a rel="noopener noreferrer" @click="copyURL">
              <span>{{ copyButtonText }}</span></a
            ></span
          >
        </h3>

        <!------- TREASURE VIEW --------->
        <h3 v-if="winnerCount > 0 || top10Count > 0">Treasure</h3>
        <div
          class="basic-white drk-theme pt-10 pb-2 tx-c"
          v-if="winnerCount > 0 || top10Count > 0 || elementTheme.length >= 1"
        >
          <p v-if="top10Count < 0 && winnerCount < 0" class="tx-c">
            You have no Treasure yet.
          </p>
          <div class="treas mt-5" v-if="winnerCount > 0 || top10Count > 0">
            <div>
              <div class="spr-treasures-winner" v-if="winnerCount > 0">
                <h3 class="mt-3">Winner</h3>
                <span
                  class="treas-badge"
                  :title="`${winnerCount}x a Winning Post`"
                  >{{ winnerCount }}</span
                >
              </div>

              <div class="spr-treasures-top10" v-if="top10Count > 0">
                <h3 class="mt-3">Top 10</h3>
                <span
                  class="treas-badge"
                  :title="`${top10Count}x in Top 10 Posts`"
                  >{{ top10Count }}</span
                >
              </div>

              <!-- -----LATER----same style as above - i will create new sprite bgimages like above ---
            <div class="spr-treasures-created" title="Most Created that week">
              <span class="treas-badge" title="Won {6} times">6</span>
            </div>

            <div class="spr-treasures-shared" title="Most Shared that week">
              <span class="treas-badge" title="Won {21} times">21</span>
            </div> -->
            </div>
          </div>

          <!-- HIDE ELEMENTS TITLE and CONTENT - Stage 2 post live dev -->
          <div class="basic-elements" v-if="elementTheme.length >= 1">
            <h3>My Custom Themes</h3>

            <div class="element-theme-wrap">
              <div
                class="element-theme"
                v-for="(item, idx) in elementTheme"
                :key="idx"
              >
                <div :class="`spr-treasures-${item}`" :title="item"></div>
              </div>
            </div>
          </div>
        </div>
        <h3>
          Current Theme
          <span class="fs-8"
            ><a class="icon-brush" :href="link">View Themes</a></span
          >
        </h3>
        <div
          v-if="
            myProfileTheme &&
            Object.keys(myProfileTheme).length >= 1 &&
            this.selectedTheme !== 'darkmode'
          "
        >
          <div v-for="(data, index) in myProfileTheme" :key="index">
            <div
              class="basic-white tx-c pt-15 pb-5"
              v-if="index === selectedTheme"
            >
              <div
                :class="
                  selectedTheme !== 'o2'
                    ? `spr-theme-${data[0]} size1-pos pos3 m-auto mt-10`
                    : 'spr-theme-oxygen size1-pos pos3 m-auto mt-10'
                "
              ></div>
              <p v-if="data[0] !== 'o2'">
                The awesome {{ data[0] }} theme is your current theme.
              </p>
              <p v-else-if="data[0] === 'o2'">
                This cool blue oxygen theme is your default O2 theme.
              </p>
            </div>
          </div>
        </div>
        <div v-else-if="this.selectedTheme === 'darkmode'">
          <div class="basic-white tx-c pt-15 pb-5 crrent-dark">
            <p>You are currently using the site in Dark Mode.</p>
          </div>
        </div>
        <div v-else>
          <div class="basic-white tx-c pt-15 pb-5">
            <div class="spr-theme-oxygen size1-pos pos3 m-auto mt-10"></div>
            <p>This cool blue oxygen theme is your default O2 theme.</p>
          </div>
        </div>
        <h3
          v-if="
            myProfileTheme &&
            Object.keys(myProfileTheme).includes('gold', 'platinium', 'dimond')
          "
        >
          My Rewards
          <span class="fs-8"
            ><a class="icon-brush" :href="link">View Themes</a></span
          >
        </h3>
        <div class="basic-white">
          <p class="pl-10">
            Be one of the first 500 people to gain
            <strong
              ><span style="color: #ab8d2c">{{ gold_count }}+</span>,
              <span style="color: #6c7a84">{{ platinum_count }}+</span>
              or
              <span style="color: #046ba8">{{ diamond_count }}+</span></strong
            >
            followers and receive limited-edition
            <strong>
              <span style="color: #ab8d2c">Gold</span>,
              <span style="color: #6c7a84">Platinum</span> or
              <span style="color: #046ba8">Diamond</span>
            </strong>
            themes, trophies & tokens, as recognition of your achievement.
          </p>

          <div class="reward-inner">
            <div
              class="reward-wrap"
              v-for="(data, idx) in rewardProfileTheme"
              :key="idx"
            >
              <div class="reward-flex">
                <div :class="`reward-block ${data[0]}`">
                  <div
                    :data-title="`${data[0]} MEMBER`"
                    data-title-top
                    :class="`spr-theme-${data[0]} size1-pos pos3 reward-scale m-auto`"
                  ></div>
                  <h3 class="count">{{ data[0] }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="my-socials tx-c mb-40">
          <a
            data-title="My Facebook"
            data-title-bottom
            v-if="user.facebook_url !== ''"
            :href="user.facebook_url"
            target="_blank"
          >
            <i class="button icon-facebook"></i> </a
          >&nbsp;
          <a
            data-title="My Instagram"
            data-title-bottom
            v-if="user.instagram_url !== ''"
            :href="user.instagram_url"
            target="_blank"
          >
            <i class="button icon-instagram"></i> </a
          >&nbsp;
          <a
            data-title="My Twitter"
            data-title-bottom
            v-if="user.twitter_url !== ''"
            :href="user.twitter_url"
            target="_blank"
          >
            <i class="button icon-twitter"></i> </a
          >&nbsp;
          <a
            data-title="My LinkedIn"
            data-title-bottom
            v-if="user.linkedin_url !== ''"
            :href="user.linkedin_url"
            target="_blank"
          >
            <i class="button icon-linkedin"></i> </a
          >&nbsp;
          <a
            data-title="My Pinterest"
            data-title-bottom
            v-if="user.pinterest_url !== ''"
            :href="user.pinterest_url"
            target="_blank"
          >
            <i class="button icon-pinterest-circled"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="right relative mt--5 no-ws"></div>
    <h2 class="inline-block akrobat"></h2>
    <div id="tabs" class="cs_tabs ban_post_btn_wrapper">
      <ul class="tabby cs_tabs_top_header">
        <li
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ active: activeTab === index }"
          @click="activeTab = index"
        >
          <a>{{ tab }}</a>
          <span v-if="unreadCount > 0" class="comment_count">
            {{ unreadCount }}
          </span>
          <span v-if="totalPosts > 0" class="comment_count draft_count">
            {{ totalPosts }}
          </span>
        </li>
      </ul>
      <p>
        <a
          class="show-panel button primary icon-upload-cloud pr-15 pl-15 mt--5"
          title="Create & Submit a Post!"
          @click="showPostCreateOverlay()"
          >Add Post</a
        >
      </p>
      <Posts v-if="activeTab === 0" />
      <Drafts v-if="activeTab === 1" />
      <Comments v-if="activeTab === 2" />
      <Stats v-if="activeTab === 3" />
      <!-- <Rank v-if="activeTab===3" /> -->
      <!-- <Awards v-if="activeTab===5" /> -->
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapState } from "vuex";
import { Posts, Drafts, Comments, Stats } from "@/components/userTabs";
import CreateOverlayMixin from "@/mixins/CreateOverlayMixin";
import axios from "axios";
import $ from "jquery";

export default {
  name: "User",
  components: {
    Posts,
    Drafts,
    Stats,
    // Rank,
    Comments,
    // Awards,
  },

  mixins: [CreateOverlayMixin],

  data() {
    return {
      activeTab: 0,
      isBioOpening: false,
      gold_count: 0,
      platinum_count: 0,
      diamond_count: 0,
      copyButtonText: "Copy Url",
      tabs: [
        "Posts",
        "Drafts",
        "Comments",
        "Stats",
        // "Rank",
        // "Awards"
      ],
    };
  },

  mounted() {
    this.$store.commit("set_shared_overlay_class", "");
    this.O2Theme();
    if (this.$route.name === "User") {
      $(".custom-screen").addClass("full-screen");
    }
    this.$store.dispatch("getMyDraft", 0);
    this.$store.dispatch("userModule/getUnreadCount");
    this.$store.dispatch("resetActiveTab");
    this.setProfileHeader();
    if (this.$route.query.activeTab) {
      this.activeTab = +this.$route.query.activeTab;
    }
  },

  destroyed() {
    $(".custom-screen").removeClass("full-screen");
  },

  computed: {
    ...mapState({
      user: (state) => state.userModule.user,
      unreadCount: (state) => state.userModule.unreadCount,
      totalPosts: (state) => state.common.totalDrafts,
      winnerCount: (state) => state.userModule.winnerCount,
      top10Count: (state) => state.userModule.top10Count,
      myProfileTheme: (state) => state.userModule.myProfileTheme,
      themeLogo: (state) => state.auth.user.themeLogo,
      selectedTheme: (state) => state.auth.selectedTheme,
      elementTheme: (state) => state.userModule.elementTheme,
    }),
    userAvatar() {
      if (this.user.avatar) {
        return this.user.avatar;
      } else {
        return "";
      }
    },
    link() {
      return process.env.VUE_APP_PREFIX + "/themes";
    },
    rewardProfileTheme() {
      let themes = [];
      if (this.myProfileTheme && Object.keys(this.myProfileTheme).length > 0) {
        Object.keys(this.myProfileTheme).forEach((key) => {
          if (
            this.myProfileTheme[key][1] != "default" &&
            this.myProfileTheme[key][1] != "extra"
          ) {
            themes.push(this.myProfileTheme[key]);
          }
        });
      }
      return themes;
    },
  },

  watch: {
    user() {
      this.setProfileHeader();
    },
  },

  methods: {
    async O2Theme() {
      await axios({
        url: "auth/user/o2-theme",
        method: "get",
      })
        .then((response) => {
          response.data.map((item) => {
            if (item.theme_type === "rewarded") {
              if (item.theme_name === "gold") {
                this.gold_count = item.follower_count;
              } else if (item.theme_name === "platinum") {
                this.platinum_count = item.follower_count;
              } else if (item.theme_name === "diamond") {
                this.diamond_count = item.follower_count;
              }
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openEditPanel() {
      this.changeMyOzone(4);
      this.moveSidebar();
    },
    moveSidebar() {
      $("body").addClass("mpanel-open hidesb");
      $(".ozone-column").addClass("moved");
      $(".my-ozone").addClass("moved");
      this.$store.commit("change_state", ["mnav_class", "move"]);
      this.$store.commit("change_state", ["mnav_close_class", "slidem"]);
    },
    copyURL() {
      const el = document.createElement("textarea");
      el.value = process.env.VUE_APP_PREFIX + this.$route.path;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      this.copyButtonText = "Copied";
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    changeMyOzone(num) {
      let changeTime = moment().format();
      this.$store.dispatch("setMyOzone", num);
      this.$store.dispatch("setChangedTimeMyOzone", moment(changeTime));
    },
    setProfileHeader() {
      const header_text = {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        city: this.user.city,
        country: this.user.country,
      };
      this.$store.dispatch("staticLabels/set_profile_header", header_text);
    },
    removePost() {
      this.isOverlay = false;
      this.$store.dispatch("userModule/removePost", this.selectedPost.uuid);
    },
  },
};
</script>

<style>
@media only screen and (max-width: 1280px) {
  table.power-users td:nth-of-type(4):before {
    content: "{Week's} Points";
  }
}
</style>
