var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.isLogin ? 'logged-in' : 'logged-out',
    this.$route.path === '/' ? 'pressure-pit-login' : '',
    this.$route.path === '/howitworks' ? 'pressure-pit-login' : '',
    this.$route.path === '/whatiso2' ? 'pressure-pit-login' : '',
    { hidesb: _vm.isLoginPannelOpen } ],on:{"click":_vm.toggleOverlay}},[_c('div',{staticClass:"ozone-column col-trigger",class:[{ moved: _vm.isLoginPannelOpen }]}),_c('div',{staticClass:"my-ozone col-trigger",class:[{ moved: _vm.isLoginPannelOpen }]},[_c('div',{staticClass:"m-ozone-wrapper"},[_c('span',{staticClass:"icon-left-open zhh",on:{"click":_vm.openZeroToHero}}),_vm._m(0),(_vm.isLogin)?_c('div',{staticClass:"vote-status"},[_c('div',{staticClass:"remaining"},[_c('span',{staticClass:"count"},[_vm._v(_vm._s(_vm.votes))])]),_c('h3',[_vm._v("Votes left")]),_c('h4',[_vm._v("Day "+_vm._s(_vm.now)+"/7")]),_c('span',{staticClass:"icon-left-open mb-5",on:{"click":_vm.moveMyOzone}}),_c('h3',[_vm._v("MY OZONE")])]):_vm._e()]),(_vm.myOzone === 0)?_c('Login'):(_vm.myOzone === 2)?_c('ForgottenPassword'):(_vm.myOzone === 3)?_c('SignUp'):_vm._e(),(_vm.isLogin)?_c('div',{staticClass:"ozone-wrapper"},[_c('Loggedin',{on:{"getGauge":_vm.getGauge,"hideSideBar":_vm.hideSidebar}}),_c('div',{staticClass:"gauge"},[_c('div',{staticClass:"gauge-wrapper"},[_c('div',{staticClass:"gauge-range"},[_c('div',{class:("gauge-status " + _vm.gauge_status_class)},[_c('h2',[_vm._v(_vm._s(_vm.gauge_h2))]),_c('h3',[_vm._v(" Winners in"),_c('span',{staticClass:"icon-clock-1"},[_vm._v(_vm._s(_vm.settimeInterval())+_vm._s(_vm.timeDiff))])])]),_c('div',{class:("pressure " + _vm.pressure_class)})])])])],1):_vm._e()],1),_c('a',{staticClass:"icon-menu show-mnav",class:{
      non_index:
        _vm.overlayClass || _vm.editPostOverlayClass || _vm.postCreateOverlayClass,
    },attrs:{"title":"Open Menu"},on:{"click":_vm.moveSidebar}}),_c('div',{staticClass:"mnav",class:_vm.mnav_class},[_c('nav',{staticClass:"m-navbar"},[_c('Navbar')],1),_c('div',{staticClass:"close-panel mnav-close",class:_vm.mnav_close_class},[_c('a',{staticClass:"icon-cancel-1",attrs:{"title":"Close Menu"},on:{"click":_vm.hideSidebar}})]),_vm._m(1)]),(_vm.myOzone === 4)?_c('EditProfileComponent'):_vm._e(),(_vm.myOzone === 5)?_c('SettingComponent',{on:{"hideSideBar":_vm.hideSidebar}}):_vm._e(),(_vm.myOzone === 6)?_c('FollowingComponent',{on:{"hideSideBar":_vm.hideSidebar}}):_vm._e(),(_vm.myOzone === 7)?_c('NotificationsComponent'):_vm._e(),(_vm.myOzone === 8)?_c('CartTokenComponent',{on:{"hideSideBar":_vm.hideSidebar}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"zero-hero-header"},[_c('h2',[_vm._v("From zero ")]),_c('h3',[_vm._v("To hero")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"sisters-mob"},[_c('li',[_c('a',{attrs:{"href":""}},[_c('span',[_vm._v("O"),_c('sup',[_vm._v("2")])]),_vm._v("POSITIVE")])])])}]

export { render, staticRenderFns }