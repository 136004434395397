export const state = {
  totalVotes: 0,
  totalNonVotePosts: 0,
  min_votes: 0,
  max_votes: 0,
};

export const mutations = {
  SET_VOTES(state, payload) {
    state.totalVotes = payload.total_votes;
    state.totalNonVotePosts = payload.total_non_votes_posts;
    state.min_votes = payload.min_votes;
    state.max_votes = payload.max_votes;
  },
};

export const getters = {};

export const actions = {
  setVotes({ commit }, data) {
    commit("SET_VOTES", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
