var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('perfect-scrollbar',{staticClass:"extra-panel bbb side-notification",class:_vm.openClass},[_c('div',{staticClass:"my-panels"},[_c('h2',{staticClass:"akrobat bk-r"},[_vm._v("NOTIFICATIONS")]),_c('div',{staticClass:"read_all",on:{"click":_vm.ReadAllNotification}},[_c('i',{staticClass:"icon-eye blk"}),_vm._v("Reset ")]),(this.notifications.length < 1)?_c('p',[_vm._v("You have no Notifications.")]):_vm._e(),_vm._l((_vm.notifications),function(notification,index){return _c('div',{key:index,staticClass:"notification-box"},[_c('div',{staticClass:"notification-box-wrap",class:[notification.read_notification === true ? 'read' : 'unread']},[(notification.title === 'Comment')?_c('span',{staticClass:"cst-icon icon-comment"}):_vm._e(),(notification.title === 'Token')?_c('span',{staticClass:"tkn-noty-icon"},[_c('span',{staticClass:"tok-icon-gen-white"},[_c('span',[_vm._v("O"),_c('sup',[_vm._v("2")])])])]):_vm._e(),(notification.title === 'Reply')?_c('span',{staticClass:"cst-icon icon-reply"}):_vm._e(),(notification.title === 'Star')?_c('span',{staticClass:"cst-icon icon-star"}):_vm._e(),(notification.title === 'Follow')?_c('span',{staticClass:"cst-icon icon-user"}):_vm._e(),(
            notification.title === 'Following Notification' ||
            notification.title === 'Following'
          )?_c('span',{staticClass:"cst-icon icon-picture"}):_vm._e(),(notification.title === 'Post Ban')?_c('span',{staticClass:"cst-icon icon-cancel-circle"}):_vm._e(),(notification.title === 'Winner')?_c('span',{staticClass:"cst-icon icon-award"}):_vm._e(),(notification.title === 'Achievement')?_c('span',{staticClass:"cst-icon icon-award"}):_vm._e(),(notification.title === 'theme_removed')?_c('span',{staticClass:"cst-icon icon-frown"}):_vm._e(),(
            notification.title === 'Follow' ||
            notification.title === 'Following Notification' ||
            notification.title === 'Following'
          )?_c('a',{staticClass:"noti-desc",attrs:{"id":notification.user_uuid},on:{"click":function($event){return _vm.goToProfile(
              notification.user_uuid,
              notification.user_email,
              index
            )}}},[_vm._v(" "+_vm._s(notification.description)+" ")]):(notification.title === 'Achievement')?_c('a',{staticClass:"noti-desc",on:{"click":function($event){return _vm.readNotification(index, notification.title)}}},[_vm._v(_vm._s(notification.description))]):(notification.title === 'theme_removed')?_c('a',{staticClass:"noti-desc",on:{"click":function($event){return _vm.readNotification(index, notification.title)}}},[_vm._v(_vm._s(notification.description))]):_c('a',{staticClass:"noti-desc",attrs:{"id":notification.post_uuid},on:{"click":function($event){return _vm.readNotification(
              index,
              notification.title,
              notification.post_uuid
            )}}},[_vm._v(" "+_vm._s(notification.description)+" ")]),_c('span',{staticClass:"remove-notification",on:{"click":function($event){return _vm.removeNotification(index)}}},[_c('i',{staticClass:"icon-trash"})])])])})],2),_c('div',{staticClass:"gauge"},[_c('div',{staticClass:"gauge-wrapper"})]),_c('div',{staticClass:"btn_center_sec mainPost_loadmore load-more-notification"},[(_vm.isLogin && _vm.loadMoreToggle)?_c('a',{staticClass:"load_more_btn",on:{"click":_vm.loadMoreNotification}},[_vm._v(" Show more "),_c('span',{staticClass:"icon-down-open"})]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }