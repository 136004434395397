<template>
  <div class="voter" v-if="isShow">
    <div class="votes cst-tooltip">
      <div class="votes-nav">
        <div data-title="Pressure Up" data-title-right>
          <div
            v-if="addAble"
            class="icon-plus votes-button votes-up tippy"
            @click="addPressure(post_id)"
          ></div>
        </div>
        <div data-title="Pressure Down" data-title-right>
          <div
            v-if="reduceAble"
            class="icon-minus votes-button votes-down tippy"
            @click="reducePressure(post_id)"
          ></div>
        </div>
      </div>
      <div data-title="Leaderboard Position" data-title-right>
        <input
          type="number"
          id="post-02-votes"
          :value="pressure"
          min="0"
          step="1"
          onInput="checkLength(5,this)"
        />
        <span>%</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "voter",
  props: {
    post_id: Number,
    pressure: String,
    isShow: Boolean,
    addAble: Boolean,
    reduceAble: Boolean,
  },

  methods: {
    async addPressure(postID) {
      let data = {
        post_id: postID,
        changeValue: 1,
      };
      await this.$store.dispatch("changePressure", data);
      await this.$store.commit("homeModule/VOTE_CHANGE", {
        postID: postID,
        vote: 1,
      });
      await this.$store.dispatch("voteCount", {
        post: postID,
        type: "plus",
      });
    },
    async reducePressure(postID) {
      let data = {
        post_id: postID,
        changeValue: -1,
      };
      await this.$store.dispatch("changePressure", data);
      await this.$store.commit("homeModule/VOTE_CHANGE", {
        postID: postID,
        vote: -1,
      });
      await this.$store.dispatch("voteCount", {
        post: postID,
        type: "minus",
      });
    },
  },
};
</script>
