<template>
  <div>
    <div class="overlay" :class="sharedOverlayClass">
      <perfect-scrollbar class="wrapper">
        <span class="close-btn">
          <a class="icon-cancel-1 close-overlay" @click="hideOverlay()"
            >Close</a
          >
        </span>
        <div class="content full-content" :class="{ logedIn: isLogin }">
          <div class="p-r custom_all_btn" style="min-height: 38px">
            <span v-if="!hideNext">
              <span class="d-ib" data-title="Edit Post" data-title-top>
                <a
                  v-show="isLogin && user.email === overlayPost.author_email"
                  class="button basic tertiary icon-edit pd-10-15 mr-10"
                  @click="editPost"
                ></a>
              </span>
              <span class="d-ib" data-title="Delete Post" data-title-top>
                <a
                  v-show="isLogin && user.email === overlayPost.author_email"
                  class="button basic tertiary icon-trash pd-10-15 mr-10"
                  @click="removePost()"
                ></a>
              </span>
            </span>
            <span
              class="d-ib"
              :data-title="`${
                diableFavorite ? 'Remove from Faves' : 'Add to Faves'
              }`"
              data-title-top
            >
              <a
                v-show="isLogin && user.email !== overlayPost.author_email"
                class="button basic tertiary icon-heart pd-10-15 mr-10"
                :class="{ liked: diableFavorite }"
                @click="clickFavButton"
              ></a>
            </span>
            <span
              class="d-ib for-btn"
              :data-title="`${disableFollow ? 'Following' : 'Follow'} ${
                overlayPost.author
              }`"
              data-title-top
            >
              <a
                v-show="isLogin && user.email !== overlayPost.author_email"
                class="button basic tertiary icon-user pd-10-15 mr-10"
                :class="{ follow: disableFollow }"
                @click="clickButton"
              >
                <span class="plus">+</span>
              </a>
            </span>
            <div v-if="!hideNext" class="logedIn-btn-wrap">
              <div
                v-if="isLogin"
                class="loged-innp-btns next-btn cst-right mnl-tip"
              >
                <a
                  v-if="hasNext"
                  class="button tertiary right nxt tip-next"
                  @click="showOtherPost(1)"
                  data-title="Next Post"
                  data-title-bottom
                >
                  Next
                  <i class="icon-right-open"></i>
                </a>
                <a
                  v-if="hasPrev"
                  class="button tertiary right prv tip-pre"
                  :class="!hasNext ? 'left-0' : ''"
                  @click="showOtherPost(-1)"
                  data-title="Previous Post"
                  data-title-left
                >
                  <i class="icon-left-open"></i>
                  <strong>Prev</strong>
                </a>
              </div>
              <div
                v-else
                class="loged-outnp-btns cmn-loged cst-left cst-top"
                :class="{ 'left-0': hasNext, 'right-0': hasPrev }"
              >
                <a
                  v-if="hasNext"
                  class="button tertiary right nxt tip-next"
                  @click="showOtherPost(1)"
                  data-title="Next Post"
                  data-title-bottom
                >
                  Next
                  <i class="icon-right-open"></i>
                </a>

                <a
                  v-if="hasPrev"
                  class="button tertiary right prv tip-pre"
                  :class="!hasNext ? 'left-0' : ''"
                  @click="showOtherPost(-1)"
                  data-title="Previous Post"
                  data-title-left
                >
                  <i class="icon-left-open"></i>
                  <strong>Prev</strong>
                </a>
              </div>
            </div>
            <span
              v-if="isLogin && overlayPost.life_support !== false"
              class="actual-count p-a"
              title="Precise position"
            >
              {{ limit2numbers(overlayPost.pressure)
              }}<span v-if="!hideNext">%</span>
            </span>
          </div>
          <ul class="feed">
            <li>
              <h2>{{ overlayPost.title }}</h2>
              <div class="relative">
                <div v-if="!hideNext">
                  <div v-if="overlayPost.life_support !== false">
                    <Voter
                      :post_id="overlayPost.post_id"
                      :isShow="isLogin && $route.name !== 'Winners'"
                      :addAble="
                        myVotes && overlayPost.post_id !== this.max_votes
                      "
                      :reduceAble="
                        overlayPost.votes &&
                        myVotes &&
                        overlayPost.post_id !== this.min_votes
                      "
                      :pressure="overlayPost.pressure"
                    />
                  </div>
                </div>
                <div class="imgWrap" v-if="overlayPost.images.length">
                  <!-- POST IMAGE / MULTI-IMAGE SLIDESHOW / VIDEO WRAP -->
                  <div class="post-life">
                    <div
                      class="bar"
                      :class="calExpiration(overlayPost.expiration)"
                      :style="`top:${overlayPost.expiration}%`"
                      :data-title="`${twoDigit(lifeSupport)} O2 remain`"
                      data-title-right
                    ></div>
                  </div>
                  <div v-if="overlayPost.images.length < 2">
                    <img :src="overlayPost.images[0].url" />
                  </div>
                  <div class="o2img-slider" v-else>
                    <VueSlickCarousel v-bind="settings">
                      <li
                        v-for="(image, idx) in overlayPost.images"
                        :key="idx"
                        :class="{ active: activeLi === idx }"
                        @click="activeLi = idx"
                      >
                        <v-lazy-image :src="image.url" />
                      </li>
                    </VueSlickCarousel>
                  </div>
                  <!-- END MEDIA - Image / slideshow /video content here-->
                </div>
                <div
                  class="imgWrap mt-20 daynamic-img-warp"
                  v-for="(video, index) in overlayPost.videos"
                  :key="index"
                >
                  <div class="post-life">
                    <div
                      class="bar"
                      :style="`top:${overlayPost.expiration}%`"
                      :data-title="`${twoDigit(
                        overlayPost.life_support_remaining
                      )} O2 remain`"
                      data-title-right
                    ></div>
                  </div>
                  <iframe
                    width="560"
                    height="315"
                    :src="video.url"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <!-- END POST IMAGE / MULTI-IMAGE SLIDESHOW / VIDEO WRAP -->
                <div class="author">
                  <span class="user mr-5">
                    <i class="icon-user"></i>
                    <a
                      class="anti-a mr-0"
                      :title="`View ${overlayPost.author}`"
                      @click="goToProfile(index)"
                      >{{ overlayPost.author }}</a
                    >
                    <span>
                      <a
                        :href="link"
                        :data-title="toCapital(overlayPost.author_theme)"
                        data-title-left
                        class="user-trophy"
                        :class="trophyClass(overlayPost.author_theme)"
                      >
                        <i class="icon-award tiny"></i>
                      </a>
                    </span>
                  </span>
                  <span class="date">
                    <i class="icon-calendar"></i>
                    {{ postTimeAgo(overlayPost.post_date) }}
                  </span>
                  <span
                    :data-title="`View ${overlayPost.author}`"
                    data-title-left
                    class="tip-user"
                  >
                    <a class="avatar" @click="goToProfile">
                      <div
                        v-if="isLogin"
                        class="on-off"
                        :class="[
                          overlayPost.author_status === true ? 'on-now' : '',
                        ]"
                        :data-title="
                          overlayPost.author_status === true
                            ? 'Online'
                            : 'Offline'
                        "
                        data-title-right
                      ></div>
                      <img
                        class="pho-circs pho-2"
                        :src="overlayPost.author_img"
                        :alt="overlayPost.author"
                        :class="
                          overlayPost.author_class === 'no-border'
                            ? 'avatar-no-border'
                            : ''
                        "
                      />
                    </a>
                  </span>
                </div>
              </div>
              <BoostToTop
                :post_id="overlayPost.post_id"
                :author="overlayPost.author_email"
                :life_support="overlayPost.life_support_remaining"
                :life="overlayPost.life_support"
                :add_time="overlayPost.add_time"
              />

              <!-- Award system -->
              <div v-if="isLogin">
                <div class="other-action-wrap">
                  <span
                    :data-title="`${
                      disableLove ? 'Remove Love' : 'Love this Post'
                    }`"
                    data-title-top
                    @click="clickLoveLikeButton('love')"
                    class="single-action"
                    v-if="user.email !== overlayPost.author_email"
                  >
                    <a
                      class="button basic icon-fire"
                      :class="disableLove ? 'secondary' : 'tertiary'"
                      >Love
                      <span v-if="loveCount" class="fs-7 ml-3">
                        ({{ loveCount }})</span
                      ></a
                    >
                  </span>
                  <span
                    :data-title="`${
                      disableLike ? 'Remove Like' : 'Like this Post'
                    }`"
                    data-title-top
                    @click="clickLoveLikeButton('like')"
                    class="single-action"
                    v-if="user.email !== overlayPost.author_email"
                  >
                    <a
                      class="button basic icon-thumbs-up"
                      :class="disableLike ? 'secondary' : 'tertiary'"
                      >Like
                      <span v-if="likeCount" class="fs-7 ml-3">
                        ({{ likeCount }})
                      </span></a
                    >
                  </span>
                  <span
                    data-title="Award Stars to Post"
                    data-title-top
                    @click="awardModalShow = !awardModalShow"
                    class="single-action"
                    v-if="user.email !== overlayPost.author_email"
                  >
                    <a class="button basic tertiary icon-star"
                      ><span class="btn-text">Stars</span>
                      <span class="fs-7 ml-3" v-if="totalStar"
                        >({{ totalStar }})</span
                      ></a
                    >
                  </span>
                  <span
                    @click="scroll('comment-box')"
                    class="single-action isMobile"
                  >
                    <a class="button basic tertiary icon-comment-empty"
                      ><span class="btn-text">Comment</span>
                      <span v-if="totalCommentsCount"
                        >({{ totalCommentsCount }})</span
                      ></a
                    >
                  </span>
                </div>
              </div>
              <p class="white-spaces-pre">{{ overlayPost.summary }}</p>
              <p v-if="overlayPost.life_support">
                <span class="acrobat-bold text-blue text-border-left fs-16">
                  O<sup>2</sup>
                </span>
                :
                <strong class="blk">
                  {{ twoDigit(overlayPost.life_support_remaining) }}
                </strong>
                of life remaining.
              </p>
              <p v-else>
                <span class="acrobat-bold text-blue text-border-left fs-16">
                  O<sup>2</sup>
                </span>
                :
                <strong class="blk">EXPIRED.</strong> This Post cannot be voted
                on.
              </p>
              <!-- END VIDEO WRAP -->
              <p v-if="overlayPost.category.length > 0" class="ovlay modal-cate-list">
                <span class="ovlay-abs">
                  <i class="icon-list-bullet"></i>
                  CATEGORY:
                </span>
                <span
                  v-for="(cat, index) in overlayPost.category"
                  :key="index"
                  :title="cat"
                  class="cate-list"
                  >{{ cat }}</span
                >
              </p>
              <p class="ovlay mt-30 total-star" v-if="totalStar">
                <span class="ovlay-abs"> <i class="icon-star"></i>stars: </span>
                <span>{{ totalStar }}</span> &middot;
                <a @click="getCountData">
                  {{ opendCountView ? "Close" : "View " }}</a
                >
              </p>
              <div class="single-star-wrap" v-if="opendCountView">
                <div
                  class="single-star-row"
                  v-for="(item, idx) in countData"
                  :key="idx"
                >
                  <p class="ovlay">
                    <span class="ovlay-abs icon-star-empty">{{ idx }}:</span>
                    {{ item }}
                  </p>
                </div>
              </div>
              <p v-if="overlayPost.credit" class="ovlay mt-30">
                <span class="ovlay-abs">
                  <i class="icon-heart-empty"></i>CREDIT:
                </span>
                <span target="_blank" :href="overlayPost.credit">{{
                  overlayPost.credit
                }}</span>
              </p>
              <!-- link created by user when Post is created in "Extras TAB" -->
              <p v-if="overlayPost.additional_link" class="ovlay">
                <span class="ovlay-abs">
                  <i class="icon-link-ext"></i>LINK:
                </span>
                <a
                  class="anti-a"
                  target="_blank"
                  :href="overlayPost.additional_link"
                  >{{ overlayPost.additional_link }}</a
                >
              </p>
              <!-- tag link created by user when Post is created in "tag input" -->
              <p v-if="overlayPost.tags.length > 0" class="spd_abs_tags ovlay">
                <span class="ovlay-abs">
                  <i class="icon-tags"></i>
                  TAGS:
                </span>
                <a
                  v-for="(tag, index) in overlayPost.tags"
                  :key="index"
                  class="anti-a f-end-tag"
                  :title="tag"
                  @click="searchTag(tag)"
                  >#{{ tag }}</a
                >
              </p>
              <!-- auto generated when the Post is created and displayed here -->
              <p class="ovlay">
                <span class="ovlay-abs">
                  <i class="icon-link-ext"></i>
                  URL:
                </span>
                <a :href="postURL" target="_blank" class="anti-a">{{
                  postURL
                }}</a>
                <a
                  style="cursor: copy"
                  data-title="Copy Post link"
                  data-title-top
                  class="button tertiary copyurl"
                  rel="noopener noreferrer"
                  @click="copyURL"
                >
                  <span>{{ copyButtonText }}</span>
                </a>
              </p>
              <!--user can ''copy'' this Post's url-->
              <reportPostModal
                :show="reportModalShow"
                :post_url="postURL"
                @toggle-modal="reportModalShow = !reportModalShow"
                @handle-submit="reportPost"
              />
              <p class="ovlay mt-30 total-star" v-if="totalStar">
                <span class="ovlay-abs"> <i class="icon-star"></i>stars: </span>
                <span>{{ totalStar }}</span> &middot;
                <a @click="getCountData">
                  {{ opendCountView ? "Close" : "View " }}</a
                >
              </p>
              <div class="single-star-wrap" v-if="opendCountView">
                <div
                  class="single-star-row"
                  v-for="(item, idx) in countData"
                  :key="idx"
                >
                  <p class="ovlay">
                    <span class="ovlay-abs icon-star-empty">{{ idx }}:</span>
                    {{ item }}
                  </p>
                </div>
              </div>
              <div class="pt-20">
                <div v-if="isLogin" class="post-action-bar">
                  <div class="mb-25">
                    <span class="ovlay-abs"
                      ><i class="blk icon-share"></i> SHARE:
                    </span>
                  </div>
                  <span class="report-error">
                    <a
                      v-if="user.email !== overlayPost.author_email"
                      data-title="Report to Admin"
                      data-title-left
                      class="fs-6 anti-red"
                      @click="disableReportButton"
                    >
                      <span class="icon-flag mr-3"></span>REPORT
                    </a>
                    <span>{{ errorMessage }}</span>
                  </span>
                </div>
                <div v-else class="mb-25">
                  <span class="ovlay-abs"
                    ><i class="blk icon-share"></i> SHARE:
                  </span>
                </div>
                <div class="social-share-links">
                  <a
                    @click="shareFacebook(overlayPost.post_id)"
                    target="_blank"
                    class="icon-box fb"
                  >
                    <i class="icon-facebook"></i>
                  </a>
                  <a
                    @click="shareTwitter(overlayPost.post_id)"
                    target="_blank"
                    class="icon-box tw"
                  >
                    <i class="icon-twitter"></i>
                  </a>
                  <a
                    @click="sharePinterest(overlayPost.post_id)"
                    target="_blank"
                    class="icon-box pi"
                  >
                    <i class="icon-pinterest-circled"></i>
                  </a>
                  <a
                    @click="modalShow = !modalShow"
                    target="_blank"
                    class="icon-box mi"
                  >
                    <i class="icon-mail-alt"></i>
                  </a>
                  <modal
                    :show="modalShow"
                    @toggle-modal="modalShow = !modalShow"
                    @handle-submit="handleSubmit"
                  />
                </div>
              </div>
              <!-- Comment -->
              <div v-if="isLogin" id="comment-box">
                <div class="comment-box">
                  <div class="fieldset pb-20">
                    <label for="add-comment">
                      <i class="icon-commenting"></i>
                      Add a Comment ({{ totalCommentsCount }})
                    </label>
                    <div class="input-holder">
                      <textarea
                        v-model="commentText"
                        placeholder="Join the discussion ..."
                      ></textarea>
                    </div>
                  </div>
                  <input
                    type="submit"
                    id="submit-comment-01"
                    class="button secondary"
                    value="COMMENT"
                    @click.prevent="addComment"
                    :disabled="disableAddCommentButton || doubleClick"
                  />
                </div>

                <!--- Use a Commenting Feed  plugin or similar  -unless its easy to code ???      --->
                <div
                  v-for="(comment, index) in comments"
                  :key="index"
                  class="comment"
                >
                  <div class="comment_inner">
                    <div class="main_comment_cs">
                      <span
                        @click="
                          goToCommentProfile(
                            comment.cmt_user_uuid,
                            comment.cmt_user_email
                          )
                        "
                      >
                        <div v-if="comment.cmt_user_avatar !== null">
                          <img
                            class="pho-circs pho-2"
                            :title="`View ${comment.cmt_user_fullname}`"
                            :src="comment.cmt_user_avatar"
                            :alt="comment.cmt_user_fullname"
                            :class="
                              comment.cmt_user_avatar ===
                              'https://test-o2.s3-ap-southeast-2.amazonaws.com/avatars/logo-160.png'
                                ? 'avatar-no-border'
                                : ''
                            "
                          />
                        </div>
                        <div
                          v-else
                          class="small-user-img pho-circs pho-4"
                          :title="`View ${comment.cmt_user_fullname}`"
                        ></div>
                        <div class="name_time_wrapper">
                          <span class="blue_text">
                            {{ comment.cmt_user_fullname }}
                          </span>

                          <div>{{ timeAgo(comment.cmmented_at) }}</div>
                          <div
                            v-if="
                              comment.cmt_user_email ===
                              overlayPost.author_email
                            "
                            class="cmt-author"
                          >
                            Author
                          </div>
                        </div>
                      </span>
                      <div class="cs_text">
                        <i>"</i>
                        {{ comment.comment }}
                        <i>"</i>
                      </div>

                      <div
                        data-title="Remove Comment"
                        data-title-left
                        class="delete_btn"
                        v-if="user.email === overlayPost.author_email"
                      >
                        <i
                          class="icon-trash com-trash"
                          @click="removeComment(index, comment.comm_id)"
                        ></i>
                      </div>
                      <div
                        data-title="Remove Comment"
                        data-title-left
                        class="delete_btn"
                        v-else
                      >
                        <i
                          v-show="user.uuid === comment.cmt_user_uuid"
                          class="icon-trash com-trash"
                          @click="removeComment(index, comment.comm_id)"
                        ></i>
                      </div>
                      <!-- comment love/like -->
                      <div v-if="isLogin" class="comment-action">
                        <div class="ca-wrap">
                          <div class="ca-box">
                            <span
                              :data-title="`${
                                disableLoveComment(comment.comm_id)
                                  ? 'Remove Love'
                                  : 'Love this Comment'
                              }`"
                              data-title-top
                              @click="CommentLoveLike('love', comment.comm_id)"
                            >
                              <a
                                class="icon-fire"
                                :class="{
                                  loved: disableLoveComment(comment.comm_id),
                                }"
                              ></a>
                            </span>
                            <span>
                              {{ getCommentLoveCount(comment.comm_id) }}
                            </span>
                          </div>
                          <div class="ca-box">
                            <span
                              :data-title="`${
                                disableLikeComment(comment.comm_id)
                                  ? 'Remove Like'
                                  : 'Like this Comment'
                              }`"
                              data-title-top
                              @click="CommentLoveLike('like', comment.comm_id)"
                            >
                              <a
                                class="icon-thumbs-up"
                                :class="{
                                  liked: disableLikeComment(comment.comm_id),
                                }"
                              ></a>
                            </span>
                            <span>
                              {{ getCommentLikeCount(comment.comm_id) }}</span
                            >
                          </div>

                          <div class="ca-box">
                            <span
                              class="view-toggle"
                              :class="{ opened: opendComment === index }"
                              title="Open/Close comments"
                              @click="toggleAccordion(index)"
                            >
                              <a
                                class="icon-reply"
                                v-if="opendComment !== index"
                              ></a>
                              <a class="icon-cancel" v-else></a>
                            </span>
                            <span v-if="comment.total_sub_comm > 0">
                              {{ comment.total_sub_comm }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="input-holder"
                      :class="{ opened: opendComment === index }"
                    >
                      <textarea
                        type="text"
                        v-model="subCommentText[index]"
                        placeholder="Add a comment ..."
                      />
                      <input
                        type="submit"
                        id="submit-comment-01"
                        class="button secondary"
                        value="REPLY"
                        @click.prevent="addSubComment(comment.comm_id, index)"
                        :disabled="
                          disableAddSubCommentButton || subCommentdoubleClick
                        "
                      />
                    </div>
                    <div
                      v-for="(sub, idx) in comment.sub_comm"
                      :key="idx"
                      class="sub-comment-wrapper"
                      :class="{ opened: opendComment === index }"
                    >
                      <div
                        class="sub-comment-inner"
                        :class="{ opened: opendComment === index }"
                      >
                        <span
                          @click="
                            goToCommentProfile(
                              sub.sub_cmt_user_uuid,
                              sub.sub_cmt_user_email
                            )
                          "
                        >
                          <div v-if="sub.sub_cmt_user_avatar !== null">
                            <img
                              class="pho-circs pho-2"
                              :title="`View ${sub.sub_cmt_user_fullname}`"
                              :src="sub.sub_cmt_user_avatar"
                              :alt="sub.sub_cmt_user_fullname"
                              :class="
                                sub.sub_cmt_user_avatar ===
                                'https://test-o2.s3-ap-southeast-2.amazonaws.com/avatars/logo-160.png'
                                  ? 'avatar-no-border'
                                  : ''
                              "
                            />
                          </div>
                          <div
                            v-else
                            class="small-user-img pho-circs pho-4"
                            :title="`View ${sub.sub_cmt_user_fullname}`"
                          ></div>
                          <div class="name_time_wrapper">
                            <span class="blue_text">
                              {{ sub.sub_cmt_user_fullname }}
                            </span>

                            <div>{{ subTimeAgo(sub.sub_cmmented_at) }}</div>
                          </div>
                        </span>
                        <div class="cs_text">
                          <i>"</i>
                          {{ sub.sub_comment }}
                          <i>"</i>
                        </div>

                        <div
                          data-title="Remove Comment"
                          data-title-left
                          class="delete_btn"
                          v-if="user.email === overlayPost.author_email"
                        >
                          <i
                            class="icon-trash com-trash"
                            @click="removeSubComment(index, idx)"
                          ></i>
                        </div>
                        <div
                          data-title="Remove Comment"
                          data-title-left
                          class="delete_btn"
                          v-else
                        >
                          <i
                            v-show="user.uuid === sub.sub_cmt_user_uuid"
                            class="icon-trash com-trash"
                            @click="removeSubComment(index, idx)"
                          ></i>
                        </div>
                      </div>
                    </div>

                    <div
                      class="btn_center_sec"
                      :class="{ opened: opendComment === index }"
                    >
                      <a
                        class="load_more_btn"
                        v-if="
                          isLogin &&
                          comment.sub_comm &&
                          comment.sub_comm.length < comment.total_sub_comm
                        "
                        @click="
                          loadMoreSubComments(
                            comment.comm_id,
                            comment.curr_page
                          )
                        "
                        >Show more <span class="icon-down-open"></span>
                        </a>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="commentH_text">
                <i class="icon-commenting"></i>
                Add a Comment
              </div>
              <div v-if="!isLogin" class="post_comment_without_login">
                <div class="innerBox_wrapper">
                  <textarea readonly placeholder="Login to view ..."></textarea>
                  <div class="btn_wrapper">
                    <a
                      class="
                        button
                        primary
                        icon-lock-open
                        pr-15
                        pl-15
                        mt--5
                        mr-10
                      "
                      data-mobile="true"
                      title="Login"
                      @click="hideSidebar(0)"
                      >Login</a
                    >
                    <a
                      class="button primary icon-lock pr-15 pl-15 mt--5"
                      data-mobile="true"
                      title="Sign up"
                      @click="hideSidebar(3)"
                      >Sign up</a
                    >
                  </div>
                </div>
                <p>It's much more fun when you're in!</p>
              </div>
            </li>
          </ul>
        </div>
        <MatureAudienceLoginModal
          v-if="showLoginModal"
          @colseModal="colseModal"
        />
        <div class="btn_center_sec">
          <a
            class="load_more_btn"
            v-if="isLogin && loadMoreToggle"
            @click="loadMoreComments"
            >Show more <span class="icon-down-open"></span>
            </a>
        </div>
        <awardStarModal
          v-if="awardModalShow"
          :show="awardModalShow"
          :post_id="overlayPost.post_id"
          @toggle-modal="awardModalShow = !awardModalShow"
        />
      </perfect-scrollbar>
    </div>
    <div class="overlay-back" @click="hideOverlay()"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import $ from "jquery";
import Voter from "@/components/shared/Voter";
import changeMyOzoneMixin from "@/mixins/ChangeMyOzoneMixin";
import CommentTimeMixin from "@/mixins/CommentTimeMixin";
import modal from "../components/shared/modal/modal.vue";
import reportPostModal from "../components/shared/modal/reportPostModal.vue";
import MatureAudienceLoginModal from "../components/modal/MatureAudienceLoginModal.vue";
import VueSlickCarousel from "vue-slick-carousel";
import VLazyImage from "v-lazy-image/v2";
import * as moment from "moment";
import awardStarModal from "./shared/modal/awardStarModal.vue";
import BoostToTop from "./shared/overlay/BoostToTop.vue";

export default {
  name: "Overlay",
  components: {
    Voter,
    modal,
    reportPostModal,
    MatureAudienceLoginModal,
    VueSlickCarousel,
    VLazyImage,
    awardStarModal,
    BoostToTop,
  },
  mixins: [changeMyOzoneMixin, CommentTimeMixin],

  data() {
    return {
      opendComment: -1,
      activeLi: 0,
      copyButtonText: "Copy Url",
      commentText: "",
      subCommentText: [],
      comments: [],
      totalComments: 0,
      totalCommentsCount: 0,
      pageNumber: 0,
      modalShow: false,
      reportModalShow: false,
      errorMessage: "",
      closeLoginModal: false,
      loginModalShow: false,
      settings: {
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
      },
      doubleClick: false,
      subCommentdoubleClick: false,
      awardModalShow: false,
      opendCountView: false,
      // fbResponse: null,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      following: (state) => state.auth.following,
      isLogin: (state) => state.auth.isLogin,
      isDraft: (state) => state.common.isDraft,
      sharedOverlayClass: (state) => state.common.sharedOverlayClass,
      overlayPost: (state) => state.common.overlayPost,
      myFavorites: (state) => state.common.myFavorites,
      myVotes: (state) => state.auth.user.votes,
      posts: (state) => state.profileModule.posts,
      leaders: (state) => state.common.leaders,
      userEmail: (state) => state.auth.user.email,
      max_votes: (state) => state.common.max_votes,
      min_votes: (state) => state.common.min_votes,
      countData: (state) => state.overlayModule.countData,
      userData: (state) => state.overlayModule.userData,
      totalStar: (state) => state.overlayModule.totalStar,
      LovePost: (state) => state.overlayModule.LovePost,
      LikePost: (state) => state.overlayModule.LikePost,
      loveCount: (state) => state.overlayModule.loveCount,
      likeCount: (state) => state.overlayModule.likeCount,
      loveComment: (state) => state.overlayModule.loveComment,
      likeComment: (state) => state.overlayModule.likeComment,
      LoveLikeCommentCount: (state) => state.overlayModule.LoveLikeCommentCount,
      lifeSupport: (state) => state.overlayModule.lifeSupport,
    }),
    userAvatar() {
      if (this.user.avatar) {
        return this.user.avatar;
      } else {
        return process.env.VUE_APP_DEFAULT_IMG_URL;
      }
    },
    loadMoreToggle() {
      const limit = 10;
      return Math.ceil(this.totalComments / limit) > this.pageNumber
        ? true
        : false;
    },
    disableFollow() {
      let $this = this;
      if (!$this.isLogin) return false;
      if (
        $this.following.find(
          (item) => item.email === $this.overlayPost.author_email
        )
      )
        return true;
      return false;
    },
    diableFavorite() {
      let $this = this;
      if (!$this.isLogin) return false;
      if ($this.myFavorites.find((item) => item === this.overlayPost.post_id))
        return true;
      return false;
    },
    postURL() {
      $("body").addClass("hero-overlay");
      return process.env.VUE_APP_PREFIX + "/post/" + this.overlayPost.uuid;
    },
    disableAddCommentButton() {
      return this.commentText === "" ? true : false;
    },
    disableAddSubCommentButton() {
      return this.subCommentText.length < 1 ? true : false;
    },
    hasNext() {
      const index = this.leaders.findIndex(
        (item) => item.post_id === this.overlayPost.post_id
      );
      if (index + 1 === this.leaders.length) return false;
      this.$store.commit(
        "overlayModule/SET_SELECTED_POSTID",
        this.overlayPost.post_id
      );
      return true;
    },
    hasPrev() {
      const index = this.leaders.findIndex(
        (item) => item.post_id === this.overlayPost.post_id
      );
      this.$store.commit(
        "overlayModule/SET_SELECTED_POSTID",
        this.overlayPost.post_id
      );
      if (index === 0) return false;
      return true;
    },
    hideNext() {
      if (
        this.$route.name !== "/post/" + this.overlayPost.uuid &&
        this.$route.path !== "/" &&
        this.$route.path !== "/user" &&
        this.$route.path !== "/o2hq" &&
        this.$route.path !== "/themes" &&
        this.$route.path !== "/howitworks" &&
        this.$route.path !== "/tokens"
      )
        return true;
      return false;
    },
    link() {
      return process.env.VUE_APP_PREFIX + "/themes";
    },
    showLoginModal() {
      if (this.loginModalShow === true && !this.closeLoginModal) {
        return true;
      }
      return false;
    },
    disableLove() {
      if (!this.isLogin) return false;
      if (this.LovePost) {
        if (this.LovePost.find((item) => item === this.overlayPost.post_id))
          return true;
      }
      return false;
    },
    disableLike() {
      if (!this.isLogin) return false;
      if (this.LikePost) {
        if (this.LikePost.find((item) => item === this.overlayPost.post_id))
          return true;
      }
      return false;
    },
  },

  created() {
    this.timeAgo();
    this.subTimeAgo();
    this.postTimeAgo();
    if (this.isLogin) {
      this.getInitialComments();
    }
  },

  async mounted() {
    $(".full-content").addClass("full-screen");
    if (this.isLogin) {
      this.$store.dispatch("getFavorites");
      await this.$store.dispatch("overlayModule/GetLoveLikeData", {
        post: this.overlayPost.post_id,
      });
      await this.$store.dispatch("overlayModule/GetCommentLoveLikeData", {
        post: this.overlayPost.post_id,
      });
    }
    await this.$store.dispatch("overlayModule/PostTrack", {
      post: this.overlayPost.post_id,
      type: "post",
    });
    await this.$store.dispatch("overlayModule/StarCount", {
      post: this.overlayPost.post_id,
    });
  },

  destroyed() {
    $(".full-content").removeClass("full-screen");
    $(".custom-screen").addClass("full-screen");
  },

  // watch: {
  //   fbResponse() {
  //     this.postShareCount();
  //   },
  // },

  methods: {
    scroll(elId) {
      setTimeout(() => {
        document.getElementById(elId).scrollIntoView({
          block: "center",
        });
      }, 100);
    },
    async getCountData() {
      this.opendCountView = !this.opendCountView;
      await this.$store.dispatch("overlayModule/StarCount", {
        post: this.overlayPost.post_id,
      });
    },
    // toggleSubCountAccordion(index) {
    //   if (index === this.opneSubCount) {
    //     this.opneSubCount = "";
    //   } else {
    //     this.opneSubCount = index;
    //   }
    // },
    async disableReportButton() {
      const data = {
        post_id: this.overlayPost.post_id,
      };
      const response = await this.$store.dispatch(
        "overlayModule/disableReportButton",
        data
      );
      if (response.data.data.reportedByUser === false) {
        this.reportModalShow = !this.reportModalShow;
      } else {
        this.errorMessage = response.data.message;
        setInterval(() => {
          this.errorMessage = "";
        }, 5000);
      }
    },
    postShareCount() {
      this.$store.dispatch("overlayModule/PostTrack", {
        post: this.overlayPost.post_id,
        type: "share",
      });
    },
    shareFacebook(postId) {
      window.open(
        "http://www.facebook.com/sharer.php?u=" +
          process.env.VUE_APP_API_URL +
          "/posts/social-sharing/" +
          postId,
        "",
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
      this.postShareCount();
    },
    shareTwitter(postId) {
      window.open(
        "https://twitter.com/intent/tweet?url=" +
          process.env.VUE_APP_API_URL +
          "/posts/social-sharing/" +
          postId,
        "",
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
      this.postShareCount();
    },
    sharePinterest(postId) {
      window.open(
        "http://pinterest.com/pin/create/button/?url=" +
          process.env.VUE_APP_API_URL +
          "/posts/social-sharing/" +
          postId,
        "",
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
      this.postShareCount();
    },
    async handleSubmit(data) {
      data.postId = this.overlayPost.post_id;
      if (this.isLogin) {
        data.user = "yes";
      } else {
        data.user = "no";
      }
      this.modalShow = false;
      const res = await this.$store.dispatch(
        "overlayModule/emailSharing",
        data
      );
      if (res.status === 200) {
        this.postShareCount();
      }
    },
    async getInitialComments() {
      return await axios({
        url: "/posts/get-comments/",
        method: "post",
        data: {
          post_id: this.overlayPost.post_id,
          page_num: 0,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            this.comments = response.data.comments;
            this.pageNumber = 1;
            this.totalComments = response.data.comment_count;
            this.totalCommentsCount = response.data.totalCommentsCount;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleAccordion(index) {
      if (index === this.opendComment) {
        this.opendComment = -1;
      } else {
        this.opendComment = index;
      }
    },
    async showOtherPost(num) {
      const index = this.leaders.findIndex(
        (item) => item.post_id === this.overlayPost.post_id
      );
      await this.$store.commit("set_overlay_post", this.leaders[index + num]);
      await this.getInitialComments();
      await this.$store.dispatch("overlayModule/StarCount", {
        post: this.leaders[index + num].post_id,
      });
      if (this.isLogin) {
        await this.$store.dispatch("overlayModule/GetCommentLoveLikeData", {
          post: this.leaders[index + num].post_id,
        });
        await this.$store.dispatch("overlayModule/GetLoveLikeData", {
          post: this.leaders[index + num].post_id,
        });
      }
      await this.$store.dispatch("overlayModule/PostTrack", {
        post: this.leaders[index + num].post_id,
        type: "post",
      });
    },
    async addComment() {
      let $this = this;
      let data = {
        post_id: $this.overlayPost.post_id,
        comment_text: $this.commentText,
        post_author: $this.overlayPost.author_email,
      };
      this.doubleClick = true;
      if (this.commentText !== " ") {
        const add_comment = await this.$store.dispatch("addComment", data);
        this.doubleClick = false;

        if (add_comment.status === 200) {
          if ($this.comments.length < 2) {
            $this.comments.unshift({
              cmt_user_uuid: $this.user.uuid,
              cmt_user_fullname:
                $this.user.first_name + " " + $this.user.last_name,
              cmt_user_avatar: $this.user.avatar,
              comment: $this.commentText,
              comm_id: add_comment.data.comm_id,
              total_sub_comm: 0,
              sub_comm: [],
            });
            $this.totalComments = $this.totalComments + 1;
            $this.totalCommentsCount = $this.totalCommentsCount + 1;
          } else {
            $this.pageNumber = 0;
            this.getInitialComments();
          }
          this.commentText = "";
        }
      }
    },
    async addSubComment(id, subCommIdx) {
      let $this = this;
      let data = {
        post_id: $this.overlayPost.post_id,
        comm_id: id,
        sub_comm_text: $this.subCommentText[subCommIdx],
        post_author: $this.overlayPost.author_email,
      };
      this.subCommentdoubleClick = true;
      if (this.subCommentText !== []) {
        const add_sub_comment = await this.$store.dispatch(
          "addSubComment",
          data
        );
        this.subCommentdoubleClick = false;
        if (add_sub_comment.status === 200) {
          const index = this.comments.findIndex((item) => item.comm_id === id);
          if (this.comments[index].sub_comm.length < 2) {
            $this.comments[index].sub_comm.unshift({
              sub_cmt_user_uuid: $this.user.uuid,
              sub_cmt_user_fullname:
                $this.user.first_name + " " + $this.user.last_name,
              sub_cmt_user_avatar: $this.user.avatar,
              sub_comment: $this.subCommentText[subCommIdx],
              sub_comm_id: add_sub_comment.data,
            });
          } else {
            $this.comments[index].curr_page = 0;
            $this.comments[index].sub_comm = [];
            this.loadMoreSubComments($this.comments[index].comm_id, 0);
          }
          $this.comments[index].total_sub_comm =
            $this.comments[index].total_sub_comm + 1;
          $this.totalCommentsCount = $this.totalCommentsCount + 1;
          this.subCommentText = [];
        }
      }
    },
    async loadMoreComments() {
      let $this = this;
      await axios({
        url: "/posts/get-comments/",
        method: "post",
        data: {
          post_id: $this.overlayPost.post_id,
          page_num: $this.pageNumber,
          totalComments: $this.totalComments,
        },
      })
        .then((response) => {
          $this.comments = this.comments.concat(response.data.comments);
          $this.pageNumber = this.pageNumber + 1;
          $this.totalComments = response.data.comment_count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async loadMoreSubComments(comm_id, subPageNum) {
      let $this = this;
      await axios({
        url: "posts/get-zerohero-subcomments/",
        method: "POST",
        data: {
          post_id: $this.overlayPost.post_id,
          comm_id: comm_id,
          page_num: subPageNum,
        },
      })
        .then((response) => {
          const index = this.comments.findIndex(
            (item) => item.comm_id === response.data.comm_id
          );
          $this.comments[index].sub_comm = this.comments[index].sub_comm.concat(
            response.data.sub_comments
          );
          $this.comments[index].curr_page = $this.comments[index].curr_page + 1;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async removeComment(index, comment_id) {
      let $this = this;
      let subCommentCount = this.comments[index].total_sub_comm;
      subCommentCount = subCommentCount + 1;
      await axios({
        url: "posts/remove-comment/",
        method: "POST",
        data: {
          comment_id: comment_id,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            $this.comments.splice(index, 1);
            $this.totalComments = $this.totalComments - 1;
            $this.totalCommentsCount =
              $this.totalCommentsCount - subCommentCount;
            if ($this.comments.length < 2) {
              this.getInitialComments();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async removeSubComment(index, idx) {
      let $this = this;
      let sub_comment_id = $this.comments[index].sub_comm[idx].sub_comm_id;
      await axios({
        url: "posts/remove-sub-comment/",
        method: "POST",
        data: { sub_comment_id: sub_comment_id },
      }).then((response) => {
        if (response.status === 200) {
          $this.comments[index].sub_comm.splice(idx, 1);
          $this.comments[index].total_sub_comm =
            $this.comments[index].total_sub_comm - 1;
          $this.totalCommentsCount = $this.totalCommentsCount - 1;
          if ($this.comments[index].sub_comm.length < 2) {
            $this.comments[index].sub_comm = [];
            this.loadMoreSubComments($this.comments[index].comm_id, 0);
          }
        }
      });
    },
    async addPressure(postID) {
      let data = {
        post_id: postID,
        changeValue: 1,
      };
      await this.$store.dispatch("changePressure", data);
    },
    reducePressure(postID) {
      let data = {
        post_id: postID,
        changeValue: -1,
      };
      this.$store.dispatch("changePressure", data);
    },
    copyURL() {
      let $this = this;

      const el = document.createElement("textarea");
      el.value = $this.postURL;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      this.copyButtonText = "Copied";
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    editPost() {
      this.$emit("editPost");
      this.$store.dispatch("uxModule/editingPost", true);
      this.$store.commit("set_edit_post_overlay_class", "opened");
      this.$emit("close");
      this.hideOverlay();
    },
    async addToFavorite() {
      await this.$store.dispatch("getFavorites");
      let $this = this;
      let data = {
        post_id: $this.overlayPost.post_id,
      };
      await this.$store.dispatch("addToFavorites", data);
      await this.$store.dispatch("getFavorites");
    },
    async removeFavourite(postID) {
      await this.$store.dispatch("getFavorites");
      await this.$store.dispatch("favoriteModule/deletePost", postID);
      await this.$store.dispatch("getFavorites");
    },
    clickFavButton() {
      if (this.diableFavorite) {
        return this.removeFavourite(this.overlayPost.post_id);
      } else {
        return this.addToFavorite();
      }
    },
    async addToLoveLike(type) {
      const data = {
        type: type,
        post: this.overlayPost.post_id,
      };
      await this.$store.dispatch("overlayModule/PostLoveLike", data);

      await this.$store.dispatch("overlayModule/GetLoveLikeData", {
        post: this.overlayPost.post_id,
      });
    },
    async removeLoveLike(type) {
      const data = {
        type: type,
        post: this.overlayPost.post_id,
      };
      await this.$store.dispatch("overlayModule/removeLoveLike", data);
      await this.$store.dispatch("overlayModule/GetLoveLikeData", {
        post: this.overlayPost.post_id,
      });
    },
    async clickLoveLikeButton(type) {
      if (type === "love") {
        if (this.disableLove) {
          await this.removeLoveLike(type);
        } else if (this.disableLike) {
          await this.removeLoveLike("like");
          await this.addToLoveLike("love");
        } else {
          await this.addToLoveLike(type);
        }
      } else {
        if (this.disableLike) {
          await this.removeLoveLike(type);
        } else if (this.disableLove) {
          await this.removeLoveLike("love");
          await this.addToLoveLike("like");
        } else {
          await this.addToLoveLike(type);
        }
      }
    },
    disableLikeComment(comm_id) {
      if (!this.isLogin) return false;
      if (this.likeComment) {
        if (this.likeComment.find((item) => item === comm_id)) return true;
      }
      return false;
    },
    disableLoveComment(comm_id) {
      if (!this.isLogin) return false;
      if (this.loveComment) {
        if (this.loveComment.find((item) => item === comm_id)) return true;
      }
      return false;
    },
    async addCommentToLoveLike(type, comment_id) {
      const data = {
        type: type,
        comment: comment_id,
        post: this.overlayPost.post_id,
      };
      await this.$store.dispatch("overlayModule/CommentLoveLike", data);
      await this.$store.dispatch("overlayModule/GetCommentLoveLikeData", {
        post: this.overlayPost.post_id,
      });
    },
    async removeCommentLoveLike(type, comm_id) {
      const data = {
        type: type,
        comment: comm_id,
        post: this.overlayPost.post_id,
      };
      await this.$store.dispatch("overlayModule/removeCommentLoveLike", data);
      await this.$store.dispatch("overlayModule/GetCommentLoveLikeData", {
        post: this.overlayPost.post_id,
      });
    },
    async CommentLoveLike(type, comment_id) {
      if (type === "love") {
        if (this.disableLoveComment(comment_id)) {
          await this.removeCommentLoveLike(type, comment_id);
        } else if (this.disableLikeComment(comment_id)) {
          await this.removeCommentLoveLike("like", comment_id);
          await this.addCommentToLoveLike("love", comment_id);
        } else {
          await this.addCommentToLoveLike(type, comment_id);
        }
      } else {
        if (this.disableLikeComment(comment_id)) {
          await this.removeCommentLoveLike(type, comment_id);
        } else if (this.disableLoveComment(comment_id)) {
          await this.removeCommentLoveLike("love", comment_id);
          await this.addCommentToLoveLike("like", comment_id);
        } else {
          await this.addCommentToLoveLike(type, comment_id);
        }
      }
    },
    calExpiration(e) {
      let color = e < 70.0 ? "blue" : "red";
      return color;
    },
    async follow() {
      if (!this.disableFollow) {
        let $this = this;
        let data = {
          user_email: $this.overlayPost.author_email,
        };
        await $this.$store.dispatch("follow", data);
      }
    },
    unFollow(email) {
      let $this = this;
      let data = {
        user_email: $this.overlayPost.author_email,
      };
      axios({
        url: "follower/remove-follow/",
        data: data,
        method: "POST",
      })
        .then((response) => {
          if (response.status === 200) {
            $this.$store.commit("removeFollowing", email);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clickButton() {
      if (this.disableFollow) {
        return this.unFollow(this.overlayPost.author_email);
      } else {
        return this.follow();
      }
    },
    hideSidebar(num) {
      this.hideOverlay();
      let $this = this;
      $this.changeMyOzone(num);
      $("body").addClass("mpanel-open hidesb");
      $(".logged-out").addClass("hidesb");
      $(".ozone-column").addClass("moved");
      $(".my-ozone").addClass("moved");
      $this.$store.commit("change_state", ["mnav_class", "move"]);
      $(".icon-left-open.zhh").removeClass("opened");
      $(".zero-hero-column").removeClass("moved");
      $(".zero-hero").removeClass("moved");
      setTimeout(function () {
        $this.$store.commit("change_state", ["mnav_close_class", "slidem"]);
      }, 350);
    },
    hideOverlay() {
      const $body = document.body;
      this.$store.dispatch("setSharedOverlayClass", "");
      $body.classList.remove("overlay-open");
      if (this.$route.name === "OpenedOverlay") {
        this.$router.push({ name: "Home" });
      }
      $("body").removeClass("hero-overlay");
      $(".sidebar-right").removeClass("hide-sidebar");
    },
    goToProfile() {
      if (this.isLogin) {
        let $this = this;
        if ($this.userEmail === $this.overlayPost.author_email) {
          if ($this.$route.name !== "User")
            $this.$router.push({ name: "User" });
        } else {
          if ($this.$route.name !== "Profile") {
            $this.$router.push({
              name: "Profile",
              params: {
                uuid: $this.overlayPost.author_uuid,
              },
            });
          }
        }
        $this.hideOverlay();
      } else {
        this.loginModalShow = true;
        $(".col-trigger").addClass("sidebar-hide");
        $(".sidebar-right").addClass("sidebar-hide");
        $(".show-mnav").addClass("sidebar-hide");
        $(".overlay").addClass("login-modal-open");
        this.removeSidebar();
      }
    },
    removeSidebar() {
      let $this = this;
      $("body").removeClass("mpanel-open hidesb");
      $(".ozone-column").removeClass("moved");
      $(".my-ozone").removeClass("moved");
      $this.$store.commit("change_state", ["mnav_class", ""]);
      setTimeout(function () {
        $this.$store.commit("change_state", ["mnav_close_class", ""]);
      }, 350);
    },
    colseModal() {
      this.closeLoginModal = true;
      this.loginModalShow = false;
      this.closeLoginModal = false;
      $(".col-trigger").removeClass("sidebar-hide");
      $(".show-mnav").removeClass("sidebar-hide");
      $(".sidebar-right").removeClass("sidebar-hide");
      $(".overlay").removeClass("login-modal-open");
      this.removeSidebar();
    },
    goToCommentProfile(uuid, email) {
      let $this = this;
      if ($this.userEmail === email) {
        if ($this.$route.name !== "User") $this.$router.push({ name: "User" });
      } else {
        if ($this.$route.name !== "Profile") {
          $this.$router.push({
            name: "Profile",
            params: {
              uuid: uuid,
            },
          });
        }
      }
      $this.hideOverlay();
    },
    otherPost(num) {
      this.$store.commit("other_post", {
        direction: num,
        uuid: this.overlayPost.uuid,
      });
    },
    reportPost(data) {
      data.post_id = this.overlayPost.post_id;
      axios({
        url: "/posts/report-post/",
        data: data,
        method: "POST",
      })
        .then((response) => {
          if (response.status === 200) {
            this.hideOverlay();
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.reportModalShow = false;
    },
    removePost() {
      let $this = this;
      $this.$store.dispatch("removePost", $this.overlayPost);
      $this.$store.commit("homeModule/REMOVE_POST", $this.overlayPost.uuid);
      $this.hideOverlay();
    },
    async searchTag(tag) {
      let $this = this;
      this.$store.dispatch("searchModule/setQuery", {
        filter: 2,
        query: tag,
      });
      const postData = await this.$store.dispatch("searchModule/search");
      this.$store.dispatch("votingModule/setVotes", postData);
      if (this.$route.name !== "Search") {
        this.$router.push({
          name: "Search",
        });
      }
      $this.hideOverlay();
    },
    limit2numbers(value) {
      return value;
    },
    trophyClass(theme) {
      if (theme === "gold") {
        return "gold-trophy";
      } else if (theme === "platinum") {
        return "platinum-trophy";
      } else if (theme === "diamond") {
        return "diamond-trophy";
      } else {
        return "";
      }
    },
    toCapital(author_theme) {
      return (
        author_theme[0].toUpperCase() + author_theme.slice(1) + " " + "Member"
      );
    },
    twoDigit(value) {
      var duration = moment.duration(value, "hours");
      const d = moment.duration(duration);
      const days = Math.abs(d._data.days);
      const hours = Math.abs(d._data.hours);
      const minutes = Math.abs(d._data.minutes);
      return days + "d" + ":" + hours + "h" + ":" + minutes + "m";
    },
    getCommentLoveCount(comm_id) {
      if (this.LoveLikeCommentCount) {
        const comment = this.LoveLikeCommentCount.find(
          (item) => item.comm_id === comm_id
        );
        if (comment) {
          if (comment.loveCount > 0) {
            return comment.loveCount;
          }
        }
      }
    },
    getCommentLikeCount(comm_id) {
      if (this.LoveLikeCommentCount) {
        const comment = this.LoveLikeCommentCount.find(
          (item) => item.comm_id === comm_id
        );
        if (comment) {
          if (comment.likeCount > 0) {
            return comment.likeCount;
          }
        }
      }
    },
  },
};
</script>