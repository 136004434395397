<template>
  <!----------------------------------------TAB 3 ----------------------------------->
  <div id="tabs-3">
    <div class="tabWrap">
      <!-- TAB 3 - MY STATS-->
      <!-- <div class="tx-c"> -->
      <!-- if they have no stats show this -->
      <!-- <h2 class="akrobat c-999 noCaps"> -->
      <!-- <i class="icon-chart-line"></i> You have no Profile Stats yet. -->
      <!-- </h2> -->
      <!-- <p>Check back here when you've posted something.</p> -->
      <!-- if they have no stats show this -->
      <!-- <h2 class="akrobat c-999"><i class="icon-chart-line"></i> my stats</h2> -->
      <!-- if they do have stats show this -->
      <!-- </div> -->

      <div class="tx-c">
        <p class="tx-c akr-gen">VOTING</p>
        <div
          class="circ-stats"
          data-title="(+) Votes you've received from others"
          data-title-top
        >
          <i class="icon-plus"></i>
          <div>{{ receivePlus }}</div>
          <div>UP VOTES<br />RECEIVED</div>
        </div>
        <div
          class="circ-stats right-stat"
          data-title="(-) Votes you've received from others"
          data-title-top
        >
          <i class="icon-minus"></i>
          <div>{{ receiveMinus }}</div>
          <div>DOWN VOTES<br />RECEIVED</div>
        </div>
        <div
          class="circ-stats"
          data-title="(+) Votes you've given to others"
          data-title-top
        >
          <i class="icon-plus"></i>
          <div>{{ givenPlus }}</div>
          <div>UP VOTES<br />GIVEN</div>
        </div>
        <div
          class="circ-stats right-stat"
          data-title="(-) Votes you've given to others"
          data-title-top
        >
          <i class="icon-minus"></i>
          <div>{{ givenMinus }}</div>
          <div>DOWN VOTES<br />GIVEN</div>
        </div>
        <p class="tx-c akr-gen">YOUR POSTS</p>
        <div
          class="circ-stats"
          data-title="Posts you've Created"
          data-title-top
        >
          <i class="icon-picture"></i>
          <div>{{ postCreated }}</div>
          <div>CREATED</div>
        </div>
        <div
          class="circ-stats right-stat"
          data-title="Your Posts have been Opened"
          data-title-top
        >
          <i class="icon-eye"></i>
          <div>{{ postViews }}</div>
          <div>OPENED</div>
        </div>
        <div
          class="circ-stats"
          data-title="Your Posts have been Shared"
          data-title-top
        >
          <i class="icon-share"></i>
          <div>{{ postShare }}</div>
          <div>SHARED</div>
        </div>
        <p class="tx-c akr-gen">YOUR PROFILE</p>
        <div
          class="circ-stats"
          data-title="Your current O2 Status"
          data-title-top
          v-if="o2Status"
        >
          <i class="icon-award"></i>
          <div>{{ o2Status }}</div>
          <div>O2 STATUS</div>
        </div>
        <div
          class="circ-stats right-stat"
          data-title="Your Profile has been viewed"
          data-title-top
        >
          <i class="icon-eye"></i>
          <div>{{ profileView }}</div>
          <div>PROFILE<br />VIEWED</div>
        </div>
        <div
          class="circ-stats"
          data-title="Week you became a member of O2"
          data-title-top
        >
          <i class="icon-calendar"></i>
          <div>{{ memberWeek }}</div>
          <div>WEEK YOU<br />JOINED</div>
        </div>
        <p class="tx-c akr-gen">FOLLOWERS</p>
        <div
          class="circ-stats"
          data-title="People you're following"
          data-title-top
        >
          <i class="icon-user p-r"><span class="plus">+</span></i>
          <div>{{ following }}</div>
          <div>FOLLOWING</div>
        </div>
        <div
          class="circ-stats right-stat"
          data-title="People following you"
          data-title-top
        >
          <i class="icon-user"></i>
          <div>{{ followedBy }}</div>
          <div>FOLLOWED BY</div>
        </div>
        <p class="tx-c akr-gen">POST COMMENTS</p>
        <div
          class="circ-stats"
          data-title="Comments received on your Posts"
          data-title-top
        >
          <i class="icon-commenting"></i>
          <div>{{ receiveComment }}</div>
          <div>RECEIVED</div>
        </div>
        <div
          class="circ-stats right-stat"
          data-title="Comments you've made on other Posts"
          data-title-top
        >
          <i class="icon-commenting"></i>
          <div>{{ givenComment }}</div>
          <div>GIVEN</div>
        </div>
        <p class="tx-c akr-gen">AWARDS</p>
        <div
          class="circ-stats"
          data-title="Star you've received from Others"
          data-title-top
        >
          <i class="icon-star"></i>
          <div>{{ receiveAward }}</div>
          <div>RECEIVED</div>
        </div>
        <div
          class="circ-stats right-stat"
          data-title="Stars you've given to Others"
          data-title-top
        >
          <i class="icon-star"></i>
          <div>{{ givenAward }}</div>
          <div>GIVEN</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Stats",

  data() {
    return {
      // receivePlus: 0,
      // receiveMinus: 0,
      postCreated: 0,
      postViews: 0,
      postShare: 0,
      profileView: 0,
      following: 0,
      followedBy: 0,
      memberWeek: 1,
      // givenPlus: 0,
      // givenMinus: 0,
      receiveAward: 0,
      givenAward: 0,
      o2Status: "",
      receiveComment: 0,
      givenComment: 0,
    };
  },

  computed: {
    ...mapState({
      givenPlus: (state) => state.userModule.givenPlus,
      givenMinus: (state) => state.userModule.givenMinus,
      receivePlus: (state) => state.userModule.receivePlus,
      receiveMinus: (state) => state.userModule.receiveMinus,
    }),
  },

  async mounted() {
    const res = await this.$store.dispatch("userModule/PostViewCount");
    this.$store.commit("userModule/set_vote_history", {
      givenPlus: res.data.given_plus,
    });
    this.$store.commit("userModule/set_vote_history", {
      givenMinus: res.data.given_minus,
    });
    this.$store.commit("userModule/set_vote_history", {
      receivePlus: res.data.receive_plus,
    });
    this.$store.commit("userModule/set_vote_history", {
      receiveMinus: res.data.receive_minus,
    });
    // this.receivePlus = res.data.receive_plus;
    // this.receiveMinus = res.data.receive_minus;
    this.postCreated = res.data.total_post;
    this.postViews = res.data.view_post;
    this.postShare = res.data.share_post;
    this.profileView = res.data.view_profile;
    this.following = res.data.following_count;
    this.followedBy = res.data.follower_count;
    this.memberWeek = res.data.member_week;
    // this.givenPlus = res.data.given_plus;
    // this.givenMinus = res.data.given_minus;
    this.receiveAward = res.data.receive_award;
    this.givenAward = res.data.given_award;
    this.o2Status = res.data.o2_status.toUpperCase();
    this.receiveComment = res.data.receive_comment;
    this.givenComment = res.data.given_comment;
  },
};
</script>
