<template>
<div>
   {{$route.params.uid}}
</div>
</template>

<script>
import axios from 'axios'

export default {
   name: 'Activation',
   created(){
      let $this = this
      axios({
         url: 'auth/user/email-validation/',
         data: {
            uid: $this.$route.params.uid,
            token: $this.$route.params.token
         },
         method: 'POST'
      })
      .then(response => {
         if(response.data.status == 'success'){
            localStorage.setItem('user_email', response.data.email)
            $this.$router.push({name: 'Home'})
            $this.$store.dispatch('setMyOzone', 5)
         }         
      })
      .catch( error => {
         console.log(error)
      })
   }
}
</script>