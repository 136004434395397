<template>
  <div class="status-section">
    <h2>My Ozone</h2>
    <p>
      <span class="status"><i class="icon-lock"></i></span>
      <span>{{ user.first_name }} {{ user.last_name }}</span>
    </p>
    <p class="p-r">
      <span class="pr">
        <a
          class="tippy js-trig-aaa prof-stat icon-edit"
          data-tooltip="Edit profile"
          data-mobile="true"
          @click="changeMyOzone(4)"
          >{{ profileStatus }} :</a
        >
      </span>
      <router-link
        class="tippy wh"
        data-tooltip="View profile"
        to="/user"
        @click.native="$emit('hideSideBar')"
        >My Profile
      </router-link>
    </p>
    <p></p>

    <div class="side-bar-status">
      <div class="sb-left">
        <h3>VOTES LEFT</h3>
        <div class="date_label_wrapper">
          <h4 v-if="user.votes <= 0" class="date_label">
            <i class="fa fa-clock-o" aria-hidden="true"></i> {{ getTimeDiff() }}
          </h4>
        </div>
        <a
          href="javascript:void(0)"
          v-b-modal.modal-vote
          @click="voteModal = !voteModal"
          >Get more</a
        >
      </div>
      <div class="sb-right">
        <span class="count">{{ user.votes }}</span>
      </div>
    </div>
    <!-- Extra vote modal -->
    <b-modal
      id="modal-vote"
      title="Get more Votes"
      hide-footer
      dialog-class="more-vote-modal"
      @hide="handleClose"
      v-if="voteModal"
    >
      <div class="more-vote-option">
        <div class="modal-footer text-left">
          <p>
            Having more votes helps you launch any Posts rapidly up the
            Leaderboard.
          </p>
        </div>
        <table class="table">
          <tr
            v-for="(vote, index) in votesList"
            :key="index"
            class="border-bot"
          >
            <td>
              Buy <span>{{ vote.paid_votes }} Extra Votes</span> for today
            </td>
            <td>
              <a href="javascript:void(0)" @click="BuyVote(vote.id)"
                >{{ vote.token_cost }} tokens</a
              >
            </td>
          </tr>
        </table>

        <div class="vote-note" v-if="errorDiv === true || successDiv === true">
          <div class="alert-token no-more-t" v-if="errorDiv === true">
            <label>Oops, you need more tokens.</label>
            <router-link
              to="/tokens"
              class="button primary"
              @click.native="voteModal = false"
            >
              <img src="@/assets/images/tkn-o2-29x29.png" /> Get More
              Tokens</router-link
            >
          </div>
          <div class="alert-token success-t" v-if="successDiv === true">
            <label>Great. Your Extra Votes have been added successfully.</label>
          </div>
        </div>

        <div class="vote-note" v-else>
          <label class="default"
            >Extra votes are only valid for use today!</label
          >
        </div>
      </div>
    </b-modal>

    <div class="side-bar-status">
      <div class="sb-left">
        <h3>TOKENS</h3>
        <router-link
          to="/tokens"
          class="mini"
          @click.native="$emit('hideSideBar')"
          >More Info</router-link
        >
        <!-- Get More -->
      </div>
      <div class="sb-right simple">
        <span class="count">{{
          parseFloat(user.token).toLocaleString("en")
        }}</span>
      </div>
    </div>
    <div
      class="side-bar-status"
      data-title="Win Boosts for being in Top 10"
      data-title-top
    >
      <div class="sb-left">
        <h3>BOOST</h3>
        <span>REWARDS</span>
      </div>
      <div class="sb-right simple">
        <span class="count">{{ rewarded_boost }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as moment from "moment";
import axios from "axios";

export default {
  name: "Loggedin",
  data() {
    return {
      visible: false,
      votesList: [],
      errorDiv: false,
      successDiv: false,
      showVotes: false,
      voteModal: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      rewarded_boost: (state) => state.auth.user.boost,
    }),
    profileStatus() {
      if (
        this.user.first_name &&
        this.user.last_name &&
        this.user.bio &&
        this.user.avatar &&
        this.user.city &&
        this.user.country
      ) {
        return "Completed";
      }
      return "Incomplete!";
    },
  },
  mounted() {
    this.$emit("getGauge");
    this.voteData();
  },
  methods: {
    handleClose() {
      this.voteModal = false;
    },
    changeMyOzone(num) {
      this.$store.dispatch("setMyOzone", num);
    },
    getTimeDiff() {
      let duration = moment.duration(
        moment().add(1, "days").startOf("day").diff(moment())
      );
      let diff_time = Math.floor(duration.asMinutes());
      let hours = Math.floor(diff_time / 60);
      let minutes = diff_time - hours * 60;
      if (minutes.toString().length == 1) {
        minutes = "0" + minutes;
      }
      return hours + "h : " + minutes + "m";
    },
    async voteData() {
      await axios({
        url: "user/token/buy-votes/",
        method: "GET",
      })
        .then((response) => {
          this.votesList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async BuyVote(id) {
      await axios({
        url: "user/token/buy-votes/",
        method: "POST",
        data: { id: id },
      })
        .then((response) => {
          if (response.data.status === 200) {
            this.successDiv = true;
            setTimeout(() => {
              this.successDiv = false;
              this.voteModal = false;
            }, 5000);
            this.$store.dispatch("getUser");
          } else {
            this.errorDiv = true;
            setTimeout(() => {
              this.errorDiv = false;
            }, 5000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    moreVote() {
      this.showVotes = !this.showVotes;
    },
  },
};
</script>
