import * as api from "../Services/api.js";

export async function deletePostImage(data) {
  return await api.post("/posts/remove-image/", data).then((response) => {
    return response;
  });
}

export async function deletePostVideo(data) {
  return await api.post("/posts/remove-video/", data).then((response) => {
    return response;
  });
}
