<template>
  <div id="content" class="pt-20" role="main">
    <h4 class="p-head blue">You agree not to contribute any Content that:</h4>
    <ul>
      <li>is illegal</li>
      <li>
        is abusive, threatening, harassing, bullying, hateful, offensive,
        pornographic, contains or depicts nudity, sexual activity, or is
        otherwise inappropriate or deemed to be unacceptable
      </li>
      <li>
        violates any patent, trademark, trade secret, copyright, right of
        publicity or other legal or human right of any other person or entity
      </li>
      <li>you know is untruthful, false, or fake news</li>
      <li>impersonates any person or entity</li>
      <li>
        contains a virus or any other computer programs that are designed or
        intended to disrupt, damage, or interfere with the proper function of
        any software or O2 Service
      </li>
    </ul>
    <p class="noellip">
      +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    </p>
    <h4 class="p-head blue">O2 reserves the right</h4>
    <p>to remove any Content you submit, at any time.</p>
    <ul>
      <li>
        All Content, whether publicly posted or privately transmitted, is the
        sole responsibility of the person who originated such Content<br />
        <br />
      </li>
      <li>
        O2 reserves the right to suspend, disable or remove any Member Account
        for any perceived or proven violation of any of these O2 Terms of
        Service, without discussion, correspondence, or agreement with or from
        the O2 Member, or any third parties<br /><br />
      </li>
      <li>
        O2 reserves the right, but has no obligation, to remove or refuse to
        distribute any Content on the O2 Services<br />
        <br />
      </li>
      <li>
        O2 may modify or adapt your Content for editorial reasons or to
        transmit, display or distribute it over computer networks and in various
        media and/or make changes to your Content as are necessary to conform
        and adapt that Content to any editorial or technical requirements or
        limitations<br />
        <br />
      </li>
      <li>
        By submitting, posting or displaying Content on or through O2 Services,
        you grant O2 a worldwide, non-exclusive, royalty-free license (with the
        right to sublicense) to use, copy, reproduce, process, adapt, modify,
        publish, transmit, display and distribute such Content in any and all
        media or distribution methods (now known or later created)<br />
        <br />
      </li>
      <li>
        Such additional uses by O2 may be made with no request from you or
        compensation paid to you with respect to the Content that you submit,
        post, transmit or otherwise make available through the O2 Services
      </li>
    </ul>
    <p class="noellip">
      +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    </p>
    <h4 class="p-head blue">What is GDPR?</h4>
    <p>
      The GDPR is the new European legislation meant to replace the
      <a
        href="https://en.wikipedia.org/wiki/Data_Protection_Directive"
        target="_blank"
        rel="noopener"
        >1995 Data Protection Directive</a
      >
    </p>

    <p>
      This regulation, which can be read in full on the
      <a
        href="https://eur-lex.europa.eu/legal-content/en/TXT/?uri=CELEX%3A32016R0679"
        target="_blank"
        rel="noopener"
        >CNIL website</a
      >, is the new European law governing the use and handling of individuals’
      personal data.
    </p>

    It has 3 primary objectives:
    <ul>
      <li>To standardize European data protection regulations.</li>
      <li>To give citizens control over how their personal data is used.</li>
      <li>
        To make sure that companies are aware of their responsibilities
        regarding personal data.
      </li>
    </ul>
    <h4 class="p-head blue">O2 is GDPR compliant</h4>
    <p>
      As an O2 customer, the GDPR gives you protection rights and assures better
      access to your personal data.
    </p>
    <ul>
      <li>
        <strong>Right to rectification:</strong> Rectify your personal
        information at any time from your account settings. You can also contact
        us directly to edit or rectify your information.
      </li>
      <li>
        <strong>Right to be forgotten:</strong> Cancel your O2subscription and
        close your account at any time. You can send us a request to erase all
        your data, which we will complete within 30 days.
      </li>
      <li>
        <strong>Right to portability:</strong> Upon request, we will export your
        data so that it can be transferred to a third party.
      </li>
      <li>
        <strong>Right to object:</strong> Unsubscribe at any time to any
        specific use of your information (newsletter, automatic emails, etc.).
      </li>
      <li>
        <strong>Right of access:</strong> We are transparent about the data that
        we collect and what we do with it. To familiarize yourself with this,
        please refer to our privacy policy. You can contact us at any time to
        access and modify any of your personal data.
      </li>
    </ul>
    <p class="noellip">
      +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    </p>
    <p v-if="!isLogin" class="blk">
      <strong>Sound good? - Step inside!</strong>
    </p>
    <a
      v-if="!isLogin"
      class="show-panel button primary icon-lock-open pr-15 pl-15 mt--5 mr-10"
      title="Login"
      data-mobile="true"
      @click="changeMyOzone(0)"
    >
      Login
    </a>
    <a
      v-if="!isLogin"
      class="show-panel button primary icon-lock pr-15 pl-15 mt--5"
      title="Sign up"
      data-mobile="true"
      @click="changeMyOzone(3)"
    >
      Sign up
    </a>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TermsOfService",
  computed: {
    ...mapState({
      isLogin: (state) => state.auth.isLogin,
    }),
  },

  methods: {
    changeMyOzone(num) {
      let $this = this;
      $this.$store.dispatch("setMyOzone", num);
      if (num === 1) {
        $this.$store.dispatch("login", true);
      }
      if ($this.$route.name !== "Home") {
        $this.$router.push({ name: "Home" });
      }
    },
  },
};
</script>