<template>
  <div class="read-panel" :class="{ open: isOpen === 7 }">
    <h4 class="p-head blue mb-20">
      Rewards Themes - Win FREE Limited Editions
    </h4>
    <p>
      Be one of the <strong>first 500</strong> people to gain
      <strong>{{ gold_count }}, {{ platinum_count }}</strong> or
      <strong>{{ diamond_count }} followers</strong> and receive awesome
      limited-edition website themes, trophies & tokens as recognition of your
      achievement.
    </p>

    <!-- dynamic theme data -->
    <div v-for="(theme, index) in themes" :key="index">
      <div
        :class="`${theme.theme_name} what-theme trophy mb-20 cp-hover`"
        v-if="theme.theme_type !== 'default' && theme.theme_type === 'rewarded'"
        @click="link"
      >
        <div :class="`spr-theme-${theme.theme_name} size1-pos`"></div>
        <div class="wt-left">
          <div class="wt-head">
            <span class="wt-name">{{ theme.theme_name }}</span> theme
            <span class="theme-trophy">
              <i class="icon-award tiny"></i>
            </span>
            <span class="reward-t-qty">
              + {{ theme.rewarded_token }} Tokens</span
            >
          </div>
          <div class="wt-body">
            <strong>{{ theme.follower_count }}+ followers.</strong>
          </div>
        </div>
      </div>
    </div>
    <h4 class="p-head blue mb-20">Custom Themes</h4>
    <div class="promo1 mb-20">
      <p>
        <strong>Choose a theme to suit your mood or superpower.</strong>
      </p>
      <p>
        You can have multiple themes and toggle them on/off whenever you want.
      </p>
      <a
        @click="link"
        title="View Custom Themes"
        class="button primary icon-brush mt--5 pl-15 pr-20"
        >View Custom Themes</a
      >
    </div>
    <h4 class="p-head blue mb-20">Boost Rewards</h4>
      <p>
        <strong>For finishing in the Top 10 on the Leaderboard</strong>, during any week, you can randomly receive <strong>2 x FREE BOOST REWARDS</strong>. Boosts let you immediately send any Post to the top of the Leaderboard.
      </p>
 </div>
</template>

<script>
import axios from "axios";
export default {
  name: "WebsiteThemes",

  props: ["isOpen"],

  data() {
    return {
      themes: [],
      member: 0,
      gold_count: 0,
      platinum_count: 0,
      diamond_count: 0,
    };
  },

  mounted() {
    this.O2Theme();
  },

  methods: {
    async O2Theme() {
      await axios({
        url: "auth/user/o2-theme",
        method: "get",
      })
        .then((response) => {
          this.themes = response.data;
          this.member = response.data[0].member_count;
          response.data.map((item) => {
            if (item.theme_type === "rewarded") {
              if (item.theme_name === "gold") {
                this.gold_count = item.follower_count;
              } else if (item.theme_name === "platinum") {
                this.platinum_count = item.follower_count;
              } else if (item.theme_name === "diamond") {
                this.diamond_count = item.follower_count;
              }
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    link() {
      this.$router.push({
        name: "Themes",
      });
    },
  },
};
</script>