<template>
  <div class="overlay createpost" :class="postCreateOverlayClass">
    <perfect-scrollbar class="wrapper" ref="post_create">
      <a
        class="icon-cancel-1 close-overlay"
        title="Close this Post"
        @click="hideOverlay"
        >Close</a
      >
      <div class="content logedIn">
        <ul class="feed">
          <li id="addImages" :class="{ open: isOpen === 1 }">
            <h2 class="akrobat">ADD IMAGES</h2>
            <div class="p-r tx-c">
              <div
                v-cloak
                @drop.prevent="addFile"
                @dragover.prevent
                @dragenter="isDragEntered = true"
                @dragleave="isDragEntered = false"
                :class="{ 'image-drag-entered mb-10': isDragEntered }"
              >
                <div class="cp icon-upload-cloud"></div>
                <h2 class="blk akrobat">Drag &amp; Drop images here</h2>
                <div class="mb-10">or</div>
              </div>
              <div id="browse-files-1">
                <a
                  class="button secondary pr-15 pl-10 fs-1 p-r"
                  title="Browse for images"
                >
                  Browse for images
                  <input
                    title="Browse for images"
                    type="file"
                    accept="image/*"
                    multiple="multiple"
                    ref="imageFiles1"
                    @change="browseImages(1)"
                  />
                </a>
                <div class="large-img-error">{{ this.error }}</div>
              </div>
              <div>or</div>
              <div>
                <a
                  class="fs-9 mb-30 js-addVideo fs-600"
                  title="Add YouTube video"
                  @click="isOpen = 2"
                >
                  Add YouTube Video
                </a>
              </div>
              <div class="screenCreds left">
                <h3 class="small">Use Good Quality Photos</h3>
                <p>
                  Everybody loves great images. Not blurry, low quality, rubbish
                  ones. The best size is 1000px (+) wide.
                </p>
              </div>
              <div class="screenCreds right">
                <h3 class="small">Copyright &amp; Credit</h3>
                <p>
                  Only use images that you have permission to use and add a
                  credit to author/photographer when possible.
                </p>
              </div>
            </div>
            <div class="tx-c fs-6 tt-u clr">
              By creating a Post you agree to our
              <router-link to="/termsofservice" title="Terms of Service"
                >Terms</router-link
              >
            </div>
          </li>

          <li id="addVideo" :class="{ open: isOpen === 2 }">
            <h2 class="akrobat">ADD VIDEO</h2>
            <div class="p-r tx-c">
              <div class="cp icon-youtube-play"></div>
              <h2 class="blk akrobat">Add<i class="icon-youtube"></i>Video</h2>
              <div class="comment-box pl-20 pr-20">
                <div class="fieldset">
                  <div
                    class="input-holder"
                    :class="videoValidationText ? 'is-invalid' : ''"
                  >
                    <input
                      v-model="embededURL"
                      placeholder="Paste Video Url here ..."
                      @blur="validateVideo"
                    />
                    <input
                      type="submit"
                      id="submit-video-url"
                      class="button tertiary vidSubmit fs-1"
                      value="Save Url"
                      @click="addVideoURL"
                    />
                  </div>
                  <p class="error-message" v-if="videoValidationText">
                    <i class="icon-attention-circled">{{
                      videoValidationText
                    }}</i>
                  </p>
                </div>
              </div>
              <p class="f80">
                Example: https://www.youtube.com/watch?v=eGsp9h47t2M
              </p>
              <div>or</div>
              <div>
                <a
                  class="fs-9 js-addImages fs-600"
                  title="Add image"
                  @click="isOpen = 1"
                >
                  Add images
                </a>
              </div>
            </div>
          </li>

          <li
            id="category"
            class="category-list-wrap"
            :class="{ open: isOpen === 3 }"
          >
            <div class="select-post-category">
              <h2 class="akrobat">CHOOSE A CATEGORY</h2>
              <div class="screenCreds left">
                <h3>What is the topic of your Post?</h3>
                <ul class="choose-cat-note">
                  <li :class="{ error: this.caterror }">
                    Select at <strong>least</strong> 1 category below - then click <strong>Next</strong>
                  </li>
                  <li :class="{ error: this.maxerror }">
                    You can choose up to 3 categories
                  </li>
                  <li>
                    If your category is not shown select the 
                    <strong>*Positive</strong> category.
                  </li>
                </ul>
              </div>
            </div>
            <div class="select-category-list">
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="selected"
                :options="categoryList"
                v-on:input="limiter"
                name="flavour-1"
                class="single-category"
              ></b-form-checkbox-group>
            </div>
            <a @click="nextStep" class="button tip-next">
              Next
              <i class="icon-right-open"></i>
            </a>
            <!-- <div
              class="category-note"
              v-if="this.caterror"
              :class="{ error: this.caterror }"
            >
              Choose at least one Category for your Post to display in
            </div>
            <p v-if="this.caterror" class="error-message">
              <i class="icon-attention-circled">* Required</i>
            </p> -->
            <div class="large-img-error">{{ this.error }}</div>
          </li>

          <li id="createPost" :class="{ open: isOpen === 4 }">
            <span class="back-btn" @click="isOpen = 3"
              ><span class="icon-left-open"></span> BACK</span
            >
            <div class="large-img-error">{{ this.error }}</div>
            <h2 class="akrobat">ADD YOUR INFO &amp; PUBLISH</h2>
            <div class="relative">
              <div class="comment-box title-box">
                <div class="fieldset">
                  <div
                    class="input-holder"
                    data-title="Make your title descriptive and unique"
                    data-title-top
                    :class="required.title ? 'is-invalid' : ''"
                  >
                    *<input
                      class="post-title"
                      v-model="postData.postTitle"
                      id="add-title"
                      placeholder="Title ..."
                      maxlength="200"
                      @blur="validate('postData.postTitle')"
                      @keyup="remaincharCount()"
                    />
                    <span>{{ remaincharactersText }}</span>
                  </div>
                  <p v-if="required.title" class="error-message">
                    <i class="icon-attention-circled">* Required</i>
                  </p>
                </div>
              </div>
              <div class="comment-box">
                <div class="fieldset">
                  <div
                    data-title="Add more information"
                    data-title-top
                    class="input-holder"
                    :class="required.text ? 'is-invalid' : ''"
                  >
                    *<textarea
                      id="add-text"
                      v-model="postData.postText"
                      placeholder="Tell us about it ..."
                      @blur="validate('postData.postText')"
                    ></textarea>
                  </div>
                  <p v-if="required.text" class="error-message">
                    <i class="icon-attention-circled">* Required</i>
                  </p>
                </div>
              </div>
              <p class="mt-20 mb-25">
                <a
                  data-title="Add More Images"
                  data-title-top
                  class="button img-icon img-icon2 tertiary pr-15 pl-10 mr-5 mb-3"
                  @click="toggleExtraTab(1)"
                >
                  <i class="icon-plus"></i>Images
                </a>
                <a
                  data-title="Add YouTube Videos"
                  data-title-top
                  class="button vid-icon vid-icon2 tertiary pl-10 pr-15 mr-5 mb-3"
                  @click="toggleExtraTab(2)"
                >
                  <i class="icon-plus"></i>Videos
                </a>
                <a
                  data-title="Add Tags, Credits, Links"
                  data-title-top
                  class="button secondary view-extras pl-5 pr-15"
                  @click="toggleExtraTab(3)"
                >
                  <i class="icon-angle-down"></i>More
                </a>
              </p>

              <!--TAB 1 -->
              <div class="bord addImages" :class="{ open: extraTab === 1 }">
                <a
                  class="icon-cancel-1"
                  title="Close"
                  @click="toggleExtraTab(0)"
                ></a>
                <div
                  v-cloak
                  @drop.prevent="addFile"
                  @dragover.prevent
                  @dragenter="isDragEntered = true"
                  @dragleave="isDragEntered = false"
                  :class="{ 'image-drag-entered mb-10': isDragEntered }"
                >
                <span class="drag-contain-bg">
                  <h2 class="blk akrobat">Drag &amp; Drop images here</h2>
                  <div id="browse-files-2" class="ta-r">
                     <span class="or-special">or</span>
                    <a
                      class="button tertiary pr-15 pl-10 p-r mr-7"
                      title="Browse for images"
                    >
                      Browse for images
                      <input
                        title="Browse for images"
                        type="file"
                        accept="image/*"
                        multiple="multiple"
                        ref="imageFiles2"
                        @change="browseImages(2)"
                      />
                    </a>
                  </div>
                  </span>
                </div>
              </div>

              <!--TAB 2 -->
              <div class="bord addVideo" :class="{ open: extraTab === 2 }">
                <a
                  class="icon-cancel-1"
                  title="Close"
                  @click="toggleExtraTab(0)"
                ></a>
                <div class="comment-box drag-contain-bg pt-0">
                  <h2 class="blk akrobat">ADD YOUTUBE VIDEO</h2>
                  <div class="fieldset mr-20 ml-20">
                    <div
                      class="input-holder"
                      :class="videoValidationText ? 'is-invalid' : ''"
                    >
                      <input
                        v-model="embededURL"
                        placeholder="Paste YouTube Video Url here ..."
                        @blur="validateVideo"
                      />
                      <input
                        type="submit"
                        id="submit-video-url"
                        class="button tertiary vidSubmit"
                        value="Save Url"
                        @click="addVideoURL"
                      />
                    </div>
                    <p class="error-message" v-if="videoValidationText">
                      <i class="icon-attention-circled">{{
                        videoValidationText
                      }}</i>
                    </p>
                  </div>
                  <p class="f80 ml-20">
                    Example: https://www.youtube.com/watch?v=eGsp9h4ST2M
                  </p>
                </div>
               
              </div>

              <!--TAB 3 -->
              <div class="bord addExtras" :class="{ open: extraTab === 3 }">
                <a
                  class="icon-cancel-1 post-close"
                  title="Close"
                  @click="toggleExtraTab(0)"
                ></a>
                <div class="comment-box drag-contain-bg pl-15 pr-15 pt-0">
                  <h2 class="blk akrobat pl-7">MORE ...</h2>
                  <h3 class="small mt-20 icon-tags">Add Search Tags</h3>
                  <div class="fieldset">
                    <div class="input-holder">
                      <span
                        data-title="Add a tag - Then press Enter"
                        data-title-top
                      >
                        <input
                          id="add-tags"
                          placeholder="E.g. 'Nature' - Then press Enter"
                          v-model="searchTag"
                          @keyup="handleKeyEvent"
                        />
                      </span>
                    </div>
                    <span
                      v-for="(tag, index) in postData.tags"
                      :key="index"
                      class="post-tag"
                    >
                      {{ tag }}
                      <a
                        class="icon-cancel"
                        title="Close"
                        @click="removeTag(index)"
                      ></a>
                    </span>
                  </div>
                  <p class="f80 mt-0">
                    *Tags are topic keywords for people to search for to find related Posts.
                  </p>
                  <div class="comment-box">
                    <h3 class="small mt-20 icon-heart-empty">Add Credit</h3>
                    <div class="fieldset">
                      <div class="input-holder">
                        <span
                          data-title="Add a credit to this Post"
                          data-title-top
                        >
                          <input
                            v-model="postData.credit"
                            placeholder="E.g. Image courtesy of John Smith"
                            maxlength="40"
                          />
                        </span>
                      </div>
                    </div>
                    <p class="f80 mt-0">*Add a credit to image or author</p>
                  </div>
                  <div class="comment-box">
                    <h3 class="small mt-20 icon-link-1">Add a Link</h3>
                    <div class="fieldset">
                      <div
                        class="input-holder"
                        :class="linkValidationText ? 'is-invalid' : ''"
                      >
                        <span data-title="Add a link or website" data-title-top>
                          <input
                            v-model="postData.additionalLink"
                            placeholder="https://somelink.com"
                            @blur="validateURL(postData.additionalLink)"
                          />
                        </span>
                      </div>
                      <p class="error-message" v-if="linkValidationText">
                        <i class="icon-attention-circled">{{
                          linkValidationText
                        }}</i>
                      </p>
                    </div>
                    <p class="f80 mt-0">
                      *Add a website, credits link, or other url
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="urls.length > 0">
                <h3 class="small mt-40 icon-picture"> CONFIRM YOUR IMAGES</h3>
                <p class="f80 mt-0">
                  If more than one image arrange their sequence using
                  the <i class="arrow down icon-up-open"></i>/<i
                    class="arrow down icon-down-open"
                  ></i>
                  arrows.
                </p>
              </div>
              <div
                v-for="(url, index) in urls"
                :key="`img-${index}`"
                class="imgWrap mt-20"
                style="overflow: visible"
              >
                <div class="arrowWrp">
                  <div
                    data-title="Move Order Up"
                    data-title-left
                    class="position-arrow up"
                  >
                    <a
                      v-if="index > 0"
                      class="arrow icon-up-open js-move-forward"
                      @click="imgBefore(index)"
                    >
                    </a>
                  </div>
                  <div
                    data-title="Move Order Down"
                    data-title-left
                    class="position-arrow"
                  >
                    <a
                      v-if="index < urls.length - 1"
                      class="arrow down icon-down-open js-move-back"
                      @click="imgAfter(index)"
                    ></a>
                  </div>
                  <div v-if="setDelete()" data-title="Remove" data-title-left>
                    <a
                      class="arrow trash icon-trash"
                      @click="removeFile(index)"
                    ></a>
                  </div>
                </div>
                <img :src="url" alt="" />
              </div>
              <h3
                v-if="videoUrls.length > 0"
                class="small mt-40 icon-youtube-play"
              >
                Confirm video ...
              </h3>
              <div
                v-for="(videoUrl, idx) in videoUrls"
                :key="idx"
                class="imgWrap mt-20"
                style="overflow: visible"
              >
                <div class="arrowWrp">
                  <div
                    data-title="Move Order Up"
                    data-title-left
                    class="position-arrow up"
                  >
                    <a
                      v-if="idx > 0"
                      class="arrow icon-up-open js-move-forward"
                      @click="videoBefore(idx)"
                    >
                    </a>
                  </div>
                  <div
                    data-title="Move Order Down"
                    data-title-left
                    class="position-arrow"
                  >
                    <a
                      v-if="idx < videoUrls.length - 1"
                      class="arrow down icon-down-open js-move-back"
                      @click="videoAfter(idx)"
                    ></a>
                  </div>
                  <div v-if="setDelete()" data-title="Remove" data-title-left>
                    <a
                      class="arrow trash icon-trash"
                      @click="removeVideo(idx, videoUrl.id, videoUrl.position)"
                    ></a>
                  </div>
                </div>
                <iframe
                  width="560"
                  height="315"
                  :src="videoUrl"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                >
                </iframe>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </perfect-scrollbar>
    <vue-progress-bar></vue-progress-bar>
    <div v-if="isOpen === 4" class="fxd-footer">
      <button
        data-title="Publish on Pressure Pit"
        data-title-top
        class="button right primary pr-15 pl-15 mr-20 ml-20 gen-jumping"
        id="postImage"
        @click.prevent="upload('submit')"
        @click="
          validate(
            'postData.postTitle',
            'postData.postText',
            postData.additionalLink
          )
        "
        :disabled="doubleClick"
      >
        <strong>PUBLISH</strong>
      </button>

      <div
        data-title="Is this for Mature viewers only?"
        data-title-top
        class="fieldset cb right"
      >
        <input type="checkbox" id="mature" v-model="postData.isMature" />
        <label for="mature" class="label">Sensitive?</label>
      </div>
      <a
        data-title="Save to my Drafts"
        data-title-top
        class="a-text right"
        @click.prevent="upload('save')"
        @click="
          validate(
            'postData.postTitle',
            'postData.postText',
            postData.additionalLink
          )
        "
      >
        <i class="icon-doc-text"></i>
        Draft
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "PostCreateOverlay",

  data() {
    return {
      postData: {
        postTitle: "",
        postText: "",
        tags: [],
        isMature: false,
        additionalLink: "",
        credit: "",
        status: "",
      },
      required: {
        title: false,
        text: false,
      },
      isDragEntered: false,
      isOpen: 1,
      extraTab: 0,
      files: [],
      urls: [],
      videoUrls: [],
      searchTag: "",
      embededURL: "",
      videoValidationText: "",
      linkValidationText: "",
      isUploading: false,
      maxcharacter: 200,
      remaincharactersText: "200",
      validation: true,
      error: "",
      doubleClick: false,
      selected: [],
      caterror: "",
      maxerror: "",
    };
  },
  computed: {
    ...mapState({
      isLogin: (state) => state.auth.isLogin,
      postCreateOverlayClass: (state) => state.common.postCreateOverlayClass,
      categoryList: (state) => state.common.categoryList,
    }),
  },

  mounted() {
    this.$store.dispatch("allCategory", {
      module: this.$route.name,
    });
  },

  methods: {
    nextStep() {
      if (this.selected.length > 0 && this.selected.length < 4) {
        this.isOpen = 4;
      } else {
        if (this.selected.length > 3) {
          this.maxerror = "You can only select 3 categories!";
        } else {
          this.caterror = "You have to select atleast one category!";
        }
      }
    },
    limiter(e) {
      if (e.length > 3) {
        // e.pop();
        this.maxerror = "You can only select 3 categories!";
      } else {
        this.caterror = "";
        this.maxerror = "";
      }
    },
    remaincharCount() {
      if (this.postData.postTitle.length <= this.maxcharacter) {
        var remainCharacters =
          this.maxcharacter - this.postData.postTitle.length;
        this.remaincharactersText = remainCharacters;
      }
    },
    setDelete() {
      if (this.urls.length > 0 || this.videoUrls.length > 0) {
        if (this.urls.length === 0) {
          if (this.videoUrls.length > 1) {
            return true;
          } else {
            return false;
          }
        } else if (this.videoUrls.length === 0) {
          if (this.urls.length > 1) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    start() {
      this.$Progress.start();
    },
    set(num) {
      this.$Progress.set(num);
    },
    increase(num) {
      this.$Progress.increase(num);
    },
    decrease(num) {
      this.$Progress.decrease(num);
    },
    finish() {
      this.$Progress.finish();
    },
    fail() {
      this.$Progress.fail();
    },
    addFile(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => {
        this.urls.push(URL.createObjectURL(f));
        this.files.push(f);
      });
      this.isOpen = 3;
      this.extraTab = 0;
    },
    addVideoURL() {
      let $this = this;
      if ($this.embededURL != undefined || $this.embededURL != "") {
        let validation = $this.validateVideoURL();
        if (validation) {
          $this.videoUrls.push($this.embededURL);
          // $this.videoValidationText = "";
          $this.isOpen = 3;
          $this.extraTab = 0;
          $this.embededURL = "";
        }
        // else {
        //   $this.videoValidationText = "Please input a valid YouTube Url!";
        // }
      }
    },
    validateVideo() {
      let validation = this.validateVideoURL();
      if (validation) {
        this.videoValidationText = "";
      } else {
        this.videoValidationText = "Please input a valid YouTube Url!";
      }
    },
    browseImages(num) {
      let $this = this;
      let imgFiles = [];
      if (num === 1) {
        imgFiles = $this.$refs.imageFiles1.files;
      } else {
        imgFiles = $this.$refs.imageFiles2.files;
      }
      for (let i = 0; i < imgFiles.length; i++) {
        if (imgFiles[i].size <= 20000000) {
          this.error = "";
          const imgType = imgFiles[i].type.split("/");
          if (
            imgType[1] === "jpg" ||
            imgType[1] === "jpeg" ||
            imgType[1] === "png" ||
            imgType[1] === "webp"
          ) {
            let file = imgFiles[i];
            $this.urls.push(URL.createObjectURL(file));
            $this.files.push(file);
            $this.isOpen = 3;
            $this.extraTab = 0;
            this.error = "";
          } else {
            this.error =
              imgType[1] +
              "image file format is not accepted. Please upload another type of image.";
          }
        } else {
          this.error = "You can not upload large images.";
        }
      }
    },
    handleKeyEvent(event) {
      let $this = this;
      if (event.key === "," || event.key === "Enter") {
        if ($this.searchTag != "" && $this.searchTag != ",") {
          $this.searchTag = $this.searchTag.replace(/,/g, "").trim();
          $this.postData.tags.push($this.searchTag);
        }
        $this.searchTag = "";
      }
    },
    hideOverlay() {
      const $body = document.body;
      this.$store.dispatch("setPostCreateOverlayClass", "");
      // this.isOpen = 1;
      // this.files = [];
      // this.urls = [];
      // this.videoUrls = [];
      // this.selected = [];
      // this.postData.postTitle = "";
      // this.postData.postText = "";
      // this.postData.tags = [];
      // this.postData.isMature = false;
      // this.postData.additionalLink = "";
      // this.postData.credit = "";
      // this.postData.status = "";
      $body.classList.remove("overlay-open");
    },
    imgBefore(index) {
      this.files = this.swap(index, this.files, "before");
      this.urls = this.swap(index, this.urls, "before");
    },
    imgAfter(index) {
      this.files = this.swap(index, this.files, "after");
      this.urls = this.swap(index, this.urls, "after");
    },
    removeFile(index) {
      this.files.splice(index, 1);
      this.urls.splice(index, 1);
    },
    removeTag(index) {
      this.postData.tags.splice(index, 1);
    },
    removeVideo(index) {
      this.videoUrls.splice(index, 1);
    },
    swap(index, arr, type) {
      let buf = [];
      if (type === "before") {
        buf = [arr[index - 1], arr[index]];
        arr.splice(index - 1, 2, buf[1], buf[0]);
      } else {
        buf = [arr[index], arr[index + 1]];
        arr.splice(index, 2, buf[1], buf[0]);
      }
      return arr;
    },
    toggleExtraTab(num) {
      if (this.extraTab === num) {
        this.extraTab = 0;
      } else {
        this.extraTab = num;
      }
    },
    async upload(type) {
      let $this = this;
      if (type === "submit") {
        $this.postData.status = "PUBLIC";
      } else if (type === "save") {
        $this.postData.status = "draft";
        this.$store.commit("INCREASE_DRAFT", 1);
      }
      if (
        this.postData.postTitle !== "" &&
        this.postData.postText !== "" &&
        this.validation === true
      ) {
        this.doubleClick = true;
        this.$Progress.start();
        $this.isUploading = true;
        await axios({
          url: "posts/create/",
          data: {
            post_title: $this.postData.postTitle,
            post_text: $this.postData.postText,
            tags: $this.postData.tags,
            is_mature: $this.postData.isMature,
            additional_link: $this.postData.additionalLink,
            credit: $this.postData.credit,
            status: $this.postData.status,
            is_video: $this.videoUrls.length > 0 ? true : false,
            videos: $this.videoUrls,
          },
          method: "POST",
        })
          .then((response) => {
            if (
              response.data != "Invalid" &&
              response.data != "Bad connection"
            ) {
              let formData = new FormData();
              formData.append("post_id", response.data);
              for (let i = 0; i < $this.files.length; i++) {
                formData.append("file[" + i + "]", $this.files[i]);
              }
              axios({
                url: "posts/image_upload/",
                data: formData,
                method: "POST",
              })
                .then((response) => {
                  this.doubleClick = false;
                  console.log(response.data);
                  $this.postData.postTitle = "";
                  $this.postData.postText = "";
                  $this.postData.tags = [];
                  $this.postData.isMature = false;
                  $this.postData.additionalLink = "";
                  $this.postData.credit = "";
                  $this.postData.status = "";
                  $this.files = [];
                  $this.urls = [];
                  $this.videoUrls = [];
                  $this.isOpen = 1;
                  const $body = document.body;
                  this.$store.dispatch("setPostCreateOverlayClass", "");
                  $body.classList.remove("overlay-open");
                  this.$Progress.finish();
                  $this.$store.commit("set_active_tab", 0);
                  this.$store.dispatch("homeModule/getAllAvailablePosts", {
                    activeTab: 0,
                    pageNumber: 0,
                    selectedCategory: localStorage.getItem("category"),
                  });
                  $this.isUploading = false;
                  if (type === "save") {
                    this.$router.push("/user?activeTab=1");
                  } else {
                    this.$router.push({ name: "Home" });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  this.$Progress.fail();
                  $this.isUploading = false;
                });
            }
            axios({
              url: "category/post-category/",
              method: "POST",
              data: {
                post_id: response.data,
                categories: this.selected,
                prev: "",
              },
            }).then((response) => {
              console.log(response);
              this.selected = [];
              if (
                this.$route.name === "Home" ||
                this.$route.name === "Leaderboard" ||
                this.$route.name === "User"
              ) {
                this.$store.dispatch("allCategory", {
                  module: this.$route.name,
                });
              }
            });
          })
          .catch((error) => {
            console.log(error);
            this.$Progress.fail();
            $this.isUploading = false;
          });
        this.$store.dispatch("getMyDraft", 0);
      } else {
        this.$refs.post_create.$el.scrollTop = 0;
      }
    },
    validate(value1, value2) {
      if (value1 === "postData.postTitle") {
        this.required.title = this.postData.postTitle ? false : true;
      }
      if (value2 === "postData.postText") {
        this.required.text = this.postData.postText ? false : true;
      }
    },
    validateURL(value) {
      if (value === "") {
        this.validation = true;
      } else {
        this.validation = this.validateAdditionalLink(value);
      }
      if (this.validation === true) {
        this.linkValidationText = "";
      } else {
        this.linkValidationText = "Please enter valid Link";
      }
    },
    validateAdditionalLink(data) {
      const expression =
        "^(http://www.|http://|https://www.|https://|ftp://www.|ftp://|www.){1}([0-9A-Za-z]+.)([A-Za-z]){2,3}(/)?";

      var regex = new RegExp(expression);
      var t = data;
      if (data) {
        if (t.match(regex)) {
          return true;
        } else {
          return false;
        }
      }
    },
    validateVideoURL() {
      let $this = this;
      const regYoutube =
        // /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\?v=)([^#]*).*/;
        /^.*(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$)).*/;
      const regVimeo =
        /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^]*)\/videos\/|)(\d+)(?:|\/\?)/;
      let match = $this.embededURL.match(regYoutube);
      if (match && match[3].length == 11) {
        $this.embededURL = "https://www.youtube.com/embed/" + match[3];
        return true;
      } else if (regVimeo.test($this.embededURL)) {
        return true;
      } else {
        return false;
      }
    },
    videoBefore(index) {
      this.videoUrls = this.swap(index, this.videoUrls, "before");
    },
    videoAfter(index) {
      this.videoUrls = this.swap(index, this.videoUrls, "after");
    },
  },
};
</script>