<template>
  <div>
    <div class="modal-overlay">
      <a title="Close" class="icon-cancel-1 modal-close" @click="closeModal">
        CLOSE
      </a>
    </div>
    <div class="modal-votes-expired">
      <div class="modal-logo"></div>

      <h1 class="blk">CONFIRM EMAIL</h1>
      <p><strong>An email has just been sent to you.</strong></p>
      <p>
        Check your inbox <em>(or junk/spam folder)</em> and click the button in
        the email to confirm your membership.
      </p>
      <a @click="resendConfirmationEmail"> Resend email</a>
      <p class="successMsg">
        <span>{{ this.message }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SignUpConfirmationModal",

  computed: {
    ...mapState({
      resendEmail: (state) => state.auth.resendEmail,
    }),
  },

  data() {
    return {
      message: "",
    };
  },

  methods: {
    closeModal() {
      this.$store.commit("toggle_signup_modal", false);
    },
    async resendConfirmationEmail() {
      const response = await this.$store.dispatch(
        "resendConfirmationEmail",
        this.resendEmail
      );
      this.message = response.data.message;
      setTimeout(() => {
        this.message = "";
      }, 3000);
    },
  },
};
</script>