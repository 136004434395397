<template>
  <div class="read-panel" :class="{ open: isOpen === 6 }">
    <span class="right fs-7 mt-10">Updated: May 7th 2022</span>
    <h4 class="p-head blue">Overview</h4>
    <p class="pt-30px">
      <strong>Wouldn't it be great</strong> if all online platforms respected
      your privacy and left you feeling better than before you arrived?
    </p>
    <p>
      <strong>Well, relax.</strong> We really are good guys and we take
      everyone's privacy seriously. The whole point of O2 is to create a warm
      and safe environment that we can all enjoy, without having to worry about
      issues on privacy!
    </p>
    <p>
      <strong>With that said,</strong> we're sure that many of you want to see
      our privacy policy written down, so here goes ...
    </p>
    <h4 class="p-head blue">The what, why & how</h4>
    <p>
      <strong>When you use</strong> our products and services, by browsing,
      registering an account, logging in, commenting, voting and similar, you do
      so with the acceptance of the policies we outline below.
    </p>
    <h4 class="p-head blue">
      What personal information do we collect and why?
    </h4>
    <p><strong>Information you provide to us</strong></p>
    <p>
      <strong>When you register</strong> for an account, we request your email
      address so we can validate whether you're already a member. If you're not,
      we ask you to provide:
    </p>
    <ul>
      <li>
        <strong>Email address:</strong> - to activate your account status and
        site activity, to edit your settings and use all members features.
      </li>
      <li>
        <strong>Password:</strong> - to confirm your account, associated with
        your email address. We can not see your actual password. This is private
        to you <em>(but you can reset it)</em>.
      </li>
      <li>
        <strong>Full name:</strong> - we would prefer transparency, trust and
        honesty here <em>(like you would expect from us)</em>. However, you can
        choose a 'pen' name if you prefer, so long as it is publically
        acceptable!!
      </li>
    </ul>
    <h4 class="p-head blue">Email newsletter</h4>
    <p>
      <strong>We may prompt you to join</strong> our community newsletter too.
      It's not compulsory <em>(it should be, because it's awesome!),</em> - it
      gives you a heads-up on what's trending, cool or worth checking out every
      week. It's superb promotion for your posts too. We
      <strong>only promote member</strong> content for people
      <strong>who are newsletter subscribers</strong>.
    </p>
    <p>
      <em
        >(We're not here taking prisoners. You can unsubscribe at all time and
        your information will be removed).</em
      >
    </p>
    <h4 class="p-head blue">Social login/signup.</h4>
    <p>
      <strong>If you choose to use</strong> your social signup/login to create
      an O2 account, you give your social account permission to share your email
      and profile details with us.
    </p>
    <h4 class="p-head blue">Device information</h4>
    <p>
      <strong>For the site to perform</strong> at its best for you - it's
      functionality, latest information display, and tailored for you, we have
      to collect technical information from your device.
    </p>
    <p><strong>This may include, but not be limited to:</strong></p>
    <ul>
      <li>
        <strong>Device info:</strong> - to enable us to display a desktop or
        mobile version of the site, including browser or app requirements.
      </li>
      <li>
        <strong>Network data:</strong> - such as IP address, to help us diagnose
        any problems with our servers, administer our sites and for comments,
        content management or moderation control.
      </li>
      <li>
        <strong>Cookies:</strong> - these do not collect any personal
        information, but they vastly improve your site usage and experience
        <em>(more on cookies below)</em>
      </li>
      .
    </ul>
    <h4 class="p-head blue">Sharing</h4>
    <p>
      <strong>When you share posts,</strong> articles or comments outside of O2,
      you do so using 3rd party social plugins or widgets, and these social
      networks, and similar, have their own privacy policies.
    </p>
    <h4 class="p-head blue">How is your information used?</h4>
    <p>
      <strong>In order to provide</strong> our services to you, we process all
      data with a legitimate purpose.
    </p>
    <p><strong>For the purpose of service delivery:</strong></p>
    <ul>
      <li>
        Communicate with you via email, according to your set notification
        preferences
      </li>
      <li>
        Contact you and maintain records to provide customer service and support
      </li>
      <li>
        Ensure there is no fraud with voting, polls and competitions we host
      </li>
      <li>When using cookies to remember your preferences</li>
      <li>
        When detecting and defending against fraudulent, abusive and unlawful
        site activity
      </li>
    </ul>
    <p><strong>When the purpose is to measure and analyse traffic:</strong></p>
    <ul>
      <li>
        <strong>Google Analytics:</strong> - a website analytics service
        provided by Google, Inc., to collect information about how visitors use
        our site. We use the information to help us improve the site. The
        information collected includes: visitor numbers, geo-locations, pages
        visited and similar anonymous data.
      </li>
    </ul>
    <!-- ScorecardResearch tags for market research purposes to count users who have visited -->
    <p>
      <strong
        >Additionally, we will ask your consent to process the data we
        need:</strong
      >
    </p>
    <p>
      e.g. When the purpose is to improve the advertising experience. We want
      ads to be relevant to your interests.
    </p>
    <ul>
      <li>
        <strong>Cookies:</strong> - and similar technologies help us to
        determine your interests
      </li>
      <li>
        <strong>Location Services:</strong> - to only show you relevant ads,
        matching your location or language
      </li>
      <li>
        <strong>Partners:</strong> - may use the data they have colected about
        you <em>(according to their own privacy policies)</em> to show ads to
        you that they believe would be relevant
      </li>
    </ul>
    <h4 class="p-head blue">How is your information shared?</h4>
    <p>
      <strong>We strive to ensure</strong> that your data is protected using
      technology and contractual mechanisms, however as per this privacy policy,
      we do have to share basic information with our trusted 3rd party partner
      supplier services.
    </p>
    <p>
      <strong>We do not actively share</strong> any of your data outside of the
      general use of O2 Services, to any third parties, except to 3rd parties
      listed, to ensure the correct use of our services. These may include, but
      not be limited to the following examples.
    </p>
    <strong>e.g.</strong>
    <ul>
      <li>
        <strong>Newsletters:</strong> - we use Sendgrid for email newsletter
        services. You can unsubscribe via the unsubscribe function in the
        newsletter
      </li>
      <li>
        <strong>Google:</strong> - for optimisation, innovation, services and
        functionalities we need, we use Google and similar reputable companies
      </li>
      <li>
        <strong>Amazon Web Services:</strong> - we deliver our website via AWS
        hosting and Cloudflare content delivery networks
      </li>
    </ul>
    <h4 class="p-head blue">How we protect the privacy of children?</h4>
    <p>
      <strong>Our services are aimed at</strong> the general adult public. We do
      not knowingly target, collect, use or share information that could
      reasonably be used to identify children, under the age of 16, without
      prior parental consent or consistent with applicable laws.
    </p>
    <p>
      <strong>By using our services</strong> you confirm that you either are of
      legal age or have an applicable consent.
    </p>
    <h4 class="p-head blue">How can you exercise your rights under GDPR?</h4>
    <ul>
      <li>
        If you are an individual browsing from within the EU/EEA, where the
        General Data Protection Regulations apply, you can exercise rights
        related to your data by contacting us via contact details at the bottom
        of this page:
      </li>
      <li>You can request access to a free copy of your data</li>
      <li>
        You can ask us to delete your personal data, and we will do so where we
        legally can
      </li>
      <li>You have a right to rectify your data</li>
      <li>
        If you wish to object to us processing your data according to a
        legitimate interest
      </li>
      <li>You also are free to revoke your consent</li>
      <li>
        You have a right to complain about us with the recognised supervisory
        authorities
      </li>
    </ul>
    <p>
      Your above requests will be executed within the legally required period of
      time <em>(one month)</em>. You will need to provide validated proof of
      identification with each request.
    </p>
    <h4 class="p-head blue">How long do we keep the data?</h4>
    <p>
      <strong>We store your data</strong> for no longer than necessary, in
      relation to the purpose under which such data was collected.
    </p>
    <p>
      This is determined on a case-by-case basis and depends on things such as
      the nature of the data provided, why it was collected, the legal basis we
      rely upon to process the data, and our relevant legal or operation
      retention requirements.
    </p>
    <p>
      For example, if you request to delete your account we still have to retain
      some data for fraud prevention purposes and financial auditing.
    </p>
    <h4 class="p-head blue">Cookies</h4>
    <p>
      <strong>When you use our website services</strong> we may collect
      information using cookies or similar technologies. Cookies are small files
      that are downloaded to your computer or mobile device when you visit a
      website. Your browser sends these cookies back to the website server every
      time you visit the site again, so it can 'anonymously' recognise you. This
      allows websites to customise your website experience for you.
    </p>
    <p>
      We use cookies, like most other companies do, as they are a critically
      important feature/technology of Internet usage.
    </p>
    <p><strong>We use Cookies for:</strong></p>
    <ul>
      <li>
        <strong>Services:</strong> - so the site functions as expected, they are
        essential for you to be able to enjoy the website experience
      </li>
      <li>
        <strong>Analytics:</strong> - permitting us to understand how all
        visitors collectively use our site, to enable us to make business
        decisions based on data
      </li>
      <li>
        <strong>Preferences:</strong> - to remember your consent status, so we
        don't annoy you with constant site pop-up reminders
      </li>
      <li>
        <strong>Advertising:</strong> - to help us provide you with the best
        possible experience with our display of promoted ads. Advertising from
        one form or another are critical revenues streams to ensure the site and
        business is viable.
      </li>
    </ul>
    <!--We use a Google tool called Google Double Click ? -->
    <p>
      If you use an ad-blocker on our site, we cannot fully execute our services
      and therefore ensure your rights under this policy.
    </p>
    <p><strong>You can manage your cookie settings by:</strong></p>
    <ul>
      <li>changing your device privacy settings</li>
      <li>changing settings on your mobile device</li>
      <li>changing your browser/cookie settings</li>
    </ul>
    <p>
      Be aware that by changing certain preferences it may cause the webpage or
      site to not work correctly, or at all, and your user experience would be
      negatively impacted.
    </p>
    <h4 class="p-head blue">What is GDPR?</h4>
    <p>
      The GDPR is the new European legislation meant to replace the
      <a
        href="https://en.wikipedia.org/wiki/Data_Protection_Directive"
        target="_blank"
        rel="noopener"
        >1995 Data Protection Directive</a
      >
    </p>

    <p>
      This regulation, which can be read in full on the
      <a
        href="https://eur-lex.europa.eu/legal-content/en/TXT/?uri=CELEX%3A32016R0679"
        target="_blank"
        rel="noopener"
        >CNIL website</a
      >, is the new European law governing the use and handling of individuals’
      personal data.
    </p>

    It has 3 primary objectives:
    <ul>
      <li>To standardize European data protection regulations.</li>
      <li>To give citizens control over how their personal data is used.</li>
      <li>
        To make sure that companies are aware of their responsibilities
        regarding personal data.
      </li>
    </ul>
    <h4 class="p-head blue">O2 is GDPR compliant</h4>
    <p>
      As an O2 customer, the GDPR gives you protection rights and assures better
      access to your personal data.
    </p>
    <ul>
      <li>
        <strong>Right to rectification:</strong> Rectify your personal
        information at any time from your account settings. You can also contact
        us directly to edit or rectify your information.
      </li>
      <li>
        <strong>Right to be forgotten:</strong> Cancel your O2 subscription and
        close your account at any time. You can send us a request to erase all
        your data, which we will complete within 30 days.
      </li>
      <li>
        <strong>Right to portability:</strong> Upon request, we will export your
        data so that it can be transferred to a third party.
      </li>
      <li>
        <strong>Right to object:</strong> Unsubscribe at any time to any
        specific use of your information (newsletter, automatic emails, etc.).
      </li>
      <li>
        <strong>Right of access:</strong> We are transparent about the data that
        we collect and what we do with it. To familiarize yourself with this,
        please refer to our privacy policy. You can contact us at any time to
        access and modify any of your personal data.
      </li>
    </ul>
    <h4 class="p-head blue">Privacy policy changes</h4>
    <p>
      <strong>From time to time,</strong> we may update this Privacy Policy, so
      check it occasionally. The date at the top of this document reflects the
      last update.
    </p>
    <h4 class="p-head blue">Contact information</h4>
    <p>
      <strong>Use this email address</strong> for enquiries you may have
      regarding privacy:
    </p>
    <p>Email : <strong>privacy@o2feed.com</strong></p>
    <p>Subject : <strong>Privacy enquiry</strong></p>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicyComponent",
  props: ["isOpen"],
};
</script>