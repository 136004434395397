<template>
  <perfect-scrollbar
    class="extra-panel bbb side-notification"
    :class="openClass"
  >
    <div class="my-panels">
      <h2 class="akrobat bk-r">NOTIFICATIONS</h2>
      <div class="read_all" @click="ReadAllNotification">
        <i class="icon-eye blk"></i>Reset
      </div>
      <p v-if="this.notifications.length < 1">You have no Notifications.</p>
      <div
        class="notification-box"
        v-for="(notification, index) in notifications"
        :key="index"
      >
        <div
          class="notification-box-wrap"
          :class="[notification.read_notification === true ? 'read' : 'unread']"
        >
          <span
            v-if="notification.title === 'Comment'"
            class="cst-icon icon-comment"
          ></span>
          <span class="tkn-noty-icon" v-if="notification.title === 'Token'">
            <span class="tok-icon-gen-white"
              ><span>O<sup>2</sup></span></span
            >
          </span>
          <span
            v-if="notification.title === 'Reply'"
            class="cst-icon icon-reply"
          ></span>
          <span
            v-if="notification.title === 'Star'"
            class="cst-icon icon-star"
          ></span>
          <span
            v-if="notification.title === 'Follow'"
            class="cst-icon icon-user"
          ></span>
          <span
            v-if="
              notification.title === 'Following Notification' ||
              notification.title === 'Following'
            "
            class="cst-icon icon-picture"
          >
          </span>
          <span
            v-if="notification.title === 'Post Ban'"
            class="cst-icon icon-cancel-circle"
          ></span>
          <span
            v-if="notification.title === 'Winner'"
            class="cst-icon icon-award"
          ></span>
          <span
            v-if="notification.title === 'Achievement'"
            class="cst-icon icon-award"
          ></span>
          <span
            v-if="notification.title === 'theme_removed'"
            class="cst-icon icon-frown"
          ></span>
          <a
            v-if="
              notification.title === 'Follow' ||
              notification.title === 'Following Notification' ||
              notification.title === 'Following'
            "
            @click="
              goToProfile(
                notification.user_uuid,
                notification.user_email,
                index
              )
            "
            class="noti-desc"
            :id="notification.user_uuid"
          >
            {{ notification.description }}
          </a>
          <a
            @click="readNotification(index, notification.title)"
            v-else-if="notification.title === 'Achievement'"
            class="noti-desc"
            >{{ notification.description }}</a
          >
          <a
            @click="readNotification(index, notification.title)"
            v-else-if="notification.title === 'theme_removed'"
            class="noti-desc"
            >{{ notification.description }}</a
          >
          <a
            v-else
            @click="
              readNotification(
                index,
                notification.title,
                notification.post_uuid
              )
            "
            class="noti-desc"
            :id="notification.post_uuid"
          >
            {{ notification.description }}
          </a>
          <span class="remove-notification" @click="removeNotification(index)"
            ><i class="icon-trash"></i
          ></span>
        </div>
      </div>
    </div>
    <div class="gauge"><div class="gauge-wrapper"></div></div>
    <div class="btn_center_sec mainPost_loadmore load-more-notification">
      <a
        class="load_more_btn"
        v-if="isLogin && loadMoreToggle"
        @click="loadMoreNotification"
      >
        Show more <span class="icon-down-open"></span>
      </a>
    </div>
  </perfect-scrollbar>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import $ from "jquery";

export default {
  name: "NotificationsComponent",

  computed: {
    ...mapState({
      isLogin: (state) => state.auth.isLogin,
      userEmail: (state) => state.auth.user.email,
      unread_notification_count: (state) => state.auth.unreadNotificationCount,
      notifications: (state) => state.auth.notification,
    }),
    loadMoreToggle() {
      const limit = 10;
      return Math.ceil(this.totalNotification / limit) > this.pageNumber
        ? true
        : false;
    },
    link() {
      return process.env.VUE_APP_PREFIX + "/themes";
    },
  },

  data() {
    return {
      openClass: "", //component slide showing effect
      totalNotification: 0,
      pageNumber: 0,
      unReadNotification: true,
      loadedNotification: [],
    };
  },

  async mounted() {
    this.openClass = "open";
    await this.getInitialnotification();
    this.$store.dispatch("unreadNotificationCount");
  },

  methods: {
    async getInitialnotification() {
      await axios({
        url: "notification/get-notification/",
        method: "POST",
        data: {
          page_num: this.pageNumber,
        },
      })
        .then((response) => {
          this.$store.commit("set_notofication", response.data.data);
          this.pageNumber = 1;
          this.totalNotification = response.data.totalNotificationCount;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async removeNotification(index) {
      if (this.notifications[index].read_notification === false) {
        this.$store.commit(
          "unread_notification_count",
          this.unread_notification_count - 1
        );
      }
      await axios({
        url: "notification/remove-notification/",
        method: "POST",
        data: { id: this.notifications[index].noti_id },
      })
        .then((response) => {
          if (response.status === 200) {
            this.notifications.splice(index, 1);
            if (this.notifications.length < 2) {
              this.getInitialnotification();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async ReadAllNotification() {
      await axios({
        url: "/notification/read-all-notification/",
        method: "POST",
      })
        .then((response) => {
          if (response.status === 200) {
            this.notifications.map((item) => (item.read_notification = true));
            this.$store.commit("unread_notification_count", 0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async loadMoreNotification() {
      await axios({
        url: "/notification/get-notification/",
        method: "post",
        data: {
          page_num: this.pageNumber,
        },
      })
        .then((response) => {
          this.loadedNotification = this.notifications.concat(
            response.data.data
          );
          this.$store.commit("set_notofication", this.loadedNotification);
          this.pageNumber = this.pageNumber + 1;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goToProfile(uuid, email, index) {
      if (this.userEmail === email) {
        if (this.$route.name !== "User") this.$router.push({ name: "User" });
      } else {
        this.$router.push({
          name: "Profile",
          params: {
            uuid: uuid,
          },
        });
      }
      let notificationId = this.notifications[index].noti_id;
      if (this.notifications[index].read_notification === false) {
        this.$store.dispatch("readNotification", notificationId);
        this.$store.commit(
          "unread_notification_count",
          this.unread_notification_count - 1
        );
        this.notifications[index].read_notification = true;
      }
    },
    async readNotification(index, title, uuid) {
      let notificationId = this.notifications[index].noti_id;
      if (this.notifications[index].read_notification === false) {
        this.$store.dispatch("readNotification", notificationId);
        this.$store.commit(
          "unread_notification_count",
          this.unread_notification_count - 1
        );
        this.notifications[index].read_notification = true;
        this.unReadNotification = false;
      }
      if (uuid) {
        this.$router.push({
          name: "OpenedOverlay",
          params: {
            uuid: uuid,
          },
        });
        const response = await this.$store.dispatch("openOverlayByUUID", {
          uuid: uuid,
        });
        await this.$store.dispatch(
          "setOverlayPostByUrl",
          response.post_data[0]
        );
        window.location.reload();
      }
      if (title === "Token") {
        this.$router.push({
          name: "Home",
        });
      }
      if (title === "Achievement" || title === "theme_removed") {
        this.$router.push({
          name: "Themes",
        });
      }
      $("body").addClass("hero-overlay");
    },
  },
};
</script>