<template>
  <div class="ozone-wrapper">
    <div class="status-section">
      <h2>Forgot password?</h2>
      <p class="blk"><strong>Receive an email to reset it.</strong></p>
      <form class="login-form">
        <div class="fieldset">
          <label for="forgotpassword" class="offset">Email</label>
          <div class="input-holder" :class="errorMessage ? 'is-invalid' : ''">
            <input
              v-model="email"
              id="forgotpassword"
              type="text"
              placeholder="Your Email"
            />
          </div>
          <p v-if="errorMessage" class="error-message">
            <i class="icon-attention-circled">{{ errorMessage }}</i>
          </p>
        </div>
        <a class="button primary full" @click="submit">SEND</a>
        <p class="fs-9">
          If an email doesn't arrive soon, check your junk or spam email folders.
        </p>
        <p>
          <span class="blk"><strong>Already a member :</strong></span>
          <a data-mobile="true" @click="changeMyOzone(0)"
            ><i class="icon-lock-open"></i>Log in</a
          >
        </p>
        <p>
          <span class="blk"><strong>Not a member :</strong></span>
          <a data-mobile="true" @click="changeMyOzone(3)"
            ><i class="icon-lock"></i>Sign up</a
          >
        </p>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgottenPassword",

  data() {
    return {
      email: "",
      errorMessage: "",
    };
  },

  methods: {
    changeMyOzone(num) {
      this.$store.dispatch("setMyOzone", num);
    },
    isValidEmail: function () {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.email);
    },
    async submit() {
      if (this.email === "") {
        this.errorMessage = "Please enter your email address";
      } else if (!this.isValidEmail()) {
        this.errorMessage = "Please enter a valid email address";
      } else if (this.isValidEmail()) {
        this.errorMessage = "";
        await this.$store.dispatch("forgetPassword", this.email);
        await this.$store.dispatch("showToast", {
          message: "We sent email for reset your password.",
        });
      }
    },
  },
};
</script>
