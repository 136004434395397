<template>
  <div class="ozone-wrapper">
    <perfect-scrollbar class="wrapper">
      <div class="status-section" :class="openClass">
        <h2>Sign up</h2>
        <p class="blk"><strong>continue with:</strong></p>
        <div class="social-btns login_sc_icons_wrapper">
          <div data-title="Facebook" data-title-top="">
            <facebook-login
              id="FaceBookBtn"
              class="fb-btn"
              appId="364191498094210"
              @login="getFacebookData"
            >
              <img src="../../../assets/images/google.png" alt="" />
            </facebook-login>
          </div>
          <div data-title="Google" data-title-top="">
            <GoogleLogin
              class="g-btn"
              :params="params"
              :onSuccess="onSuccess"
              :onFailure="onFailure"
            >
              <img src="../../../assets/images/google.png" alt="" />
            </GoogleLogin>
          </div>
        </div>
        <p class="blk"><strong>or your email:</strong></p>
        <form class="login-form">
          <div class="fieldset">
            <label for="first_name" class="offset">First Name</label>
            <div
              class="input-holder"
              :class="required.fname ? 'is-invalid' : ''"
            >
              <input
                id="first_name"
                v-model="first_name"
                type="text"
                placeholder="First Name"
                @blur="validate('first_name')"
              />
            </div>
          </div>
          <div class="fieldset">
            <label for="last_name" class="offset">Last Name</label>
            <div
              class="input-holder"
              :class="required.lname ? 'is-invalid' : ''"
            >
              <input
                id="last_name"
                v-model="last_name"
                type="text"
                placeholder="Last Name"
                @blur="validate('last_name')"
              />
            </div>
          </div>
          <div class="fieldset">
            <label for="email" class="offset">Email</label>
            <div class="input-holder" :class="errorMessage ? 'is-invalid' : ''">
              <input
                id="email"
                v-model="email"
                type="email"
                placeholder="Email"
              />
            </div>
          </div>
          <div class="fieldset">
            <label for="password" class="offset">Password</label>
            <div class="input-holder" :class="errorMessage ? 'is-invalid' : ''">
              <input
                id="password"
                v-model="password"
                type="password"
                placeholder="Password"
              />
            </div>
          </div>
          <div class="fieldset cb">
            <input type="checkbox" id="keepme" />
            <label for="keepme" class="label">Keep me logged in</label>
          </div>
          <p class="error-message" v-if="errorMessage">
            <i class="icon-attention-circled">{{ errorMessage }}</i>
          </p>
          <a
            data-title="Sign up to O2"
            data-title-top
            class="button primary full"
            title="Sign up to O2"
            @click="signup"
            ><i class="icon-lock"></i>SIGN UP</a
          >
          <p class="blk tx-c"><strong>or</strong></p>
          <p>
            <a
              data-title="Log in to your account"
              data-title-top
              class="button outline full"
              @click="$store.dispatch('setMyOzone', 0)"
            >
              <i class="icon-lock-open"></i>Log in
            </a>
          </p>
          <p class="fs-9">
            <a
              data-title="Don't Panic!"
              data-title-top
              class="nowrap"
              @click="$store.dispatch('setMyOzone', 2)"
            >
              Forgot Password?
            </a>
          </p>
          <p class="fs-8">
            O2 is GDPR compliant.<br />
            <span data-title="Terms of Service" data-title-top>
              <router-link to="/termsofservice">Terms of Service</router-link>
            </span>
            |
            <span data-title="Privacy Policy" data-title-top>
              <router-link to="/privacy">Privacy Policy</router-link> </span
            >.
          </p>
        </form>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GoogleLogin from "vue-google-login";
import facebookLogin from "facebook-login-vuejs";

export default {
  name: "SignUp",
  components: { GoogleLogin, facebookLogin },

  data() {
    return {
      openClass: "",
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      params: {
        client_id:
          "2133277209-a8dn0n9nomkia9c5g7vlr7hnqbdb5opb.apps.googleusercontent.com",
        scope: "email",
        response_type: "code",
      },
      // only needed if you want to render the button with the google ui
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
      required: {
        fname: false,
        lname: false,
      },
      social: {
        password: "",
        email: "",
        personalID: "",
        first_name: "",
        last_name: "",
      },
    };
  },

  computed: {
    ...mapState({
      errorMessage: (state) => state.auth.ErrorMessage,
      loginType: (state) => state.auth.loginType,
    }),
  },

  mounted() {
    this.openClass = "open";
  },

  methods: {
    validate(value) {
      if (value === "first_name") {
        this.required.fname = this.first_name ? false : true;
      }
      if (value === "last_name") {
        this.required.lname = this.last_name ? false : true;
      }
    },
    isValidEmail: function () {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.email);
    },
    async onSuccess(googleUser) {
      this.social.first_name = googleUser.Lu.iY;
      this.social.last_name = googleUser.Lu.wW;
      this.social.personalID = googleUser.Lu.Bv;
      this.social.email = googleUser.Lu.Bv;
      this.social.email = googleUser.Lu.Bv;
      this.$store.commit("set_loginType", googleUser.xc.idpId);
      setTimeout(() => {
        this.socailLogin();
      }, 1000);
    },
    getFacebookData(facebookUser) {
      window.FB.api(
        `/me`,
        "GET",
        { fields: "id,email,name,first_name,last_name" },
        (userInformation) => {
          this.social.first_name = userInformation.first_name;
          this.social.last_name = userInformation.last_name;
          this.social.personalID = userInformation.id;
          this.$store.commit(
            "set_loginType",
            facebookUser.response.authResponse.graphDomain
          );
          if (userInformation.email) {
            this.social.email = userInformation.email;
          } else {
            this.social.email = userInformation.id + "@facebook.com";
          }
        }
      );
      setTimeout(() => {
        this.socailLogin();
      }, 1000);
    },
    async socailLogin() {
      const checkType = await this.$store.dispatch("checkType", {
        email: this.social.email,
      });
      let user = {
        email: this.social.email,
        password: this.social.email,
        loginType: this.loginType,
      };
      if (checkType.data === "login") {
        await this.$store.dispatch("login", user);
      } else {
        let data = {
          first_name: this.social.first_name,
          last_name: this.social.last_name,
          email: this.social.email,
          password: this.social.email,
          loginType: this.loginType,
        };
        this.$store.dispatch("signUp", data);
        setTimeout(() => {
          this.loginAPI();
        }, 1000);
      }
    },
    loginAPI() {
      let user = {
        email: this.social.email,
        password: this.social.email,
        loginType: this.loginType,
      };
      this.$store.dispatch("login", user);
    },
    onFailure(error) {
      console.log(error);
    },
    formValidation() {
      if (
        !this.first_name ||
        !this.last_name ||
        !this.email ||
        !this.password
      ) {
        this.$store.dispatch("updateErrorMsg", "*Please complete all fields");
        return false;
      } else if (!this.isValidEmail()) {
        this.$store.dispatch(
          "updateErrorMsg",
          "Please enter a valid email address."
        );
        return false;
      }
      if (this.password.length < 8) {
        this.$store.dispatch(
          "updateErrorMsg",
          "Please enter a stronger password"
        );
        return false;
      }
      return true;
    },
    signup() {
      if (this.formValidation()) {
        let data = {
          first_name: this.first_name.toUpperCase(),
          last_name: this.last_name.toUpperCase(),
          email: this.email,
          password: this.password,
          loginType: "normal",
        };
        this.$store.commit("set_resend_email", this.email);
        this.$store.dispatch("signUp", data);
      }
    },
    loginView() {
      this.$store.dispatch("setMyOzone", 0);
    },
  },
};
</script>
