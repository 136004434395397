<template>
  <div class="panel-blue-block read-panel" :class="{ open: isOpen === 5 }">
    <h4 class="p-head blue">You agree not to contribute any Content that:</h4>
    <ul>
      <li>is illegal</li>
      <li>
        is abusive, threatening, harassing, bullying, hateful, offensive,
        pornographic, contains or depicts nudity, sexual activity, or is
        otherwise inappropriate or deemed to be unacceptable
      </li>
      <li>
        violates any patent, trademark, trade secret, copyright, right of
        publicity or other legal or human rights of any other person or entity
      </li>
      <li>you know is untruthful, false, or fake news</li>
      <li>impersonates any person or entity</li>
      <li>
        contains a virus or any other computer programs that are designed or
        intended to disrupt, damage, or interfere with the proper function of
        any software or O2 Service
      </li>
    </ul>
    <p class="noellip">
      +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    </p>
    <h4 class="p-head blue">O2 reserves the right</h4>
    <p>to remove any Content you submit, at any time.</p>
    <ul>
      <li>
        All Content, whether publicly posted or privately transmitted, is the
        sole responsibility and liability of the person who originated such
        Content<br />
        <br />
      </li>
      <li>
        O2 reserves the right but has no obligation, to remove or refuse to
        distribute any Content on the O2 Services<br />
        <br />
      </li>
      <li>
        O2 may modify or adapt your Content for editorial reasons or to
        transmit, display or distribute it over computer networks and in various
        media and/or make changes to your Content as are necessary to conform
        and adapt that Content to any editorial or technical requirements or
        limitations<br />
        <br />
      </li>
      <li>
        Such additional uses by O2 may be made with no request from you or
        compensation paid to you with respect to the Content that you submit,
        post, transmit or otherwise make available through the O2 Services<br />
        <br />
      </li>
      <li>
        By submitting, posting or displaying Content on or through O2 Services,
        you grant O2 a worldwide, non-exclusive, royalty-free license (with the
        right to sublicense) to use, copy, reproduce, process, adapt, modify,
        publish, transmit, display and distribute such Content in any and all
        media or distribution methods (now known or later created)<br />
        <br />
      </li>
      <li>
        O2 reserves the right to suspend, disable or remove any member account
        for any perceived or proven violation of any of these O2 Terms of
        Service, without discussion, correspondence, or agreement with or from
        the O2 Member, or any third parties
      </li>
    </ul>
    <p class="noellip">
      +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    </p>
  </div>
</template>

<script>
export default {
  name: "TermsOfServiceComponent",

  props: ["isOpen"],
};
</script>