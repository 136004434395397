import firebase from "firebase/app";
import "firebase/messaging";

const clientCredentials = {
  apiKey: "AIzaSyAQ-ugzsz1EOn9mKFqH6DQzC2-itcEbj2w",
  authDomain: "o2feed-291709.firebaseapp.com",
  projectId: "o2feed-291709",
  storageBucket: "o2feed-291709.appspot.com",
  messagingSenderId: "2133277209",
  appId: "1:2133277209:web:9b21ded530774419a8c888",
  measurementId: "G-DY1709Y3TL",
};
firebase.initializeApp(clientCredentials);

let messaging = null;

if (firebase.messaging.isSupported() === true) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(
    "BKn7TaKLgCq5X3Gt4yClvAwOEf6Y7lRdN7KmIjucn2ckNoikJ_smMpIeqF9HSSl9V4TwfHv0mA-sdqQTHi2Ryuw"
  );
}

export default messaging;
