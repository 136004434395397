import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// All Theme Css
import "@/assets/css/theme-gold.css";
import "@/assets/css/theme-platinum.css";
import "@/assets/css/theme-diamond.css";
import "@/assets/css/theme-black.css";
import "@/assets/css/theme-bluegreen.css";
import "@/assets/css/theme-brown.css";
import "@/assets/css/theme-darkgreen.css";
import "@/assets/css/theme-grey.css";
import "@/assets/css/theme-pink.css";
import "@/assets/css/theme-purple.css";
import "@/assets/css/theme-darkmode.css";
import "@/assets/css/theme-red.css";
import "@/assets/css/theme-yellowgreen.css";
import "@/assets/css/theme-yelloworange.css";

// Custom Css
import "@/assets/css/site-base.css";
import "@/assets/css/custom.css";
import "@/assets/css/members.css";

import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import Axios from "axios";
import VueProgressBar from "vue-progressbar";
import VueSocialauth from "vue-social-auth";
import VueMeta from "vue-meta";
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import firebaseMessaging from "./firebase";
import VueAnalytics from "vue-analytics";

// google analytic config
Vue.use(VueAnalytics, {
  id: "UA-221712319-1",
  router,
});

Vue.use(VueProgressBar, {
  color: "rgb(17, 137, 172)",
  failedColor: "red",
  thickness: "3px",
});

Vue.use(PerfectScrollbar);

Vue.use(VueMeta);

Vue.use(VueSocialauth, {
  providers: {
    facebook: {
      clientId: "364191498094210",
    },
    google: {
      clientId:
        "2133277209-a8dn0n9nomkia9c5g7vlr7hnqbdb5opb.apps.googleusercontent.com",
    },
  },
});
Vue.use(BootstrapVue);

Vue.prototype.$http = Axios;
const token = localStorage.getItem("accessToken");
const API_URL = process.env.VUE_APP_API_URL;

Vue.prototype.$http.defaults.baseURL = API_URL;
Vue.prototype.$http.defaults.headers.post["Accept"] = "application/json";
Vue.prototype.$http.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] =
    "JWT " + localStorage.accessToken;
}
Vue.config.productionTip = false;

// add firebase messaging object globally
Vue.prototype.$messaging = firebaseMessaging;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
