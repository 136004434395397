<template>
  <div class="read-panel" :class="{ open: isOpen === 2 }">
  <h4 class="p-head blue">Earn 50 Tokens</h4>
    <p v-if="this.subscribe === false">
      <strong>Join our O2 Community newsletter</strong> to receive
      inspiring images & stories directly in your inbox - and earn 50 Tokens.
    </p>
    <p v-if="this.subscribe === true">
      <strong>You are subscribed</strong> to the O2 community Newsletter
    </p>
    <div>
      <form
        id="newsletterForm"
        action=""
        method="post"
        onSubmit="return send_request()"
      >
        <div class="gen-inputs">
          <div class="block">
            <label for="email" class="offset">Your email ...</label>
            <input
              v-model="userEmail"
              type="text"
              placeholder="Your email ..."
              readonly
            />
            <a
              v-if="this.subscribe === false"
              class="submit"
              style="bottom: 7px"
              @click="submit"
            >
              Subscribe
            </a>
          </div>
        </div>
        <p class="error-message">
          {{ errorMessage }}
        </p>
      </form>
      <div v-if="this.subscribeToggle === true" class="promo1 mt-20 mb-10">
        <h2 class="akrobat mb-10 mt-10 wh">SUCCESS</h2>
        <p><strong>Thank you for subscribing ;-)</strong></p>
      </div>
      <div v-if="this.unSubscribeToggle === true" class="promo1 mt-20 mb-10">
        <h2 class="akrobat mb-10 mt-10 wh">AWWW</h2>
        <p><strong>Sorry to see you go! Sob. Sob.</strong></p>
      </div>
      <p class="fs-8">
        <span class="icon-heart red"></span
        ><strong>We're not taking prisoners,</strong> you can
        <span
          v-if="this.subscribe === true"
          @click="unSubscribe"
          class="unsubscribe"
          >unsubscribe</span
        >
        <span v-else> unsubscribe</span>
        at any time ;-)
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "NewsletterComponent",

  props: ["isOpen"],

  computed: {
    ...mapState({
      userEmail: (state) => state.auth.user.email,
      userSubEmail: (state) => state.auth.user.subscription_email,
    }),
  },

  data() {
    return {
      email: "",
      errorMessage: "",
      subscribe: "",
      subscribeToggle: false,
      unSubscribeToggle: false,
    };
  },

  async mounted() {
    const response = await this.checkSubscribeEmail();
    this.subscribe = response.data.subscription;
  },

  methods: {
    async submit() {
      this.errorMessage = "";
      const tmp = await this.$store.dispatch("setUserSubEmail", this.userEmail);
      if (tmp.status === 200) {
        this.subscribe = true;
        this.subscribeToggle = true;
        setTimeout(() => {
          this.subscribeToggle = false;
        }, 3000);
      }
    },
    async unSubscribe() {
      const res = await this.$store.dispatch("unSubscribe", this.userEmail);
      if (res.status === 200) {
        this.subscribe = false;
        this.unSubscribeToggle = true;
        setTimeout(() => {
          this.unSubscribeToggle = false;
        }, 3000);
      }
    },
    async checkSubscribeEmail() {
      const tmp = await axios({
        url: "auth/user/check-subscribe-email/",
        method: "POST",
      })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      return tmp;
    },
  },
};
</script>