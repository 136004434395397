export default {
  state: {
    goUpButtonClass: "",
    activeTab: 0, //WallTabWarp active Tab
    mnav_close_class: "",
    mnav_class: "",
    showToast: false,
    toastMsg: {
      message: "",
      type: "success",
    },
  },
  mutations: {
    set_active_tab(state, index) {
      state.activeTab = index;
    },
    change_state(state, data) {
      state[data[0]] = data[1];
    },
    show_toast(state, data) {
      state.showToast = true;
      state.toastMsg = { message: data.message };
    },
    close_toast(state) {
      state.showToast = false;
      state.toastMsg = "";
    },
  },
  actions: {
    setActiveTab(context, payload) {
      context.commit("set_active_tab", payload);
    },
    resetActiveTab(context) {
      context.commit("set_active_tab", 0);
    },
    showToast(context, payload) {
      context.commit("show_toast", payload);
      setTimeout(() => {
        if (context.state.showToast) context.commit("close_toast");
      }, 3000);
    },
  },
  modules: {},
  getters: {},
};
