<template>
  <div>
    <header class="site-header">
      <div class="wrapper">
        <nav class="navbar" @click="hideSearchBar">
          <Navbar />
        </nav>
        <div class="page-title-wrapper" :class="{ searching: isSearching }">
          <div class="searchbox">
            <a
              class="icon-search sb-trigger"
              title="Search for ..."
              @click="displaySearchBar"
            ></a>
            <div class="fieldset search-fieldset">
              <label for="search-me" class="offset">Search O2</label>
              <input
                type="search"
                v-model="searchQuery"
                id="search-me"
                @keyup="keyHandler"
                placeholder="Search for..."
                ref="search"
              />
              <input
                type="submit"
                class="submit-to-search"
                value="Search now"
                @click="search"
                :disabled="disabled"
              />
              <span class="icon-search" aria-hidden="true"></span>
              <div
                id="search-dropdown"
                class="search-dd"
                :class="{ open: isSearching }"
              >
                <div v-if="isLogin" class="search-dropdown-item">
                  <a
                    v-for="(filter, index) in filters"
                    :key="index"
                    :class="{ focused: searchFilter === index }"
                    @click="changeSearchFilter(index)"
                    >{{ filter }}</a
                  >
                </div>
                <div v-else class="search-dropdown-item logout">
                  <a
                    v-for="(filter, index) in newFilter"
                    :key="index"
                    :class="{ focused: searchFilterLogout === index }"
                    @click="changeSearchFilter(index)"
                    >{{ filter }}</a
                  >
                </div>
              </div>
            </div>
          </div>

          <h1
            title="O2 Positive is a weekly competition for the world's most popular images and articles - as voted by you!"
            class="m-0"
            :class="{
              ab568: $route.name === 'User' || $route.name === 'Profile',
            }"
          >
            {{ header_text[$route.name].h1_text1 }}
            <span class="light-blue">
              {{ header_text[$route.name].h1_text2 }}
            </span>
          </h1>

          <h2
            title="O2 Positive. Suck Air. Live Longer! "
            class="byline"
            :class="{
              ab568: $route.name === 'User' || $route.name === 'Profile',
            }"
          >
            {{ header_text[$route.name].h2_text }}
          </h2>
        </div>
        <p class="share-trigger">
          {{ header_text[$route.name].p_text1
          }}<span>{{ header_text[$route.name].p_text2 }}</span>
          <a
            class="
              show-panel
              button
              primary
              icon-upload-cloud
              pl-15
              pr-20
              mb-10
            "
            :class="
              $route.name === 'O2hq' || $route.name === 'HowItWorks'
                ? 'hide-panel'
                : ''
            "
            @click="showPostCreateOverlay"
          >
            Add Post
          </a>
        </p>
      </div>
    </header>
    <MatureAudienceLoginModal v-if="showLoginModal" @colseModal="colseModal" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/navigationbar/Navbar";
import CreateOverlayMixin from "@/mixins/CreateOverlayMixin";
import $ from "jquery";
import MatureAudienceLoginModal from "../components/modal/MatureAudienceLoginModal.vue";

export default {
  name: "SiteHeader",
  components: {
    Navbar,
    MatureAudienceLoginModal,
  },

  mixins: [CreateOverlayMixin],

  computed: {
    ...mapState({
      isLogin: (state) => state.auth.isLogin,
      isSearching: (state) => state.common.isSearching,
      header_text: (state) => state.staticLabels.header_text,
    }),
    disabled() {
      return this.searchQuery === "" ? true : false;
    },
  },

  data() {
    return {
      searchQuery: "",
      searchFilter: 0,
      searchFilterLogout: 1,
      newFilter: ["", "posts", "#Tags"],
      filters: ["People", "Posts", "#Tags"],
    };
  },

  mounted() {
    $(".custom-screen").addClass("full-screen");
  },

  methods: {
    changeSearchFilter(index) {
      if (this.isLogin) {
        this.searchFilter = index;
      } else {
        this.searchFilterLogout = index;
      }
      $("#screen").addClass("full-screen");
      this.$store.dispatch("searchModule/setQuery", {
        query: "",
        filter: index,
      });
    },
    keyHandler(e) {
      if (e.key === "Enter" || e.keyCode === 13) {
        this.search();
      }
    },
    async displaySearchBar() {
      await this.$store.dispatch("setSearching", true);
      if (
        this.$route.name === "Home" ||
        this.$route.name === "O2hq" ||
        this.$route.name === "User" ||
        this.$route.name === "Tokens"
      ) {
        $(".custom-screen").addClass("full-screen");
      }
      this.$refs.search.focus();
    },
    async hideSearchBar() {
      if (this.isSearching === true) {
        await this.$store.dispatch("setSearching", false);
      }
      if (
        this.$route.name === "Home" ||
        this.$route.name === "O2hq" ||
        this.$route.name === "User" ||
        this.$route.name === "Tokens"
      ) {
        $(".custom-screen").addClass("full-screen");
      }
    },
    async search() {
      let $this = this;
      if (this.isLogin) {
        this.$store.dispatch("searchModule/setQuery", {
          filter: this.searchFilter,
          query: this.searchQuery,
        });
      } else {
        this.$store.dispatch("searchModule/setQuery", {
          filter: this.searchFilterLogout,
          query: this.searchQuery,
        });
      }
      const postData = await this.$store.dispatch("searchModule/search");
      if (this.searchFilter !== 0)
        this.$store.dispatch("votingModule/setVotes", postData);
      if ($this.$route.name !== "Search") {
        $this.$router.push({
          name: "Search",
        });
      }
      $this.searchQuery = "";
      this.$store.dispatch("setSearching", false);
    },
  },
};
</script>
