<template>
  <div>
    <div id="content" class="panel-blue-block p-15 pr-20 pl-20 mb-30 tx-c">
      <div class="mb-10 mt-10">
        <div class="spr-treasures-favicon m-auto"></div>
      </div>
      <h2 class="byline tt-u">DON'T PANIC</h2>
      <p>
        <span class="akr-gen"
          >We'll get you out of here.<br />Try one of these...</span
        >
      </p>
      <p>
        <router-link
          class="button ml-10 pl-10 pr-10 primary"
          to="/"
          title="Pressure Pit"
        >
          Pressure Pit
        </router-link>
        <router-link
          class="button ml-10 pl-10 pr-10 primary"
          to="/leaderboard"
          title="Leaderboard"
        >
          Leaderboard
        </router-link>
        <router-link
          class="button ml-10 pl-10 pr-10 primary"
          to="/winners"
          title="Winners' Wall"
        >
          Winner's Wall
        </router-link>
      </p>
    </div>
  </div>
  
</template>

<script>
export default {
  name: "ErrorPage",
};
</script>