<template>
  <div>
    <div class="modal-overlay">
      <a
        title="Close this Post"
        class="icon-cancel-1 modal-close"
        @click="closeModal"
        >CLOSE</a
      >
      <div class="modal-votes-expired modal-login">
        <div class="o2-img">
          <img
            src="https://test-o2.s3-ap-southeast-2.amazonaws.com/avatars/logo-160.png"
          />
        </div>
        <h1 class="blk">Login</h1>
        <p>You need to login to view this content</p>
        <div class="btn-wrapper">
          <span
            class="button primary icon-lock-open pr-15 pl-15 mr-5 ml-5 cptr"
            @click="moveSidebar(0)"
            >Login</span
          >
          <span
            class="button primary icon-lock pr-15 pl-15 mr-5 ml-5 cptr"
            @click="moveSidebar(3)"
            >Signup</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import changeMyOzoneMixin from "@/mixins/ChangeMyOzoneMixin";

export default {
  name: "MatureAudienceLoginModal",

  mixins: [changeMyOzoneMixin],

  methods: {
    moveSidebar(num) {
      this.closeModal();
      this.$emit("close");
      let $this = this;
      $this.changeMyOzone(num);

      $("body").removeClass("zero-hero-hide");
      $("body").addClass("mpanel-open hidesb");
      $(".mpanel-open").addClass("hello");
      $(".zero-hero-column").removeClass("moved");
      $(".zero-hero").removeClass("moved");
      $(".logged-out").addClass("hidesb");
      $(".ozone-column").addClass("moved");
      $(".my-ozone").addClass("moved");
      $this.$store.commit("change_state", ["mnav_class", "move"]);
      setTimeout(function () {
        $this.$store.commit("change_state", ["mnav_close_class", "slidem"]);
      }, 350);
    },
    closeModal() {
      this.$emit("colseModal");
    },
  },
};
</script>