<template>
  <div id="content" class="pt-20" role="main">
    <div class="using-theme">
      <h6 class="inline-block akrobat" v-if="this.selectedTheme === 'darkmode'">
        You are currently using ...
        <span class="crrent-dark"> DARK MODE theme </span>
      </h6>
      <h6 class="inline-block akrobat" v-else>You are currently using ...</h6>
      <!-- Selected theme -->
      <div
        v-if="availableTheme[0] && Object.keys(availableTheme[0]).length > 1"
      >
        <div v-for="(theme, index) in availableTheme[0]" :key="index">
          <div
            :class="`${index} what-theme trophy mb-20`"
            v-if="index === selectedTheme"
          >
            <div
              :class="
                selectedTheme !== 'o2'
                  ? `spr-theme-${theme[2]} size1-pos`
                  : 'spr-theme-oxygen size1-pos'
              "
            ></div>
            <div class="wt-left">
              <div class="wt-head">
                <span v-if="index !== 'o2'" class="wt-name">{{
                  theme[2]
                }}</span>
                <span v-else-if="index === 'o2'" class="wt-name">Oxygen</span>
                theme
                <span class="theme-trophy" v-if="theme[3] === 'rewarded'">
                  <i class="icon-award tiny"></i>
                </span>
              </div>
              <div class="wt-body" v-if="theme[3] === 'rewarded'">
                <strong>{{ theme[1] }}+ followers.</strong>
              </div>
              <div class="wt-body" v-else-if="theme[3] !== 'rewarded'">
                {{ theme[4] }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div :class="`o2 what-theme trophy mb-20`">
          <div class="spr-theme-oxygen size1-pos"></div>
          <div class="wt-left">
            <div class="wt-head">
              <span class="wt-name">Oxygen</span>
              theme
            </div>
            <div class="wt-body">
              This cool-blue oxygen theme is your default O2 theme.
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Reward Themes -->
    <h2 class="inline-block akrobat">Reward Themes</h2>
    <div class="light-theme">
      <p>
        Win FREE exclusive
        <strong> <span style="color: #ab8d2c">Gold</span> </strong>,
        <strong>
          <span style="color: #6c7a84">Platinum</span>
        </strong>
        &
        <strong>
          <span style="color: #0ea3ce">Diamond</span>
        </strong>
        themes.
      </p>
      <p>
        Be one of the first 500 people to gain
        <strong
          ><span style="color: #ab8d2c">{{ gold_count }}+</span>,
          <span style="color: #6c7a84">{{ platinum_count }}+</span>
          or
          <span style="color: #046ba8">{{ diamond_count }}+</span></strong
        >
        followers and receive awesome limited-edition website themes, trophies &
        tokens as recognition of your achievement.
      </p>

      <!-- Dynamic theme data -->
      <div v-for="(theme, index) in themes" :key="index">
        <div
          :class="`${theme.theme_name} what-theme trophy mb-20`"
          v-if="
            theme.theme_type !== 'default' && theme.theme_type === 'rewarded'
          "
        >
          <div :class="`spr-theme-${theme.theme_name} size1-pos`"></div>
          <div class="wt-left">
            <div class="wt-head">
              <span class="wt-name">{{ theme.theme_name }}</span> theme
              <span class="theme-trophy">
                <i class="icon-award tiny"></i>
              </span>
              <span class="reward-t-qty">
                + {{ theme.rewarded_token }} Tokens</span
              >
            </div>
            <div class="wt-body">
              <strong>{{ theme.follower_count }}+ followers.</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h2 class="inline-block akrobat">O2: Theme</h2>
    <div class="what-theme mb-20">
      <div class="spr-theme-oxygen size1-pos"></div>
      <div class="wt-head">
        <span style="color: #0ea3ce">OXYGEN</span> theme.
      </div>
      <div class="wt-body">
        This cool-blue oxygen theme is your default O2 theme.
      </div>
    </div>
    <p class="mb-40"></p>
    <h2 class="inline-block akrobat">Custom Themes</h2>
    <div class="light-theme">
      <p>
        <strong
          >Choose custom colour themes to suit your mood or superpower.</strong
        >
      </p>
      <p>
        Turn them on/off whenever you want!<br />
        All your themes are managed in your
        <a
          title="Settings"
          class="icon-cog js-trig-bbb"
          :class="{ active: myOzone === 5 }"
          @click="openSettingPanel"
          >Settings</a
        >
      </p>
      <div v-for="(item, idx) in gasTheme" :key="idx">
        <div class="what-theme mb-20">
          <div :class="`spr-theme-${item.theme_value} size1-pos`"></div>

          <div class="wt-head">
            <span>{{ item.theme_value }}</span> theme
          </div>
          <div class="wt-body">
            {{ item.extra }}
          </div>
          <div class="wt-view">
            <a class="theme-sample-js" @click="opening(idx)">View Theme</a>
          </div>
          <div class="theme-panel" :class="{ open: isOpen === idx }">
            <img :src="item.theme_image" class="mb-10" />
            <div class="add-theme-wrap light-theme">
              <div class="left-side">
                <a
                  href="javascript:void(0)"
                  title="Cancel"
                  class="button simple"
                  @click="cancel"
                  >Cancel</a
                >
              </div>
              <div class="right-side">
                <span class="akr-gen-gry">{{ item.token }} Tokens</span>
                <button
                  class="button primary pr-10 pl-10"
                  @click.prevent="ThemeCheck(idx)"
                  :disabled="item.theme_purchased"
                >
                  <div v-if="item.theme_purchased">Already Purchase</div>
                  <div v-else>Add Theme</div>
                </button>
              </div>

              <b-modal
                ref="modal_idx"
                hide-footer
                v-if="openModal === idx ? true : false"
                v-model="openDemo"
                title="Add theme"
                dialog-class="add-theme-modal"
                @close="handleClose(idx)"
              >
                <div class="d-block">
                  <div class="theme-confirm-text">
                    <h3>
                      You have enough tokens to buy the
                      <span class="theme-color">{{ item.theme_value }}</span>
                      theme.
                    </h3>
                    <p>Please confirm if you want this theme.</p>
                  </div>
                </div>
                <div class="btn-wrap">
                  <b-button class="button outline" @click="handleClose(idx)"
                    >Cancel</b-button
                  >
                  <b-button
                    class="button primary"
                    @click="AddElementGasTheme(idx)"
                    :disabled="doubleClick"
                    >Confirm</b-button
                  >
                </div>
              </b-modal>
            </div>
          </div>
        </div>

        <div class="alert-tokens" v-if="errorDiv === idx">
          <h2>Oops, you need more tokens.</h2>
        </div>
        <div class="current-tkns tx-c" v-if="errorDiv === idx">
          <h2>You have {{ user.token }} Tokens available</h2>
          <!-- <a class="button primary gettkns" title="Button"
            ><span class="tok-icon-gen-white"><span>O2</span></span>
            <router-link to="/tokens">Get More Tokens</router-link>
          </a> -->
        </div>
        <div class="alert-tokens success-t" v-if="successDiv === idx">
          <h2>{{ item.theme_value }} theme added successfully.</h2>
        </div>
        <div
          class="current-tkns tx-c current-tkns-note"
          v-if="successDiv === idx"
        >
          <label>
            Click on
            <a
              title="Settings"
              class="icon-cog js-trig-bbb"
              :class="{ active: myOzone === 5 }"
              @click="openSettingPanel"
              >Settings Panel</a
            >
            for apply {{ item.theme_value }} theme.
          </label>
        </div>
      </div>
    </div>

    <div class="panel-blue-block p-15 pr-20 pl-20 mb-30 mt-30 tx-c">
      <h2 class="byline">O2 Social</h2>
      <p>
        <span class="akr-gen">FOLLOW US ON...</span>
      </p>
      <div class="o2-socials">
        <span data-title="O2 Facebook" data-title-top
          ><a
            href="https://www.facebook.com/O2Unlimited"
            target="_blank"
            class="icon-facebook"
          ></a
        ></span>
        <span data-title="O2 Instagram" data-title-top
          ><a
            href="https://www.instagram.com/o2positive_com"
            target="_blank"
            class="icon-instagram"
          ></a
        ></span>
        <span data-title="O2 Pinterest" data-title-top>
          <a
            href="https://www.pinterest.nz/o2positive_com"
            target="_blank"
            class="icon-pinterest"
          ></a
        ></span>
        <span data-title="O2 Twitter" data-title-top
          ><a
            href="https://www.twitter.com/O2Unlimited"
            target="_blank"
            class="icon-twitter"
          ></a
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import $ from "jquery";
import ChangeMyOzoneMixin from "@/mixins/ChangeMyOzoneMixin";

export default {
  name: "Themes",

  mixins: [ChangeMyOzoneMixin],

  computed: {
    ...mapState({
      availableTheme: (state) => state.auth.availableTheme,
      selectedTheme: (state) => state.auth.selectedTheme,
      user: (state) => state.auth.user,
    }),
    link() {
      return process.env.VUE_APP_PREFIX + "/themes";
    },
  },

  data() {
    return {
      themes: [],
      gasTheme: [],
      member: 0,
      gold_count: 0,
      platinum_count: 0,
      diamond_count: 0,
      icon_img: "",
      openModal: "",
      isOpen: null,
      openDemo: false,
      errorDiv: null,
      successDiv: null,
      doubleClick: false,
    };
  },

  mounted() {
    if (this.$route.name === "Themes") {
      $(".custom-screen").addClass("full-screen");
    }
    this.O2Theme();
    this.ElementTheme();
  },

  destroyed() {
    $(".custom-screen").removeClass("full-screen");
  },

  methods: {
    openSettingPanel() {
      this.changeMyOzone(5);
      this.moveSidebar();
    },
    handleClose() {
      this.openDemo = false;
    },
    async O2Theme() {
      await axios({
        url: "auth/user/o2-theme",
        method: "get",
      }).then((response) => {
        this.themes = response.data;
        this.member = response.data[0].member_count;
        response.data.map((item) => {
          if (item.theme_type === "rewarded") {
            if (item.theme_name === "gold") {
              this.gold_count = item.follower_count;
            } else if (item.theme_name === "platinum") {
              this.platinum_count = item.follower_count;
            } else if (item.theme_name === "diamond") {
              this.diamond_count = item.follower_count;
            }
          }
        });
      });
    },
    async ElementTheme() {
      await axios({
        url: "user/token/element-theme/",
        method: "GET",
      }).then((response) => {
        this.gasTheme = response.data.data;
      });
    },
    async ThemeCheck(num) {
      const data = {
        token: this.gasTheme[num].token,
      };
      const tmp = await this.$store.dispatch("CheckThemeToken", data);
      if (tmp.status !== 200) {
        this.errorDiv = num;
        setTimeout(() => {
          this.errorDiv = false;
        }, 5000);
      } else {
        this.openModal = num;
        if (this.openModal === num) {
          this.openDemo = true;
        }
      }
    },
    async AddElementGasTheme(idx) {
      const data = {
        token: this.gasTheme[idx].token,
        theme: this.gasTheme[idx].theme_name,
      };
      this.doubleClick = true;
      const tmp = await this.$store.dispatch("AddGasTheme", data);
      this.doubleClick = false;

      if (tmp.status === 200) {
        this.handleClose(idx);
        this.successDiv = idx;
        this.gasTheme[idx].theme_purchased = true;
        setTimeout(() => {
          this.successDiv = false;
        }, 6000);
        this.$store.commit(
          "set_user_token",
          this.user.token - this.gasTheme[idx].token
        );
        await this.$store.dispatch("showToast", {
          message: tmp.message,
        });
      }
    },
    opening(num) {
      if (this.isOpen === num) {
        this.isOpen = 0;
      } else {
        this.isOpen = num;
      }
    },
    cancel() {
      this.isOpen = null;
    },
    moveSidebar() {
      if (window.screen.width < 768) {
        $("body").addClass("mpanel-open hidesb");
      }
      $(".ozone-column").addClass("moved");
      $(".my-ozone").addClass("moved");
      this.$store.commit("change_state", ["mnav_class", "move"]);
      this.$store.commit("change_state", ["mnav_close_class", "slidem"]);
    },
  },
};
</script>
