<template>
  <div id="post-list">
    <div class="tx-ctr">
      <div v-if="posts.length === 0 && this.activeTab === 0">
        <h2 class="akrobat c-999 noCaps">
          <i class="icon-picture"></i> No Posts yet.
          <br />
          <br />Be the first. Create & Post something new.
        </h2>
      </div>
      <div v-else-if="posts.length === 0 && this.activeTab === 1">
        <h2 class="akrobat c-999 noCaps">
          <i class="icon-chart-line"></i> Nothing is trending yet today.
          <br />
          <br />Vote on a Post to give it a push!
        </h2>
      </div>
      <div v-else-if="posts.length === 0 && this.activeTab === 2">
        <h2 class="akrobat c-999 noCaps">
          <i class="icon-picture"></i> All Posts have now expired.
          <br />
          <br />Go on, post something new!
        </h2>
      </div>
      <div v-else-if="posts.length === 0 && this.activeTab === 3">
        <h2 class="akrobat c-999 noCaps">
          <i class="icon-picture"></i> No Posts yet.
          <br />
          <br />Be the first. Create & Post something new.
        </h2>
      </div>
    </div>
    <div id="container-div">
      <ul
        class="feed list-grid"
        id="post-data"
        :class="{ listview: isListView }"
      >
        <li v-for="(post, index) in posts" :key="index">
          <div
            class="voter"
            v-if="isLogin && showPressure && $route.name !== 'User'"
          >
            <div
              v-if="posts.length === 1 && post.votes < 1"
              class="votes only_plus pp"
            >
              <div data-title="Vote me up the Leaderboard" data-title-right>
                <div
                  class="icon-plus votes-button votes-up tippy"
                  @click="increment(post.post_id)"
                ></div>
              </div>

              <div class="cst_input value_center">
                <div
                  data-title="Votes Received"
                  v-if="post.votes > 0"
                  data-title-right
                >
                  <input
                    type="number"
                    id="post-02-votes"
                    :value="post.votes"
                    min="0"
                  />
                </div>
                <div class="no_count" v-else-if="post.votes < 1">
                  <div data-title="Help Me!" data-title-right>NO VOTES YET</div>
                </div>
              </div>
            </div>
            <div v-else>
              <div
                class="votes only_plus pp"
                :class="[
                  myVotes && max_votes !== post.post_id ? '' : 'count_up',
                ]"
              >
                <div data-title="Vote me up the Leaderboard" data-title-right>
                  <div
                    v-if="myVotes && max_votes !== post.post_id"
                    class="icon-plus votes-button votes-up tippy"
                    @click="increment(post.post_id)"
                  ></div>
                </div>
                <div
                  class="cst_input value_center"
                  v-if="post.votes > 0 && max_votes !== post.post_id"
                >
                  <div data-title="Votes Received" data-title-right>
                    <input
                      type="number"
                      id="post-02-votes"
                      :value="post.votes"
                      min="0"
                    />
                  </div>
                </div>

                <div class="with_box" v-else-if="max_votes === post.post_id">
                  <div data-title="Top of the Leaderboard" data-title-right>
                    CURRENT LEADER
                  </div>
                </div>

                <div class="no_count" v-else-if="post.votes < 1">
                  <div data-title="Help Me!" data-title-right>NO VOTES YET</div>
                </div>
              </div>
            </div>
          </div>

          <div class="imgWrap" :class="noShadow(index)">
            <div class="post-info-block">
              <div class="ext-img">
                <div v-if="!post.videos">
                  <div v-if="post.images.length > 1">
                    <span data-title="No. of Images" data-title-left
                      >{{ "+" }}{{ post.images.length - 1 }}</span
                    >
                  </div>
                </div>
                <div v-else>
                  <div
                    v-if="post.videos.length > 1 && post.images.length === 0"
                  >
                    <span data-title="No. of Videos" data-title-left
                      >{{ "+" }}{{ post.videos.length - 1 }}</span
                    >
                  </div>
                  <div v-else-if="post.images.length && post.videos.length">
                    {{ "+"
                    }}{{ imgCount(post.images.length, post.videos.length) }}
                  </div>
                </div>
              </div>
              <span
                class="tip-icon"
                :class="
                  (post.images.length === 0 && post.videos.length < 2) ||
                  (post.images.length < 2 && !post.videos)
                    ? 'only-like'
                    : ''
                "
                :data-title="`${
                  diableFavorite(post.post_id)
                    ? 'Remove from Faves'
                    : 'Add to Faves'
                }`"
                data-title-left
              >
                <a
                  v-show="isLogin && userEmail !== post.author_email"
                  class="icon-heart sav2fav"
                  :class="{ liked: diableFavorite(post.post_id) }"
                  @click="clickFavButton(post.post_id)"
                ></a>
              </span>
            </div>
            <div
              class="trash-Wrp"
              v-if="$route.name === 'User' && !isLeaders"
              @click="removePost(post)"
            >
              <div data-title="Remove" data-title-left>
                <div class="trash icon-trash" title="Delete"></div>
              </div>
            </div>
            <LifeRemainingBarComponent
              v-if="post.life_support"
              :expiration="post.expiration"
              :life_remaining="post.life_support_remaining"
            />
            <div
              v-if="post.mature_content"
              class="mature-audience"
              style="display: block"
              @click="checkIfLogin(post.post_id, post.mature_content)"
            >
              <div>
                <div>
                  <i class="icon-eye-off"></i>
                </div>
                <p class="mature">MATURE AUDIENCE</p>
                <span>Content may be sensitive</span>
                <span class="letmesee">LET ME SEE</span>
              </div>
            </div>
            <MatureAudienceLoginModal
              v-if="showLoginModal"
              @colseModal="colseModal"
            />
            <div
              class="thank_you"
              :class="[post.post_id === post_id ? 'show' : '']"
              :id="`msgID${post.post_id}`"
            >
              <canvas class="confetti" :id="`canvas${post.post_id}`"></canvas>
              <div class="message">
                <p>THANK YOU!</p>
                <span>This Post is now on the Leaderboard</span>
              </div>
            </div>
            <div @click="showOverlay(post.post_id, post.mature_content)">
              <div class="prevent-vid-click"></div>
              <div v-if="post.is_video && post.videos" class="vid-arrow">
                <div data-title="Video" data-title-top>
                  <span>{{ post.videos.length }}</span>
                </div>
              </div>
            </div>

            <div v-if="post.mature_content && !isLogin">
              <img src="../../assets/images/logintoview.png" />
            </div>
            <div v-else class="daynamic-img-warp">
              <v-lazy-image v-if="!post.is_video" :src="post.images[0].url" />
              <iframe
                v-else
                width="560"
                height="315"
                :src="post.videos[0].url"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <div class="author with-tag">
            <span class="asign-cate">
              <span
                v-for="(cat, index) in post.category"
                :key="index"
                :title="cat"
                @click="filterCate(cat)"
                >{{ cat }}</span
              >
            </span>
            <span>
              <span class="user mr-5">
                <i class="icon-user"></i>
                <a
                  class="anti-a mr-0"
                  :title="`View ${post.author}`"
                  @click="goToProfile(index)"
                  >{{ post.author }}</a
                >
                <span>
                  <a
                    :href="link"
                    :data-title="toCapital(post.author_theme)"
                    data-title-left
                    class="user-trophy"
                    :class="trophyClass(post.author_theme)"
                  >
                    <i class="icon-award tiny"></i>
                  </a>
                </span>
              </span>
              <span class="date">
                <i class="icon-calendar"></i>
                {{ postTimeAgo(post.post_id) }}
              </span>
            </span>

            <span :data-title="post.author" data-title-left class="tip-user">
              <a class="avatar" @click="goToProfile(index)">
                <div
                  v-if="isLogin"
                  class="on-off"
                  :class="[post.author_status === true ? 'on-now' : '']"
                  :data-title="
                    post.author_status === true ? 'Online' : 'Offline'
                  "
                  data-title-right
                ></div>
                <img
                  :src="post.author_img"
                  :alt="post.author"
                  :class="
                    post.author_class === 'no-border' ? 'avatar-no-border' : ''
                  "
                />
              </a>
            </span>
          </div>
          <h3>
            <a
              v-if="post.title && post.title.length < 45"
              class="show-panel"
              :title="post.title"
              @click="showOverlay(post.post_id, post.mature_content)"
              >{{ post.title }}</a
            >
            <a
              v-else
              class="show-panel"
              :title="post.title"
              @click="showOverlay(post.post_id, post.mature_content)"
              >{{ post.title.substring(0, 45) + "..." }}</a
            >
          </h3>
          <p
            v-if="post.summary && post.summary.length < 300"
            class="summary"
            :class="{ 'white-spaces-pre': isListView }"
          >
            {{ post.summary }}
          </p>
          <p v-else class="summary">
            {{ post.summary.substring(0, 100) + "..." }}
          </p>
        </li>
      </ul>
    </div>
    <div class="btn_center_sec">
      <a
        class="load_more_btn"
        v-if="paginate && loadMoreToggle"
        @click="loadMorePosts"
        >Show more <span class="icon-down-open"></span>
        </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import LifeRemainingBarComponent from "../shared/LifeRemainingBarComponent.vue";
import moment from "moment";
import showLeaderMessageMixin from "@/mixins/showLeaderMessageMixin";
import MatureAudienceLoginModal from "../../components/modal/MatureAudienceLoginModal.vue";
import $ from "jquery";
import VLazyImage from "v-lazy-image/v2";

export default {
  name: "PressurePit",
  props: {
    moduleName: String,
    paginate: Boolean,
  },

  components: {
    LifeRemainingBarComponent,
    MatureAudienceLoginModal,
    VLazyImage,
  },

  mixins: [showLeaderMessageMixin],

  mounted() {
    if (this.$route.name === "Home") {
      $(".custom-screen").addClass("full-screen");
    }
    this.$store.dispatch("getFavorites");
  },
  destroyed() {
    $(".custom-screen").removeClass("full-screen");
  },

  computed: {
    ...mapGetters({ getOrderBySort: "homeModule/getOrderBySort" }),
    ...mapState({
      isLogin: (state) => state.auth.isLogin,
      isDraft: (state) => state.common.isDraft,
      totalVotes: (state) => state.votingModule.totalVotes,
      min_votes: (state) => state.votingModule.min_votes,
      max_votes: (state) => state.votingModule.max_votes,
      isListView: (state) => state.common.isListView,
      showPressure: (state) => state.common.showPressure,
      myVotes: (state) => state.auth.user.votes,
      userEmail: (state) => state.auth.user.email,
      myFavorites: (state) => state.common.myFavorites,
      posts(state) {
        return state[this.moduleName].posts;
      },
      activeTab: (state) => state.base.activeTab,
      pageNumber(state) {
        return state[this.moduleName].pageNumber;
      },
      totalPosts(state) {
        return state[this.moduleName].totalPosts;
      },
    }),
    pressures() {
      let pressures = [];
      this.posts.map((post) => {
        switch (post.post_id) {
          case this.max_votes:
            pressures.push(99.99);
            break;
          case this.min_votes:
            pressures.push(0.01);
            break;
          default:
            if (this.totalVotes) {
              pressures.push(
                (
                  (post.votes / this.totalVotes) * 100 +
                  post.post_id * 0.01
                ).toFixed(2)
              );
            } else {
              pressures.push(0.0);
            }
            break;
        }
      });
      return pressures;
    },
    loadMoreToggle() {
      const limit = 10;
      return Math.ceil(this.totalPosts / limit) > this.pageNumber
        ? true
        : false;
    },
    showLoginModal() {
      if (this.modalShow === true && !this.closeLoginModal) {
        return true;
      }
      return false;
    },
    link() {
      return process.env.VUE_APP_PREFIX + "/themes";
    },
  },

  data() {
    return {
      post_id: "",
      show: false,
      closeLoginModal: false,
      modalShow: false,
      orderBy: null,
    };
  },

  methods: {
    async filterCate(cate) {
      localStorage.setItem("category", cate);
      this.$store.commit("set_selected_cate", cate);
      const postData = await this.$store.dispatch(
        "homeModule/getAllAvailablePosts",
        {
          activeTab: 0,
          pageNumber: 0,
          orderBy: this.orderBy,
          selectedCategory: localStorage.getItem("category"),
        }
      );
      this.orderBy = postData.order_by;
      await this.$store.dispatch("votingModule/setVotes", postData);
      await this.$store.dispatch("getLeaders");
    },
    noShadow(index) {
      if (this.isLogin) {
        if (this.userEmail === this.posts[index].author_email) {
          if (this.posts[index].images.length) {
            if (this.posts[index].is_video) {
              return "";
            } else {
              if (this.posts[index].images.length < 2) {
                return "no-shadow";
              }
            }
          } else {
            if (this.posts[index].videos.length < 2) {
              return "no-shadow";
            }
          }
        }
      } else {
        return "no-shadow";
      }
    },
    imgCount(imgLen, videoLen) {
      return imgLen - 1 + videoLen;
    },
    async increment(postID) {
      this.$emit("increment", postID);
      const index = this.posts.findIndex((post) => post.post_id === postID);
      this.showLeaderMsg(postID, this.posts[index].votes, "");
      this.conffetti(postID);
    },
    postTimeAgo(postId) {
      const post = this.posts.find((post) => post.post_id === postId);
      if (!post) {
        return;
      }
      const now = moment.utc()._d;
      const creat_at =
        moment(post.post_date).format("DD/MM/YYYY") +
        " " +
        moment(post.post_date).format("HH:mm:ss");
      const curr_time =
        moment(now).format("DD/MM/YYYY") + " " + moment(now).format("HH:mm:ss");
      var ms = moment(curr_time, "DD/MM/YYYY HH:mm:ss").diff(
        moment(creat_at, "DD/MM/YYYY HH:mm:ss")
      );
      var d = moment.duration(ms);
      var days = Math.floor(d.asDays());
      var seconds = Math.floor(d.asSeconds());
      if (days === 0 && seconds >= 0 && seconds < 60) {
        if (seconds === 0) {
          return "few seconds ago";
        } else {
          return seconds + "s";
        }
      }

      if (days === 0 && seconds >= 60 && seconds < 3600) {
        let minutes = Math.floor(seconds / 60);
        if (minutes === 0 && seconds === 60) {
          return " minute ago";
        } else {
          return minutes + "m";
        }
      }

      if (days === 0 && seconds >= 3600 && seconds < 86400) {
        let hours = Math.floor(seconds / 3600);
        if (hours === 0) {
          return "hour ago";
        } else {
          return hours + "h";
        }
      }

      //  1 day to 30 days
      if (days >= 1 && days < 30) {
        return days + "d";
      }

      if (days >= 30 && days < 35) {
        let weeks = Math.floor(days / 7);
        return weeks + "w";
      }

      if (days >= 30 && days < 365) {
        let months = Math.floor(days / 30);
        return months + "mo";
      }

      if (days >= 365) {
        let years = Math.floor(days / 365);
        return years + "y";
      }
    },
    goToProfile(index) {
      if (this.isLogin) {
        let post = { ...this.posts[index] };
        if (this.userEmail === post.author_email) {
          this.$router.push({ name: "User" });
        } else {
          this.$router.push({
            name: "Profile",
            params: {
              uuid: post.author_uuid,
            },
          });
        }
      } else {
        this.modalShow = true;
        $(".col-trigger").addClass("sidebar-hide");
        $(".sidebar-right").addClass("sidebar-hide");
        $(".show-mnav").addClass("sidebar-hide");
        this.hideSidebar();
      }
    },
    showOverlay(post_id, isMature) {
      if (this.isLogin) {
        this.$store.dispatch("changeState", ["overlayClass", "opened"]);
        this.$emit("showOverlay", post_id);
      } else {
        if (isMature === false) {
          this.$store.dispatch("changeState", ["overlayClass", "opened"]);
          this.$emit("showOverlay", post_id);
        }
      }
    },
    checkIfLogin(post_id, isMature) {
      if (this.isLogin) {
        this.modalShow = false;
        this.showOverlay(post_id, isMature);
      } else {
        this.modalShow = true;
        $(".col-trigger").addClass("sidebar-hide");
        $(".sidebar-right").addClass("sidebar-hide");
        $(".show-mnav").addClass("sidebar-hide");
        this.hideSidebar();
      }
    },
    colseModal() {
      this.closeLoginModal = true;
      this.modalShow = false;
      this.closeLoginModal = false;
      $(".col-trigger").removeClass("sidebar-hide");
      $(".show-mnav").removeClass("sidebar-hide");
      $(".sidebar-right").removeClass("sidebar-hide");
      this.hideSidebar();
    },
    hideSidebar() {
      let $this = this;
      $("body").removeClass("mpanel-open hidesb");
      $(".ozone-column").removeClass("moved");
      $(".my-ozone").removeClass("moved");
      $this.$store.commit("change_state", ["mnav_class", ""]);
      setTimeout(function () {
        $this.$store.commit("change_state", ["mnav_close_class", ""]);
      }, 350);
    },
    async loadMorePosts() {
      const newContainer = document.getElementById("app");
      const scrollData = newContainer.scrollHeight - 400;
      await this.$store.dispatch(`${this.moduleName}/loadMorePosts`, {
        activeTab: this.activeTab,
        pageNumber: this.pageNumber,
        selectedCategory: localStorage.getItem("category"),
      });
      newContainer.scrollTo({
        top: scrollData,
        left: 0,
        behavior: "smooth",
      });
    },
    clickFavButton(postID) {
      if (this.diableFavorite(postID)) {
        return this.removeFavourite(postID);
      } else {
        return this.addToFavorite(postID);
      }
    },
    async addToFavorite(postID) {
      await this.$store.dispatch("getFavorites");
      let data = {
        post_id: postID,
      };
      await this.$store.dispatch("addToFavorites", data);
      await this.$store.dispatch("getFavorites");
    },
    diableFavorite(postID) {
      if (!this.isLogin) return false;
      if (this.myFavorites.find((item) => item === postID)) {
        return true;
      }
      return false;
    },
    async removeFavourite(postID) {
      await this.$store.dispatch("getFavorites");
      await this.$store.dispatch("favoriteModule/deletePost", postID);
      await this.$store.dispatch("getFavorites");
    },
    trophyClass(theme) {
      if (theme === "gold") {
        return "gold-trophy";
      } else if (theme === "platinum") {
        return "platinum-trophy";
      } else if (theme === "diamond") {
        return "diamond-trophy";
      } else {
        return "";
      }
    },
    toCapital(author_theme) {
      return (
        author_theme[0].toUpperCase() + author_theme.slice(1) + " " + "Member"
      );
    },
  },
};
</script>