import * as api from "../Services/api.js";
import axios from "axios";

export const state = {
  user: {},
  posts: [],
  pageNumber: 0,
  banPageNumber: 0,
  totalPosts: 0,
  banePostCount: 0,
  unreadCount: 0,
  toggleShow: false,
  postToggle: false,
  winnerCount: 0,
  top10Count: 0,
  myProfileTheme: [],
  elementTheme: [],
  givenPlus: 0,
  givenMinus: 0,
  receivePlus: 0,
  receiveMinus: 0,
};

export const mutations = {
  set_vote_history(state, data) {
    if (data.givenPlus) {
      state.givenPlus = data.givenPlus;
    } else if (data.givenMinus) {
      state.givenMinus = data.givenMinus;
    } else if (data.receivePlus) {
      state.receivePlus = data.receivePlus;
    } else if (data.receiveMinus) {
      state.receiveMinus = data.receiveMinus;
    }
  },
  set_count(state, data) {
    state.winnerCount = data.winner_count;
    state.top10Count = data.top10_count;
    state.myProfileTheme = data.my_profile_theme;
    state.elementTheme = data.element_theme;
  },
  SET_TOGGLE(state, data) {
    state.toggleShow = data;
  },
  SET_POST_TOGGLE(state, data) {
    state.postToggle = data;
  },
  SET_UNREAD_COUNT(state, data) {
    state.unreadCount = data;
  },
  INCREASE_UNREAD_COUNT(state, data) {
    state.unreadCount = state.unreadCount + data;
  },
  SET_UNREAD_COMMENT_COUNT(state, data) {
    state.unreadCount = state.unreadCount - data;
  },
  SET_UNREAD_SUB_COMMENT_COUNT(state, data) {
    state.unreadCount = state.unreadCount - data;
  },
  SET_USER_DATA(state, payload) {
    state.user = {
      ...state.user,
      ...payload,
    };
    for (let key in state.user) {
      if (state.user[key] === "null") state.user[key] = "";
    }
  },
  SET_USER_POST(state, payload) {
    state.posts = payload.post_data;
    state.pageNumber = 0;
    state.totalPosts = payload.post_count;
    state.banePostCount = payload.bane_post_count;
  },
  SET_BAN_POSTS(state, payload) {
    state.posts = payload.data.post_data;
    state.banPageNumber = 0;
    state.totalPosts = payload.data.post_count;
  },
  SET_LOADED_BAN_POST(state, payload) {
    state.posts = state.posts.concat(payload.data.post_data);
    state.banPageNumber += 1;
  },
  SET_LOADED_USER_POST(state, payload) {
    state.posts = state.posts.concat(payload.post_data);
    state.pageNumber += 1;
  },
  CHANGE_PRESSURE(state, payload) {
    const index = state.posts.findIndex(
      ({ post_id }) => post_id === payload.post_id
    );
    state.posts[index].votes += payload.amount;
  },
  EDIT_POST(state, payload) {
    const index = state.posts.findIndex(
      ({ post_id }) => post_id === payload.post_id
    );
    state.posts.splice(index, 1, { ...payload });
  },
  REMOVE_POST(state, payload) {
    const index = state.posts.findIndex(({ uuid }) => uuid === payload);
    state.posts.splice(index, 1);
  },
};

export const actions = {
  async getUserData({ commit }) {
    return await api.get("/auth/user/get/").then((response) => {
      commit("SET_USER_DATA", response);
    });
  },
  async getUnreadCount({ commit }) {
    await axios({
      url: "/posts/get-unread-count/",
      method: "post",
    }).then((response) => {
      commit("SET_UNREAD_COUNT", response.data);
    });
  },
  async getUserPost({ commit }, data) {
    return await api
      .get(
        "/posts/user-posts/?page_num=" +
          data.pageNumber +
          "&active_tab=" +
          data.activeTab +
          "&show=" +
          data.show +
          "&category=" +
          data.selectedCategory
      )
      .then((response) => {
        commit("SET_USER_POST", response);
        commit("SET_TOGGLE", data.show);
        return response;
      });
  },
  async getLoadedUserPost(context, data) {
    context.dispatch("setStatus", true, { root: true });
    return await api
      .get(
        "/posts/user-posts/?page_num=" +
          (data.pageNumber + 1) +
          "&active_tab=" +
          data.activeTab +
          "&show=" +
          state.toggleShow +
          "&category=" +
          data.selectedCategory
      )
      .then((response) => {
        context.dispatch("setStatus", false, { root: true });
        context.commit("SET_LOADED_USER_POST", response);
        return response;
      });
  },
  async banPostData({ commit }, data) {
    await axios({
      url: "/posts/ban-post-data/",
      method: "post",
      data: {
        postToggle: data.postToggle,
        page_num: data.pageNumber,
      },
    })
      .then((response) => {
        commit("SET_BAN_POSTS", response.data);
        commit("SET_POST_TOGGLE", data.postToggle);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async loadMoreBanPosts(context, data) {
    context.dispatch("setStatus", true, { root: true });
    await axios({
      url: "/posts/ban-post-data/",
      method: "post",
      data: {
        page_num: data.pageNumber + 1,
        postToggle: state.postToggle,
      },
    }).then((response) => {
      context.dispatch("setStatus", false, { root: true });
      context.commit("SET_LOADED_BAN_POST", response.data);
    });
  },
  async changePressure({ commit }, data) {
    return await api
      .get(
        "/posts/change-vote/?post_id=" + data.post_id + "&amount=" + data.amount
      )
      .then((response) => {
        commit("CHANGE_PRESSURE", data);
        return response;
      });
  },
  async editPost({ commit }, data) {
    return await api.post("/posts/update/", data).then((response) => {
      if (response.status) {
        commit("EDIT_POST", data);
      }
    });
  },
  async removePost({ commit }, data) {
    return await api.get("/posts/remove-post?uuid=" + data).then((response) => {
      if (response.status === 200) {
        commit("REMOVE_POST", data);
      }
    });
  },
  async PostViewCount() {
    const tmp = await axios({
      url: "posts/post-track/",
      method: "GET",
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return tmp;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
