import { mapState } from "vuex";
import * as moment from "moment";
import $ from "jquery";

export default {
  computed: {
    ...mapState({
      myOzone: (state) => state.common.myOzone,
      isLogin: (state) => state.auth.isLogin,
    }),
  },
  methods: {
    changeMyOzone(num) {
      let changeTime = moment().format();
      if (this.myOzone === num && this.isLogin) {
        this.$store.dispatch("setMyOzone", 1);
      } else if (num === 0 && !this.isLogin) {
        this.$store.dispatch("setMyOzone", 0);
      } else {
        this.$store.dispatch("setMyOzone", num);
      }

      setTimeout(function () {
        $("body").addClass("hidesb");
      }, 50);
      this.$store.dispatch("setChangedTimeMyOzone", moment(changeTime));
    },
  },
};
