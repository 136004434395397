<template>
  <div
    :class="[
      isLogin ? 'logged-in' : 'logged-out',
      this.$route.path === '/' ? 'pressure-pit-login' : '',
      this.$route.path === '/howitworks' ? 'pressure-pit-login' : '',
      this.$route.path === '/whatiso2' ? 'pressure-pit-login' : '',
      { hidesb: isLoginPannelOpen },
    ]"
    @click="toggleOverlay"
  >
    <div
      class="ozone-column col-trigger"
      :class="[{ moved: isLoginPannelOpen }]"
    ></div>
    <div class="my-ozone col-trigger" :class="[{ moved: isLoginPannelOpen }]">
      <div class="m-ozone-wrapper">
        <span class="icon-left-open zhh" @click="openZeroToHero"></span>
        <div class="zero-hero-header">
          <h2>From zero </h2>
          <h3>To hero</h3>
        </div>
        <div v-if="isLogin" class="vote-status">
          <div class="remaining">
            <span class="count">{{ votes }}</span>
          </div>
          <h3>Votes left</h3>
          <h4>Day {{ now }}/7</h4>
          <span class="icon-left-open mb-5" @click="moveMyOzone"></span>
          <h3>MY OZONE</h3>
        </div>
      </div>
      <Login v-if="myOzone === 0" />
      <ForgottenPassword v-else-if="myOzone === 2" />
      <SignUp v-else-if="myOzone === 3" />
      <div v-if="isLogin" class="ozone-wrapper">
        <Loggedin @getGauge="getGauge" @hideSideBar="hideSidebar" />
        <div class="gauge">
          <div class="gauge-wrapper">
            <div class="gauge-range">
              <div :class="`gauge-status ${gauge_status_class}`">
                <h2>{{ gauge_h2 }}</h2>
                <h3>
                  Winners in<span class="icon-clock-1"
                    >{{ settimeInterval() }}{{ timeDiff }}</span
                  >
                </h3>
              </div>
              <div :class="`pressure ${pressure_class}`"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ///// > mobile nav  ///// -->
    <a
      class="icon-menu show-mnav"
      title="Open Menu"
      @click="moveSidebar"
      :class="{
        non_index:
          overlayClass || editPostOverlayClass || postCreateOverlayClass,
      }"
    ></a>
    <div class="mnav" :class="mnav_class">
      <nav class="m-navbar">
        <Navbar />
      </nav>
      <div class="close-panel mnav-close" :class="mnav_close_class">
        <a class="icon-cancel-1" title="Close Menu" @click="hideSidebar"></a>
      </div>
      <!-- ///// Paul for later ///// -->
      <ul class="sisters-mob">
        <li>
          <a href="" class=""
            ><span>O<sup>2</sup></span
            >POSITIVE</a
          >
        </li>
      </ul>
      <!-- ///// End Paul ///// -->
    </div>
    <EditProfileComponent v-if="myOzone === 4" />
    <SettingComponent v-if="myOzone === 5" @hideSideBar="hideSidebar" />
    <FollowingComponent v-if="myOzone === 6" @hideSideBar="hideSidebar" />
    <NotificationsComponent v-if="myOzone === 7" />
    <CartTokenComponent v-if="myOzone === 8" @hideSideBar="hideSidebar" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import $ from "jquery";
import * as moment from "moment";

import Login from "@/components/sidebar/ozone/Login";
import Loggedin from "@/components/sidebar/ozone/Loggedin";
import ForgottenPassword from "@/components/sidebar/ozone/ForgottenPassword";
import SignUp from "@/components/sidebar/ozone/SignUp";
import FollowingComponent from "@/components/sidebar/ozone/FollowingComponent";
import EditProfileComponent from "@/components/sidebar/ozone/EditProfileComponent";
import SettingComponent from "@/components/sidebar/ozone/SettingComponent";
import Navbar from "@/components/navigationbar/Navbar";
import NotificationsComponent from "@/components/hq/NotificationsComponent.vue";
import CartTokenComponent from "@/components/hq/CartTokenComponent.vue";

export default {
  name: "MyOzone",
  components: {
    Login,
    Loggedin,
    ForgottenPassword,
    SignUp,
    FollowingComponent,
    EditProfileComponent,
    SettingComponent,
    Navbar,
    NotificationsComponent,
    CartTokenComponent,
  },
  emits: ["closeLoginPanel"],
  props: {
    isLoginPannelOpen: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      votes: (state) => state.auth.user.votes,
      isLogin: (state) => state.auth.isLogin,
      isSearching: (state) => state.common.isSearching,
      myOzone: (state) => state.common.myOzone,
      overlayClass: (state) => state.common.overlayClass,
      editPostOverlayClass: (state) => state.common.editPostOverlayClass,
      mnav_class: (state) => state.base.mnav_class,
      mnav_close_class: (state) => state.base.mnav_close_class,
      postCreateOverlayClass: (state) => state.common.postCreateOverlayClass,
      votes_text: (state) => state.staticLabels.votes_text,
    }),
  },

  data() {
    return {
      ozoneColume_class: "",
      myOzone_class: "",
      mobile_vote_status: "",
      gauge_h2: "",
      gauge_h3: "",
      gauge_status_class: "off",
      pressure_class: "pressure-280",
      now: "",
      timeDiff: "",
    };
  },

  mounted() {
    this.settimeInterval();
    this.timeDiff;
  },
  methods: {
    loginPanelClass() {
      return this.$route.path === "/" &&
        this.$route.path === "/howitworks" &&
        this.$route.path === "/whatiso2"
        ? "pressure-pit-login"
        : "";
    },
    settimeInterval() {
      setInterval(this.countDown, 10000);
    },
    countDown() {
      moment.updateLocale("en", {
        week: {
          dow: 1, // Monday is the first day of the week.
        },
      });
      const now = moment.utc()._d;
      const curr_time =
        moment(now).format("DD/MM/YYYY") + " " + moment(now).format("HH:mm:ss");
      var current = moment().endOf("week");
      const end_time =
        moment(current).format("DD/MM/YYYY") +
        " " +
        moment(current).format("HH:mm:ss");
      var ms = moment(curr_time, "DD/MM/YYYY HH:mm:ss").diff(
        moment(end_time, "DD/MM/YYYY HH:mm:ss")
      );
      var d = moment.duration(ms);
      var days = Math.abs(d._data.days);
      var hours = Math.abs(d._data.hours);
      var minutes = Math.abs(d._data.minutes);
      this.timeDiff = days + "d" + ":" + hours + "h" + ":" + minutes + "m";
      return days + "d" + ":" + hours + "h" + ":" + minutes + "m";
    },

    getGauge() {
      let lable = moment.utc().format("d");
      this.now = lable;
      moment.updateLocale("en", {
        week: {
          dow: 1, // Monday is the first day of the week.
        },
      });
      const now = moment.utc()._d;
      const curr_time =
        moment(now).format("DD/MM/YYYY") + " " + moment(now).format("HH:mm:ss");
      var current = moment().endOf("week");
      const end_time =
        moment(current).format("DD/MM/YYYY") +
        " " +
        moment(current).format("HH:mm:ss");
      var ms = moment(curr_time, "DD/MM/YYYY HH:mm:ss").diff(
        moment(end_time, "DD/MM/YYYY HH:mm:ss")
      );
      var d = moment.duration(ms);
      var days = Math.abs(d._data.days);
      var hours = Math.abs(d._data.hours);
      const decrementTime = 280 / 24 / 7;
      const days_time = days * decrementTime * 24;
      const hours_time = hours * decrementTime;
      const degree = Math.floor(days_time + hours_time);
      this.mobile_vote_status = this.votes_text[lable].mobile_vote_status;
      this.gauge_h2 = this.votes_text[lable].gauge_h2;
      this.gauge_h3 = this.votes_text[lable].gauge_h3;
      this.gauge_status_class = "";
      this.pressure_class = "pressure-" + degree;
    },
    moveSidebar() {
      let $this = this;
      $this.$store.commit("change_state", ["mnav_class", "move"]);
      setTimeout(function () {
        $this.$store.commit("change_state", ["mnav_close_class", "slidem"]);
        $("body").addClass("mpanel-open");
      }, 350);
    },
    hideSidebar() {
      let $this = this;
      $this.$emit("closeLoginPanel");
      $("body").removeClass("mpanel-open");
      $("body").removeClass("hello");
      $this.$store.commit("change_state", ["mnav_class", ""]);
      $this.$store.commit("change_state", ["mnav_close_class", ""]);
      $(".ozone-column").removeClass("moved");
      $(".my-ozone").removeClass("moved");
      if (this.isLogin) $this.$store.dispatch("setMyOzone", 1);
      else $this.$store.dispatch("setMyOzone", 0);
    },
    openZeroToHero() {
      $(".icon-left-open.zhh").toggleClass("opened");
      $(".zero-hero-column").toggleClass("moved");
      $(".zero-hero").toggleClass("moved");
    },
    moveMyOzone() {
      $(".ozone-column").addClass("moved");
      $(".my-ozone").addClass("moved");
      $("body").addClass("hidesb");
    },
    async toggleOverlay() {
      const $body = document.body;
      if (
        $body.classList.contains("overlay-open") &&
        this.overlayClass === "opened"
      ) {
        this.$store.dispatch("setOverlayClass", "");
        $body.classList.remove("overlay-open");
      }
      if (
        $body.classList.contains("overlay-open") &&
        this.postCreateOverlayClass === "opened"
      ) {
        this.$store.dispatch("setPostCreateOverlayClass", "");
        $body.classList.remove("overlay-open");
      }
      if (
        $body.classList.contains("overlay-open") &&
        this.editPostOverlayClass === "opened"
      ) {
        this.$store.commit("set_edit_post_overlay_class", "");
        $body.classList.remove("overlay-open");
      }
      // Hide Search Bar
      if (this.isSearching === true) {
        await this.$store.dispatch("setSearching", false);
        if (
          this.$route.name === "Home" ||
          this.$route.name === "O2hq" ||
          this.$route.name === "User" ||
          this.$route.name === "Tokens"
        ) {
          $(".custom-screen").addClass("full-screen");
        }
      }
    },
  },
};
$(document).click(function (f) {
  let $col_trigger = $(".col-trigger");
  let $my_ozone = $(".my-ozone");
  if (!f.target.dataset.mobile) {
    if (!$col_trigger.is(f.target) && $col_trigger.has(f.target).length === 0) {
      $(".icon-left-open.zhh").removeClass("opened");
      $(".zero-hero-column").removeClass("moved");
      $(".zero-hero").removeClass("moved");
    }
    if (!$my_ozone.is(f.target) && $my_ozone.has(f.target).length === 0) {
      // $(".ozone-column").removeClass("moved");
      // $(".my-ozone").removeClass("moved");
      $("body").removeClass("hidesb");
    }
  }
});
</script>
