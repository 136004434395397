export default {
  methods: {
    async addPressure(postID) {
      let data = {
        post_id: postID,
        changeValue: 1,
      };
      await this.$store.dispatch("changePressure", data);
      await this.$store.commit("VOTE_CHANGE", {
        postID: postID,
        vote: 1,
      });
      await this.$store.dispatch("voteCount", {
        post: postID,
        type: "plus",
      });
      await this.$store.dispatch("homeModule/voteRecord", postID);
    },
    async reducePressure(postID) {
      let data = {
        post_id: postID,
        changeValue: -1,
      };
      await this.$store.dispatch("changePressure", data);
      await this.$store.commit("VOTE_CHANGE", {
        postID: postID,
        vote: -1,
      });
      await this.$store.dispatch("voteCount", {
        post: postID,
        type: "minus",
      });
      await this.$store.dispatch("homeModule/voteRecord", postID);
    },
  },
};
