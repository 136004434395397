<template>
  <div class="content_inner_posts" @click="checkStatus">
    <div class="non-member-intro tx-c" v-if="!isLogin">
      <h2>WELCOME</h2>
      <p>
        O2 is a Weekly Competition to find inspiring images, videos, news  &amp; stories<br />
        <strong>CREATED BY YOU ~ VOTED BY YOU!</strong>
      </p>
      <p>
       ~ Step inside ~<br>
       <strong>Add your posts & vote on your favourites</strong>
      </p>
      <p class="tx-c mt-30">
        <a
          title="Login"
          class="show-panel button primary icon-lock-open pr-15 pl-10 mr-10"
          @click="moveSidebar(0)"
        >
          Login</a
        >
        <a
          title="Sign up"
          class="show-panel button primary icon-lock pr-15 pl-10 mr-10"
          @click="moveSidebar(3)"
        >
          Sign up
        </a>
        <router-link
          class="button primary pr-15 pl-15 ml-5 mr-5"
          to="/whatiso2"
          title="Find out more"
        >
          Find out more
        </router-link>
      </p>
    </div>
    <PostCreateOverlay />
    <div id="content" class="pt-20 content-t-m" role="main">
      <a
        class="
          show-panel
          button
          primary
          icon-upload-cloud
          right
          mt--5
          pl-15
          pr-20
          hideUnder575
        "
        @click="showPostCreateOverlay"
      >
        Add Post
      </a>
      <MatureAudienceLoginModal
        v-if="showLoginModal"
        @colseModal="colseModal"
      />
      <h2 class="inline-block akrobat">Pressure Pit</h2>
      <span
        class="cate-filter"
        v-b-hover="handleHover"
        v-if="availableCate.length > 0"
      >
        <b-button :class="isHovered ? 'not-collapsed' : 'collapsed'"
          >Categories <span class="icon-angle-down"></span>
        </b-button>
        <div class="selected-cate" v-if="selectedItem">
          <span><span class="icon-filter"></span> {{ selectedItem }} </span>
          <a @click="removeCategory" v-if="selectedItem">
            <i class="icon-cancel-circle"></i
          ></a>
        </div>
        <div v-else class="selected-cate">
          <span><span class="icon-picture"></span> All Posts </span>
        </div>
        <b-collapse id="collapse-1" class="show-cate" v-model="isHovered">
          <b-card>
            <div
              v-for="(cate, index) in availableCate"
              :key="index"
              class="single-cate"
              :class="{ active: selectedItem === cate }"
            >
              <label @click="selectCate(cate)">{{ cate }}</label>
              <!-- :disabled="!availableCate.includes(cate)"
              :data-title="`${
                !availableCate.includes(cate)
                  ? 'Category not available!'
                  : 'Select Category'
              }`"
              data-title-top -->
            </div>
          </b-card>
        </b-collapse>
      </span>
      <WallTabsWrap @changeTab="changeTab" />

      <LoaderOverlay v-if="isLoading" />

      <PressurePit
        :moduleName="`homeModule`"
        :paginate="true"
        @increment="increment"
        @decrement="decrement"
        @showOverlay="showOverlay"
      />

      <Overlay
        v-if="isOverlay"
        :post="selectedPost"
        :isOverlay="isOverlay"
        :hasNext="hasNext"
        :hasPrev="hasPrev"
        @increment="increment"
        @decrement="decrement"
        @close="closeOveraly"
        @removePost="removePost"
        @showOtherPost="showOtherPost"
        :new="'ahsdukhsuakjhdk'"
      />
      <EditPostOverlay
        v-if="editingPost"
        :post="selectedPost"
        @editPost="editPost"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import PressurePit from "@/components/shared/PressurePit";
import CreateOverlayMixin from "@/mixins/CreateOverlayMixin";
import WallTabsWrap from "../components/shared/WallTabsWrap.vue";
import Overlay from "@/components/shared/overlay/Overlay";
import EditPostOverlay from "../components/shared/overlay/EditPostOverlay.vue";
import LoaderOverlay from "../components/shared/overlay/LoaderOverlay.vue";
import $ from "jquery";
import changeMyOzoneMixin from "@/mixins/ChangeMyOzoneMixin";
import MatureAudienceLoginModal from "../components/modal/MatureAudienceLoginModal.vue";

export default {
  name: "Home",
  components: {
    PressurePit,
    WallTabsWrap,
    Overlay,
    EditPostOverlay,
    LoaderOverlay,
    MatureAudienceLoginModal,
  },

  mixins: [CreateOverlayMixin, changeMyOzoneMixin],

  data: () => {
    return {
      selectedPostId: 0,
      isOverlay: false,
      orderBy: null,
      isHovered: false,
    };
  },

  watch: {
    getpostId(newVal) {
      this.selectedPostId = newVal;
    },
  },

  computed: {
    ...mapGetters({ getpostId: "overlayModule/getpostId" }),
    ...mapState({
      isLogin: (state) => state.auth.isLogin,
      isLoading: (state) => state.auth.isLoading,
      isSearching: (state) => state.common.isSearching,
      activeTab: (state) => state.base.activeTab,
      posts: (state) => state.homeModule.posts,
      leaders: (state) => state.common.leaders,
      editingPost: (state) => state.uxModule.editingPost,
      categoryList: (state) => state.common.categoryList,
      selectedItem: (state) => state.common.selectedItem,
      availableCate: (state) => state.common.availableCate,
    }),
    hasNext() {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPost.post_id
      );
      if (index + 1 === this.posts.length) return false;
      return true;
    },
    hasPrev() {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPost.post_id
      );
      if (index === 0) return false;
      return true;
    },
    selectedPost() {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPostId
      );
      if (index === -1) {
        const leaderIndex = this.leaders.findIndex(
          (item) => item.post_id === this.selectedPostId
        );
        return { ...this.leaders[leaderIndex] };
      }
      return { ...this.posts[index] };
    },
  },

  created() {
    this.initialProfileData();
    this.$store.dispatch("allCategory", { module: "Home" });
    this.$store.commit("set_selected_cate", localStorage.getItem("category"));
  },

  methods: {
    handleHover(hovered) {
      this.isHovered = hovered;
    },
    async selectCate(cate) {
      this.isHovered = false;
      localStorage.setItem("category", cate);
      this.$store.commit("set_selected_cate", cate);
      await this.initialProfileData();
      await this.$store.dispatch("getLeaders");
    },
    async removeCategory() {
      localStorage.removeItem("category");
      this.$store.commit("set_selected_cate", "");
      await this.initialProfileData();
      await this.$store.dispatch("getLeaders");
    },
    async checkStatus() {
      if (this.isSearching === true) {
        await this.$store.dispatch("setSearching", false);
        if (
          this.$route.name === "Home" ||
          this.$route.name === "O2hq" ||
          this.$route.name === "User" ||
          this.$route.name === "Tokens"
        ) {
          $(".custom-screen").addClass("full-screen");
        }
      }
    },
    editPost(postData) {
      this.$store.dispatch("homeModule/editPost", { ...postData });
    },
    async initialProfileData() {
      this.$store.dispatch("resetActiveTab");
      const postData = await this.$store.dispatch(
        "homeModule/getAllAvailablePosts",
        {
          activeTab: 0,
          pageNumber: 0,
          orderBy: this.orderBy,
          selectedCategory: localStorage.getItem("category"),
        }
      );
      this.orderBy = postData.order_by;
      this.$store.dispatch("votingModule/setVotes", postData);
    },
    async changeTab(index) {
      const postData = await this.$store.dispatch(
        "homeModule/getAllAvailablePosts",
        {
          activeTab: index,
          pageNumber: 0,
          selectedCategory: localStorage.getItem("category"),
        }
      );
      this.$store.dispatch("votingModule/setVotes", postData);
      this.$store.dispatch("setActiveTab", index);
    },
    async increment(postID) {
      const res = await this.$store.dispatch("homeModule/changePressure", {
        post_id: postID,
        amount: 1,
      });
      await this.$store.dispatch("getLeaders");
      await this.$store.dispatch("homeModule/voteRecord", postID);
      await this.$store.dispatch("voteCount", {
        post: postID,
        type: "plus",
      });
      if (this.activeTab === 1) {
        await this.$store.dispatch("homeModule/getAllAvailablePosts", {
          activeTab: 1,
          pageNumber: 0,
          selectedCategory: localStorage.getItem("category"),
        });
      }
      if (res.status === 200) {
        const postData = this.posts.find((post) => post.post_id === postID);
        this.$store.dispatch("votingModule/setVotes", res.data);
        if (this.leaders.length < 10) {
          if (postData.votes === 1) {
            this.$store.dispatch("appendLeaderboard", postData);
            this.$store.dispatch("getLeaders");
          }
        }
        this.$store.commit("reduce_my_votes");
      }
    },
    async decrement(postID) {
      const res = await this.$store.dispatch("homeModule/changePressure", {
        post_id: postID,
        amount: -1,
      });
      await this.$store.dispatch("voteCount", {
        post: postID,
        type: "minus",
      });
      if (res.status === 200) {
        this.$store.dispatch("votingModule/setVotes", res.data);
        this.$store.commit("reduce_my_votes");
      }
    },
    async showOverlay(postId) {
      await this.$store.dispatch("overlayModule/getComments", postId);
      this.selectedPostId = postId;
      this.isOverlay = true;
    },
    async showOtherPost(num) {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPostId
      );
      this.selectedPostId = this.posts[index + num].post_id;
      await this.$store.dispatch(
        "overlayModule/getComments",
        this.posts[index + num].post_id
      );
      await this.$store.dispatch("overlayModule/StarCount", {
        post: this.posts[index + num].post_id,
      });
      if (this.isLogin) {
        await this.$store.dispatch("overlayModule/GetCommentLoveLikeData", {
          post: this.posts[index + num].post_id,
        });
        await this.$store.dispatch("overlayModule/GetLoveLikeData", {
          post: this.posts[index + num].post_id,
        });
      }
      await this.$store.dispatch("overlayModule/PostTrack", {
        post: this.posts[index + num].post_id,
        type: "post",
      });
    },
    closeOveraly() {
      this.isOverlay = false;
      this.$store.dispatch("changeState", ["overlayClass", ""]);
      $("body").removeClass("hero-overlay");
    },
    removePost() {
      this.isOverlay = false;
      this.$store.dispatch("homeModule/removePost", this.selectedPost.uuid);
    },
    moveSidebar(num) {
      let $this = this;
      $this.changeMyOzone(num);
      $("body").addClass("mpanel-open hidesb");
      $(".logged-out").addClass("hidesb");
      $(".my-ozone").addClass("moved");
      $(".ozone-column").addClass("moved");
      $this.$store.commit("change_state", ["mnav_class", "move"]);
      setTimeout(function () {
        $this.$store.commit("change_state", ["mnav_close_class", "slidem"]);
      }, 350);
    },
  },
};
</script>
