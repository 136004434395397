<template>
  <div>
    <div class="modal-overlay">
      <a title="Close" class="icon-cancel-1 modal-close" @click="closeModal"
        >CLOSE</a
      >
      <div class="modal-votes-expired">
        <div class="vote-circle">0</div>
        <h1 class="blk">No votes left</h1>
        <p>You've used all your voting power today.</p>
        <h2>You'll receive another <br />50 votes in</h2>
        <p>{{ closeExpire() }}</p>
        <h4 class="date_label">
          <i class="fa fa-clock-o" aria-hidden="true"></i>
          {{ settimeInterval() }}{{ timeDiff }}
        </h4>
      </div>
    </div>
  </div>
</template>
<script>
import * as moment from "moment";

export default {
  name: "VoteExpiredModal",

  data() {
    return {
      timeDiff: "",
    };
  },

  methods: {
    closeExpire() {
      const now = moment.utc()._d;
      const curr_time = moment(now).format("HH:mm:ss");
      const end_time = moment("00:00:00", "HH:mm:ss").format("HH:mm:ss");
      if (curr_time === end_time) {
        this.$emit("colseModal");
        this.$store.dispatch("getUser");
      }
    },
    closeModal() {
      this.$emit("colseModal");
    },
    settimeInterval() {
      setInterval(this.getTimeDiff, 500);
    },

    getTimeDiff() {
      let duration = moment.duration(
        moment().add(1, "days").startOf("day").diff(moment())
      );
      let diff_time = duration.asSeconds();
      let hours = Math.floor(diff_time / 3600);
      let minutes = Math.floor((diff_time - hours * 3600) / 60);
      let seconds = Math.floor(diff_time - hours * 3600 - minutes * 60);
      if (minutes.toString().length == 1) {
        minutes = "0" + minutes;
      }
      this.timeDiff = hours + "h : " + minutes + "m : " + seconds + "s";
      return hours + "h : " + minutes + "m : " + seconds + "s";
    },
  },
};
</script>