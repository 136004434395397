<template>
  <ul>
    <li
      :class="{ selected: $route.name === 'Home' && !isActive }"
      @click="hideSidebar"
    >
      <router-link title="The home for all Posts this week" to="/">
        Pressure Pit
      </router-link>
    </li>
    <li
      :class="{ selected: $route.name === 'Leaderboard' && !isActive }"
      @click="hideSidebar"
    >
      <router-link title="This week's Leaderboard" to="/leaderboard">
        Leaderboard
      </router-link>
    </li>
    <li
      :class="{ selected: $route.name === 'Winners' && !isActive }"
      @click="hideSidebar"
    >
      <router-link title="Previous weeks' Winners " to="/winners">
        Winners
      </router-link>
    </li>
    <li
      ref="share"
      class="p-r invite-trigger"
      @click="isActive = !isActive"
      :class="{ selected: isActive }"
    >
      <a title="Share O2">Share</a>
      <div v-show="isActive" class="invite-wrap">
        <div class="social-share-links">
          <a @click="shareFacebook()" target="_blank" class="icon-box fb">
            <i class="icon-facebook"></i
          ></a>
          <a @click="shareTwitter()" target="_blank" class="icon-box tw">
            <i class="icon-twitter"></i
          ></a>
          <a @click="sharePinterest()" target="_blank" class="icon-box pi">
            <i class="icon-pinterest-circled"></i
          ></a>
          <a @click="shareLinkedin()" target="_blank" class="icon-box li">
            <i class="icon-linkedin"></i
          ></a>
          <a
            @click="modalShow = !modalShow"
            target="_blank"
            class="icon-box mi"
          >
            <i class="icon-mail-alt"></i
          ></a>
          <modal
            :show="modalShow"
            @toggle-modal="modalShow = !modalShow"
            @handle-submit="handleSubmit"
          />
        </div>
      </div>
    </li>

    <li
      v-if="isLogin"
      :class="{ selected: $route.name === 'O2hq' && !isActive }"
      @click="hideSidebar"
    >
      <router-link title="O2 Headquarters" to="/o2hq"> HQ </router-link>
    </li>
  </ul>
</template>

<script>
import { mapState } from "vuex";
import $ from "jquery";
import modal from "../shared/modal/modal.vue";

export default {
  name: "Navbar",
  components: {
    modal,
  },
  computed: {
    ...mapState({
      isLogin: (state) => state.auth.isLogin,
    }),
    siteUrl() {
      return process.env.VUE_APP_PREFIX;
    },
  },
  created() {
    document.addEventListener("click", this.close);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
  data() {
    return {
      isActive: false,
      modalShow: false,
    };
  },
  methods: {
    close(e) {
      const shareEl = this.$refs.share;
      if (!shareEl.contains(e.target)) {
        if (this.isActive) {
          this.isActive = false;
        }
      }
    },
    shareFacebook() {
      window.open(
        "https://www.facebook.com/sharer.php?u=" + process.env.VUE_APP_PREFIX,
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
    },
    shareTwitter() {
      window.open(
        "https://twitter.com/intent/tweet?url=" + process.env.VUE_APP_PREFIX,
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
    },
    shareLinkedin() {
      window.open(
        "https://www.linkedin.com/shareArticle?mini=true&url=" +
          process.env.VUE_APP_PREFIX,
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
    },
    sharePinterest() {
      window.open(
        "http://pinterest.com/pin/create/button/?url=" +
          process.env.VUE_APP_PREFIX,
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
    },
    handleSubmit(data) {
      data.route = this.$route.path;
      if (this.isLogin) {
        data.user = "yes";
      } else {
        data.user = "no";
      }
      this.modalShow = false;
      this.$store.dispatch("overlayModule/o2SiteSharing", data);
    },
    moveSidebar() {
      let $this = this;
      $this.$store.commit("change_state", ["mnav_class", "move"]);
      $this.mnav_class = "move";
      setTimeout(function () {
        $this.$store.commit("change_state", ["mnav_close_class", "slidem"]);
        $("body").addClass("mpanel-open");
      }, 350);
    },
    hideSidebar() {
      let $this = this;
      $this.$emit("closeLoginPanel");
      $("body").removeClass("mpanel-open");
      $this.$store.commit("change_state", ["mnav_class", ""]);
      $this.$store.commit("change_state", ["mnav_close_class", ""]);
      if (this.isLogin) $this.$store.dispatch("setMyOzone", 1);
      else $this.$store.dispatch("setMyOzone", 0);
    },
  },
};
</script>