<template>
  <div
    class="wall-tabs-wrap"
    :class="this.$route.name === 'User' ? 'no-space' : ''"
  >
    <div class="filt-wrap">
      <div class="w-tab-inner" v-if="this.$route.name === 'Leaderboard'">
        <a
          v-for="(tab, index) in leaderTab"
          :key="index"
          style=""
          class="button filt-1 tertiary tip-tab"
          :class="[activeTab === index ? 'active' : '']"
          @click="$emit('changeTab', index)"
        >
          <span :data-title="tab.title" data-title-top>
            {{ tab.text1 }} <span v-if="tab.text2 != ''">{{ tab.text2 }}</span>
          </span>
        </a>
      </div>
      <div
        class="w-tab-inner three-tab"
        v-else-if="this.$route.name === 'FavoritePosts'"
      >
        <a
          v-for="(tab, index) in userTabs"
          :key="index"
          style=""
          class="button filt-1 tertiary tip-tab"
          :class="[activeTab === index ? 'active' : '']"
          @click="changeUserTab(index)"
        >
          <span :data-title="tab.title" data-title-top>
            {{ tab.text1 }}
            <div v-if="tab.text2 != ''">{{ tab.text2 }}</div>
            <div v-if="index == 2">
              <i
                v-if="isAtoZ === true"
                class="icon-up-open"
                aria-hidden="true"
              ></i>
              <i v-else class="icon-down-open" aria-hidden="true"></i>
            </div>
          </span>
        </a>
      </div>
      <div
        class="w-tab-inner three-tab"
        v-else-if="this.$route.name === 'User'"
      >
        <a
          v-for="(tab, index) in userTabs"
          :key="index"
          style=""
          class="button filt-1 tertiary tip-tab"
          :class="[activeTab === index ? 'active' : '']"
          @click="changeUserTab(index)"
        >
          <span :data-title="tab.title" data-title-top>
            {{ tab.text1 }}
            <span v-if="tab.text2 != ''">{{ tab.text2 }} </span>
            <div v-if="index == 2">
              <i
                v-if="isAtoZ === true"
                class="icon-up-open"
                aria-hidden="true"
              ></i>
              <i v-else class="icon-down-open" aria-hidden="true"></i>
            </div>
          </span>
        </a>
      </div>
      <div
        class="w-tab-inner"
        v-else-if="
          this.$route.name !== 'Home' &&
          this.$route.name !== 'Leaderboard' &&
          this.$route.name !== 'FavoritePosts' &&
          this.$route.name !== 'User'
        "
      >
        <a
          v-for="(tab, index) in profileTabs"
          :key="index"
          style=""
          class="button filt-1 tertiary tip-tab"
          :class="[activeTab === index ? 'active' : '']"
          @click="changeProfileTab(index)"
        >
          <span :data-title="tab.title" data-title-top>
            {{ tab.text1 }}
            <span v-if="tab.text2 != ''">{{ tab.text2 }} </span>
            <div v-if="index == 3">
              <i
                v-if="profileIsAtoZ === true"
                class="icon-up-open"
                aria-hidden="true"
              ></i>
              <i v-else class="icon-down-open" aria-hidden="true"></i>
            </div>
          </span>
        </a>
      </div>
      <div class="w-tab-inner" v-else-if="this.$route.name === 'Home'">
        <a
          v-for="(tab, index) in tabs"
          :key="index"
          style=""
          class="button filt-1 tertiary tip-tab"
          :class="[activeTab === index ? 'active' : '']"
          @click="$emit('changeTab', index)"
        >
          <span :data-title="tab.title" data-title-top>
            {{ tab.text1 }} <span v-if="tab.text2 != ''">{{ tab.text2 }}</span>
          </span>
        </a>
      </div>
    </div>

    <div v-if="isLogin">
      <div v-if="this.$route.name !== 'User'" class="tog-wrap">
        <span data-title="Show/Hide Vote Block" data-title-left>
          <a
            class="button filt-1 filt-2 JS-vote-togl tip-tab"
            @click="togglePressure"
          >
            %
          </a>
        </span>
        <span data-title="Toggle View" data-title-left class="view-change">
          <a class="button filt-1 filt-2 icon-exchange" @click="toggleListView">
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "WallTabsWrap",
  computed: {
    ...mapState({
      isLogin: (state) => state.auth.isLogin,
      activeTab: (state) => state.base.activeTab,
      tabs: (state) => state.staticLabels.tabs,
      leaderTab: (state) => state.staticLabels.leaderTab,
      favoriteTab: (state) => state.staticLabels.favoriteTab,
      profilePostTab: (state) => state.staticLabels.profilePostTab,
    }),
    isAtoZ() {
      return this.userTabs[2].active;
    },
    profileIsAtoZ() {
      return this.profileTabs[3].active;
    },
  },
  mounted() {
    this.userTabs = this.favoriteTab.map((tab, idx) => {
      if (idx == 2) {
        return { ...tab, active: true };
      }
      return tab;
    });

    this.profileTabs = this.profilePostTab.map((tab, idx) => {
      if (idx === 3) {
        return { ...tab, active: true };
      }
      return tab;
    });
  },
  data() {
    return {
      userTabs: [],
      profileTabs: [],
    };
  },
  methods: {
    togglePressure() {
      this.$store.dispatch("togglePressure");
    },
    toggleListView() {
      this.$store.dispatch("toggleListView");
    },
    changeUserTab(index) {
      if (this.activeTab == 2 && index == 2) {
        if (this.isAtoZ) {
          this.userTabs[index].text1 = "Sort Z-A";
          this.userTabs[index].active = false;
          this.$emit("getByTitle", true);
        } else {
          this.userTabs[index].text1 = "Sort A-Z";
          this.userTabs[index].active = true;
          this.$emit("getByTitle", false);
        }
      } else {
        this.userTabs[index].active = true;
        this.userTabs[2].text1 = "Sort A-Z";
        this.$emit("changeTab", index);
      }
    },
    changeProfileTab(index) {
      if (this.activeTab === 3 && index === 3) {
        if (this.profileIsAtoZ) {
          this.profileTabs[index].text1 = "Sort Z-A";
          this.profileTabs[index].active = false;
          this.$emit("getByPostTitle", true);
        } else {
          this.profileTabs[index].text1 = "Sort A-Z";
          this.profileTabs[index].active = true;
          this.$emit("getByPostTitle", false);
        }
      } else {
        this.profileTabs[3].text1 = "Sort A-Z";
        this.profileTabs[index].active = true;
        this.$emit("changeTab", index);
      }
    },
  },
};
</script>