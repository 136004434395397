<template>
  <div class="after-payment-page payment-fail">
    <div class="ap-wrap">
      <img src="@/assets/images/false.png" />
      <div class="ap-info">
        <h5>Payment failed</h5>
        <p>
          We aren't able to process your payment. <br />
          Please try again.
        </p>
        <a class="button primary outline" href="/">Try again</a>
      </div>
      <p>
        We take security of your data! If you have any questions, please email
        <a href="mailto:admin@o2feed.com">admin@o2feed.com</a>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FailurePayment",

  mounted() {
    localStorage.removeItem("paymentIntent");
    localStorage.removeItem("paymentID");
  },
};
</script>