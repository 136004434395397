import axios from "axios";

export default {
  state: {
    isSearching: false,
    myOzone: 0,
    overlayClass: "",
    sharedOverlayClass: "",
    postCreateOverlayClass: "",
    editPostOverlayClass: "",
    changedTimeMyOzone: "",
    posts: [],
    drafts: [],
    totalDrafts: 0,
    searchedPeople: [],
    myComments: [],
    mySubComments: [],
    leaders: {},
    totalLeaders: 0,
    overlayPost: {},
    myFavorites: [],
    totalVotes: 0,
    totalNonVotePosts: 0,
    min_votes: 0,
    max_votes: 0,
    isListView: false,
    showPressure: true,
    pageNumber: 0,
    otherUserUUID: "",
    isDraft: false,
    otherUser: {
      first_name: "",
      last_name: "",
      email: "",
      votes: "",
      avatar: "",
      bio: "",
      city: "",
      country: "",
      site_url: "",
      facebook_url: "",
      instagram_url: "",
      twitter_url: "",
      linkedin_url: "",
      pinterest_url: "",
      subscription_email: "",
    },
    totalComments: 0,
    totalSubComment: 0,
    totalCommentsCount: 0,
    setBanPeriod: {},
    categoryList: [],
    selectedItem: "",
    availableCate: [],
  },

  mutations: {
    set_selected_cate(state, data) {
      state.selectedItem = data;
    },
    set_ban_time(state, data) {
      state.setBanPeriod = data.data;
    },
    add_favorites(state, data) {
      if (state.myFavorites.length > 0) {
        state.myFavorites.push(data.post_id);
      } else {
        state.myFavorites[0] = data.post_id;
      }
    },
    cal_pressure(state) {
      let index = 0;
      for (index = 0; index < state.posts.length; index++) {
        let pressure = 0.0;
        if (state.posts[index].post_id === state.max_votes) {
          pressure = 99.99;
        } else if (state.posts[index].post_id === state.min_votes) {
          pressure = 0.01;
        } else {
          pressure =
            (state.posts[index].votes / state.totalVotes) * 100 +
            state.posts[index].post_id * 0.01;
        }
        if (state.posts[index].pressure) {
          state.posts[index].pressure = pressure.toFixed(2);
        } else {
          Object.assign(state.posts[index], { pressure: pressure.toFixed(2) });
        }
      }
      for (index = 0; index < state.leaders.length; index++) {
        let pressure = 0.0;
        if (state.leaders[index].post_id === state.max_votes) {
          pressure = 99.99;
        } else if (state.leaders[index].post_id === state.min_votes) {
          pressure = 0.01;
        } else {
          if (state.totalVotes) {
            pressure =
              (state.leaders[index].votes / state.totalVotes) * 100 +
              state.leaders[index].post_id * 0.01;
          }
        }
        if (state.leaders[index].pressure) {
          state.leaders[index].pressure = pressure.toFixed(2);
        } else {
          Object.assign(state.leaders[index], {
            pressure: pressure.toFixed(2),
          });
        }
      }
    },
    change_min_max(state, data) {
      state.min_votes = data.min_votes;
      state.max_votes = data.max_votes;
    },
    change_pressure(state, data) {
      let post_index = 0;
      let start_index = 0;
      let end_index = 0;
      let j = 0;
      for (j in state.posts) {
        if (data.post_id === state.posts[j].post_id) {
          if (!(data.changeValue === -1 && state.posts[j].votes === 0)) {
            state.posts[j].votes += data.changeValue;
            state.totalVotes += data.changeValue;
            post_index = j;
          }
          break;
        }
      }
      for (let i in state.leaders) {
        if (data.post_id === state.leaders[i].post_id) {
          if (!(data.changeValue === -1 && state.leaders[i].votes === 0)) {
            state.leaders[i].votes += data.changeValue;
            state.totalVotes += data.changeValue;
            post_index = i;
          }
          break;
        }
      }
      // If user clicks + button, compare from 0 to current post, else compare from current post to last post
      if (data.changeValue > 0) {
        start_index = 0;
        end_index = post_index;
        for (let i = start_index; i < end_index; i++) {
          if (state.leaders[post_index].votes > state.leaders[i].votes) {
            let buf = state.leaders[post_index];
            state.leaders.splice(post_index, 1);
            state.leaders.splice(i, 0, buf);
            break;
          } else if (
            state.leaders[post_index].votes === state.leaders[i].votes
          ) {
            let current_post_date = new Date(
              state.leaders[post_index].post_date
            );
            let buf_post_date = new Date(state.leaders[i].post_date);
            if (current_post_date < buf_post_date) {
              let buf = state.leaders[post_index];
              state.leaders.splice(post_index, 1);
              state.leaders.splice(i, 0, buf);
              break;
            }
          }
        }
      } else {
        start_index = parseInt(post_index);
        end_index = parseInt(state.leaders.length) - 1;
        for (let i = end_index; i > start_index; i--) {
          if (state.leaders[post_index].votes < state.leaders[i].votes) {
            let buf = state.leaders[post_index];
            state.leaders.splice(post_index, 1);
            state.leaders.splice(i, 0, buf);
            break;
          } else if (
            state.leaders[post_index].votes === state.leaders[i].votes
          ) {
            let current_post_date = new Date(
              state.leaders[post_index].post_date
            );
            let buf_post_date = new Date(state.leaders[i].post_date);
            if (current_post_date > buf_post_date) {
              let buf = state.leaders[post_index];
              state.leaders.splice(post_index, 1);
              state.leaders.splice(i, 0, buf);
              break;
            }
          }
        }
      }
    },
    set_other_user_uuid(state, data) {
      state.otherUserUUID = data;
    },
    set_other_user(state, data) {
      state.otherUser.first_name =
        data.first_name === "null" ? "" : data.first_name;
      state.otherUser.last_name =
        data.last_name === "null" ? "" : data.last_name;
      state.otherUser.email = data.email === "null" ? "" : data.email;
      state.otherUser.votes = data.votes;
      state.otherUser.avatar =
        data.avatar === "null"
          ? "https://test-o2.s3-ap-southeast-2.amazonaws.com/avatars/logo-160.png"
          : data.avatar;
      state.otherUser.bio = data.bio === "null" ? "" : data.bio;
      state.otherUser.city = data.city === "null" ? "" : data.city;
      state.otherUser.country = data.country === "null" ? "" : data.country;
      state.otherUser.site_url = data.site_url === "null" ? "" : data.site_url;
      state.otherUser.facebook_url =
        data.facebook_url === "null" ? "" : data.facebook_url;
      state.otherUser.instagram_url =
        data.instagram_url === "null" ? "" : data.instagram_url;
      state.otherUser.twitter_url =
        data.twitter_url === "null" ? "" : data.twitter_url;
      state.otherUser.linkedin_url =
        data.linkedin_url === "null" ? "" : data.linkedin_url;
      state.otherUser.pinterest_url =
        data.pinterest_url === "null" ? "" : data.pinterest_url;
      // Notifications
      state.otherUser.new_follower_notification =
        data.new_follower_notification;
      state.otherUser.new_comment_notification = data.new_comment_notification;
      state.otherUser.friend_post_notification = data.friend_post_notification;

      state.otherUser.subscription_email = data.subscription_email;
    },
    loadmore_posts(state, data) {
      state.posts = state.posts.concat(data.post_data);
      state.totalVotes = data.total_votes;
      state.totalNonVotePosts = data.total_non_votes_posts;
      state.min_votes = data.min_votes;
      state.max_votes = data.max_votes;
      state.order_by = data.query && data.pageNumber;
    },

    push_image(state, data) {
      state.posts.push(data);
    },
    other_post(state, data) {
      for (let post in state.posts) {
        if (state.posts[post].uuid === data.uuid) {
          state.overlayPost =
            state.posts[parseInt(post) + parseInt(data.direction)];
          break;
        }
      }
    },
    remove_post(state, post) {
      const index = state.posts.findIndex(
        (item) => item.post_id === post.post_id
      );
      state.posts.splice(index, 1);
    },
    remove_leaders(state, post) {
      const index = state.leaders.findIndex(
        (item) => item.post_id === post.post_id
      );
      state.leaders.splice(index, 1);
    },
    remove_draft(state, post) {
      const index = state.drafts.findIndex(
        (item) => item.post_id === post.post_id
      );
      if (index !== -1) {
        state.drafts.splice(index, 1);
        state.totalDrafts--;
      }
      if (!state.drafts) state.isDraft = false;
    },
    edit_draft(state, post) {
      const index = state.drafts.findIndex(
        (item) => item.post_id === post.post_id
      );
      state.drafts.splice(index, 1, { ...post });
    },
    remove_comment(state, data) {
      state.myComments[data.index1].comments.splice(data.index2, 1);
      if (state.myComments[data.index1].comments.length === 0) {
        state.myComments.splice(data.index1, 1);
      }
    },
    remove_sub_comment(state, data) {
      state.myComments[data.index].comments[data.idx].sub_comm.splice(
        data.sub_idx,
        1
      );
      state.myComments[data.index].comments[data.idx].total_sub_comm =
        state.myComments[data.index].comments[data.idx].total_sub_comm - 1;
    },
    reset_page_number(state) {
      state.pageNumber = 0;
    },
    set_my_comments(state, data) {
      state.myComments = data.comments;
      state.totalComments = data.comment_count;
      state.pageNumber = 1;
      state.totalCommentsCount = data.totalCommentsCount;
    },
    set_initial_my_comments(state) {
      state.myComments = [];
      state.totalComments = 0;
      state.totalCommentsCount = 0;
    },
    SET_COMMENTS_COUNT(state, data) {
      state.totalComments = state.totalComments + data;
    },
    SET_TOTAL_COMMENTS_COUNT(state, data) {
      state.totalCommentsCount = state.totalCommentsCount + data;
    },
    set_loaded_comment(state, data) {
      if (data.comments) {
        state.myComments = state.myComments.concat(data.comments);
        state.pageNumber += 1;
      }
    },
    SET_LOADED_MY_SUB_COMENTS(state, data) {
      const index = state.myComments.findIndex(
        (item) => item.post_id === data.post_id
      );
      const idx = state.myComments[index].comments.findIndex(
        (item) => item.comment_id === data.data.comm_id
      );
      state.myComments[index].comments[idx].sub_comm = state.myComments[
        index
      ].comments[idx].sub_comm.concat(data.data.sub_comments);
      state.totalSubComment = data.data.total_sub_comm;
      state.myComments[index].comments[idx].curr_page =
        state.myComments[index].comments[idx].curr_page + 1;
    },
    set_favorites(state, data) {
      state.myFavorites = data;
    },
    set_post(state, data) {
      state.posts = data.post_data;
      state.totalVotes = data.total_votes;
      state.totalNonVotePosts = data.total_non_votes_posts;
      state.min_votes = data.min_votes;
      state.max_votes = data.max_votes;
      state.pageNumber = 1;
    },
    INCREASE_DRAFT(state, data) {
      state.totalDrafts = state.totalDrafts + data;
    },
    set_draft(state, data) {
      state.isDraft = true;
      if (data.post_data) {
        state.drafts = data.post_data;
        state.totalDrafts = data.post_count;
        state.pageNumber = 1;
      }
    },
    set_loaded_drafts(state, data) {
      if (data.post_data) {
        state.drafts = state.drafts.concat(data.post_data);
        state.pageNumber += 1;
      }
    },

    set_loaded_leaders(state, data) {
      state.leaders = state.leaders.concat(data.post_data);
      state.pageNumber += 1;
    },
    set_leaders(state, data) {
      state.leaders = data.post_data;
      state.totalVotes = data.total_votes;
      state.totalNonVotePosts = data.total_non_votes_posts;
      state.min_votes = data.min_votes;
      state.max_votes = data.max_votes;
      state.totalLeaders = data.post_count;
      state.pageNumber = 1;
    },
    VOTE_CHANGE(state, data) {
      const index = state.leaders.findIndex(
        (post) => post.post_id === data.postID
      );
      state.leaders[index].votes += data.vote;
    },
    edit_leaders(state, payload) {
      const index = state.leaders.findIndex(
        ({ post_id }) => post_id === payload.post_id
      );
      state.leaders.splice(index, 1, { ...payload });
    },

    set_searching(state, data) {
      state.isSearching = data;
    },
    set_my_ozone(state, data) {
      state.myOzone = data;
    },
    set_overlay_post(state, data) {
      state["overlayPost"] = data;
    },
    set_overlay_post_by_uuid(state, data) {
      state.overlayPost = data.post_data[0];
      if (state.overlayPost.post_id === data.max_votes) {
        state.overlayPost.pressure = 99.99;
      } else if (state.overlayPost.post_id === data.min_votes) {
        state.overlayPost.pressure = 0.01;
      } else if (state.overlayPost.votes > 0) {
        state.overlayPost.pressure =
          (state.overlayPost.votes / data.total_votes) * 100;
      } else {
        state.overlayPost.pressure = state.overlayPost.post_id / 100;
      }
    },
    set_overlay_class(state, data) {
      state.overlayClass = data;
    },
    set_shared_overlay_class(state, data) {
      state.sharedOverlayClass = data;
    },
    set_edit_post_overlay_class(state, data) {
      state.editPostOverlayClass = data;
    },
    set_post_create_overlay_class(state, data) {
      state.postCreateOverlayClass = data;
    },
    set_changed_time_my_ozone(state, data) {
      state.changedTimeMyOzone = data;
    },
    set_searched(state, data) {
      state.searchedPeople = data;
    },
    toggle_pressure(state) {
      state.showPressure = !state.showPressure;
    },
    toggle_listview(state) {
      state.isListView = !state.isListView;
    },
    change_state(state, data) {
      state[data[0]] = data[1];
    },
    append_leaderboard(state, data) {
      state.leaders = [...state.leaders, data];
    },
  },
  actions: {
    async setBanTime({ commit }, postID) {
      const tmp = await axios({
        url: "/posts/set-ban-time/",
        method: "post",
        data: {
          post_id: postID,
        },
      })
        .then((response) => {
          commit("set_ban_time", response.data);
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      return tmp;
    },
    async addComment({ commit }, data) {
      let add_comment = await axios({
        url: "/posts/add-comment/",
        method: "post",
        data: data,
      })
        .then((response) => {
          commit("cal_pressure");
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
      return add_comment;
    },
    async addSubComment({ commit }, data) {
      let add_sub_comment = await axios({
        url: "/posts/add-sub-comment/",
        method: "POST",
        data: data,
      })
        .then((response) => {
          commit("cal_pressure");
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
      return add_sub_comment;
    },
    async addToFavorites({ commit }, data) {
      await axios({
        url: "follower/add-favorite/",
        data: data,
        method: "POST",
      })
        .then((response) => {
          if (response.status === 200) {
            commit("add_favorites", data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async changePressure({ commit }, data) {
      await axios({
        url: "/posts/change-pressure/",
        method: "post",
        data: data,
      })
        .then((response) => {
          commit("change_min_max", response.data);
          commit("cal_pressure");
          commit("change_pressure", data);
          commit("reduce_my_votes");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeState({ commit }, data) {
      commit("change_state", data);
    },
    getMyFavorites({ commit, state }, activeTab) {
      axios({
        url: "/follower/my-favorites/",
        method: "POST",
        data: {
          pageNumber: state.pageNumber,
          activeTab: activeTab,
        },
      })
        .then((response) => {
          commit("set_post", response.data);
          commit("cal_pressure");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPostByUser({ commit, state }, activeTab) {
      axios({
        url: "posts/posts-by-user/",
        data: {
          uuid: state.otherUserUUID,
          pageNumber: state.pageNumber,
          activeTab: activeTab,
        },
        method: "POST",
      })
        .then((response) => {
          if (response.data !== "No have next page") {
            commit("set_post", response.data.post_data);
            commit("cal_pressure");
            let userData = response.data.user_data;
            let header_text = {
              first_name:
                userData.first_name === "null" ? "" : userData.first_name,
              last_name:
                userData.last_name === "null" ? "" : userData.last_name,
              city: userData.city === "null" ? "" : userData.city,
              country: userData.country === "null" ? "" : userData.country,
            };
            commit("set_siteheader", header_text);
            commit("set_other_user", userData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setOverlayPost({ commit, state }, data) {
      let idx = 0;
      if (data[0]) {
        idx = state.leaders.findIndex((post) => post.post_id === data[1]);
        commit("set_overlay_post", state.leaders[idx]);
      } else {
        idx = state.posts.findIndex((post) => post.post_id === data[1]);
        commit("set_overlay_post", state.posts[idx]);
      }
    },
    async openOverlayByUUID(_, data) {
      const tmp = await axios({
        url: "posts/get/?uuid=" + data.uuid,
        method: "GET",
      })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      return tmp;
    },
    setOverlayPostByUrl({ commit }, overlayPost) {
      commit("set_overlay_post", overlayPost);
    },
    async follow({ commit }, data) {
      await axios({
        url: "follower/follow/",
        data: data,
        method: "POST",
      })
        .then((response) => {
          commit("add_following", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMyComments({ commit }) {
      axios({
        url: "/posts/get-mycomments/?page_num=0",
        method: "get",
      }).then((response) => {
        if (response.data !== "no comments") {
          commit("set_my_comments", response.data);
        } else {
          commit("set_initial_my_comments");
        }
      });
    },
    async getUnreadComments(data, commData) {
      await axios({
        url: "/posts/get-unreads/",
        method: "POST",
        data: {
          post_id: commData.post_id,
          comm_id: commData.comm_id,
        },
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadMoreComments({ commit, dispatch, state }) {
      dispatch("setStatus", true, { root: true });
      axios({
        url: "/posts/get-mycomments/?page_num=" + state.pageNumber,
        method: "get",
      }).then((response) => {
        dispatch("setStatus", false, { root: true });
        commit("set_loaded_comment", response.data);
      });
    },
    loadMoreSubComments({ commit }, data) {
      axios({
        url:
          "/posts/get-my-sub-comments/?comm_id=" +
          data.comm_id +
          "&page_num=" +
          data.subPageNum,
      }).then((response) => {
        const comm_data = { data: response.data, post_id: data.post_id };

        commit("SET_LOADED_MY_SUB_COMENTS", comm_data);
      });
    },
    async getFavorites({ commit }) {
      await axios({
        url: "follower/get-favorite-ids/",
        method: "GET",
      })
        .then((response) => {
          commit("set_favorites", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMyPosts({ commit, state }, activeTab) {
      axios({
        url: "/posts/myposts/",
        method: "POST",
        data: {
          pageNumber: state.pageNumber,
          activeTab: activeTab,
        },
      })
        .then((response) => {
          commit("set_post", response.data);
          commit("cal_pressure");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMyDraft({ commit, state }) {
      axios({
        url: "/posts/mydrafts/",
        method: "POST",
        data: {
          pageNumber: state.pageNumber,
        },
      })
        .then((response) => {
          commit("set_draft", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadMoreDrafts({ commit, dispatch, state }) {
      dispatch("setStatus", true);
      axios({
        url: "/posts/mydrafts/",
        method: "POST",
        data: {
          pageNumber: state.pageNumber,
        },
      })
        .then((response) => {
          dispatch("setStatus", false);
          commit("set_loaded_drafts", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadMoreLeaders({ commit, dispatch, state }) {
      dispatch("setStatus", true);
      axios({
        url:
          "/posts/zero-hero/?pageNumber=" +
          state.pageNumber +
          "&category=" +
          localStorage.getItem("category"),
        method: "get",
      })
        .then((response) => {
          dispatch("setStatus", false);
          commit("set_loaded_leaders", response.data);
          commit("cal_pressure");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPressurePit({ commit, state }, activeTab) {
      axios({
        url: "/posts/pressurepit/",
        method: "POST",
        data: {
          pageNumber: state.pageNumber,
          activeTab: activeTab,
        },
      })
        .then((response) => {
          commit("set_post", response.data);
          commit("cal_pressure");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getLastestPosts({ commit }) {
      axios({
        url: "/posts/latest/",
        method: "get",
      })
        .then((response) => {
          commit("set_post", response.data);
          commit("cal_pressure");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRisingStars({ commit }) {
      axios({
        url: "/posts/rising-stars/",
        method: "get",
      })
        .then((response) => {
          commit("set_post", response.data);
          commit("cal_pressure");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getExpiringSoon({ commit }) {
      axios({
        url: "/posts/expiring-soon/",
        method: "get",
      })
        .then((response) => {
          commit("set_post", response.data);
          commit("cal_pressure");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getShuffle({ commit }) {
      axios({
        url: "/posts/shuffle/",
        method: "get",
      })
        .then((response) => {
          commit("set_post", response.data);
          commit("cal_pressure");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getLeaders({ commit }) {
      axios({
        url:
          "/posts/zero-hero/?pageNumber=" +
          0 +
          "&category=" +
          localStorage.getItem("category"),
        method: "get",
        // params: {
        //   pageNumber: 0,
        //   category: localStorage.getItem("category"),
        // },
      })
        .then((response) => {
          commit("set_leaders", response.data);
          commit("cal_pressure");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSearchResults({ commit }, data) {
      axios({
        url: "/posts/search/",
        method: "POST",
        data: data,
      })
        .then((response) => {
          if (data.filter !== 0) {
            commit("set_post", response.data);
            commit("cal_pressure");
          } else {
            commit("set_searched", response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pushImage({ commit }, data) {
      commit("push_image", data);
      commit("cal_pressure");
    },
    loadMore({ commit, state, dispatch }, data) {
      let url = "";
      switch (data.routerName) {
        case "Home":
          url = "/posts/pressurepit/";
          break;
        case "Leaderboard":
          url = "/posts/pressurepit/";
          break;
        case "FavoritePosts":
          url = "/follower/my-favorites/";
          break;
        case "Profile":
          dispatch("profileModule/loadMorePosts");
          return;
        case "User":
          url = state.isDraft ? "posts/mydrafts/" : "posts/myposts/";
          break;
        default:
          url = "/posts/shuffle/";
      }
      axios({
        url: url,
        data: {
          pageNumber: state.pageNumber,
          activeTab: data.activeTab,
          uuid: state.otherUserUUID,
        },
        method: "POST",
      })
        .then((response) => {
          if (response.data !== "No have next page") {
            commit("loadmore_posts", response.data);
            commit("cal_pressure");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removePost({ commit }, post) {
      axios({
        url: "posts/remove-post/",
        method: "GET",
        params: {
          uuid: post.uuid,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            commit("remove_post", post);
            commit("remove_leaders", post);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeDraft({ commit }, post) {
      axios({
        url: "posts/remove-post/",
        method: "GET",
        params: {
          uuid: post.uuid,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            commit("remove_draft", post);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editDraft({ commit }, post) {
      commit("edit_draft", post);
      axios({
        url: "post/update-post/",
        method: "POST",
        data: { uuid: post.uuid },
      })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async removeComment({ commit }, data) {
      const res = await axios({
        url: "posts/remove-comment/",
        method: "POST",
        data: {
          comment_id: data.comment_id,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            commit("remove_comment", data);
          }
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
      return res;
    },
    async removeSubComment({ commit }, data) {
      const res = await axios({
        url: "posts/remove-sub-comment/",
        method: "POST",
        data: {
          sub_comment_id: data.sub_comment_id,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            commit("remove_sub_comment", data);
          }
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
      return res;
    },
    setSearching({ commit }, status) {
      commit("set_searching", status);
    },
    setMyOzone({ commit }, status) {
      commit("set_my_ozone", status);
    },
    setOverlayClass({ commit }, status) {
      commit("set_overlay_class", status);
    },
    setSharedOverlayClass({ commit }, status) {
      commit("set_shared_overlay_class", status);
    },
    setPostCreateOverlayClass({ commit }, status) {
      commit("set_post_create_overlay_class", status);
    },
    async setOverlayPostByUUID({ commit }, uuid) {
      const tmp = await axios({
        url: "posts/get/?uuid=" + uuid,
        method: "GET",
      })
        .then((response) => {
          let overlayPost = response.data;
          commit("set_overlay_post_by_uuid", overlayPost);
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      return tmp;
    },
    setChangedTimeMyOzone({ commit }, status) {
      commit("set_changed_time_my_ozone", status);
    },
    togglePressure({ commit }) {
      commit("toggle_pressure");
    },
    toggleListView({ commit }) {
      commit("toggle_listview");
    },
    resetPageNumber({ commit }) {
      commit("reset_page_number");
    },
    appendLeaderboard({ commit }, data) {
      commit("append_leaderboard", data);
    },
    // api for vote track history
    async voteCount(_, data) {
      await axios({
        url: "posts/vote-count/",
        method: "POST",
        data: data,
      }).then((response) => {
        console.log(response);
      });
    },
    allCategory({ state }, data) {
      axios({
        url: "category/categories/",
        method: "GET",
        params: data,
      }).then((response) => {
        state.categoryList = response.data.category;
        state.availableCate = response.data.availableCategory;
      });
    },
  },
  modules: {},
  getters: {},
};
