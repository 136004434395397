<template>
  <div>
    <b-modal
      ref="my-modal"
      title="Award Stars to this Post"
      hide-footer
      :visible="show"
      @hide="handleclose"
      content-class="award-star-modal"
    >
      <div id="modal-prevent-closing___BV_modal_content_" class="modal-content">
        <div id="modal-prevent-closing___BV_modal_body_" class="modal-body">
          <div class="stars-wrap">
            <div
              class="single-stars-box"
              v-for="(star, index) in starList"
              :key="index"
            >
              <div
                :class="`stars-3col ${star.scheme_name} ${
                  star.available === false ? 'not-availabel' : ''
                }`"
                @click.once="awardStar(index)"
                :disabled="star.available === false"
                :data-title="
                  star.available === false
                    ? `You can not Award a ${star.scheme_name} star`
                    : `Award a ${star.scheme_name} star`
                "
                data-title-top
              >
                <i :class="`icon-star ${star.scheme_name}`"></i>
                <h5>{{ star.scheme_name }}</h5>
                <p>{{ star.token_cost }} TOKENS</p>
                <span title="Quantity Received">{{ star.star_count }}</span>
              </div>
              <p v-if="star.available === false" class="star-error">
                Not Available!
              </p>
            </div>
          </div>
        </div>
        <div
          class="alert-tokens tx-c tokens-confirm"
          :class="{ open: errorDiv }"
          style="background: #0ea3ce"
        >
          <h2 class="wh">Oops, you need more tokens.</h2>
          <!-- <a class="button primary mt--5 pr-15 pl-10" title="Button">
            <span class="tok-icon-gen-white"
              ><span>O<sup>2</sup></span></span
            >
            <router-link to="/tokens"> Get More Tokens</router-link>
          </a> -->
        </div>
        <div
          class="alert-tokens tx-c tokens-confirm"
          :class="{ open: successDiv }"
        >
          <h2 class="wh">THANK YOU!</h2>
        </div>
        <footer
          id="modal-prevent-closing___BV_modal_footer_"
          class="modal-footer text-left"
        >
          <p>
            <strong>Encourage Creators</strong> by awarding them Stars for great
            content.
          </p>
          <p>
            Gifting Stars also awards the Creator with
            <strong>Free Tokens</strong>.
          </p>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  props: ["show", "post_id"],

  data() {
    return {
      errorDiv: false,
      successDiv: false,
      starList: [],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      totalStar: (state) => state.overlayModule.totalStar,
    }),
  },

  async mounted() {
    await this.starData();
  },

  methods: {
    handleclose() {
      this.$emit("toggle-modal", false);
    },
    async starData() {
      await axios({
        url: "user/token/star-count/?post=" + this.post_id,
        method: "GET",
      })
        .then((response) => {
          this.starList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async awardStar(index) {
      await axios({
        url: "user/token/award-star/",
        method: "POST",
        data: {
          post: this.post_id,
          scheme_name: this.starList[index].scheme_name,
        },
      })
        .then((response) => {
          if (response.data.status === 200) {
            this.starList[index].star_count += 1;
            this.successDiv = true;
            setTimeout(() => {
              this.successDiv = false;
              this.$emit("toggle-modal", false);
            }, 6000);
            this.$store.commit(
              "set_user_token",
              this.user.token - this.starList[index].token_cost
            );
            this.$store.commit(
              "overlayModule/set_total_star",
              this.totalStar + 1
            );
            if (this.starList[index].scheme_name === "gold") {
              this.starList[1].available = false;
              this.starList[2].available = false;
            } else if (this.starList[index].scheme_name === "silver") {
              this.starList[2].available = false;
            }
          }
        })
        .catch((error) => {
          this.errorDiv = true;
          setTimeout(() => {
            this.errorDiv = false;
            this.$emit("toggle-modal", false);
          }, 6000);
          console.log(error);
        });
    },
  },
};
</script>