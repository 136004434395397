<template>
  <div>
    <div class="fearless-leaders no-ws display-flex search-user">
      <div
        v-for="(peoples, idx) in alignedPeople"
        :key="idx"
        :class="col_class"
      >
        <div
          v-for="(people, index) in peoples"
          :key="index"
          class="brick"
          style="position: relative"
        >
          <div class="brick-wrapper">
            <router-link
              :to="`/profile/${people.uuid}`"
              title="View profile"
              class="no-underline"
            >
              <div>
                <div>
                  <div
                    v-if="isLogin"
                    class="on-off"
                    :class="[people.status === true ? 'on-now' : '']"
                    :data-title="people.status === true ? 'Online' : 'Offline'"
                    data-title-right
                  ></div>
                </div>
              </div>
              <img
                v-if="people.avatar"
                class="pho-circs pho-1"
                :title="people.fullname"
                :src="people.avatar"
                :alt="people.fullname"
              />
              <div class="search-prof-img p-r small-img" v-else></div>
              <p class="search-prof blk">
                <strong>{{ people.fullname }}</strong>
              </p>
              <p class="search-prof">{{ people.location }}</p>
            </router-link>
          </div>
        </div>
      </div>
      <div class="btn_center_sec">
        <a class="load_more_btn" v-if="loadMoreToggle" @click="loadMorePeople">
         Show more <span class="icon-down-open"></span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SearchPeople",
  computed: {
    ...mapState({
      searchedPeoples: (state) => state.searchModule.people,
      pageNumber: (state) => state.searchModule.pageNumber,
      totalPeople: (state) => state.searchModule.totalPeople,
      isLogin: (state) => state.auth.isLogin,
    }),
    col_class() {
      return this.columns === 4 ? "col-3" : "col-6";
    },
    alignedPeople() {
      let $this = this;
      if ($this.width < 992) {
        $this.columns = 2;
        var temp = [[], []];
      } else {
        $this.columns = 4;
        temp = [[], [], [], []];
      }
      for (let i = 0; i < $this.searchedPeoples.length; i++) {
        temp[i % $this.columns].push($this.searchedPeoples[i]);
      }
      return temp;
    },
    loadMoreToggle() {
      const limit = 50;
      return Math.ceil(this.totalPeople / limit) > this.pageNumber
        ? true
        : false;
    },
  },

  data() {
    return {
      columns: 3,
      width: window.innerWidth,
    };
  },

  mounted() {
    window.addEventListener("resize", this.resizeWindow);
  },

  destroyed() {
    window.removeEventListener("resize", this.resizeWindow);
  },

  methods: {
    resizeWindow() {
      this.width = window.innerWidth;
    },
    loadMorePeople() {
      this.$store.dispatch(`searchModule/loadMorePosts`);
    },
  },
};
</script>
