<template>
  <perfect-scrollbar class="extra-panel bbb" :class="openClass">
    <div class="my-panels">
      <div class="cart-token-panel">
        <h2 class="akrobat bk-r">CART</h2>
        <div class="token-panel-head">
          <h3>
            <span>O<sup>2</sup></span> Tokens
          </h3>
          <p class="fs-8">
            Tokens are our currency for good.<br />
            Use them to purchase things like:
            <span>
              extra votes, more time (oxygen), boosts, custom themes, etc.</span
            >
          </p>
          <p class="fs-8">
            You can also use tokens for <span>gifting stars</span> for great
            Posts to encourage your favourites contributors.
          </p>
        </div>
        <div class="token-cart-wrap">
          <div
            class="single-cart-item"
            v-for="(item, index) in cartData"
            :key="index"
          >
            <div
              class="trash icon-trash"
              title="Delete"
              @click="RemoveToken(index)"
            ></div>
            <div class="token-cart-info">
              <div class="top">
                <div
                  :class="`spr-tokens-s-${item.item_token} s70 p-r token-cart-icon`"
                ></div>
                <div class="token-cart-right">
                  <ul>
                    <li>
                      <label>Tokens: </label>
                      <p>{{ item.item_token }}</p>
                    </li>
                    <li>
                      <label>Qty: </label>
                      <p>{{ item.quantity }}</p>
                    </li>
                    <li>
                      <label>Price: </label>
                      <p>${{ item.item_cost }}</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="token-cart-total">
                <p>Total: ${{ item.quantity * item.item_cost }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="grand-total" v-if="cartTotal > 0.0">
          <label>Grand Total: </label><span>${{ cartTotal }}</span>
        </div>
        <div class="cart-buy">
          <a
            v-if="cartTotal > 0.0"
            class="button primary full show-pay-options-JS"
            title="Buy Now"
            @click="buyNow('m-note')"
            >BUY NOW</a
          >
          <span v-if="cartTotal > 0.0" class="payment-deets">
            Secure checkout with Stripe or Paypal.
          </span>
        </div>
        <div class="method-bar" v-if="paymentDiv === true">
          <div class="method-bar-inner">
            <div class="single-method" @click="CheckOut">
              <input type="radio" id="stripe" name="Stripe" />
              <label for="Stripe">
                <div class="pay-wrap two">
                  <div class="icon-credit-card"></div>
                  <!-- <div class="icon-cc-stripe"></div> -->
                  <!-- <div class="icon-cc-visa"></div> -->
                </div>
              </label>
            </div>
            <div class="single-method" @click="withPayPal">
              <input type="radio" id="paypal" name="Paypal" />
              <label for="Paypal">
                <div class="pay-wrap">
                  <div class="icon-cc-paypal"></div>
                </div>
              </label>
            </div>
            <div class="single-method" @click="withPayPal">
              <input type="radio" id="paypal" name="Paypal" />
              <label for="Paypal">
                <div class="pay-wrap">
                  <div class="icon-google"></div>
                </div>
              </label>
            </div>
            <div class="single-method" @click="withPayPal">
              <input type="radio" id="paypal" name="Paypal" />
              <label for="Paypal">
                <div class="pay-wrap">
                  <div class="icon-apple"></div>
                </div>
              </label>
            </div>
          </div>
          <span class="m-note" id="m-note"
            ><span class="icon-attention-circled"></span> Select any one method
            to process checkout.</span
          >
        </div>
        <div class="empty-cart" v-if="cartData.length === 0">
          <div class="icon-shopping-basket emty-icon"></div>
          <div class="empty-info">
            <label>Your cart is empty</label>
            <span>Find out more about Tokens and how to get them.</span>
          </div>
          <router-link
            to="/tokens"
            class="button primary"
            @click.native="$emit('hideSideBar')"
          >
            Get Tokens</router-link
          >
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "CartTokenComponent",

  computed: {
    ...mapState({
      cartData: (state) => state.auth.cartData,
      user: (state) => state.auth.user,
    }),
  },

  data() {
    return {
      openClass: "",
      cartTotal: 0,
      paymentDiv: false,
    };
  },

  async mounted() {
    this.openClass = "open";
    await this.$store.dispatch("CartData");
    for (let i = 0; i < this.cartData.length; i++) {
      this.cartTotal += this.cartData[i].quantity * this.cartData[i].item_cost;
    }
    this.cartTotal = parseFloat(this.cartTotal).toFixed(2);
  },

  methods: {
    buyNow(elId) {
      this.paymentDiv = !this.paymentDiv;
      setTimeout(() => {
        document.getElementById(elId).scrollIntoView({
          block: "center",
        });
      }, 100);
    },
    async withPayPal() {
      await axios({
        url: "user/token/paypal/",
        method: "POST",
        data: { total: this.cartTotal },
      })
        .then((response) => {
          localStorage.setItem("paymentID", response.data.payment_id);
          window.location.replace(response.data.link);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async RemoveToken(index) {
      const data = {
        item_id: this.cartData[index].item_id,
      };
      await axios({
        url: "user/token/remove-token/",
        method: "POST",
        data: data,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      if (this.cartData[index].quantity > 0) {
        this.cartData[index].quantity -= 1;
        this.cartTotal -= this.cartData[index].item_cost.toFixed(2);
        this.cartTotal = parseFloat(this.cartTotal).toFixed(2);
        await this.$store.dispatch("CartData");
      }
    },
    async CheckOut() {
      var stripe = window.Stripe(
        "pk_test_51HSFwnIe4mLI9ikiqVQLQdoH3eBfMKdXlwJ4c8tUHqxoQ80tEoU1mKJlKo206g8etssO1xxzi2Ixz3cNLRckh5s400LH4o7zM2"
      );
      await axios({
        url: "user/token/stripe/",
        method: "POST",
        data: { total: this.cartTotal * 100 },
      })
        .then((response) => {
          localStorage.setItem("paymentIntent", response.data.payment_intent);
          return stripe.redirectToCheckout({ sessionId: response.data.id });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>