<template>
  <div>
    <div id="content" role="main">
      <p class="mb-40">
        <i class="icon-calendar"></i>Week : <strong>{{ currentWeek }}</strong
        ><span class="fs-7"> : {{ currentYear }}</span>
      </p>
      <PostCreateOverlay />
      <a
        class="
          show-panel
          button
          primary
          icon-upload-cloud
          right
          mt--5
          pl-15
          pr-20
          hideUnder575
        "
        @click="showPostCreateOverlay"
      >
        Add Post
      </a>
      <MatureAudienceLoginModal
        v-if="showLoginModal"
        @colseModal="colseModal"
      />
      <h2 class="inline-block akrobat m-0 mt-2 mb-20">voted by you</h2>
      <span
        class="cate-filter"
        v-b-hover="handleHover"
        v-if="availableCate.length > 0"
      >
        <b-button :class="isHovered ? 'not-collapsed' : 'collapsed'"
          >Categories <span class="icon-angle-down"></span>
        </b-button>
        <div class="selected-cate" v-if="selectedItem">
          <span><span class="icon-filter"></span> {{ selectedItem }} </span>
          <a @click="removeCategory" v-if="selectedItem">
            <i class="icon-cancel-circle"></i
          ></a>
        </div>
        <div v-else class="selected-cate">
          <span><span class="icon-picture"></span> All Posts </span>
        </div>
        <b-collapse id="collapse-1" class="show-cate" v-model="isHovered">
          <b-card>
            <div
              v-for="(cate, index) in availableCate"
              :key="index"
              class="single-cate"
              :class="{ active: selectedItem === cate }"
            >
              <label @click="selectCate(cate)">{{ cate }}</label>
            </div>
          </b-card>
        </b-collapse>
      </span>
      <WallTabsWrap @changeTab="changeTab" />
      <LoaderOverlay v-if="isLoading" />
      <TileView
        :moduleName="`leaderModule`"
        :paginate="true"
        @increment="increment"
        @decrement="decrement"
        @showOverlay="showOverlay"
      />
      <Overlay
        v-if="isOverlay"
        :post="selectedPost"
        :isOverlay="isOverlay"
        :hasNext="hasNext"
        :hasPrev="hasPrev"
        @increment="increment"
        @decrement="decrement"
        @removePost="removePost"
        @close="closeOveraly"
        @showOtherPost="showOtherPost"
      />
      <EditPostOverlay
        v-if="editingPost"
        :post="selectedPost"
        @editPost="editPost"
        @showOverlay="showOverlay"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TileView from "@/components/shared/TileView";
import Overlay from "@/components/shared/overlay/Overlay";
import WallTabsWrap from "../components/shared/WallTabsWrap.vue";
import GetCurrentWeekMixin from "@/mixins/GetCurrentWeekMixin";
import EditPostOverlay from "../components/shared/overlay/EditPostOverlay.vue";
import LoaderOverlay from "../components/shared/overlay/LoaderOverlay.vue";
import $ from "jquery";
import CreateOverlayMixin from "@/mixins/CreateOverlayMixin";
import MatureAudienceLoginModal from "../components/modal/MatureAudienceLoginModal.vue";

export default {
  name: "LeaderBoard",

  components: {
    WallTabsWrap,
    TileView,
    Overlay,
    EditPostOverlay,
    LoaderOverlay,
    MatureAudienceLoginModal,
  },

  mixins: [GetCurrentWeekMixin, CreateOverlayMixin],

  data: () => {
    return {
      selectedPostId: 0,
      isOverlay: false,
      orderBy: null,
      isHovered: false,
    };
  },

  computed: {
    ...mapState({
      isLogin: (state) => state.auth.isLogin,
      isLoading: (state) => state.auth.isLoading,
      activeTab: (state) => state.base.activeTab,
      posts: (state) => state.leaderModule.posts,
      editingPost: (state) => state.uxModule.editingPost,
      selectedItem: (state) => state.common.selectedItem,
      categoryList: (state) => state.common.categoryList,
      availableCate: (state) => state.common.availableCate,
    }),
    hasNext() {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPost.post_id
      );
      if (index + 1 === this.posts.length) return false;
      return true;
    },
    hasPrev() {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPost.post_id
      );
      if (index === 0) return false;
      return true;
    },
    selectedPost() {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPostId
      );
      if (index === -1) return { ...this.posts[0] };
      return { ...this.posts[index] };
    },
  },

  mounted() {
    this.initialProfileData();
    this.$store.dispatch("allCategory", { module: "Leaderboard" });
    this.$store.commit("set_selected_cate", localStorage.getItem("category"));
  },

  methods: {
    handleHover(hovered) {
      this.isHovered = hovered;
    },
    selectCate(cate) {
      this.isHovered = false;
      localStorage.setItem("category", cate);
      this.$store.commit("set_selected_cate", cate);
      this.initialProfileData();
    },
    removeCategory() {
      localStorage.removeItem("category");
      this.$store.commit("set_selected_cate", "");
      this.initialProfileData();
    },
    async initialProfileData() {
      this.$store.dispatch("resetActiveTab");
      const postData = await this.$store.dispatch(
        "leaderModule/getAllAvailablePosts",
        {
          activeTab: 0,
          pageNumber: 0,
          orderBy: this.orderBy,
          selectedCategory: localStorage.getItem("category"),
        }
      );
      this.orderBy = postData.order_by;
      this.$store.dispatch("votingModule/setVotes", postData);
    },
    editPost(postData) {
      this.$store.dispatch("leaderModule/editPost", { ...postData });
    },
    async changeTab(index) {
      const postData = await this.$store.dispatch(
        "leaderModule/getAllAvailablePosts",
        {
          activeTab: index,
          pageNumber: 0,
          selectedCategory: localStorage.getItem("category"),
        }
      );
      this.$store.dispatch("votingModule/setVotes", postData);
      this.$store.dispatch("setActiveTab", index);
    },
    async increment(postID) {
      const res = await this.$store.dispatch("leaderModule/changePressure", {
        post_id: postID,
        amount: 1,
        activeTab: this.activeTab,
      });
      await this.$store.dispatch("homeModule/voteRecord", postID);
      await this.$store.dispatch("voteCount", {
        post: postID,
        type: "plus",
      });
      if (this.activeTab === 1) {
        await this.$store.dispatch("leaderModule/getAllAvailablePosts", {
          activeTab: 1,
          pageNumber: 0,
          selectedCategory: localStorage.getItem("category"),
        });
      }
      if (res.status === 200) {
        this.$store.dispatch("votingModule/setVotes", res.data);
        this.$store.commit("reduce_my_votes");
      }
    },
    async decrement(postID) {
      const res = await this.$store.dispatch("leaderModule/changePressure", {
        post_id: postID,
        amount: -1,
        activeTab: this.activeTab,
      });
      await this.$store.dispatch("voteCount", {
        post: postID,
        type: "minus",
      });
      if (this.activeTab === 1) {
        await this.$store.dispatch("leaderModule/getAllAvailablePosts", {
          activeTab: 1,
          pageNumber: 0,
          selectedCategory: localStorage.getItem("category"),
        });
      }
      if (res.status === 200) {
        this.$store.dispatch("votingModule/setVotes", res.data);
        this.$store.commit("reduce_my_votes");
      }
    },
    async showOverlay(postId) {
      await this.$store.dispatch("overlayModule/getComments", postId);
      this.selectedPostId = postId;
      this.isOverlay = true;
    },
    async showOtherPost(num) {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPostId
      );
      this.selectedPostId = this.posts[index + num].post_id;
      await this.$store.dispatch(
        "overlayModule/getComments",
        this.posts[index + num].post_id
      );
      await this.$store.dispatch("overlayModule/StarCount", {
        post: this.posts[index + num].post_id,
      });
      if (this.isLogin) {
        await this.$store.dispatch("overlayModule/GetCommentLoveLikeData", {
          post: this.posts[index + num].post_id,
        });
        await this.$store.dispatch("overlayModule/GetLoveLikeData", {
          post: this.posts[index + num].post_id,
        });
      }
      await this.$store.dispatch("overlayModule/PostTrack", {
        post: this.posts[index + num].post_id,
        type: "post",
      });
    },
    closeOveraly() {
      this.isOverlay = false;
      this.$store.dispatch("changeState", ["overlayClass", ""]);
      $("body").removeClass("hero-overlay");
    },
    removePost() {
      this.isOverlay = false;
      this.$store.dispatch("leaderModule/removePost", this.selectedPost.uuid);
    },
  },
};
</script>
