<template>
  <div class="overlay editPostOverlay" :class="isShowOverlay ? 'opened' : ''">
    <perfect-scrollbar class="wrapper" ref="draft_post">
      <a
        class="icon-cancel-1 close-overlay"
        title="Close this Post"
        @click="hideOverlay"
        >Close</a
      >
      <div class="content logedIn">
        <ul class="feed">
          <li id="createPost" class="open">
            <h2 class="akrobat">EDIT DRAFT</h2>
            <div class="large-img-error">{{ this.error }}</div>
            <div class="relative">
              <div class="comment-box">
                <div class="fieldset">
                  <div
                    data-title="Make your Title descriptive and unique"
                    data-title-top
                    class="input-holder"
                    :class="required.title ? 'is-invalid' : ''"
                  >
                    *<input
                      class="post-title"
                      v-model="postData.title"
                      id="add-title"
                      placeholder="Title ..."
                      @blur="validate('postData.title')"
                    />
                  </div>
                  <p v-if="required.title" class="error-message">
                    <i class="icon-attention-circled">* Required</i>
                  </p>
                </div>
              </div>
              <div class="comment-box">
                <div class="fieldset">
                  <div
                    data-title="Add more information"
                    data-title-top
                    class="input-holder"
                    :class="required.text ? 'is-invalid' : ''"
                  >
                    *<textarea
                      id="add-text"
                      v-model="postData.summary"
                      placeholder="Tell us about it ..."
                      @blur="validate('postData.summary')"
                    ></textarea>
                  </div>
                  <p v-if="required.text" class="error-message">
                    <i class="icon-attention-circled">* Required</i>
                  </p>
                </div>
              </div>
              <p class="mt-20 mb-25 cate-res">
                <!-- User has ability to add more images and/or videos on SCREEN 3 ---->
                <a
                  data-title="Add More Images"
                  data-title-top
                  class="button img-icon img-icon2 tertiary pr-15 pl-10 mr-5 mb-3"
                  @click="toggleExtraTab(1)"
                >
                  <i class="icon-plus"></i>Images
                </a>
                <a
                  data-title="Add YouTube Videos"
                  data-title-top
                  class="button vid-icon vid-icon2 tertiary pr-15 pl-10 mr-5 mb-3"
                  @click="toggleExtraTab(2)"
                >
                  <i class="icon-plus"></i>Videos
                </a>
                <a
                  data-title="Change Categories"
                  data-title-top
                  class="button tertiary mr-5 pl-7 pr-15"
                  @click="toggleExtraTab(4)"
                >
                  <i class="icon-angle-down"></i><span>Edit Category</span>
                </a>
                <a
                  data-title="Add Tags, Credits, Links"
                  data-title-top
                  class="button tertiary view-extras pl-5 pr-15"
                  @click="toggleExtraTab(3)"
                >
                  <i class="icon-angle-down"></i>More
                </a>
              </p>
              <!--TAB 1 -->
              <div class="bord addImages" :class="{ open: extraTab === 1 }">
                <a
                  class="icon-cancel-1"
                  title="Close"
                  @click="toggleExtraTab(0)"
                ></a>
                <div
                  v-cloak
                  @drop.prevent="addFile"
                  @dragover.prevent
                  @dragenter="isDragEntered = true"
                  @dragleave="isDragEntered = false"
                  :class="{ 'image-drag-entered mb-10': isDragEntered }"
                >
                <span class="drag-contain-bg">
                  <h2 class="blk akrobat">Drag &amp; Drop images here</h2>
                  <div id="browse-files-2" class="ta-r">
                    <span class="or-special">or</span>
                    <a
                      class="button tertiary pr-15 pl-10 p-r mr-7"
                      title="Browse for images"
                    >
                      Browse for images
                      <input
                        title="Browse for images"
                        type="file"
                        accept="image/*"
                        multiple="multiple"
                        ref="imageFiles2"
                        @change="browseImages(2)"
                      />
                    </a>
                  </div>
                  </span>
                </div>
              </div>
              <!--TAB 2 -->
              <div class="bord addVideo" :class="{ open: extraTab === 2 }">
                <a
                  class="icon-cancel-1"
                  title="Close"
                  @click="toggleExtraTab(0)"
                ></a>
                <div class="comment-box drag-contain-bg pt-0">
                  <h2 class="blk akrobat">ADD YOUTUBE VIDEO</h2>
                  <div class="fieldset mr-20 ml-20">
                    <div
                      class="input-holder"
                      :class="videoValidationText ? 'is-invalid' : ''"
                    >
                      <input
                        v-model="embededURL"
                        placeholder="Paste YouTube Video Url here ..."
                        @blur="validateVideo"
                      />
                      <input
                        type="submit"
                        id="submit-video-url"
                        class="button tertiary vidSubmit"
                        value="Save Url"
                        @click="addVideoURL"
                      />
                    </div>
                    <p class="error-message" v-if="videoValidationText">
                      <i class="icon-attention-circled">{{
                        videoValidationText
                      }}</i>
                    </p>
                  </div>
                  <p class="f80 ml-20">
                    Example: https://www.youtube.com/watch?v=eGsp9h4ST2M
                  </p>
                </div>
              </div>
              <!--TAB 3 -->
              <div class="bord addExtras" :class="{ open: extraTab === 3 }">
                <a
                  class="icon-cancel-1"
                  title="Close"
                  @click="toggleExtraTab(0)"
                ></a>
                <div class="comment-box drag-contain-bg pl-15 pr-15 pt-0">
                  <h2 class="blk akrobat pl-7">MORE ...</h2>
                  <h3 class="small mt-20 icon-tags">Add Search tags</h3>
                  <div class="fieldset">
                    <div class="input-holder">
                      <span
                        data-title="Add a tag - Then press Enter"
                        data-title-top
                      >
                        <input
                          id="add-tags"
                          placeholder="E.g. 'Nature' - Then press 'Enter'"
                          v-model="searchTag"
                          @keyup="handleKeyEvent"
                        />
                      </span>
                    </div>
                    <span
                      v-for="(tag, index) in postData.tags"
                      :key="index"
                      class="post-tag"
                    >
                      {{ tag }}
                      <a
                        class="icon-cancel"
                        title="Close"
                        @click="removeTag(index)"
                      ></a>
                    </span>
                  </div>
                 <p class="f80 mt-0">
                    *Tags are topic keywords for people to search for to find related Posts.
                  </p>
                  <div class="comment-box">
                    <h3 class="small mt-20 icon-heart-empty">Add Credit</h3>
                    <div class="fieldset">
                      <div class="input-holder">
                        <span
                          data-title="Add a credit to this Post"
                          data-title-top
                        >
                        <input
                          v-model="postData.credit"
                          placeholder="e.g. Image courtesy of John Smith"
                          maxlength="40"
                        />
                        </span>
                      </div>
                    </div>
                   <p class="f80 mt-0">*Add a credit to image or author</p>
                  </div>
                  <div class="comment-box">
                    <h3 class="small mt-20 icon-link-1">Add Link</h3>
                    <div class="fieldset">
                      <div
                        class="input-holder"
                        :class="linkValidationText ? 'is-invalid' : ''"
                      >
                        <span data-title="Add a link or website" data-title-top>
                          <input
                            v-model="postData.additionalLink"
                            placeholder="https://somelink.com"
                            @blur="validateURL(postData.additionalLink)"
                          />
                        </span>
                      </div>
                      <p class="error-message" v-if="linkValidationText">
                        <i class="icon-attention-circled">{{
                          linkValidationText
                        }}</i>
                      </p>
                    </div>
                    <p class="f80 mt-0">
                      *Add a website, credits link, or other url
                    </p>
                  </div>
                </div>
              </div>

              <div class="bord editCate" :class="{ open: extraTab === 4 }">
                               <a
                  class="icon-cancel-1"
                  title="Close"
                  @click="toggleExtraTab(0)"
                ></a>
                <div class="select-category-list drag-contain-bg pl-15 pr-15 pt-0">
                  <h2 class="blk akrobat pl-7">EDIT CATEGORIES</h2>
                  <b-form-checkbox-group
                    id="checkbox-group-2"
                    v-model="selected"
                    :options="categoryList"
                    v-on:input="limiter"
                    name="flavour-1"
                    class="single-category"
                  ></b-form-checkbox-group>
                </div>
                <span
                  class="cate-error"
                  :class="{ error: this.error }"
                  v-if="caterror"
                >
                  <i class="icon-attention-circled"></i> {{ caterror }}</span
                >
              </div>

              <div
                v-for="(image, index) in postData.images"
                :key="index"
                class="imgWrap mt-20"
                style="overflow: visible"
              >
                <div class="arrowWrp">
                  <a
                    v-if="index > 0"
                    class="arrow icon-up-open js-move-forward"
                    @click="imgBefore(index)"
                  >
                  </a>
                  <!-- move this image  up one place (insertBefore) in the display img order-->
                  <a
                    v-if="index < postData.images.length - 1"
                    class="arrow down icon-down-open js-move-back"
                    @click="imgAfter(index)"
                  ></a>
                  <!-- move this image down one place (insertAfter) in the display img order-->
                  <a
                    v-if="setDelete()"
                    class="arrow trash icon-trash"
                    @click="removeFile(index, image.id)"
                  ></a>
                </div>
                <img :src="image.url" alt="" />
              </div>
              <!-- VIDEO(S) -->

              <!-- MEDIA EMBEDDED VIDEO WRAP -->
              <div
                v-for="(video, idx) in postData.videos"
                :key="idx + 5"
                class="imgWrap mt-20"
                style="overflow: visible"
              >
                <!--- user can add more than one video url/embed - they just add one at a time and the videos show here -->
                <div class="arrowWrp">
                  <a
                    v-if="idx > 0"
                    class="arrow icon-up-open js-move-forward"
                    @click="videoBefore(idx)"
                  >
                  </a>
                  <!-- move this image  up one place (insertBefore) in the display img order-->
                  <a
                    v-if="idx < postData.videos.length - 1"
                    class="arrow down icon-down-open js-move-back"
                    @click="videoAfter(idx)"
                  ></a>
                  <!-- move this image down one place (insertAfter) in the display img order-->
                  <a
                    v-if="setDelete()"
                    class="arrow trash icon-trash"
                    @click="removeVideo(idx, video.id)"
                  ></a>
                </div>
                <iframe
                  width="560"
                  height="315"
                  :src="video.url"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                >
                </iframe>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </perfect-scrollbar>
    <vue-progress-bar></vue-progress-bar>
    <div class="fxd-footer">
      <button
        data-title="Publish on Pressure Pit"
        data-title-top
        class="button icon-upload-cloud right primary pr-15 pl-15 mr-20 ml-20"
        id="postImage"
        @click.prevent="upload('submit')"
        @click="validate('postData.title', 'postData.summary')"
        :disabled="doubleClick"
      >
        <strong>PUBLISH</strong>
      </button>
      <div
        data-title="Is this for Mature viewers only?"
        data-title-top
        class="fieldset cb right"
      >
        <input type="checkbox" id="mature" v-model="postData.mature_content" />
        <label for="mature" class="label">Sensitive?</label>
      </div>
      <a
        data-title="Save to my Drafts"
        data-title-top
        class="a-text right"
        @click.prevent="upload('save')"
        @click="validate('postData.title', 'postData.summary')"
        :disabled="doubleClick"
      >
        <i class="icon-doc-text"></i>
        Save
      </a>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {
  deletePostImage,
  deletePostVideo,
} from "../../../store/Services/comman";
import { mapState } from "vuex";

export default {
  name: "DraftOverlay",
  props: {
    isShowOverlay: Boolean,
    post: Object,
  },

  computed: {
    ...mapState({
      categoryList: (state) => state.common.categoryList,
    }),
  },

  data() {
    return {
      postData: {},
      required: {
        title: false,
        text: false,
      },
      isDragEntered: false,
      extraTab: 0,
      files: [],
      urls: [],
      videoUrls: [],
      searchTag: "",
      embededURL: "",
      videoValidationText: "",
      isUploading: false,
      validation: true,
      linkValidationText: "",
      error: "",
      doubleClick: false,
      selected: [],
      prev: [],
      caterror: "",
    };
  },

  created() {
    this.postData = {
      ...this.postData,
      ...this.post,
    };
    this.selected = this.postData.category;
    this.prev = this.postData.category;
    if (!this.postData.tags) this.postData.tags = [];
    if (!this.postData.is_video) this.postData.videos = [];
  },

  methods: {
    limiter(e) {
      if (e.length > 3) {
        this.caterror = "You can only select 3 categories!";
      } else if (e.length === 0) {
        this.caterror = "Select at least one category for your Post";
      } else {
        this.caterror = "";
      }
    },
    start() {
      this.$Progress.start();
    },
    set(num) {
      this.$Progress.set(num);
    },
    increase(num) {
      this.$Progress.increase(num);
    },
    decrease(num) {
      this.$Progress.decrease(num);
    },
    finish() {
      this.$Progress.finish();
    },
    fail() {
      this.$Progress.fail();
    },
    setDelete() {
      if (this.postData.images.length > 0 || this.postData.videos.length > 0) {
        if (this.postData.images.length === 0) {
          if (this.postData.videos.length > 1) {
            return true;
          } else {
            return false;
          }
        } else if (this.postData.videos.length === 0) {
          if (this.postData.images.length > 1) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    addFile(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      [...droppedFiles].forEach((f) => {
        this.files.push({
          position: this.postData.images.length,
          img_file: f,
        });
        this.postData.images.push(URL.createObjectURL(f));
      });
    },
    addVideoURL() {
      let $this = this;
      if ($this.embededURL != undefined || $this.embededURL != "") {
        let validation = $this.validateVideoURL();
        if (validation) {
          let lastPoisition;
          if ($this.postData.videos.length) {
            lastPoisition =
              $this.postData.videos[$this.postData.videos.length - 1].position;
          } else {
            lastPoisition = 0;
          }
          const tempData = {
            id: null,
            position: lastPoisition,
            url: $this.embededURL,
          };
          $this.postData.videos.push(tempData);
          // $this.videoValidationText = "";
          $this.extraTab = 0;
          $this.embededURL = "";
        }
        // else {
        //   $this.videoValidationText = "Please input validated URL!";
        // }
      }
    },
    validateVideo() {
      let validation = this.validateVideoURL();
      if (validation) {
        this.videoValidationText = "";
      } else {
        this.videoValidationText = "Please input a valid YouTube Url!";
      }
    },
    browseImages(num) {
      let $this = this;
      let imgFiles = [];
      if (num === 1) {
        imgFiles = $this.$refs.imageFiles1.files;
      } else {
        imgFiles = $this.$refs.imageFiles2.files;
      }
      for (let i = 0; i < imgFiles.length; i++) {
        if (imgFiles[i].size <= 20000000) {
          this.error = "";
          const imgType = imgFiles[i].type.split("/");
          if (
            imgType[1] === "jpg" ||
            imgType[1] === "jpeg" ||
            imgType[1] === "png" ||
            imgType[1] === "webp"
          ) {
            this.error = "";
            let file = imgFiles[i];
            $this.files.push({
              position: $this.postData.images.length,
              img_file: file,
            });
            const tempData = {
              id: null,
              url: URL.createObjectURL(file),
              position: $this.postData.images.length,
            };
            this.postData.images.push(tempData);
          } else {
            this.error =
              imgType[1] +
              " image file format is not accepted. Please upload another type of image.";
          }
        } else {
          this.error = "You can not upload large images.";
        }
        $this.extraTab = 0;
      }
    },
    handleKeyEvent(event) {
      let $this = this;
      if (event.key === "," || event.key === "Enter") {
        if ($this.searchTag != "" && $this.searchTag != ",") {
          $this.searchTag = $this.searchTag.replace(/,/g, "").trim();
          $this.postData.tags.push($this.searchTag);
        }
        $this.searchTag = "";
      }
    },
    hideOverlay() {
      const $body = document.body;
      $body.classList.remove("overlay-open");
      this.$emit("hideOverlay");
    },
    imgBefore(index) {
      let foundedItem = this.files.find((file) => file.position === index);
      let foundedItem1 = this.files.find((file) => file.position === index - 1);
      if (foundedItem) foundedItem.position--;
      if (foundedItem1) foundedItem1.position++;
      this.postData.images = this.swap(index, this.postData.images, "before");
    },

    imgAfter(index) {
      let foundedItem = this.files.find((file) => file.position === index);
      let foundedItem1 = this.files.find((file) => file.position === index + 1);
      if (foundedItem) foundedItem.position++;
      if (foundedItem1) foundedItem1.position--;
      this.postData.images = this.swap(index, this.postData.images, "after");
    },
    async removeFile(index, imageId) {
      const data = {
        post_id: this.postData.post_id,
        image_id: imageId,
      };
      const response = await deletePostImage(data);
      console.log(response);
      let idx = this.files.findIndex((item) => item.position === index);
      if (idx !== -1) {
        this.files.splice(idx, 1);
      }
      this.files.forEach((item) => {
        if (item.position > index) item.position--;
      });
      this.postData.images.splice(index, 1);
    },
    removeTag(index) {
      this.postData.tags.splice(index, 1);
    },
    async removeVideo(index, videoId) {
      let $this = this;
      const data = {
        post_id: this.postData.post_id,
        video_id: videoId,
      };
      $this.postData.videos.splice(index, 1);
      const response = await deletePostVideo(data);
      $this.postData.is_video = response.is_video;
    },
    swap(index, arr, type) {
      let buf = [];
      if (type === "before") {
        buf = [arr[index - 1], arr[index]];
        arr.splice(index - 1, 2, buf[1], buf[0]);
      } else {
        buf = [arr[index], arr[index + 1]];
        arr.splice(index, 2, buf[1], buf[0]);
      }
      return arr;
    },
    toggleExtraTab(num) {
      if (this.extraTab === num) {
        this.extraTab = 0;
      } else {
        this.extraTab = num;
      }
    },
    async upload(type) {
      let $this = this;
      if (type === "submit") {
        $this.postData.status = "PUBLIC";
      } else {
        $this.postData.status = "draft";
      }

      if (
        $this.postData.title !== "" &&
        $this.postData.summary !== "" &&
        this.validation === true &&
        this.caterror === ""
      ) {
        this.doubleClick = true;
        this.$Progress.start();
        $this.isUploading = true;
        if ($this.files.length > 0) {
          let formData = new FormData();
          for (let i = 0; i < $this.files.length; i++) {
            formData.append("post_id", $this.postData.post_id);
            formData.append("length", $this.files.length);
            formData.append("file[" + i + "]", $this.files[i].img_file);
            formData.append("position[" + i + "]", $this.files[i].position);
          }
          await axios({
            url: "posts/image-upload/",
            data: formData,
            method: "POST",
          })
            .then((response) => {
              this.doubleClick = false;
              if (response.data !== "Failed") {
                response.data.forEach((item) => {
                  $this.postData.images[item.position] = item.url;
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.$Progress.fail();
              $this.isUploading = false;
            });
        }
        if ($this.postData.videos.length > 0) $this.postData.is_video = true;
        await axios({
          url: "posts/update-post/",
          data: this.postData,
          method: "POST",
        }).then((res) => {
          this.$Progress.finish();
          $this.isUploading = false;
          this.postData.images = res.data.data.images;
          this.postData.videos = res.data.data.videos;
          if (type === "submit") {
            $this.$router.push({ name: "Home" });
            this.$store.dispatch("homeModule/getAllAvailablePosts", {
              activeTab: 0,
              pageNumber: 0,
              orderBy: this.orderBy,
              selectedCategory: localStorage.getItem("category"),
            });
            // this.orderBy = postData.order_by;
            // this.$store.dispatch("votingModule/setVotes", postData);
          }
        });
        await axios({
          url: "category/post-category/",
          method: "POST",
          data: {
            post_id: $this.postData.post_id,
            categories: this.selected,
            prev: this.prev,
          },
        }).then((response) => {
          console.log(response);
          this.postData.category = this.selected;
          this.selected = [];
          this.$store.dispatch("allCategory", {
            module: this.$route.name,
          });
        });
        this.$store.commit("edit_draft", { ...this.postData });
        this.hideOverlay();
      } else {
        this.$refs.draft_post.$el.scrollTop = 0;
      }
    },
    validate(value1, value2) {
      if (value1 === "postData.title") {
        this.required.title = this.postData.title ? false : true;
      }
      if (value2 === "postData.summary") {
        this.required.text = this.postData.summary ? false : true;
      }
    },
    validateURL(value) {
      if (value === "") {
        this.validation = true;
      } else {
        this.validation = this.validateAdditionalLink(value);
      }
      if (this.validation === true) {
        this.linkValidationText = "";
      } else {
        this.linkValidationText = "Please enter valid Link";
      }
    },
    validateAdditionalLink(data) {
      const expression =
        /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
      var regex = new RegExp(expression);
      var t = data;
      if (data) {
        if (t.match(regex)) {
          return true;
        } else {
          return false;
        }
      }
    },
    validateVideoURL() {
      let $this = this;
      const regYoutube =
        /^.*(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$)).*/;
      const regVimeo =
        /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^]*)\/videos\/|)(\d+)(?:|\/\?)/;
      let match = $this.embededURL.match(regYoutube);
      if (match && match[3].length == 11) {
        $this.embededURL = "https://www.youtube.com/embed/" + match[3];
        return true;
      } else if (regVimeo.test($this.embededURL)) {
        return true;
      } else {
        return false;
      }
    },
    videoBefore(index) {
      this.postData.videos = this.swap(index, this.postData.videos, "before");
    },
    videoAfter(index) {
      this.postData.videos = this.swap(index, this.postData.videos, "after");
    },
  },
};
</script>