<template>
  <div :class="['c-alert', toastType]">
    <div class="c-close" @click="closeToast">✖</div>
    <div class="c-alert-inner">
      <div class="c-alert-body">
        <span>{{ toastMsg }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["toastMsg", "type"],
  computed: {
    toastType() {
      return this.type === "error" ? "c-alert-red" : "c-alert-green";
    },
  },
  methods: {
    closeToast() {
      this.$store.commit("close_toast");
    },
  },
};
</script>
