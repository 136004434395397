<template>
  <div id="content" class="pt-20" role="main">
    <Member v-if="isLogin" />
    <Public v-else />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Public from "@/components/whatiso2/Public";
import Member from "@/components/whatiso2/Member";
import $ from "jquery";

export default {
  name: "WhatisO2",
  computed: {
    ...mapState({
      isLogin: (state) => state.auth.isLogin,
      overlayClass: (state) => state.common.overlayClass,
      myOzone: (state) => state.common.myOzone,
    }),
  },

  mounted() {
    $(".custom-screen").addClass("full-screen");
  },

  destroyed() {
    $(".custom-screen").removeClass("full-screen");
  },

  components: {
    Public,
    Member,
  },
  
  methods: {
    changeMyOzone(num) {
      this.$store.dispatch("setMyOzone", num);
    },
  },
};
</script>