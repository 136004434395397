<template>
  <div id="content" class="profile" role="main">
    <PostCreateOverlay />
    <div class="prof-img new-design-me p-r">
      <div class="user-active">
        <span v-if="userData.user_status === true" class="under320"
          >online</span
        >
        <span v-else class="under320">offline</span>
        <span
          class="on-off"
          :class="[userData.user_status === true ? 'on-now' : '']"
        ></span>
      </div>
      <div class="mob-t-margin new-design-me p-r">
        <img
          v-if="userAvatar.length > 0"
          class="pho-new-design-me"
          :class="userAvatar ? 'round-logo' : ''"
          :title="`${userData.first_name} ${userData.last_name}`"
          :src="userAvatar"
          :alt="`${userData.first_name} ${userData.last_name}`"
        />
        <div v-else class="search-prof-img p-r user-defaul-logo"></div>
      </div>
      <div class="bio-toggle p-a">
        <a
          class="button secondary p-a bio-pos"
          :title="`View ${userData.first_name}'s Bio`"
          @click="isBioOpening = !isBioOpening"
        >
          <i
            class="icon-down-open"
            :class="{ 'icon-up-open': isBioOpening }"
          ></i>
        </a>
      </div>
    </div>

    <!--BIO -->
    <div class="bio new-design-me bio-others" :class="{ open: isBioOpening }">
      <div class="m-space">
        <h3
          v-if="
            (userData.bio !== '' && userData.bio !== null) ||
            (userData.site_url !== '' && userData.site_url !== null)
          "
        >
          About
        </h3>
        <pre v-if="userData.bio !== null && userData.bio !== ''">{{
          userData.bio
        }}</pre>
        <div
          v-if="userData.site_url !== null && userData.site_url !== ''"
          class="url tx-c"
        >
          <i class="icon-link-ext"></i>
          <a :href="userData.site_url" target="_blank">{{
            userData.site_url
          }}</a>
        </div>
        <h3 class="mb-15">
          Profile Link<span class="fs-8">
            -
            <a rel="noopener noreferrer" ref="mylink" @click="copyURL">
              <span>{{ copyButtonText }}</span></a
            ></span
          >
        </h3>

        <!-- Treasure view -->
        <h3 v-if="elementTheme.length > 0 || winnerCount > 0 || top10Count > 0">
          Treasure
        </h3>
        <div
          class="basic-white drk-theme pt-10 pb-2 tx-c"
          v-if="elementTheme.length > 0 || winnerCount > 0 || top10Count > 0"
        >
          <div class="treas mt-5" v-if="winnerCount > 0 || top10Count > 0">
            <div>
              <div
                class="spr-treasures-winner"
                title="O2: Winning Posts"
                v-if="winnerCount > 0"
              >
                <h3 class="mt-3">Winner</h3>
                <span
                  class="treas-badge"
                  :title="`${winnerCount}x a Winning Post`"
                  >{{ winnerCount }}</span
                >
              </div>
              <div
                class="spr-treasures-top10"
                title="O2: Top 10 Posts"
                v-if="top10Count > 0"
              >
                <h3 class="mt-3">Top 10</h3>
                <span
                  class="treas-badge"
                  :title="`${top10Count} times in Top 10 Posts`"
                  >{{ top10Count }}</span
                >
              </div>
              <!-- <div
                class="spr-treasures-created"
                title="Created the most Posts that week"
              >
                <span class="treas-badge" title="Won {6}x Most Posts">6</span>
              </div>

              <div
                class="spr-treasures-shared"
                title="Shared the most Posts that week"
              >
                <span class="treas-badge" title="Won {21}x Most Posts Shared"
                  >21</span
                >
              </div> -->
            </div>
          </div>

          <!-- HIDE ELEMENTS TITLE and CONTENT - Stage 2 post live dev -->
          <div class="basic-elements" v-if="elementTheme.length >= 1">
            <h3>{{ userData.first_name }}'s Custom Themes</h3>
            <div class="element-theme-wrap">
              <div
                class="element-theme"
                v-for="(theme, idx) in elementTheme"
                :key="idx"
              >
                <div :class="`spr-treasures-${theme}`" :title="theme"></div>
              </div>
            </div>
          </div>
        </div>

        <h3>
          Current Theme
          <span class="fs-8"
            ><a class="icon-brush" :href="link">View Themes</a></span
          >
        </h3>
        <div
          v-if="
            themeData &&
            Object.keys(themeData).length >= 1 &&
            this.selectedTheme !== 'darkmode'
          "
        >
          <div v-for="(data, index) in themeData" :key="index">
            <div
              class="basic-white tx-c pt-15 pb-5"
              v-if="index === profileTheme"
            >
              <div
                :class="
                  profileTheme !== 'o2'
                    ? `spr-theme-${data[0]} size1-pos pos3 m-auto mt-10`
                    : 'spr-theme-oxygen size1-pos pos3 m-auto mt-10'
                "
              ></div>
              <p v-if="data[0] !== 'o2'">
                {{ userData.first_name }} is currently using the
                {{ data[0] }} theme.
              </p>
              <p v-else-if="index === 'o2'">
                {{ userData.first_name }} is currently using the oxygen theme.
              </p>
            </div>
          </div>
        </div>
        <div v-else-if="this.selectedTheme === 'darkmode'">
          <div class="basic-white tx-c pt-15 pb-5 crrent-dark">
            <p>You are currently using the site in Dark Mode.</p>
          </div>
        </div>
        <div v-else>
          <div class="basic-white tx-c pt-15 pb-5">
            <div class="spr-theme-oxygen size1-pos pos3 m-auto mt-10"></div>
            <p>
              {{ userData.first_name }} is currently using the oxygen theme.
            </p>
          </div>
        </div>
        <h3
          v-if="
            themeData &&
            Object.keys(themeData).includes('gold', 'platinium', 'dimond')
          "
        >
          Rewards
          <span class="fs-8"
            ><a class="icon-brush" :href="link">View Themes</a></span
          >
        </h3>
        <div
          class="basic-white"
          v-if="
            themeData &&
            Object.keys(themeData).includes('gold', 'platinium', 'dimond')
          "
        >
          <div class="reward-inner">
            <div
              class="reward-wrap"
              v-for="(theme, idx) in rewardProfileTheme"
              :key="idx"
            >
              <div class="reward-flex">
                <div :class="`reward-block ${theme[0]}`">
                  <div
                    :data-title="`${theme[0]} MEMBER`"
                    data-title-top
                    :class="`spr-theme-${theme[0]} size1-pos pos3 reward-scale m-auto`"
                  ></div>
                  <h3 class="count">{{ theme[0] }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="my-socials tx-c mb-40">
        <a v-if="hasFacebook" :href="userData.facebook_url" target="_blank">
          <i
            class="button icon-facebook"
            :title="`${userData.first_name}'s Facebook`"
          ></i>
        </a>
        &nbsp;
        <a v-if="hasInstagram" :href="userData.insgtagram_url" target="_blank">
          <i
            class="button icon-instagram"
            :title="`${userData.first_name}'s Instagram`"
          ></i>
        </a>
        &nbsp;
        <a v-if="hasTwitter" :href="userData.twitter_url" target="_blank">
          <i
            class="button icon-twitter"
            :title="`${userData.first_name}'s Twitter`"
          ></i>
        </a>
        &nbsp;
        <a v-if="hasLinkedin" :href="userData.linkedin_url" target="_blank">
          <i
            class="button icon-linkedin"
            :title="`${userData.first_name}'s LinkedIn`"
          ></i>
        </a>
        &nbsp;
        <a v-if="hasPinterest" :href="userData.pinterest_url" target="_blank">
          <i
            class="button icon-pinterest-circled"
            :title="`${userData.first_name}'s Pinterest`"
          ></i>
        </a>
      </div>
    </div>

    <div class="p-r" v-if="userData.email !== this.userEmail">
      <div v-if="isLogin" class="followMebutton">
        <button
          class="button tertiary"
          title
          @click="clickButton"
          :class="disableFollowButton ? '' : 'follow-user'"
          :disabled="disableFollow"
        >
          <span v-if="disableFollowButton">
            Unfollow {{ userData.first_name }}
            <i class="icon-user p-r">
              <span class="plus-inline-wh">-</span>
            </i>
          </span>
          <span v-else>
            Follow {{ userData.first_name }}
            <i class="icon-user p-r">
              <span class="plus-inline-wh">+</span>
            </i>
          </span>
        </button>
      </div>
    </div>

    <h2 class="inline-block akrobat m-0 mt-2">
      <i class="icon-picture"></i> POSTS
    </h2>
    <WallTabsWrap @changeTab="changeTab" @getByPostTitle="getByPostTitle" />
    <LoaderOverlay v-if="isLoading" />
    <TileView
      :moduleName="`profileModule`"
      @increment="increment"
      @decrement="decrement"
      @showOverlay="showOverlay"
    />
    <Overlay
      v-if="isOverlay"
      :post="selectedPost"
      :isOverlay="isOverlay"
      :hasNext="hasNext"
      :hasPrev="hasPrev"
      @increment="increment"
      @decrement="decrement"
      @removePost="removePost"
      @close="closeOveraly"
      @showOtherPost="showOtherPost"
    />
    <EditPostOverlay
      v-if="editingPost"
      :post="selectedPost"
      @editPost="editPost"
    />
    <div class="btn_center_sec">
      <a class="load_more_btn" v-if="loadMoreToggle" @click="loadMorePosts"
        >Show more <span class="icon-down-open"></span></a
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TileView from "@/components/shared/TileView";
import Overlay from "@/components/shared/overlay/Overlay";
import WallTabsWrap from "../components/shared/WallTabsWrap.vue";
import EditPostOverlay from "../components/shared/overlay/EditPostOverlay.vue";
import LoaderOverlay from "../components/shared/overlay/LoaderOverlay.vue";
import axios from "axios";
import CreateOverlayMixin from "@/mixins/CreateOverlayMixin";
import $ from "jquery";

export default {
  name: "User",
  components: {
    TileView,
    WallTabsWrap,
    Overlay,
    EditPostOverlay,
    LoaderOverlay,
  },

  mixins: [CreateOverlayMixin],

  data() {
    return {
      isBioOpening: false,
      selectedPostId: 0,
      isOverlay: false,
      orderBy: null,
      winnerCount: 0,
      top10Count: 0,
      gold_count: 0,
      copyButtonText: "Copy Url",
      themeData: {},
      elementTheme: [],
      profileTheme: "",
      disableFollow: false,
    };
  },

  computed: {
    ...mapState({
      userEmail: (state) => state.auth.user.email,
      userTheme: (state) => state.auth.user.selected_theme[0],
      following: (state) => state.auth.following,
      isLogin: (state) => state.auth.isLogin,
      activeTab: (state) => state.base.activeTab,
      userData: (state) => state.profileModule.userData,
      posts: (state) => state.profileModule.posts,
      editingPost: (state) => state.uxModule.editingPost,
      totalPosts: (state) => state.profileModule.totalPosts,
      pageNumber: (state) => state.profileModule.pageNumber,
      isLoading: (state) => state.auth.isLoading,
      toggleShow: (state) => state.profileModule.toggleShow,
      follower_count: (state) => state.profileModule.follower_count,
      selectedTheme: (state) => state.auth.selectedTheme,
    }),
    link() {
      return process.env.VUE_APP_PREFIX + "/themes";
    },
    loadMoreToggle() {
      const limit = 10;
      return Math.ceil(this.totalPosts / limit) > this.pageNumber
        ? true
        : false;
    },
    disableFollowButton() {
      let $this = this;
      for (let item in $this.following) {
        if ($this.following[item].email === $this.userData.email) {
          return true;
        }
      }
      return false;
    },

    uuid() {
      return this.$route.params.uuid;
    },
    userAvatar() {
      if (this.userData.avatar) {
        return this.userData.avatar;
      } else {
        return "";
      }
    },
    hasFacebook() {
      if (this.userData.facebook_url === "null") return false;
      return this.userData.facebook_url;
    },
    hasInstagram() {
      if (this.userData.instagram_url === "null") return false;
      return this.userData.instagram_url;
    },
    hasTwitter() {
      if (this.userData.twitter_url === "null") return false;
      return this.userData.twitter_url;
    },
    hasLinkedin() {
      if (this.userData.linkedin_url === "null") return false;
      return this.userData.linkedin_url;
    },
    hasPinterest() {
      if (this.userData.pinterest_url === "null") return false;
      return this.userData.pinterest_url;
    },
    hasNext() {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPost.post_id
      );
      if (index + 1 === this.posts.length) return false;
      return true;
    },
    hasPrev() {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPost.post_id
      );
      if (index === 0) return false;
      return true;
    },
    selectedPost() {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPostId
      );
      if (index === -1) return { ...this.posts[0] };
      return { ...this.posts[index] };
    },
    rewardProfileTheme() {
      let themes = [];
      if (this.themeData && Object.keys(this.themeData).length > 0) {
        Object.keys(this.themeData).forEach((key) => {
          if (
            this.themeData[key][1] != "default" &&
            this.themeData[key][1] != "extra"
          ) {
            themes.push(this.themeData[key]);
          }
        });
      }
      return themes;
    },
  },

  watch: {
    uuid() {
      this.initialProfileData();
    },
    follower_count() {
      this.O2Theme();
    },
  },

  mounted() {
    this.$store.dispatch("profileModule/profileTrack", this.$route.params);
    $("body").addClass("other-user-profile");
    this.O2Theme();
    this.initialProfileData();
  },

  destroyed() {
    // if (this.userTheme) {
    //   this.$store.commit("set_selected_theme", this.userTheme);
    // } else {
    //   this.$store.commit("set_selected_theme", "o2");
    // }
    $("body").removeClass("other-user-profile");
    this.$store.commit("set_other_user_uuid", "");
  },

  methods: {
    copyURL() {
      const el = document.createElement("textarea");
      el.value = process.env.VUE_APP_PREFIX + this.$route.path;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      this.copyButtonText = "Copied";
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    async initialProfileData() {
      this.$store.dispatch("resetActiveTab");
      const postData = await this.$store.dispatch(
        "profileModule/getProfileData",
        {
          id: this.uuid,
          activeTab: 0,
          pageNumber: 0,
          orderBy: this.orderBy,
        }
      );
      this.profileTheme = postData.userTheme;
      this.themeData = postData.otherProfileTheme;
      this.elementTheme = postData.elementTheme;
      this.winnerCount = postData.winner_count;
      this.top10Count = postData.top10_count;

      // if (this.userProfileTheme.length > 0) {
      //   this.$store.commit("set_selected_theme", this.userProfileTheme[0]);
      // } else {
      //   this.$store.commit("set_selected_theme", "o2");
      // }
      this.$store.dispatch("votingModule/setVotes", postData.post_data);
      this.$store.dispatch("staticLabels/set_siteheader", {
        first_name: this.userData.first_name,
        last_name: this.userData.last_name,
        country: this.userData.country,
        city: this.userData.city,
      });
    },
    editPost(postData) {
      this.$store.dispatch("profileModule/editPost", { ...postData });
    },
    async follow() {
      let $this = this;
      let data = {
        user_email: $this.userData.email,
      };
      this.disableFollow = true;
      await $this.$store.dispatch("follow", data);
      this.disableFollow = false;
      this.$store.commit(
        "profileModule/SET_FOLLOWER_COUNT",
        this.follower_count + 1
      );
    },
    unFollow(email) {
      let $this = this;
      let data = {
        user_email: $this.userData.email,
      };
      this.disableFollow = true;
      axios({
        url: "follower/remove-follow/",
        data: data,
        method: "POST",
      })
        .then((response) => {
          if (response.status === 200) {
            $this.$store.commit("removeFollowing", email);
            this.$store.commit(
              "profileModule/SET_FOLLOWER_COUNT",
              this.follower_count - 1
            );
            this.disableFollow = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clickButton() {
      if (this.disableFollowButton) {
        return this.unFollow(this.userData.email);
      } else {
        return this.follow();
      }
    },
    async changeTab(index) {
      const postData = await this.$store.dispatch(
        "profileModule/getProfileData",
        {
          id: this.uuid,
          activeTab: index,
          pageNumber: 0,
        }
      );
      this.$store.dispatch("votingModule/setVotes", postData.post_data);
      this.$store.dispatch("setActiveTab", index);
    },
    async getByPostTitle(isAtoZ) {
      await this.$store.dispatch("profileModule/getProfileData", {
        id: this.uuid,
        activeTab: 3,
        pageNumber: 0,
        show: isAtoZ,
      });
    },
    async increment(postID) {
      const res = await this.$store.dispatch("profileModule/changePressure", {
        post_id: postID,
        amount: 1,
      });
      await this.$store.dispatch("homeModule/voteRecord", postID);
      await this.$store.dispatch("voteCount", {
        post: postID,
        type: "plus",
      });
      if (this.activeTab === 1) {
        await this.$store.dispatch("profileModule/profileTrendingPost", {
          id: this.uuid,
          pageNumber: 0,
        });
      }
      if (res.status === 200) {
        this.$store.dispatch("votingModule/setVotes", res.data);
        this.$store.commit("reduce_my_votes");
      }
    },
    async decrement(postID) {
      const res = await this.$store.dispatch("profileModule/changePressure", {
        post_id: postID,
        amount: -1,
      });
      await this.$store.dispatch("voteCount", {
        post: postID,
        type: "minus",
      });
      if (res.status === 200) {
        this.$store.dispatch("votingModule/setVotes", res.data);
        this.$store.commit("reduce_my_votes");
      }
    },
    async showOverlay(postId) {
      await this.$store.dispatch("overlayModule/getComments", postId);
      this.selectedPostId = postId;
      this.isOverlay = true;
    },
    async showOtherPost(num) {
      const index = this.posts.findIndex(
        (item) => item.post_id === this.selectedPostId
      );
      this.selectedPostId = this.posts[index + num].post_id;
      await this.$store.dispatch(
        "overlayModule/getComments",
        this.posts[index + num].post_id
      );
      await this.$store.dispatch("overlayModule/StarCount", {
        post: this.posts[index + num].post_id,
      });
      if (this.isLogin) {
        await this.$store.dispatch("overlayModule/GetCommentLoveLikeData", {
          post: this.posts[index + num].post_id,
        });
        await this.$store.dispatch("overlayModule/GetLoveLikeData", {
          post: this.posts[index + num].post_id,
        });
      }
      await this.$store.dispatch("overlayModule/PostTrack", {
        post: this.posts[index + num].post_id,
        type: "post",
      });
    },
    closeOveraly() {
      this.isOverlay = false;
      this.$store.dispatch("changeState", ["overlayClass", ""]);
      $("body").removeClass("hero-overlay");
    },
    async loadMorePosts() {
      await this.$store.dispatch("profileModule/loadMorePosts", {
        show: this.toggleShow,
        pageNumber: this.pageNumber,
        activeTab: this.activeTab,
      });
    },
    removePost() {
      this.isOverlay = false;
      this.$store.dispatch("profileModule/removePost", this.selectedPost.uuid);
    },
    async O2Theme() {
      await axios({
        url: "auth/user/o2-theme",
        method: "get",
      })
        .then((response) => {
          response.data.map((item) => {
            if (item.theme_type === "rewarded") {
              if (item.theme_name === "gold") {
                this.gold_count = item.follower_count;
              }
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
