<template>
  <div class="logged-in">
    <perfect-scrollbar class="extra-panel ccc" :class="openClass">
      <div class="my-panels">
        <div
          @click="followerData"
          :class="this.toggleShow === true ? 'togg-white' : ''"
        >
          <a v-if="this.toggleShow === true" class="ozone-arr icon-up-open">
          </a>
          <a v-else class="ozone-arr icon-down-open"> </a>
        </div>

        <div v-if="this.toggleShow === false">
          <h2 class="akrobat bk-r">FOLLOWING</h2>
          <div v-if="this.following.length > 0">
            <div class="countFav p-r">
              <p>My Fave Members</p>
              <span class="follow-count" title="Following">{{
                followingCount
              }}</span>
            </div>
          </div>
          <div v-else class="following-desc-text">
            <p class="white mt-40">You're not following anyone yet.</p>
            <p class="black">
              Open any Post and hit the
              <span class="icon-user p-r"
                ><span class="plus-std-size">+</span></span
              >
              icon (top-left) to add them to your Favourite Members.
            </p>
          </div>
        </div>
        <div v-else-if="this.toggleShow === true">
          <h2 class="akrobat bk-r">FOLLOWED BY</h2>
          <div v-if="this.follower.length > 0">
            <div class="countFav p-r">
              <p>My Followers</p>
              <span title="Following">{{ followerCount }}</span>
            </div>
          </div>
          <div v-else class="following-desc-text">
            <p class="white mt-40">You're not followed by anyone yet.</p>
          </div>
        </div>

        <div v-if="this.toggleShow === false">
          <div
            v-for="(follow, index) in following"
            :key="index"
            class="faves"
            title="View Profile"
          >
            <router-link
              :to="`/profile/${follow.uuid}`"
              @click.native="$emit('hideSideBar')"
            >
              <img
                id=""
                class="pho-circs pho-2"
                :title="`${follow.first_name} ${follow.last_name}'s Profile`"
                :src="follow.avatar"
                :alt="`${follow.first_name} ${follow.last_name}`"
              />
              <h3>{{ follow.first_name }} {{ follow.last_name }}</h3>
              <h5>
                <span v-if="follow.city !== 'null' && follow.city">
                  {{ follow.city }},</span
                >
                <span v-if="follow.country !== 'null' && follow.country">
                  {{ follow.country }}</span
                >
              </h5>
            </router-link>
            <a
              class="inn-right remove"
              title="Stop following"
              @click="removeFollowing(follow.email)"
            >
              <i class="icon-trash"></i>
            </a>
          </div>
        </div>
        <div v-else-if="this.toggleShow === true">
          <div
            v-for="(follow, index) in follower"
            :key="index"
            class="faves"
            title="View Profile"
          >
            <router-link
              :to="`/profile/${follow.uuid}`"
              @click.native="$emit('hideSideBar')"
            >
              <img
                id=""
                class="pho-circs pho-2"
                :title="`View ${follow.first_name} ${follow.last_name}`"
                :src="follow.avatar"
                :alt="`${follow.first_name} ${follow.last_name}`"
              />
              <h3>{{ follow.first_name }} {{ follow.last_name }}</h3>
              <h5>
                <span v-if="follow.city !== 'null' && follow.city">
                  {{ follow.city }},</span
                >
                <span v-if="follow.country !== 'null' && follow.country">
                  {{ follow.country }}</span
                >
              </h5>
            </router-link>
            <a
              class="inn-right remove"
              title="Stop following"
              @click="removeFollower(follow.email)"
            >
              <i class="icon-trash"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="gauge"><div class="gauge-wrapper"></div></div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "FollowingComponent",

  computed: {
    ...mapState({
      myOzone: (state) => state.common.myOzone,
      isLogin: (state) => state.auth.isLogin,
      following: (state) => state.auth.following,
      follower: (state) => state.auth.follower,
      followingCount: (state) => state.auth.followingCount,
      followerCount: (state) => state.auth.followerCount,
    }),
  },

  data() {
    return {
      openClass: "",
      toggleShow: false,
    };
  },

  mounted() {
    this.openClass = "open";
  },

  methods: {
    followerData() {
      this.toggleShow = !this.toggleShow;
    },
    async removeFollowing(email) {
      let $this = this;
      let data = {
        user_email: email,
      };
      await axios({
        url: "follower/remove-follow/",
        data: data,
        method: "POST",
      })
        .then((response) => {
          if (response.status === 200) {
            $this.$store.commit("removeFollowing", email);
            $this.$store.commit("removeFollowingCount", -1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async removeFollower(email) {
      let $this = this;
      let data = {
        user_email: email,
      };
      await axios({
        url: "follower/remove-follower/",
        data: data,
        method: "POST",
      })
        .then((response) => {
          if (response.status === 200) {
            $this.$store.commit("removeFollower", email);
            $this.$store.commit("removeFollowerCount", -1);
            this.$store.dispatch("getUser");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
