<template>
  <div class="admin-overlay">
    <div class="action-btn with-user-action">
      <div class="single-btn">
        <h4>Remove Post Ban</h4>
        <button @click.once="removeBane" :disabled="removeBanePost">YES</button>
      </div>
      <div
        class="single-btn two-btn"
        :class="{ disabled: this.baneUser === true }"
      >
        <div>
          <h4>Ban User</h4>
          <b-dropdown
            :text="selectedItem"
            id="dropdown-dropup"
            variant="primary"
            class="m-2"
            v-model="selectedItem"
          >
            <template #button-content v-if="!selectedItem">
              Select Time
            </template>
            <b-dropdown-item
              v-for="time in list"
              :key="time.id"
              :value="time[1]"
              @click="selectedItem = time[1] + ' ' + time[0]"
            >
              <span v-if="time[0] === 'Permanent'">{{ time[0] }}</span>
              <span v-else>{{ time[1] }} {{ time[0] }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="sb-wrap">
          <h4 class="invisible">rmv</h4>
          <button @click.once="userBane">YES</button>
        </div>
      </div>
    </div>
    <perfect-scrollbar class="wrapper">
      <div class="content">
        <ul class="feed">
          <li>
            <div class="title">
              <div class="title-row">
                <label>Post Title:</label>
                <p class="post-name">{{ title }}</p>
              </div>
              <div class="title-row">
                <label>Post Description:</label>
                <p>{{ discription }}</p>
              </div>
            </div>
            <a :href="postURL" target="_blank" class="anti-a">
              <div class="imgWrap" v-if="overlayImages.length">
                <img
                  v-if="overlayImages.length"
                  :src="overlayImages[activeLi].url"
                  alt=""
                />
                <ul
                  data-title="Image"
                  data-title-top
                  v-if="overlayImages && overlayImages.length > 1"
                  class="multi-image-controls"
                >
                  <li
                    v-for="(image, index) in overlayImages"
                    :key="index"
                    :class="{ active: activeLi === index }"
                    @click="activeLi = index"
                  ></li>
                </ul>
              </div>
              <div
                class="imgWrap mt-20"
                v-for="(video, index) in overlayVideo"
                :key="index"
              >
                <iframe
                  width="560"
                  height="315"
                  :src="video.url"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </a>
            <div class="author">
              <span class="user mr-5">
                <i class="icon-user"></i>
                <a
                  class="anti-a mr-0"
                  :title="`${overlayPostAuthor}'s rofile`"
                  @click="goToProfile"
                >
                  {{ overlayPostAuthor }}
                </a>
              </span>
              <span class="date">
                <i class="icon-calendar"></i>
                {{ postTimeAgo(overlayPostDate) }}
              </span>
              <a class="avatar" @click="goToProfile">
                <div
                  class="on-off"
                  :class="[status === true ? 'on-now' : '']"
                  :data-title="status === true ? 'Online' : 'Offline'"
                  data-title-right
                ></div>
                <img
                  class="pho-circs pho-2"
                  :title="`${overlayPostAuthor}'s Profile`"
                  :src="overlayPostAuthorImg"
                  :alt="overlayPostAuthor"
                />
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div></div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import CommentTimeMixin from "@/mixins/CommentTimeMixin";
import axios from "axios";
import { mapState } from "vuex";
import $ from "jquery";
import { BDropdown, BDropdownItem } from "bootstrap-vue";

export default {
  name: "AdminOverlay",

  components: {
    BDropdown,
    BDropdownItem,
  },

  data() {
    return {
      title: "",
      discription: "",
      overlayImages: [],
      overlayVideo: [],
      activeLi: 0,
      overlayPostAuthor: "",
      overlayPostDate: "",
      overlayPostAuthorImg: "",
      authorUUID: "",
      banePost: false,
      postId: "",
      baneUser: false,
      setTimePeriod: {},
      list: [],
      selectedItem: "",
      uuid: "",
      status: false,
    };
  },

  computed: {
    ...mapState({
      showPressure: (state) => state.common.showPressure,
      setBanPeriod: (state) => state.common.setBanPeriod,
    }),
    postURL() {
      return process.env.VUE_APP_PREFIX + "/post/" + this.uuid;
    },
    removeBanePost() {
      return this.banePost === false ? true : false;
    },
  },

  mixins: [CommentTimeMixin],

  mounted() {
    this.overlayPostData();
    $("body").addClass("adm-overlay");
  },

  created() {
    this.postTimeAgo();
  },

  methods: {
    async overlayPostData() {
      const post_uuid = this.$route.params.uuid;
      const data = await this.$store.dispatch(
        "setOverlayPostByUUID",
        post_uuid
      );
      this.overlayImages = data.post_data[0].images;
      this.overlayVideo = data.post_data[0].videos;
      this.title = data.post_data[0].title;
      this.discription = data.post_data[0].summary;
      this.overlayPostAuthor = data.post_data[0].author;
      this.overlayPostDate = data.post_data[0].post_date;
      this.overlayPostAuthorImg = data.post_data[0].author_img;
      this.authorUUID = data.post_data[0].author_uuid;
      this.banePost = data.post_data[0].bane_post;
      this.postId = data.post_data[0].post_id;
      this.baneUser = data.post_data[0].bane_user;
      this.uuid = data.post_data[0].uuid;
      this.status = data.post_data[0].author_status;
      this.setBanTimePeriod();
    },

    async setBanTimePeriod() {
      const tmp = await this.$store.dispatch("setBanTime", this.postId);
      this.baneUser = tmp.userBan;
      this.setTimePeriod = tmp.data;
      const entries = Object.entries(this.setTimePeriod);
      this.list = entries;
    },
    async removeBane() {
      await axios({
        url: "/posts/remove-bane/",
        method: "post",
        data: {
          post_id: this.postId,
        },
      })
        .then((response) => {
          this.banePost = false;
          this.$store.dispatch("showToast", {
            message: response.data.message,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async userBane() {
      await axios({
        url: "/posts/bane-user/",
        method: "post",
        data: {
          post_id: this.postId,
          timePeriod: this.selectedItem,
        },
      })
        .then((response) => {
          this.baneUser = true;
          this.$store.dispatch("showToast", {
            message: response.data.message,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goToProfile() {
      $("body").removeClass("adm-overlay");
      this.$router.push({
        name: "Profile",
        params: {
          uuid: this.authorUUID,
        },
      });
    },
  },
};
</script>