import * as api from "../Services/api.js";
import axios from "axios";

export const state = {
  winners: [],
  comments: [],
  overlayPost: {},
  totalComments: 0,
  totalCommentsCount: 0,
  pageNumber: 0,
};

export const mutations = {
  SET_WINNERS(state, payload) {
    state.winners = payload.post_data;
  },
  SET_COMMENTS(state, data) {
    state.comments = data.comments;
    state.totalComments = data.comment_count;
    state.totalCommentsCount = data.totalCommentsCount;
    state.pageNumber = 1;
  },
  SET_COMMENTS_COUNT(state, data) {
    state.totalComments = state.totalComments + data;
  },
  SET_TOTAL_COMMENTS_COUNT(state, data) {
    state.totalCommentsCount = state.totalCommentsCount + data;
  },
  SET_LOADED_COMMENT(state, data) {
    state.comments = state.comments.concat(data.comments);
    state.pageNumber += 1;
  },
  SET_PAGE_NUMBER(state, data) {
    state.pageNumber = data;
  },
  SET_SUB_COMMENT_CURR_PAGE(state, data) {
    const index = state.comments.findIndex((item) => item.comm_id === data);
    state.comments[index].curr_page = 0;
  },
  SET_LOADED_SUB_COMENTS(state, data) {
    const index = state.comments.findIndex(
      (item) => item.comm_id === data.comm_id
    );
    state.comments[index].sub_comm = state.comments[index].sub_comm.concat(
      data.sub_comments
    );
    state.comments[index].curr_page = state.comments[index].curr_page + 1;
  },
  REMOVE_COMMENT(state, data) {
    state.comments.splice(data.index, 1);
    if (state.comments.length === 0) {
      state.comments.splice(data.index, 1);
    }
  },
  REMOVE_SUB_COMMENT(state, data) {
    state.comments[data.index].sub_comm.splice(data.idx, 1);
    state.comments[data.index].total_sub_comm =
      state.comments[data.index].total_sub_comm - 1;
  },
  SET_OVERLAY_POST(state, payload) {
    state.overlayPost = payload;
  },
};

export const getters = {};

export const actions = {
  async getWinners({ commit }, data) {
    await api
      .get(
        "/posts/get-winners/?query=" + data.query
      )
      .then((response) => {
        commit("SET_WINNERS", response);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async setOverlayPost(context, post_id) {
    const post = context.state.winners.find((post) => post.post_id === post_id);
    context.commit("SET_OVERLAY_POST", post);
  },
  async getComments({ commit }, post_id) {
    await api
      .get("/posts/comments/?post_id=" + post_id + "&page_num=0")
      .then((response) => {
        commit("SET_COMMENTS", response);
      });
  },
  async loadMoreComments({ commit }, post_id) {
    await api
      .get(
        "/posts/comments/?post_id=" + post_id + "&page_num=" + state.pageNumber
      )
      .then((response) => {
        commit("SET_LOADED_COMMENT", response);
      });
  },
  async loadMoreSubComments({ commit }, data) {
    await api
      .get(
        "/posts/get-subcomments/?comm_id=" +
          data.comm_id +
          "&page_num=" +
          data.subPageNum
      )
      .then((response) => {
        commit("SET_LOADED_SUB_COMENTS", response);
      });
  },
  async removeComment({ commit }, data) {
    const res = await axios({
      url: "posts/remove-comment/",
      method: "POST",
      data: { comment_id: data.comment_id },
    })
      .then((response) => {
        if (response.status === 200) {
          commit("REMOVE_COMMENT", data);
        }
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  },
  async removeSubComment({ commit }, data) {
    const res = await axios({
      url: "posts/remove-sub-comment/",
      method: "POST",
      data: {
        sub_comment_id: data.sub_comment_id,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          commit("REMOVE_SUB_COMMENT", data);
        }
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
