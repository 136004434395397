<template>
  <div class="token-info-page">
    <div id="content" class="content-t-m" role="main">
      <div class="title">
        <label
          ><img
            style="vertical-align: bottom"
            src="../assets/images/tkn-o2-29x29.png"
            alt=""
          />Tokens are O2's virtual currency.</label
        >
        <p>
          They can be earned, won, gifted or received
          <!-- bought, gifted or received, -->
          and used for many cool, fun or strategic purposes on the site.
        </p>
      </div>

      <div class="t-info-box">
        <h2 class="akrobat">How to Earn Tokens</h2>

        <div>
          <ul>
            <li>
              <strong class="blk">Winner: 1st place</strong> -
              <strong class="darkC">{{ firstWinner }} Tokens</strong>
              <em class="fs-9 pl-5">(finish 1st on the Leaderboard)</em>
            </li>
            <li>
              <strong class="blk">Winner: 2nd-3rd places</strong> -
              <strong class="darkC">{{ secondWinner }} Tokens</strong> /
              <strong class="darkC">2 Boosts</strong> /
              <strong class="darkC"> 1 Custom Theme</strong> - Randomly received
              -
              <em class="fs-9 pl-5">(finish 2nd-3rd on the Leaderboard)</em>
            </li>
            <li>
              <strong class="blk">Winner: 4th-10th places</strong> -
              <strong class="darkC">{{ restWinners }} Tokens</strong>
              <em class="fs-9 pl-5">(finish 4th-10th on the Leaderboard)</em>
            </li>
            <li>
              <strong class="blk">Most Posts</strong> -
              <strong class="darkC">{{ userActivity }} Tokens</strong>
              <em class="fs-9 pl-5">(Posting the most times, any week)</em>
            </li>
            <li>
              <strong class="blk">{{ goldCount }}+ Followers</strong> -
              <strong class="darkC">{{ goldToken }} Tokens</strong> +
              <strong>Gold theme</strong>
              <em class="fs-9 pl-5">(having {{ goldCount }}+ followers)</em>
            </li>
            <li>
              <strong class="blk">{{ platinumCount }}+ Followers</strong> -
              <strong class="darkC">{{ platinumToken }} Tokens</strong> +
              <strong>Platinum</strong> theme
              <em class="fs-9 pl-5">(having {{ platinumCount }}+ followers)</em>
            </li>
            <li>
              <strong class="blk">{{ diamondCount }}+ Followers</strong> -
              <strong class="darkC">{{ diamondToken }} Tokens</strong> +
              <strong>Diamond</strong> theme
              <em class="fs-9 pl-5">(having {{ diamondCount }}+ followers)</em>
            </li>
            <li>
              <strong class="blk">Receiving Stars</strong> - on your Posts
              <em class="fs-9 pl-5">(for posting great content)</em><br />
              <i class="icon-star gold"></i>Gold Star -
              <strong class="darkC">{{ goldStar }} Tokens</strong><br />
              <i class="icon-star silver"></i>Silver Star -
              <strong class="darkC">{{ silverStar }} Tokens</strong><br />
              <i class="icon-star bronze"></i>Bronze Star -
              <strong class="darkC">{{ bronzeStar }} Tokens</strong><br />
            </li>
            <li>
              <strong class="blk">Refer a friend</strong> -
              <strong class="darkC">{{ referFriend }} Tokens</strong>
              <em class="fs-9 pl-5">
                (Refer-a-friend form on
                <router-link to="/o2hq">O2HQ</router-link> page)</em
              >
            </li>
            <li>
              <strong class="blk">Newsletter signup</strong> -
              <strong class="darkC">{{ newsLatter }} Tokens</strong>
              <em class="fs-9 pl-5">
                (Signup on
                <router-link to="/o2hq">O2HQ</router-link> page)</em
              >
            </li>
            <li>
              <strong class="blk">Bugs/Site suggestion</strong> -
              <strong class="darkC">{{ bugs }} Tokens</strong>
              <em class="fs-9 pl-5">
                (Finding site bugs or suggesting an accepted site improvement -
                form on
                <router-link to="/o2hq">O2HQ</router-link> page)</em
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="t-info-box">
        <h2 class="akrobat">How can Tokens be used?</h2>

        <div>
          <!-- For... -->
          <ul>
            <li>
              <strong class="blk">Get Extra Votes:</strong> to send Posts faster
              up the
              <router-link to="/leaderboard">Leaderboard</router-link>
            </li>
            <li>
              <strong class="blk">Get More Time:</strong> Extend a Post's Life,
              so it can be voted on for longer, or into the next week's
              competition
            </li>
            <li>
              <strong class="blk">Boost Posts:</strong> Send any Post to the Top
              of the Leaderboard <em class="fs-9">(Nice!)</em>
            </li>
            <li>
              <strong class="blk">Custom Themes:</strong> Treat yourself to a
              selection of multi-coloured
              <router-link to="/themes">Custom website themes</router-link> to
              suit your mood or superpower
            </li>
            <li>
              <strong class="blk">Gifting to Others:</strong> Reward others with
              Bronze, Silver or Gold Stars for posting great content.
            </li>
          </ul>
        </div>
      </div>

      <div class="t-info-box">
        <h2 class="akrobat" v-if="user.admin">How to Add Tokens</h2>
        <div class="manually-buy-token" v-if="user.admin">
          <span>Admin: </span>
          <div class="m-buy-token">
            <input
              type="number"
              v-model="token"
              placeholder="Token Quantity"
              @input="filterInput"
            />
            <a
              href="javascript:void(0)"
              class="button primary"
              @click="addToken"
              >Add</a
            >
          </div>
        </div>
        <!-- <div class="or-seprator" v-if="user.admin">
          <span>OR</span>
        </div>
        <div v-for="(item, index) in tokenScheme" :key="index">
          <div class="what-token mb-20" :class="item.tags ? 'lgt-bg' : ''">
            <div class="tag" v-if="item.tags">{{ item.tags }}</div>
            <div class="discount" v-if="item.bonus_token">
              {{ item.bonus_token }}<span>%</span>
              <div>BONUS</div>
            </div>
            <div class="t1">
              <div
                :class="`spr-tokens-t-${item.token_quantity} size120 p-r`"
              ></div>
            </div>
            <div class="t3">
              <h2 class="hide600">{{ item.token_quantity }}</h2>
              <p class="hide600 blk">TOKENS</p>
              <p class="fs-9">
                <strong>{{ item.slogan }}</strong>
              </p>
              <p class="fs-7">{{ item.extra }}</p>
            </div>
            <div class="t2">
              <a
                v-if="isLogin"
                data-title="Add to Cart"
                data-title-top
                class="token-buy button primary pr-15 pl-15"
                @click="AddTokenToCart(index)"
                >${{ item.token_cost }}</a
              >
            </div>
          </div>
        </div> -->

        <div class="panel-blue-block p-15 pr-20 pl-20 mb-30 mt-30 tx-c">
          <h2 class="byline">O2 Social</h2>
          <p>
            <span class="akr-gen">FOLLOW US ON...</span>
          </p>
          <div class="o2-socials">
            <span data-title="O2 Facebook" data-title-top
              ><a
                href="https://www.facebook.com/O2Unlimited"
                target="_blank"
                class="icon-facebook"
              ></a
            ></span>
            <span data-title="O2 Instagram" data-title-top
              ><a
                href="https://www.instagram.com/o2positive_com"
                target="_blank"
                class="icon-instagram"
              ></a
            ></span>
            <span data-title="O2 Pinterest" data-title-top>
              <a
                href="https://www.pinterest.nz/o2positive_com"
                target="_blank"
                class="icon-pinterest"
              ></a
            ></span>
            <span data-title="O2 Twitter" data-title-top
              ><a
                href="https://www.twitter.com/O2Unlimited"
                target="_blank"
                class="icon-twitter"
              ></a
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import $ from "jquery";
import axios from "axios";

export default {
  name: "Tokens",

  data() {
    return {
      token: "",
      goldToken: 0,
      platinumToken: 0,
      diamondToken: 0,
      firstWinner: 0,
      secondWinner: 0,
      restWinners: 0,
      userActivity: 0,
      referFriend: 0,
      newsLatter: 0,
      bugs: 0,
      goldCount: 0,
      platinumCount: 0,
      diamondCount: 0,
      goldStar: 0,
      silverStar: 0,
      bronzeStar: 0,
    };
  },

  mounted() {
    this.tokenData();
    if (this.$route.name === "Tokens") {
      $(".custom-screen").addClass("full-screen");
    }
    this.$store.dispatch("TokenScheme");
  },
  destroyed() {
    $(".custom-screen").removeClass("full-screen");
  },

  computed: {
    ...mapState({
      tokenScheme: (state) => state.auth.tokenScheme,
      user: (state) => state.auth.user,
      isLogin: (state) => state.auth.isLogin,
    }),
  },

  methods: {
    tokenData() {
      axios({
        url: "user/token/token-value/",
        method: "GET",
      }).then((response) => {
        response.data.freeToken.forEach((element) => {
          if (element.gold) {
            this.goldToken = element.gold;
          } else if (element.platinum) {
            this.platinumToken = element.platinum;
          } else if (element.diamond) {
            this.diamondToken = element.diamond;
          } else if (element.first_winner_token) {
            this.firstWinner = element.first_winner_token;
          } else if (element.random_token) {
            this.secondWinner = element.random_token;
          } else if (element.winner_token) {
            this.restWinners = element.winner_token;
          } else if (element.user_activity) {
            this.userActivity = element.user_activity;
          } else if (element.refer_friend) {
            this.referFriend = element.refer_friend;
          } else if (element.news_latter) {
            this.newsLatter = element.news_latter;
          } else if (element.site_bug) {
            this.bugs = element.site_bug;
          }
        });
        response.data.themeCount.forEach((element) => {
          if (element.gold) {
            this.goldCount = element.gold;
          } else if (element.platinum) {
            this.platinumCount = element.platinum;
          } else if (element.diamond) {
            this.diamondCount = element.diamond;
          }
        });
        response.data.paidToken.forEach((element) => {
          if (element.gold) {
            this.goldStar = element.gold;
          } else if (element.silver) {
            this.silverStar = element.silver;
          } else if (element.bronze) {
            this.bronzeStar = element.bronze;
          }
        });
      });
    },
    filterInput(e) {
      e.target.value = e.target.value.replace(/[^0-9]+/g, "");
    },
    async addToken() {
      const data = {
        token: parseInt(this.token),
      };
      await axios({
        url: "user/token/admin-token/",
        method: "POST",
        data: data,
      }).then((response) => {
        if (response.status === 200) {
          this.$store.commit(
            "set_user_token",
            this.user.token + parseInt(this.token)
          );
          this.$store.dispatch("showToast", {
            message: this.token + " Token added in your account.",
          });
          this.token = "";
        }
      });
    },
    async AddTokenToCart(index) {
      const data = {
        item_cost: this.tokenScheme[index].token_cost,
        item_id: this.tokenScheme[index].token_id,
        user: this.user.id,
        quantity: 1,
      };
      await this.$store.dispatch("AddTokenToCart", data);
      await this.$store.dispatch("CartData");
      await this.$store.dispatch("showToast", {
        message: "Token Succesfully added to the Cart.",
      });
    },
  },
};
</script>
