import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import LeaderBoard from "../views/LeaderBoard.vue";
import HowItWorks from "../views/HowItWorks.vue";
import WhatIsO2 from "../views/WhatIsO2.vue";
import Winners from "../views/Winners.vue";
import FavoritePosts from "../views/FavoritePosts.vue";
import O2hq from "../views/O2hq.vue";
import Profile from "../views/Profile.vue";
import User from "../views/User.vue";
import TermsOfService from "../views/TermsOfService.vue";
import Activation from "../views/Activation.vue";
import ForgetPassword from "../views/ForgetPassword.vue";
import OpenedOverlay from "../views/OpenedOverlay.vue";
import Search from "../views/Search.vue";
import SocialPrivacyPolicyComponent from "../../src/components/hq/SocialPrivacyPolicyComponent.vue";
import AdminOverlay from "../views/AdminOverlay";
import AdminUserView from "../views/AdminUserView.vue";
import Themes from "../views/Themes.vue";
import ErrorPage from "../views/ErrorPage.vue";
import Tokens from "../views/Tokens.vue";
import SuccessPayment from "../components/SuccessPayment.vue";
import FailurePayment from "../components/FailurePayment.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/users/activation/:uid/:token",
    name: "Activation",
    component: Activation,
  },
  {
    path: "/users/reset-password/:uid/:token",
    name: "ForgetPassword",
    component: ForgetPassword,
  },
  {
    path: "/post/:uuid",
    name: "OpenedOverlay",
    component: OpenedOverlay,
  },
  {
    path: "/leaderboard",
    name: "Leaderboard",
    component: LeaderBoard,
  },
  {
    path: "/winners",
    name: "Winners",
    component: Winners,
  },
  {
    path: "/favoriteposts",
    name: "FavoritePosts",
    component: FavoritePosts,
    meta: { requiresAuth: true },
  },
  {
    path: "/o2hq",
    name: "O2hq",
    component: O2hq,
    params: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/user",
    name: "User",
    component: User,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/:uuid",
    name: "Profile",
    component: Profile,
    params: true,
  },
  {
    path: "/howitworks",
    name: "HowItWorks",
    component: HowItWorks,
  },
  {
    path: "/whatiso2",
    name: "WhatIsO2",
    component: WhatIsO2,
  },
  {
    path: "/termsofservice",
    name: "TermsOfService",
    component: TermsOfService,
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
    props: true,
  },
  {
    path: "/privacy",
    name: "PrivacyPolicy",
    component: SocialPrivacyPolicyComponent,
  },
  {
    path: "/admin-post/:uuid",
    name: "AdminOverlay",
    component: AdminOverlay,
  },
  {
    path: "/user-profile/:uuid",
    name: "AdminUserView",
    component: AdminUserView,
    params: true,
  },
  {
    path: "/themes",
    name: "Themes",
    component: Themes,
    meta: { requiresAuth: true },
  },
  {
    path: "/tokens",
    name: "Tokens",
    component: Tokens,
  },
  {
    path: "/success/:uuid",
    name: "SuccessPayment",
    component: SuccessPayment,
    meta: { requiresAuth: true },
  },
  {
    path: "/failure",
    name: "FailurePayment",
    component: FailurePayment,
    meta: { requiresAuth: true },
  },
  {
    path: "/404",
    name: "PageNotFound",
    component: ErrorPage,
  },
  {
    // path: "/:notFound(.*)",
    path: "*",
    name: "ErrorPage",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((rec) => rec.meta.requiresAuth)) {
    const data = localStorage.getItem("accessToken");
    // if (store.getters.isLogin) {
    //   next();
    // }
    if (data) {
      next();
    } else {
      next({ name: "Home" });
    }
  } else {
    next();
  }
});
export default router;
