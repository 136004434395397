<template>
  <div class="ozone-wrapper">
    <perfect-scrollbar class="wrapper">
      <div class="status-section" :class="openClass">
        <h2>Login</h2>
        <p class="blk"><strong>continue with:</strong></p>
        <div class="social-btns login_sc_icons_wrapper">
          <div data-title="Facebook" data-title-top="">
            <facebook-login
              id="FaceBookBtn"
              class="fb-btn"
              appId="364191498094210"
              @login="getFacebookData"
            >
              <img src="../../../assets/images/google.png" alt="" />
            </facebook-login>
          </div>

          <div data-title="Google" data-title-top="">
            <GoogleLogin
              class="g-btn"
              :params="params"
              :onSuccess="onSuccess"
              :onFailure="onFailure"
              :onCurrentUser="onCurrentUser"
              ><img src="../../../assets/images/google.png" alt=""
            /></GoogleLogin>
          </div>
        </div>
        <div ref="modalShow">
          <userBanedModal
            :show="userBanedModal"
            :period="suspendedTimePeriod"
            :count="suspendedTimeCount"
            :userBanBy="user_ban_by"
            @toggle-modal="userBanedModal = !userBanedModal"
            @handle-submit="userAdminConatct"
          />
        </div>
        <userAdminContactModal
          :showModal="userAdminContactModal"
          :user_email="this.user.email"
          @toggle-modal-user="userAdminContactModal = !userAdminContactModal"
          @handle-submit="contactToAdmin"
        />
        <p class="blk"><strong>or your email:</strong></p>
        <form class="login-form">
          <div class="fieldset">
            <label for="username" class="offset">User name</label>
            <div class="input-holder" :class="errorMessage ? 'is-invalid' : ''">
              <input
                id="email"
                v-model="user.email"
                type="text"
                placeholder="Email"
              />
            </div>
          </div>
          <div class="fieldset">
            <label for="password" class="offset">Password</label>
            <div class="input-holder" :class="errorMessage ? 'is-invalid' : ''">
              <input
                id="password"
                v-model="user.password"
                @keyup="handleKeyEvent"
                type="password"
                placeholder="Password"
              />
            </div>
            <p class="error-message" v-if="errorMessage">
              <i class="icon-attention-circled">{{ errorMessage }}</i>
            </p>
          </div>
          <div class="fieldset cb">
            <input type="checkbox" id="keepme" />
            <label for="keepme" class="label">Remember me</label>
          </div>
          <a
            class="button primary full"
            data-title="Log in to your account"
            data-title-top
            @click="login"
            ><i class="icon-lock-open"></i>Log in</a
          >
          <p class="blk tx-c"><strong>or</strong></p>
          <p>
            <a
              class="button outline full"
              data-title="Join O2"
              data-title-top
              data-mobile="true"
              @click="changeMyOzone(3)"
            >
              <i class="icon-lock"></i>
              Sign up
            </a>
          </p>
          <p class="fs-8">
            <a
              class="nowrap anti-a"
              data-mobile="true"
              data-title="Don't Panic!"
              data-title-top
              @click="changeMyOzone(2)"
            >
              Forgot your Password?
            </a>
          </p>
        </form>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GoogleLogin from "vue-google-login";
import facebookLogin from "facebook-login-vuejs";
import ChangeMyOzoneMixin from "@/mixins/ChangeMyOzoneMixin";
import userBanedModal from "../../shared/modal/userBanedModal.vue";
import userAdminContactModal from "../../shared/modal/userAdminContactModal.vue";
import $ from "jquery";

export default {
  name: "Login",
  components: {
    GoogleLogin,
    facebookLogin,
    userBanedModal,
    userAdminContactModal,
  },

  mixins: [ChangeMyOzoneMixin],

  computed: {
    ...mapState({
      errorMessage: (state) => state.auth.ErrorMessage,
      posts: (state) => state.searchModule.posts,
      selectedTheme: (state) => state.auth.selectedTheme,
      loginType: (state) => state.auth.loginType,
    }),
  },

  data() {
    return {
      openClass: "",
      user: {
        email: "",
        password: "",
      },
      params: {
        client_id:
          "2133277209-a8dn0n9nomkia9c5g7vlr7hnqbdb5opb.apps.googleusercontent.com",
        scope: "email",
        response_type: "code",
      },
      // only needed if you want to render the button with the google ui
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
      social: {
        password: "",
        email: "",
        personalID: "",
        first_name: "",
        last_name: "",
      },
      userBanedModal: false,
      userAdminContactModal: false,
      suspendedTimePeriod: "",
      suspendedTimeCount: "",
      user_ban_by: "",
    };
  },

  mounted() {
    this.openClass = "open";
  },

  created() {
    document.addEventListener("click", this.close);
  },

  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },

  methods: {
    // fetch outer click of modal
    close(e) {
      const modalEl = this.$refs.modalShow;
      if (!modalEl.contains(e.target)) {
        if (this.userBanedModal) {
          this.userBanedModal = false;
        }
      }
    },
    onCurrentUser() {},
    async onSuccess(googleUser) {
      const profileData = googleUser.getBasicProfile();
      this.social.first_name = profileData.getGivenName();
      this.social.last_name = profileData.getFamilyName();
      this.social.personalID = profileData.getId();
      this.social.email = profileData.getEmail();
      this.$store.commit("set_loginType", "google");
      setTimeout(() => {
        this.socialLogin();
      }, 1000);
    },
    getFacebookData(facebookUser) {
      window.FB.api(
        `/me`,
        "GET",
        { fields: "id,email,name,first_name,last_name, picture" },
        (userInformation) => {
          this.social.first_name = userInformation.first_name;
          this.social.last_name = userInformation.last_name;
          this.social.personalID = userInformation.id;
          this.$store.commit(
            "set_loginType",
            facebookUser.response.authResponse.graphDomain
          );
          if (userInformation.email) {
            this.social.email = userInformation.email;
          } else {
            this.social.email = userInformation.id + "@facebook.com";
          }
        }
      );
      setTimeout(() => {
        this.socialLogin();
      }, 1000);
    },
    async socialLogin() {
      const checkType = await this.$store.dispatch("checkType", {
        email: this.social.email,
      });
      let user = {
        email: this.social.email,
        password: this.social.email,
        loginType: this.loginType,
      };
      if (checkType.data === "login") {
        await this.$store.dispatch("login", user);
      } else {
        let data = {
          first_name: this.social.first_name,
          last_name: this.social.last_name,
          email: this.social.email,
          password: this.social.email,
          loginType: this.loginType,
        };
        this.$store.dispatch("signUp", data);
        setTimeout(() => {
          this.loginAPI();
        }, 1000);
      }
    },
    loginAPI() {
      let user = {
        email: this.social.email,
        password: this.social.email,
        loginType: this.loginType,
      };
      this.$store.dispatch("login", user);
    },
    onFailure(error) {
      console.log(error);
    },
    handleKeyEvent(event) {
      let $this = this;
      if (event.key === "Enter") {
        $this.login();
      }
    },
    async login() {
      if (this.user.email !== "" && this.user.password !== "") {
        const response = await this.$store.dispatch("login", this.user);
        await this.$store.dispatch("setUserStatus", {
          status: true,
          user: this.user.email,
        });
        $("body").addClass("theme-" + response.data.selected_theme);
        if (response.status === 404) {
          this.userBanedModal = true;
          const data = {
            user_email: this.user.email,
          };
          const tmp = await this.$store.dispatch("suspendedTime", data);
          this.suspendedTimeCount = tmp.timeCount;
          this.suspendedTimePeriod = tmp.timePeriod;
          this.user_ban_by = tmp.user_ban_by;
        }
      }
    },
    async userAdminConatct() {
      this.userBanedModal = false;
      this.userAdminContactModal = true;
    },
    async contactToAdmin(data) {
      await this.$store.dispatch("contactToAdmin", data);
      this.userAdminContactModal = false;
      await this.$store.dispatch("showToast", {
        message: "Request sent Succesfully to Admin!",
      });
    },
  },
};
</script>
