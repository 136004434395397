<template>
  <div id="content" class="pt-20" role="main">
    <div class="panel-blue-block pt-10 pl-30 pr-30 pb-15 tx-c mt-10">
      <div class="mb-10 mt-10">
        <div class="spr-treasures-favicon m-auto"></div>
      </div>
      <h2 class="byline tx-c">The Basics</h2>
    </div>
    <div class="sub-panel">
      <h2 class="byline mt-20 mb-5 icon-percent h2icon">Pressure Pit</h2>
      <p>
        <strong
          >When you submit a Post it appears in the
          <router-link to="/">Pressure Pit</router-link>.</strong
        ><br />
        The Pressure Pit displays <strong>all</strong> Posts created
        <strong>this week</strong>. When a Post in the Pressure Pit receives its
        first <span class="icon-plus grn"></span>vote it also appears on the
        <strong><router-link to="/leaderboard">Leaderboard</router-link></strong
        >.
      </p>
      <h2 class="byline mt-30 mb-5 icon-chart-bar h2icon">Leaderboard</h2>
      <p>
        <strong
          >All <span class="icon-plus grn"></span> voted Posts appear on the
          <router-link to="/leaderboard">Leaderboard</router-link></strong
        >.<br />
        You can<span class="icon-plus grn"></span>or<span
          class="icon-minus redc"
        ></span>
        any Post to push it higher or lower on the Leaderboard.
      </p>
      <h2 class="byline mt-30 mb-5 icon-chart-bar h2icon">From Zero to Hero</h2>
      <p>
        <strong>This column is a quick peek</strong> of the current week's
        <strong><router-link to="/leaderboard">Leaderboard</router-link></strong
        >.
      </p>
      <h2 class="byline mt-30 mb-5 icon-clock-1 h2icon">7 day competition</h2>
      <p>
        <strong>O2 Competitions start & end every 7 days.</strong> As one
        competition ends the next one begins. There's a 7-day countdown in your
        <strong>'My Ozone'</strong> panel to remind you.
      </p>
      <h2 class="byline mt-30 mb-5 icon-award h2icon">Winners</h2>
      <p>
        <strong>The Top 10 Posts</strong>
        on the
        <strong
          ><router-link to="/leaderboard">Leaderboard</router-link></strong
        >
        when each competition ends <em>(Sunday)</em> are the winners and
        permanently display on the
        <strong><router-link to="/winners">Winners'</router-link></strong> page.
        <em>
          (Plus you win rewards & trophies ...and probably global fame,
          too!)</em
        >
      </p>
      <div v-if="isLogin">
        <h2 class="p-head byline mt-20 mb-5 icon-user h2icon">Your Profile</h2>
        <p>
          <strong
            >Your <router-link to="/user">Profile</router-link> is the window to
            your world.</strong
          >
        </p>
        <p>
          Where other members can view your Posts and basic info and it's where
          you
          <em>(only)</em> can view your drafts, stats, rankings, comments,
          tokens, etc.
        </p>
        <h2 class="byline mt-30 mb-5 icon-user h2icon">02:HQ</h2>
        <p>
          This is your personal
          <strong><router-link to="/o2hq">Headquarters</router-link>,</strong>
          where you'll find information on: rewards, themes, tokens, newsletter,
          reporting, rules, terms, privacy, along with other mission critical
          info.
        </p>
      </div>
    </div>
    <!---end section -->
    <div class="panel-blue-block pt-10 pl-30 pr-30 pb-15 tx-c mt-10">
      <div class="mb-10 mt-20">
        <div class="spr-treasures-favicon m-auto"></div>
      </div>
      <h2 class="byline tx-c">What Can You Post?</h2>
    </div>
    <div class="sub-panel">
      <h2 class="byline mt-20 mb-5 icon-smile h2icon">Anything Positive</h2>
      <p>
        The O2 Community loves unique and creative content and it rewards people
        who share the most inspiring or interesting stuff.
      </p>
      <h2 class="byline mt-30 mb-5">For Example:</h2>
      <p>
        Inspiring stories or news, entertainment, art, photography, quotes,
        travel, music, pets, animals, nature, awesome people or food,
        celebrities, environment, etc. Whatever you think will be enjoyed by our
        positive-minded community.
      </p>
    </div>
    <div class="panel-blue-block pt-10 pl-30 pr-30 pb-15 tx-c mt-10">
      <div class="mb-10 mt-10">
        <div class="spr-treasures-favicon m-auto"></div>
      </div>
      <h2 class="byline tx-c">Creating Posts</h2>
    </div>
    <div class="sub-panel">
      <h2 class="byline mt-30 mb-5 icon-picture h2icon">
        How to create a Post
      </h2>
      <p>
        Just hit the <strong>ADD P0ST</strong> button
        <em
          >(at the top of the <router-link to="/">Pressure Pit</router-link>,
          <router-link to="/leaderboard">Leaderboard</router-link> and your
          <router-link to="/user">Profile</router-link> page)</em
        >
        then follow the simple steps
      </p>
    </div>
    <div class="panel-blue-block pt-10 pl-30 pr-30 pb-15 tx-c mt-10">
      <div class="mb-10 mt-10">
        <div class="spr-treasures-favicon m-auto"></div>
      </div>
      <h2 class="byline tx-c">Posts, Voting & Boosts</h2>
    </div>
    <div class="sub-panel">
      <h2 class="byline mt-30 mb-5 icon-picture h2icon">Posts</h2>
      <p>You can post as many times as you like.</p>
      <p>
        <strong>Posts receive 7-days of life <em>(oxygen)</em></strong> from the
        moment they're created. They can only be voted on during this 7-day
        period. When a Post has used its oxygen supply it 'expires' and cannot
        be voted on again.
      </p>
      <p>
        <strong
          >Expired Posts remain on the
          <router-link to="/leaderboard">Leaderboard</router-link></strong
        >
        until the competition ends
        <em>(and will be a winner if they finish in the Top 10)</em>.
      </p>
      <p>
        <strong>All Posts you create can be seen</strong> on your Profile page.
      </p>
      <p>
        <strong>It doesn't matter what time or day you post</strong>. If you
        have any O<sup>2</sup> remaining at the end of this week's competition,
        and you're not currently in the Leaderboard's Top 10, your Post will
        continue into next week's competition.
        <em>(O2 is designed to be a fair system for all users)</em>.
      </p>
      <h2 class="byline mt-20 mb-5 icon-right-hand h2icon">Voting</h2>
      <p>
        <strong
          >You can add<span class="icon-plus grn"></span>or reduce
          <span class="icon-minus redc"></span> pressure on any Posts.</strong
        ><br />
        The most popular (<span class="icon-plus grn"></span>) Posts rise up the
        <strong
          ><router-link to="/leaderboard">Leaderboard</router-link></strong
        >
        and the less popular (<span class="icon-minus redc"></span>) Posts
        disappear into the big black hole of cyberspace.
      </p>
      <ul>
        <li>You have <strong>50</strong> votes available to use every day</li>
        <li v-if="isLogin">
          You have <strong class="red">{{ votes }}</strong>
          votes remaining for today
        </li>
        <li>You can vote on any Posts as many times as you want to</li>
        <li>
          Our unique voting algorithm fairly measures positive & negative
          pressure, quantity, time & percentage, to relatively position every
          Post, in real-time.
        </li>
        <li>
          The top 10 Posts on the
          <strong
            ><router-link to="/leaderboard">Leaderboard</router-link></strong
          >
          at the end of each week, are the winners for that week.
        </li>
      </ul>
      <p>
        <strong class="blk">Get more Votes</strong> to use by clicking the
        <strong>'Get more'</strong> link in your
        <strong>My Ozone</strong> Panel.
      </p>
      <h2 class="byline mt-20 mb-5 icon-rocket h2icon">Boosts</h2>
      <div>
        Send any Posts immediately to first place at the top of the Leaderboard
        by simply clicking the <strong>BOOST TO TOP</strong> button
        <em>(found inside each Post)</em>.
        <p>
          You need to
          <strong
            ><router-link to="/tokens">Earn some Tokens</router-link></strong
          >
          before you can Boost a Post
        </p>
      </div>
      <!-- end section -->

      <!-- SHOW THIS  CONTENT  WHEN A USER IS LOGGED IN ---->
      <div
        v-if="isLogin"
        class="panel-blue-block pt-10 pl-30 pr-30 pb-15 tx-c mt-10"
      >
        <div class="mb-10 mt-20">
          <div class="spr-treasures-favicon m-auto"></div>
        </div>
        <h2 class="byline tx-c">How to find</h2>
      </div>
      <div v-if="isLogin" class="sub-panel">
        <h4 class="icon-user p-head noHover h2icon">HQ</h4>
        <p>
          Click the
          <strong><router-link to="/o2hq">HQ icon</router-link></strong> link to
          open your mission headquarters.
        </p>
        <h4 class="icon-user p-head h2icon">Profile</h4>
        <p>
          Click the
          <strong><router-link to="/user">My Profile</router-link></strong> link
          in your <strong>Ozone</strong> panel to view your Profile page.
        </p>
        <h4 class="icon-edit p-head h2icon">Edit Profile</h4>
        <p>
          Click the
          <a
            title="Edit my profile"
            class="js-trig-aaa"
            data-mobile="true"
            @click="openEditPanel"
          >
            <span class="icon-edit"></span><strong>icon</strong>
          </a>
          to open and edit your Profile.
        </p>
        <h4 class="icon-cog p-head noHover h2icon">Settings</h4>
        <p>
          Click the
          <a
            title="My settings"
            class="js-trig-bbb"
            data-mobile="true"
            @click="openSettingPanel"
          >
            <span class="icon-cog"></span><strong>icon</strong>
          </a>
          to open your Settings.
        </p>
        <h4 class="icon-user-plus p-head noHover h2icon">Following Members</h4>
        <p>
          Click the
          <a
            title="Following Members"
            class="js-trig-ccc"
            data-mobile="true"
            @click="openFollowingPanel"
          >
            <span class="icon-user"></span><strong>icon</strong>
          </a>
          to open your Favourite Members.
        </p>
        <h4 class="icon-heart p-head noHover h2icon">Favourite Posts</h4>
        <p>
          Click the

          <router-link to="/favoriteposts" title="My Favourite Posts">
            <span class="icon-heart"></span><strong>icon</strong>
          </router-link>
          to view your Favourite Posts.
        </p>

        <h4 class="icon-bell-alt p-head noHover h2icon">Notifications</h4>
        <p>
          Click the
          <a
            title="Notifications"
            class=""
            data-mobile="true"
            @click="openNotiPanel"
          >
            <span class="icon-bell-alt"></span><strong>icon</strong>
          </a>
          to open recent activity Notifications.
        </p>
        <h4 class="icon-percent p-head noHover h2icon">Pressure Pit</h4>
        <p>
          This is the O2
          <strong
            ><router-link to="/" title="The Pressure Pit"
              >Homepage</router-link
            ></strong
          ><br />
          The Pressure Pit displays all this week's Posts
          <em>(with or without any votes)</em>.
        </p>
        <h4 class="icon-chart-bar p-head noHover h2icon">Leaderboard</h4>
        <p>
          The displays the current week's
          <strong
            ><router-link to="/leaderboard" title="This week's Leaderboard"
              >Leaders</router-link
            ></strong
          >. To appear on the Leaderboard, Posts must receive at least one
          <span class="icon-plus grn"></span> vote.
        </p>
        <h4 class="icon-award p-head noHover h2icon">Winners</h4>
        <p>
          The weekly Top 10 winners are permanently displayed on the
          <strong>
            <router-link to="/winners" title="Winners"
              >Winners Walls</router-link
            ></strong
          >
        </p>
        <h4 class="p-head noHover">
          <img
            style="vertical-align: bottom"
            src="../assets/images/tkn-o2-29x29.png"
            alt=""
          />
          Tokens
        </h4>
        <p>
          O2's virtual currency.
          <strong>
            <router-link to="/tokens" title="Tokens"
              >Tokens</router-link
            ></strong
          >
          can be earned, won, gifted or received and are used for cool, fun or
          strategic purposes across the site.
        </p>
        <h4 class="icon-brush p-head noHover h2icon">Themes</h4>
        <p>
          Find out more about Rewarded and Custom
          <strong>
            <router-link to="/themes" title="Winners"
              >Themes</router-link
            ></strong
          >
        </p>
        <p class="noellip">
          ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++=++
        </p>
        <p class="tx-c">
          <router-link
            class="button secondary ml-10 pl-5 pr-5"
            to="/whatiso2"
            title="What is O2 & Back Story"
          >
            <span class="pr-10 pl-10">What is O2</span>
          </router-link>
        </p>
      </div>
      <!-- END OF LOGGED IN -->

      <!-- NOT LOGGED IN -->
      <div v-else>
        <p>Join us ...</p>
        <p>
          <a
            class="
              show-panel
              button
              primary
              icon-lock-open
              pr-15
              pl-15
              mb-10
              mr-10
            "
            data-mobile="true"
            title="Login"
            @click="hideSidebar(0)"
          >
            Login
          </a>
          <a
            class="show-panel button primary icon-lock pr-15 pl-15 mb-10"
            title="Sign up"
            data-mobile="true"
            @click="hideSidebar(3)"
          >
            Sign up
          </a>
        </p>

        <p class="noellip">
          +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        </p>
      </div>
      <div class="pt-20 tx-c">
        <p class="gen-akr fs-9">We always need air - Please share us on...</p>
        <div class="social-share-links center">
          <a @click="shareFacebook()" target="_blank" class="icon-box fb">
            <i class="icon-facebook"></i
          ></a>
          <a @click="shareTwitter()" target="_blank" class="icon-box tw">
            <i class="icon-twitter"></i
          ></a>
          <a @click="sharePinterest()" target="_blank" class="icon-box pi">
            <i class="icon-pinterest-circled"></i
          ></a>
          <a @click="shareLinkedin()" target="_blank" class="icon-box li">
            <i class="icon-linkedin"></i
          ></a>
          <a
            @click="emailModalShow = !emailModalShow"
            target="_blank"
            class="icon-box mi"
          >
            <i class="icon-mail-alt"></i
          ></a>
          <modal
            :show="emailModalShow"
            @toggle-modal="emailModalShow = !emailModalShow"
            @handle-submit="handleSubmit"
          />
        </div>
      </div>
      <p>&nbsp;</p>
      <div class="panel-blue-block p-15 pr-20 pl-20 mb-30 mt-30 tx-c">
        <h2 class="byline">O2 Social</h2>
        <p>
          <span class="akr-gen">FOLLOW US ON</span>
        </p>
        <div class="o2-socials">
          <span data-title="O2 Facebook" data-title-top
            ><a
              href="https://www.facebook.com/O2Unlimited"
              target="_blank"
              class="icon-facebook"
            ></a
          ></span>
          <span data-title="O2 Instagram" data-title-top
            ><a
              href="https://www.instagram.com/o2positive_com"
              target="_blank"
              class="icon-instagram"
            ></a
          ></span>
          <span data-title="O2 Pinterest" data-title-top>
            <a
              href="https://www.pinterest.nz/o2positive_com"
              target="_blank"
              class="icon-pinterest"
            ></a
          ></span>
          <span data-title="O2 Twitter" data-title-top
            ><a
              href="https://www.twitter.com/O2Unlimited"
              target="_blank"
              class="icon-twitter"
            ></a
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ChangeMyOzoneMixin from "@/mixins/ChangeMyOzoneMixin";
import modal from "../components/shared/modal/modal.vue";
import $ from "jquery";

export default {
  name: "HowItWorks",
  components: {
    modal,
  },
  mixins: [ChangeMyOzoneMixin],

  computed: {
    ...mapState({
      votes: (state) => state.auth.user.votes,
      isLogin: (state) => state.auth.isLogin,
    }),
  },

  data() {
    return {
      isActive: false,
      emailModalShow: false,
    };
  },

  mounted() {
    if (this.$route.name === "HowItWorks") {
      $(".custom-screen").addClass("full-screen");
    }
    this.$store.commit("set_shared_overlay_class", "");
  },

  destroyed() {
    $(".custom-screen").removeClass("full-screen");
  },

  methods: {
    openSettingPanel() {
      this.changeMyOzone(5);
      this.moveSidebar();
    },
    openNotiPanel() {
      this.changeMyOzone(7);
      this.moveSidebar();
    },
    openFollowingPanel() {
      this.changeMyOzone(6);
      this.moveSidebar();
    },
    openEditPanel() {
      this.changeMyOzone(4);
      this.moveSidebar();
    },
    hideSidebar(num) {
      this.$emit("close");
      let $this = this;
      $this.changeMyOzone(num);
      $("body").addClass("mpanel-open hidesb");
      $(".logged-out").addClass("hidesb");
      $(".ozone-column").addClass("moved");
      $(".my-ozone").addClass("moved");
      $this.$store.commit("change_state", ["mnav_class", "move"]);
      setTimeout(function () {
        $this.$store.commit("change_state", ["mnav_close_class", "slidem"]);
      }, 350);
    },
    shareFacebook() {
      window.open(
        "https://www.facebook.com/sharer.php?u=" + process.env.VUE_APP_PREFIX,
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
    },
    shareTwitter() {
      window.open(
        "https://twitter.com/intent/tweet?url=" + process.env.VUE_APP_PREFIX,
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
    },
    shareLinkedin() {
      window.open(
        "https://www.linkedin.com/shareArticle?mini=true&url=" +
          process.env.VUE_APP_PREFIX,
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
    },
    sharePinterest() {
      window.open(
        "http://pinterest.com/pin/create/button/?url=" +
          process.env.VUE_APP_PREFIX,
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
    },
    handleSubmit(data) {
      if (this.isLogin) {
        data.user = "yes";
      } else {
        data.user = "no";
      }
      data.route = this.$route.path;
      this.emailModalShow = false;
      this.$store.dispatch("overlayModule/o2SiteSharing", data);
    },
    moveSidebar() {
      if (window.screen.width < 768) {
        $("body").addClass("mpanel-open hidesb");
      }
      $(".ozone-column").addClass("moved");
      $(".my-ozone").addClass("moved");
      this.$store.commit("change_state", ["mnav_class", "move"]);
      this.$store.commit("change_state", ["mnav_close_class", "slidem"]);
    },
  },
};
</script>