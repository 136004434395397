import PostCreateOverlay from "@/components/PostCreateOverlay";
import { mapState } from "vuex";
import $ from "jquery";

export default {
  components: {
    PostCreateOverlay,
  },

  computed: {
    ...mapState({
      isLogin: (state) => state.auth.isLogin,
    }),
    showLoginModal() {
      if (this.modalShow === true && !this.closeLoginModal) {
        return true;
      }
      return false;
    },
  },

  data() {
    return {
      modalShow: false,
    };
  },

  methods: {
    showPostCreateOverlay() {
      if (this.isLogin) {
        const $body = document.body;
        $body.classList.add("overlay-open");
        this.$store.dispatch("setPostCreateOverlayClass", "opened");
      } else {
        this.modalShow = true;
        $(".col-trigger").addClass("sidebar-hide");
        $(".sidebar-right").addClass("sidebar-hide");
        $(".show-mnav").addClass("sidebar-hide");
        this.hideLoginModal();
      }
    },
    hideLoginModal() {
      let $this = this;
      $("body").removeClass("mpanel-open hidesb");
      $(".ozone-column").removeClass("moved");
      $(".my-ozone").removeClass("moved");
      $this.$store.commit("change_state", ["mnav_class", ""]);
      setTimeout(function () {
        $this.$store.commit("change_state", ["mnav_close_class", ""]);
      }, 350);
    },
    colseModal() {
      this.closeLoginModal = true;
      this.modalShow = false;
      this.closeLoginModal = false;
      $(".col-trigger").removeClass("sidebar-hide");
      $(".show-mnav").removeClass("sidebar-hide");
      $(".sidebar-right").removeClass("sidebar-hide");
      this.hideLoginModal();
    },
  },
};
