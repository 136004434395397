<template>
  <div>
    <perfect-scrollbar class="extra-panel bbb" :class="openClass">
      <div class="my-panels">
        <h2 class="akrobat bk-r">SETTINGS</h2>
        <p>Change Password?</p>
        <form class="login-form">
          <div class="fieldset">
            <label for="changepassword" class="offset"
              >Type a new password</label
            >
            <div class="input-holder" :class="errorMessage ? 'is-invalid' : ''">
              <input
                id="changepassword"
                type="password"
                placeholder="Type a new password"
                v-model="password1"
              />
            </div>
          </div>
          <div class="fieldset">
            <label for="repeatpassword" class="offset"
              >Repeat the password</label
            >
            <!-- validate they are identical passwords --->
            <div class="input-holder" :class="errorMessage ? 'is-invalid' : ''">
              <input
                id="repeatpassword"
                type="password"
                placeholder="Repeat password"
                v-model="password2"
              />
            </div>
          </div>
          <p class="error-message" v-if="errorMessage">
            <i class="icon-attention-circled">{{ errorMessage }}</i>
          </p>
          <a
            class="button primary full bsbb"
            title="Save new password"
            @click="changePassword"
            >SAVE</a
          >
          <!-- <div v-if="isLogin">
            <p class="mt-30">Email Notifications</p>

            <div class="fieldset cb">
              <input
                type="checkbox"
                id="newfollower"
                v-model="notifications.newFollower"
                @change="changeNotificationSetting"
              />
              <label for="newfollower" class="label"
                >When I get a new Follower</label
              >
            </div>
            <div class="fieldset cb">
              <input
                type="checkbox"
                id="newcomment"
                v-model="notifications.newComment"
                @change="changeNotificationSetting"
              />
              <label for="newcomment" class="label"
                >When I get a new Comment</label
              >
            </div>
            <div class="fieldset cb">
              <input
                type="checkbox"
                id="ifollowposts"
                v-model="notifications.friendPost"
                @change="changeNotificationSetting"
              />
              <label for="ifollowposts" class="label"
                >When a friend Posts.</label
              >
            </div>
          </div> -->
        </form>
        <div class="theme-mode" v-if="isLogin">
          <p>Dark~Light Mode</p>
          <label>
            <input
              class="toggle-checkbox"
              id="checkbox-1"
              name="checkbox-1"
              v-model="status"
              type="checkbox"
              @change="nightMode(status)"
            />
            <div class="toggle-slot">
              <div class="sun-icon-wrapper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  aria-hidden="true"
                  focusable="false"
                  width="1em"
                  height="1em"
                  style="
                    -ms-transform: rotate(360deg);
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                  "
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                  class="iconify sun-icon"
                  data-icon="feather-sun"
                  data-inline="false"
                >
                  <g
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  >
                    <circle cx="12" cy="12" r="5"></circle>
                    <path
                      d="M12 1v2m0 18v2M4.22 4.22l1.42 1.42m12.72 12.72l1.42 1.42M1 12h2m18 0h2M4.22 19.78l1.42-1.42M18.36 5.64l1.42-1.42"
                    ></path>
                  </g>
                </svg>
              </div>
              <div class="toggle-button"></div>
              <div class="moon-icon-wrapper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  aria-hidden="true"
                  focusable="false"
                  width="1em"
                  height="1em"
                  style="
                    -ms-transform: rotate(360deg);
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                  "
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                  class="iconify moon-icon"
                  data-icon="feather-moon"
                  data-inline="false"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 12.79A9 9 0 1 1 11.21 3A7 7 0 0 0 21 12.79z"
                  ></path>
                </svg>
              </div>
            </div>
          </label>
        </div>
        <div class="theme-change">
          <h5
            v-if="
              availableTheme[0] && Object.keys(availableTheme[0]).length > 0
            "
          >
            Available Themes
          </h5>
          <div
            class="form-group"
            v-for="(theme, index) in availableTheme[0]"
            :key="index"
          >
            <div
              class="theme-icon"
              :data-title="
                index !== 'o2' ? `For ${theme[1]}+ Followers` : 'Oxygen'
              "
              data-title-right
            >
              <div
                :class="
                  index !== 'o2'
                    ? `spr-theme-${theme[2]} size1-pos`
                    : 'spr-theme-oxygen size1-pos'
                "
              ></div>
            </div>
            <div class="theme-name">
              <div class="theme-view">
                <span>{{ theme[2] }}</span>
                <router-link to="/themes" @click.native="$emit('hideSideBar')"
                  >Themes
                </router-link>
              </div>
              <div class="theme-toggle">
                <input
                  type="radio"
                  :id="index"
                  name="flexRadioDefault"
                  class="chkbx-toggle"
                  :value="index"
                  :checked="selectedTheme == index"
                  @change="themeChange(index)"
                />
                <label :for="index"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gauge">
        <div class="gauge-wrapper"></div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import $ from "jquery";

export default {
  name: "SettingComponent",

  data() {
    return {
      openClass: "",
      notifications: {
        newFollower: "",
        newComment: "",
        friendPost: "",
      },
      password1: "",
      password2: "",
      errorMessage: "",
      status: "",
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      isLogin: (state) => state.auth.isLogin,
      selectedTheme: (state) => state.auth.selectedTheme,
      availableTheme: (state) => state.auth.availableTheme,
    }),
    link() {
      return process.env.VUE_APP_PREFIX + "/themes";
    },
  },

  async mounted() {
    await this.$store.dispatch("getUser");
    if (this.selectedTheme === "darkmode") {
      this.status = true;
    }
    this.openClass = "open";
    this.notifications.newFollower = this.user.new_follower_notification;
    this.notifications.newComment = this.user.new_comment_notification;
    this.notifications.friendPost = this.user.friend_post_notification;
  },

  methods: {
    async nightMode(theme) {
      if (theme === true) {
        if ($("body").attr("class")) {
          const themeClass = $("body").attr("class").split(" ");
          const index = themeClass.indexOf("theme-" + this.selectedTheme);
          const splitTheme = themeClass[index].split("-");
          if (splitTheme[1] !== "darkmode") {
            await this.$store.dispatch("siteView", { theme: splitTheme[1] });
          }
        } else {
          await this.$store.dispatch("siteView", { theme: "o2" });
        }
        await this.$store.commit("set_selected_theme", "darkmode");
        await this.$store.dispatch("changeTheme", "darkmode");
        await this.$store.dispatch("getUser");
      } else {
        await this.$store.commit("set_selected_theme", this.user.lightMode);
        await this.$store.dispatch("changeTheme", this.user.lightMode);
      }
    },
    async themeChange(theme) {
      this.status = false;
      await this.$store.commit("set_selected_theme", theme);
      await this.$store.dispatch("changeTheme", theme);
      await this.$store.dispatch("getUser");
    },
    changeNotificationSetting() {
      this.$store.dispatch(
        "changeUserNotificationSettings",
        this.notifications
      );
    },
    async changePassword() {
      let $this = this;
      if ($this.password1 === $this.password2) {
        if ($this.password1.length > 7) {
          if ($this.isLogin) {
            await this.$store.dispatch("changePassword", $this.password1);
            await this.$store.dispatch("changeMyOzone", 1);
            await this.$store.dispatch("showToast", {
              message: "Password Reset Suceesfully!",
            });
          } else {
            await this.$store.dispatch("resetPassword", $this.password1);
            await this.$store.dispatch("showToast", {
              message: "Password Reset Suceesfully!",
            });
          }
          $this.errorMessage = "";
        } else {
          $this.errorMessage = "Please use at least 8 characters";
        }
      } else {
        $this.errorMessage = "Oops. Passwords do not match";
      }
    },
  },
};
</script>