import axios from "axios";

export async function get(subURL) {
  return axios
    .get(process.env.VUE_APP_API_URL + subURL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("Error: " + error);
      return error;
    });
}

export async function post(subURL, data) {
  return axios
    .post(process.env.VUE_APP_API_URL + subURL, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("Error: " + error);
      return error;
    });
}
