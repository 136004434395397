<template>
  <b-modal
    id="report-modal-prevent-closing"
    ref="report-modal"
    title="Report this Post"
    ok-title="Submit"
    @show="resetModal"
    @hide="handleClose"
    @ok="handleOk"
    dialog-class="email-share-modal report-modal"
    :visible="show"
  >
    <form ref="form" @submit.stop.prevent="reportPost">
      <b-form-group
        label="This Post's Url"
        label-for="post-url"
        :state="nameState"
      >
        <b-form-input
          id="post-url"
          v-model="this.post_url"
          placeholder="https://"
          :state="nameState"
          disabled
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="What is your complaint?"
        label-for="description"
        :state="nameState"
      >
        <b-form-textarea
          id="description"
          v-model="description"
          :state="nameState"
          required
        ></b-form-textarea>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";
export default {
  props: ["show", "post_url"],

  data: () => ({
    postURL: "",
    description: "",
    nameState: null,
  }),

  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
  },
  directives: {
    "b-modal": VBModal,
    "b-form-group": VBModal,
    "b-form-input": VBModal,
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.postURL = "";
      this.description = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.reportPost();
    },
    reportPost() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.$emit("handle-submit", {
        postURL: this.post_url,
        description: this.description,
      });
    },
    handleClose() {
      this.$emit("toggle-modal", false);
    },
  },
};
</script>
