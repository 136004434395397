<template>
  <div>
    <PostCreateOverlay />
    <div class="panel-blue-block pt-20 pl-30 mb-30 pr-30 pb-20 tx-c">
      <div class="mb-10 mt-10">
        <div class="spr-treasures-favicon m-auto"></div>
      </div>
      <h2 class="byline">Community Entertainment<br />Powered by You</h2>
    </div>
    <div class="sub-panel">
      <h2 class="byline">O2 is a Weekly Competition</h2>
      <p>
        To find the best images & visual stories -
        <strong>CREATED & VOTED BY YOU!</strong>
      </p>
      <p class="noellip">
        +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
      </p>
      <h2 class="byline">How it works</h2>
      <p>
        Simply
        <a class="show-panel" @click="showPostCreateOverlay()">
          <strong>Add a Post</strong>
        </a>
        for it to display in the
        <router-link to="/"> <strong>Pressure Pit</strong></router-link
        >. Anyone can then vote it onto the
        <router-link to="/leaderboard">
          <strong>Leaderboard</strong></router-link
        >. At the end of every week, the Top 10 Posts on the Leaderboard appear
        on the
        <router-link to="/winners"> <strong>Winners Walls</strong></router-link>
        to receive recognition, rewards and permanent promotion.
      </p>
      <p class="noellip">
        +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
      </p>
      <h2 class="byline">Back Story</h2>
      <p>
        O2 was born in June 2022. (still a baby!)<br />
Being surrounded by negative news and a world of constant 'doom and gloom': we wanted to create a site for a community that enjoys the positives of life.
A site for people to enjoy, create and share inspiring images and stories of the world.
      </p>
      <p class="noellip">
        +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
      </p>
      <h2 class="byline">Important Tips</h2>
      <p>
        <strong class="icon-lightbulb">Be quick to vote:</strong> Posts only have 7
        days of life before they expire.
      </p>
      <p>
        <strong class="icon-lightbulb">Use all your votes:</strong> You have 40
        votes per day.
      </p>
      <p>
        <strong class="icon-lightbulb">Check</strong> the
        <strong><router-link to="/">Pressure Pit</router-link></strong> <em>(Homepage)</em>for new
        posts.
      </p>
      <p>
        <strong class="icon-lightbulb">Post often:</strong> You get rewards and
        awards for your activity.
      </p>
      <p class="noellip">
        ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++=++
      </p>
      <p class="tx-c">
        <router-link
          class="button secondary pl-5 pr-5"
          to="/howitworks"
          title="Main Features"
        >
          <span class="pr-10 pl-10">Main Features</span>
        </router-link>
      </p>
    </div>

    <div class="pt-20 tx-c">
      <p class="gen-akr fs-9">We always need air - Please share us on...</p>
      <div class="social-share-links center">
        <a @click="shareFacebook()" target="_blank" class="icon-box fb">
          <i class="icon-facebook"></i
        ></a>
        <a @click="shareTwitter()" target="_blank" class="icon-box tw">
          <i class="icon-twitter"></i
        ></a>
        <a @click="sharePinterest()" target="_blank" class="icon-box pi">
          <i class="icon-pinterest-circled"></i
        ></a>
        <a @click="shareLinkedin()" target="_blank" class="icon-box li">
          <i class="icon-linkedin"></i
        ></a>
        <a
          @click="emailModalShow = !emailModalShow"
          target="_blank"
          class="icon-box mi"
        >
          <i class="icon-mail-alt"></i
        ></a>
        <modal
          :show="emailModalShow"
          @toggle-modal="emailModalShow = !emailModalShow"
          @handle-submit="handleSubmit"
        />
      </div>
    </div>

    <p>&nbsp;</p>
  </div>
</template>

<script>
import * as moment from "moment";
import modal from "../../components/shared/modal/modal.vue";
import CreateOverlayMixin from "@/mixins/CreateOverlayMixin";

export default {
  name: "Member",
  components: {
    modal,
  },

  mixins: [CreateOverlayMixin],

  data() {
    return {
      currentWeek: 0,
      emailModalShow: false,
    };
  },

  created() {
    let $this = this;
    let now = moment().format();
    $this.currentWeek = moment(now).isoWeek();
  },

  methods: {
    shareFacebook() {
      window.open(
        "https://www.facebook.com/sharer.php?u=" + process.env.VUE_APP_PREFIX,
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
    },
    shareTwitter() {
      window.open(
        "https://twitter.com/intent/tweet?url=https://pre-live.o2feed.com/",
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
    },
    shareLinkedin() {
      window.open(
        "https://www.linkedin.com/shareArticle?mini=true&url=https://pre-live.o2feed.com/",
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
    },
    sharePinterest() {
      window.open(
        "http://pinterest.com/pin/create/button/?url=https://pre-live.o2feed.com/",
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
      );
    },
    handleSubmit(data) {
      if (this.isLogin) {
        data.user = "yes";
      } else {
        data.user = "no";
      }
      data.route = this.$route.path;
      this.emailModalShow = false;
      this.$store.dispatch("overlayModule/o2SiteSharing", data);
    },
  },
};
</script>
