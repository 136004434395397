<template>
  <div id="tabs-2">
    <div class="tabWrap">
      <div>
        <div class="tx-c">
          <h2 v-if="posts.length" class="akrobat c-999">
            <i class="icon-doc-text"></i> my drafts
          </h2>
          <h2 v-else class="akrobat c-999 noCaps">
            <i class="icon-doc-text"></i> You have no Draft Posts yet.
          </h2>
        </div>
        <LoaderOverlay v-if="isLoading" />
        <ul class="feed list-grid">
          <li v-for="(post, index) in posts" :key="index">
            <div class="imgWrap">
              <div class="trash-Wrp">
                <div data-title="Remove" data-title-left>
                  <div class="trash icon-trash" @click="removePost(post)"></div>
                </div>
              </div>
              <div
                v-if="post.mature_content"
                class="mature-audience"
                style="display: block"
                @click="checkIfLogin(post)"
              >
                <div>
                  <div><i class="icon-eye-off"></i></div>
                  <p class="mature">MATURE AUDIENCE</p>
                  <span>Content may be sensitive</span>
                  <span class="letmesee">LET ME SEE</span>
                </div>
              </div>
              <div @click="showOverlay(post)">
                <div class="prevent-vid-click"></div>
                <div v-if="post.is_video && post.videos" class="vid-arrow">
                  <div data-title="Video" data-title-top>
                    <span>{{ post.videos.length }}</span>
                  </div>
                </div>
              </div>
              <img v-if="!post.is_video" :src="post.images[0].url" alt="" />
              <iframe
                v-else
                width="560"
                height="315"
                :src="post.videos[0].url"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              >
              </iframe>
            </div>
            <div class="author with-tag">
              <span :data-title="post.author" data-title-left class="tip-user">
                <a class="avatar" :title="post.author">
                  <div
                    v-if="isLogin"
                    class="on-off"
                    :class="[post.author_status === true ? 'on-now' : '']"
                    :data-title="
                      post.author_status === true ? 'Online' : 'Offline'
                    "
                    data-title-right
                  ></div>
                  <img
                    :src="post.author_img"
                    :alt="post.author"
                    :class="
                      post.author_class === 'no-border'
                        ? 'avatar-no-border'
                        : ''
                    "
                  />
                </a>
              </span>
            </div>
            <h3>
              <a
                v-if="post.title && post.title.length < 45"
                class="show-panel"
                :title="post.title"
                @click="showOverlay(post)"
              >
                {{ post.title }}
              </a>
              <a
                v-else
                class="show-panel"
                :title="post.title"
                @click="showOverlay(post)"
              >
                {{ post.title.substring(0, 45) + ".." }}
              </a>
            </h3>
            <p v-if="post.summary && post.summary.length < 100" class="summary">
              {{ post.summary }}
            </p>
            <p v-else class="summary">
              {{ post.summary.substring(0, 100) + "..." }}
            </p>
          </li>

          <div class="btn_center_sec">
            <a
              class="load_more_btn"
              v-if="loadMoreToggle"
              @click="loadMoreDrafts"
            >
             Show more <span class="icon-down-open"></span>
            </a>
          </div>
        </ul>
      </div>
    </div>
    <div
      v-if="isShowOverlay"
      class="close-overlay-panel"
      @click="isShowOverlay = false"
    ></div>
    <DraftOverlay
      v-if="isShowOverlay"
      :isShowOverlay="isShowOverlay"
      :post="post"
      @hideOverlay="hideOverlay()"
      @editDraft="editDraft"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import DraftOverlay from "@/components/shared/overlay/DraftOverlay";
import LoaderOverlay from "../shared/overlay/LoaderOverlay.vue";

export default {
  name: "Posts",

  components: {
    DraftOverlay,
    LoaderOverlay,
  },

  data() {
    return {
      post: {},
      isShowOverlay: false,
    };
  },

  computed: {
    ...mapState({
      isLogin: (state) => state.auth.isLogin,
      isLoading: (state) => state.auth.isLoading,
      posts: (state) => state.common.drafts,
      pageNumber: (state) => state.common.pageNumber,
      totalPosts: (state) => state.common.totalDrafts,
      isDraft: (state) => state.common.isDraft,
    }),
    loadMoreToggle() {
      const limit = 10;
      return Math.ceil(this.totalPosts / limit) > this.pageNumber
        ? true
        : false;
    },
  },

  mounted() {
    this.$store.commit("set_active_tab", 0);
    this.$store.commit("reset_page_number");
    this.$store.dispatch("getMyDraft", 0);
  },

  destroyed() {
    this.$store.commit("set_draft", false);
    this.$store.dispatch("resetPageNumber");
  },

  methods: {
    showOverlay(post) {
      this.isShowOverlay = true;
      const $body = document.body;
      $body.classList.add("overlay-open");
      this.$store.dispatch("changeState", ["overlayClass", "opened"]);
      this.post = { ...post };
    },
    hideOverlay() {
      this.isShowOverlay = false;
      this.$store.dispatch("changeState", ["overlayClass", ""]);
    },
    removePost(post) {
      this.$store.dispatch("removeDraft", post);
    },
    loadMoreDrafts() {
      this.$store.dispatch("loadMoreDrafts", { pageNumber: this.pageNumber });
    },
    editDraft(post) {
      this.$store.dispatch("editDraft", post);
    },
    checkIfLogin(post) {
      if (this.isLogin) this.showOverlay(post);
    },
  },
};
</script>
