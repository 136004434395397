<template>
  <div class="imgWrap">
    <div
      class="trash-Wrp"
      v-if="$route.name === 'User' && !isLeaders"
      @click="removePost(post)"
    >
      <div data-title="Remove" data-title-left>
        <div class="trash icon-trash" title="Delete"></div>
      </div>
    </div>
    <div class="post-life">
      <div
        class="bar"
        :class="calExpiration(post.expiration)"
        :style="`top:${post.expiration}%`"
        :data-title="`${limit2numbers(
          post.life_support_remaining
        )} of O2 remains`"
        data-title-right
      ></div>
    </div>
    <div
      v-if="post.mature_content"
      class="mature-audience"
      style="display: block"
      @click="checkIfLogin(post.post_id, post.mature_content)"
    >
      <div>
        <div><i class="icon-eye-off"></i></div>
        <p class="mature">MATURE AUDIENCE</p>
        <span>Content may be sensitive</span>
        <span class="letmesee">LET ME SEE</span>
      </div>
    </div>
    <MatureAudienceLoginModal v-if="showLoginModal" @colseModal="colseModal" />
    <div @click="showOverlay(post.post_id, post.mature_content)">
      <div class="prevent-vid-click"></div>
      <div v-if="post.is_video && post.videos" class="vid-arrow">
        <div data-title="Video" data-title-top>
          <span>{{ post.videos.length }}</span>
        </div>
      </div>
    </div>
    <div v-if="post.mature_content && !isLogin">
      <img src="../../assets/images/logintoview.png" />
    </div>
    <div v-else>
      <v-lazy-image v-if="!post.is_video" :src="post.images[0].url" />
      <iframe
        v-else
        width="560"
        height="315"
        :src="post.videos[0].url"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      >
      </iframe>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MatureAudienceLoginModal from "../../components/modal/MatureAudienceLoginModal.vue";
import $ from "jquery";
import VLazyImage from "v-lazy-image/v2";
import * as moment from "moment";

export default {
  name: "ImgWrap",

  props: {
    post: Object,
    isLeaders: Boolean,
  },

  computed: {
    ...mapState({
      isLogin: (state) => state.auth.isLogin,
    }),
    showLoginModal() {
      if (this.modalShow === true && !this.closeLoginModal) {
        return true;
      }
      return false;
    },
  },

  components: {
    MatureAudienceLoginModal,
    VLazyImage,
  },

  data() {
    return {
      closeLoginModal: false,
      modalShow: false,
    };
  },

  destroyed() {
    this.$store.dispatch("setSharedOverlayClass", "");
  },

  methods: {
    calExpiration(e) {
      let color = e < 70.0 ? "blue" : "red";
      return color;
    },
    showOverlay(post_id, isMature) {
      if (this.isLogin) {
        this.$store.dispatch("setOverlayPost", [this.isLeaders, post_id]);
        this.$store.dispatch("setSharedOverlayClass", "opened");
      } else {
        if (isMature === false) {
          this.$store.dispatch("setOverlayPost", [this.isLeaders, post_id]);
          this.$store.dispatch("setSharedOverlayClass", "opened");
        }
      }
    },
    checkIfLogin(post_id, isMature) {
      if (this.isLogin) {
        this.modalShow = false;
        this.showOverlay(post_id, isMature);
      } else {
        this.modalShow = true;
        $("body").addClass("zero-hero-hide");
        this.hidePressureList();
        this.hideSideBar();
      }
    },
    hidePressureList() {
      $(".Hellow").addClass("pressure-pit-list-hide");
      $(".sidebar-right").addClass("sidebar-hide");
      $(".ozone-column").addClass("sidebar-hide");
      $(".my-ozone").addClass("sidebar-hide");
    },
    colseModal() {
      this.closeLoginModal = true;
      this.modalShow = false;
      this.closeLoginModal = false;
      $("body").removeClass("zero-hero-hide");
      $(".ozone-column").removeClass("sidebar-hide");
      $(".my-ozone").removeClass("sidebar-hide");
      $("body").removeClass("mpanel-open");
      this.hideSideBar();
    },
    hideSideBar() {
      let $this = this;
      $(".ozone-column").removeClass("moved");
      $(".my-ozone").removeClass("moved");
      $this.$store.commit("change_state", ["mnav_class", ""]);
      setTimeout(function () {
        $this.$store.commit("change_state", ["mnav_close_class", ""]);
      }, 350);
    },
    removePost(post) {
      this.$store.dispatch("removePost", post);
    },
    limit2numbers(value) {
      var duration = moment.duration(value, "hours");
      const d = moment.duration(duration);
      const days = Math.abs(d._data.days);
      const hours = Math.abs(d._data.hours);
      const minutes = Math.abs(d._data.minutes);
      return days + "d" + ":" + hours + "h" + ":" + minutes + "m";
    },
  },
};
</script>