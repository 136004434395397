<template>
  <div>
    <div class="logged-in">
      <perfect-scrollbar class="extra-panel aaa" :class="openClass">
        <div class="my-panels padding-scroll">
          <h2 class="akrobat bk-r">EDIT PROFILE</h2>

          <form class="login-form">
            <div class="mt-15 mb-5">Name</div>

            <div class="fieldset">
              <label for="first_name" class="offset">First name</label>
              <div
                class="input-holder"
                :class="required.fname ? 'is-invalid' : ''"
              >
                <input
                  v-model="first_name"
                  type="text"
                  placeholder="First name"
                  @blur="validate('first_name')"
                />
              </div>
              <p v-if="required.fname" class="error-message">
                <i class="icon-attention-circled">*Required</i>
              </p>
            </div>
            <div class="fieldset">
              <label for="last_name" class="offset">Last name</label>
              <div
                class="input-holder"
                :class="required.lname ? 'is-invalid' : ''"
              >
                <input
                  v-model="last_name"
                  type="text"
                  placeholder="Last name"
                  @blur="validate('last_name')"
                />
              </div>
              <p v-if="required.lname" class="error-message">
                <i class="icon-attention-circled">*Required</i>
              </p>
              <span v-if="loginType !== 'facebook'" class="em-addss">{{
                this.user.email
              }}</span>
            </div>

            <div class="mt-15 mb-5">
              Profile image
              <a
                data-title="Upload Profile Image"
                data-title-left
                class="txt right fs-9 p-r upload-prof-img"
                >Upload
                <input
                  class=""
                  type="file"
                  accept="image/*"
                  ref="ProfileImage"
                  @change="uploadImage"
                />
              </a>
            </div>

            <div class="prof-img2 p-r">
              <a
                class="edit-Image p-a icon-trash removeImage"
                @click="removeAvatar"
                title="Remove Image"
              ></a>
              <div>
                <img
                  v-if="avatar_url"
                  class="pho-circs pho-1"
                  :src="avatar_url"
                  alt="Profile image"
                />
                <div
                  v-else
                  class="
                    search-prof-img
                    p-r
                    user-defaul-logo
                    pho-circs pho-1
                    settings-img
                  "
                ></div>
              </div>
            </div>
            <div class="mt-15 mb-5">
              About me<span
                class="right fs-7 drk-b"
                title="800 characters maximum"
                >{{ remaincharactersText }}</span
              >
            </div>

            <div class="fieldset profile">
              <label for="bio" class="offset">About me</label>
              <div class="input-holder">
                <textarea
                  v-model="bio"
                  placeholder="Tell us about yourself ..."
                  maxlength="800"
                  @keyup="remaincharCount()"
                ></textarea>
              </div>
            </div>

            <div class="mt-15 mb-5">Location</div>

            <div class="fieldset">
              <label for="city" class="offset">Town/City</label>
              <div class="input-holder">
                <input v-model="city" type="text" placeholder="Town/City" />
              </div>
            </div>

            <div class="fieldset">
              <label for="country" class="offset">Country</label>
              <div class="input-holder">
                <input v-model="country" type="text" placeholder="Country" />
              </div>
            </div>

            <div class="mt-15 mb-5">Website / Link</div>
            <div class="fieldset">
              <label for="web-url" class="offset">Website / Link</label>
              <div
                class="input-holder"
                :class="required.site ? 'is-invalid' : ''"
              >
                <input
                  v-model="site_url"
                  type="url"
                  placeholder="https://"
                  @blur="validateUrl('site_url')"
                />
              </div>
              <p v-if="required.site" class="error-message">
                <i class="icon-attention-circled">Enter Valid URL.</i>
              </p>
            </div>
            <!-----------------------socials------------------->
            <div class="fieldset">
              <div class="mt-15 mb-5">Social links</div>
              <label for="fb-url" class="offset">Facebook</label>
              <div
                class="input-holder socials"
                :class="required.facebook ? 'is-invalid' : ''"
              >
                <i class="button icon-facebook" title="Facebook page"></i>
                <input
                  v-model="facebook_url"
                  type="url"
                  placeholder="Facebook url"
                  @blur="validateUrl('facebook_url')"
                />
              </div>
              <p v-if="required.facebook" class="error-message">
                <i class="icon-attention-circled">Enter Valid URL.</i>
              </p>
            </div>
            <div class="fieldset">
              <label for="inst-url" class="offset">Instagram</label>
              <div
                class="input-holder socials"
                :class="required.instagram ? 'is-invalid' : ''"
              >
                <i class="button icon-instagram" title="Instagram page"></i>
                <input
                  v-model="instagram_url"
                  type="url"
                  placeholder="Instagram url"
                  @blur="validateUrl('instagram_url')"
                />
              </div>
              <p v-if="required.instagram" class="error-message">
                <i class="icon-attention-circled">Enter Valid URL.</i>
              </p>
            </div>
            <div class="fieldset">
              <label for="twi-url" class="offset">Twitter</label>
              <div
                class="input-holder socials"
                :class="required.twitter ? 'is-invalid' : ''"
              >
                <i class="button icon-twitter" title="Twitter page"></i>
                <input
                  v-model="twitter_url"
                  type="url"
                  placeholder="Twitter url"
                  @blur="validateUrl('twitter_url')"
                />
              </div>
              <p v-if="required.twitter" class="error-message">
                <i class="icon-attention-circled">Enter Valid URL.</i>
              </p>
            </div>

            <div class="fieldset">
              <label for="lin-url" class="offset">Linkedin</label>
              <div
                class="input-holder socials"
                :class="required.linkedin ? 'is-invalid' : ''"
              >
                <i class="button icon-linkedin" title="LinkedIn page"></i>
                <input
                  v-model="linkedin_url"
                  type="url"
                  placeholder="Linkedin url"
                  @blur="validateUrl('linkedin_url')"
                />
              </div>
              <p v-if="required.linkedin" class="error-message">
                <i class="icon-attention-circled">Enter Valid URL.</i>
              </p>
            </div>

            <div class="fieldset">
              <label for="pin-url" class="offset">Pinterest</label>
              <div
                class="input-holder socials"
                :class="required.pinterest ? 'is-invalid' : ''"
              >
                <i
                  class="button icon-pinterest-circled"
                  title="Pinterest page"
                ></i>
                <input
                  v-model="pinterest_url"
                  type="url"
                  placeholder="Pinterest url"
                  @blur="validateUrl('pinterest_url')"
                />
              </div>
              <p v-if="required.pinterest" class="error-message">
                <i class="icon-attention-circled">Enter Valid URL.</i>
              </p>
            </div>

            <a class="button primary full" @click.once="submit"> SAVE </a>
          </form>
        </div>
        <div class="gauge"><div class="gauge-wrapper"></div></div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "EditProfileComponent",
  data() {
    return {
      first_name: "",
      last_name: localStorage.getItem("last_name"),
      avatar_img: "",
      avatar_url: "",
      bio: "",
      city: "",
      country: "",
      site_url: "",
      facebook_url: "",
      instagram_url: "",
      twitter_url: "",
      linkedin_url: "",
      pinterest_url: "",
      openClass: "", //component slide showing effect
      isDisabled: false,
      required: {
        fname: false,
        lname: false,
        site_url: false,
        facebook: false,
        instagram: false,
        linkedin: false,
        twitter: false,
        pinterest: false,
      },
      maxcharacter: 800,
      remaincharactersText: "800",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      isLogin: (state) => state.auth.isLogin,
      loginType: (state) => state.auth.loginType,
    }),
  },
  async created() {
    let $this = this;
    if ($this.isLogin && $this.user.first_name === "") {
      await $this.$store.dispatch("getUser");
    }
  },
  mounted() {
    this.openClass = "open";
    this.first_name = this.user.first_name;
    this.last_name = this.user.last_name;
    this.bio = this.user.bio;
    this.city = this.user.city;
    this.country = this.user.country;
    this.site_url = this.user.site_url;
    this.facebook_url = this.user.facebook_url;
    this.instagram_url = this.user.instagram_url;
    this.twitter_url = this.user.twitter_url;
    this.linkedin_url = this.user.linkedin_url;
    this.pinterest_url = this.user.pinterest_url;
    this.avatar_url = this.user.avatar;
  },
  methods: {
    remaincharCount() {
      if (this.bio.length <= this.maxcharacter) {
        var remainCharacters = this.maxcharacter - this.bio.length;
        this.remaincharactersText = remainCharacters;
      }
    },
    uploadImage() {
      let $this = this;
      $this.avatar_img = $this.$refs.ProfileImage.files[0];
      $this.avatar_url = URL.createObjectURL($this.$refs.ProfileImage.files[0]);
    },
    removeAvatar() {
      this.avatar_url = "";
      this.avatar_img = "";
    },
    validate(value) {
      if (value === "first_name") {
        this.required.fname = this.first_name ? false : true;
      }
      if (value === "last_name") {
        this.required.lname = this.last_name ? false : true;
      }
    },
    validateUrl: function (value) {
      var regex =
        /^(?:(?:https?|http):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      if (value === "site_url") {
        if (this.site_url == "" || this.site_url.match(regex)) {
          this.required.site = false;
          if (!this.site_url.includes("://") && this.site_url !== "") {
            this.site_url = "https://" + this.site_url;
          }
        } else {
          this.required.site = true;
        }
      } else if (value === "facebook_url") {
        if (this.facebook_url == "" || this.facebook_url.match(regex)) {
          this.required.facebook = false;
          if (!this.facebook_url.includes("://") && this.facebook_url !== "") {
            this.facebook_url = "https://" + this.facebook_url;
          }
        } else {
          this.required.facebook = true;
        }
      } else if (value === "instagram_url") {
        if (this.instagram_url == "" || this.instagram_url.match(regex)) {
          this.required.instagram = false;
          if (
            !this.instagram_url.includes("://") &&
            this.instagram_url !== ""
          ) {
            this.instagram_url = "https://" + this.instagram_url;
          }
        } else {
          this.required.instagram = true;
        }
      } else if (value === "twitter_url") {
        if (this.twitter_url == "" || this.twitter_url.match(regex)) {
          this.required.twitter = false;
          if (!this.twitter_url.includes("://") && this.twitter_url !== "") {
            this.twitter_url = "https://" + this.twitter_url;
          }
        } else {
          this.required.twitter = true;
        }
      } else if (value === "linkedin_url") {
        if (this.linkedin_url == "" || this.linkedin_url.match(regex)) {
          this.required.linkedin = false;
          if (!this.linkedin_url.includes("://") && this.linkedin_url !== "") {
            this.linkedin_url = "https://" + this.linkedin_url;
          }
        } else {
          this.required.linkedin = true;
        }
      } else if (value === "pinterest_url") {
        if (this.pinterest_url == "" || this.pinterest_url.match(regex)) {
          this.required.pinterest = false;
          if (
            !this.pinterest_url.includes("://") &&
            this.pinterest_url !== ""
          ) {
            this.pinterest_url = "https://" + this.pinterest_url;
          }
        } else {
          this.required.pinterest = true;
        }
      }
    },
    submit() {
      if (!this.first_name || !this.last_name) return;
      if (
        this.required.site ||
        this.required.facebook ||
        this.required.instagram ||
        this.required.twitter ||
        this.required.pinterest
      )
        return;
      let $this = this;
      let formData = new FormData();
      $this.isDisabled = true;
      formData.append("first_name", $this.first_name);
      formData.append("last_name", $this.last_name);
      formData.append("avatar_img", $this.avatar_img);
      formData.append("avatar_url", $this.avatar_url);
      formData.append("bio", $this.bio);
      formData.append("city", $this.city);
      formData.append("country", $this.country);
      formData.append("site_url", $this.site_url);
      formData.append("facebook_url", $this.facebook_url);
      formData.append("instagram_url", $this.instagram_url);
      formData.append("twitter_url", $this.twitter_url);
      formData.append("linkedin_url", $this.linkedin_url);
      formData.append("pinterest_url", $this.pinterest_url);
      axios({
        url: "auth/user/edit/",
        data: formData,
        method: "POST",
      })
        .then((response) => {
          if (response.status === 200) {
            $this.$store.commit("set_my_ozone", 1);
            $this.$store.dispatch("showToast", {
              message: response.data.message,
            });
          }
          this.isDisabled = false;
          $this.$store.dispatch("getUser");
        })
        .catch((error) => {
          console.log(error);
          this.isDisabled = false;
        });
    },
  },
};
</script>
