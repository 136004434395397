<template>
  <div>
    <b-modal
      id="bv-modal-example"
      hide-footer
      v-model="show"
      @cancel="handleCancel"
      @close="handleClose"
      dialog-class="bane-modal"
    >
      <div v-if="this.userBanBy === 'admin'">
        <div class="d-block text-center">
          <h3 class="akrobat">ACCOUNT SUSPENDED</h3>
        </div>
        <p class="fs-8 tx-c">
          Your Account has been suspended due to multiple Member Reports about
          your Post, or a breach of O2 Rules and Terms of Service.
        </p>
        <div v-if="this.period === 'Permanent'">
          <p class="fs-8 tx-c">SUSPENSION : {{ this.period }}</p>
        </div>
        <div v-else>
          <p class="fs-8 tx-c">
            SUSPENSION : {{ this.count }} {{ this.period }}
          </p>
        </div>
        <b-button class="mt-3" block @click="handleSubmit"
          >Contact Admin</b-button
        >
        <p class="fs-7 tx-c">
          Repeat suspensions may result in your account closure. Suspension
          reviews may take several days to complete. No correspondence will be
          entered into.
        </p>
      </div>
      <div v-else>
        <div class="d-block text-center">
          <h3 class="akrobat">ACCOUNT SUSPENDED</h3>
        </div>
        <p class="fs-8 tx-c">
          Your Account has been suspended due to multiple Member Reports made
          about several of your Posts.
        </p>
        <p class="fs-8 tx-c">SUSPENSION : {{ this.count }} {{ this.period }}</p>
        <b-button class="mt-3" block @click="handleSubmit"
          >Contact Admin</b-button
        >
        <p class="fs-7 tx-c">
          Repeat suspensions may result in your account closure. Suspension
          reviews may take several days to complete. No correspondence will be
          entered into.
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal } from "bootstrap-vue";
export default {
  props: ["show", "period", "count", "userBanBy"],

  components: {
    BButton,
    BModal,
  },

  methods: {
    handleSubmit() {
      this.$emit("handle-submit");
    },
    handleCancel() {
      this.$emit("toggle-modal", false);
    },
    handleClose() {
      this.$emit("toggle-modal", false);
    },
  },
};
</script>

<style>
</style>