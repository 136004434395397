<template>
  <div class="admin-overlay for-user">
    <div class="action-btn">
      <div class="single-btn" :class="{ disabled: this.userBan === false }">
        <h4>Remove User Ban</h4>
        <button @click.once="removeBaneUser">YES</button>
      </div>
    </div>
    <perfect-scrollbar class="wrapper">
      <div class="content">
        <ul class="feed">
          <li>
            <div class="title">
              <div class="title-row">
                <label>UserName: </label>
                <p class="post-name">{{ first_name }} {{ last_name }}</p>
              </div>
              <div class="title-row">
                <label>Email: </label>
                <p>{{ email }}</p>
              </div>

              <div class="title-row" v-if="this.bio !== 'null'">
                <label>Bio: </label>
                <p>{{ bio }}</p>
              </div>
            </div>
            <div class="imgWrap" @click="goToProfile">
              <img :src="profile_pic" alt="" />
            </div>
          </li>
        </ul>
      </div>
      <div></div>
    </perfect-scrollbar>
  </div>
</template>

 <script>
import CommentTimeMixin from "@/mixins/CommentTimeMixin";
import axios from "axios";
import $ from "jquery";

export default {
  name: "AdminUserView",

  data() {
    return {
      profile_pic: "",
      bio: "",
      email: "",
      first_name: "",
      last_name: "",
      authorUUID: "",
      user_id: "",
      userBan: true,
    };
  },

  mixins: [CommentTimeMixin],

  mounted() {
    this.userData();
    $("body").addClass("adm-overlay");
  },

  created() {
    this.postTimeAgo();
  },
  methods: {
    async userData() {
      const user_uuid = this.$route.params.uuid;
      const data = await this.$store.dispatch("GetUserByUUID", user_uuid);
      this.profile_pic = data.avatar;
      if (data.avatar) {
        this.profile_pic = data.avatar;
      } else {
        this.profile_pic =
          "https://test-o2.s3-ap-southeast-2.amazonaws.com/avatars/logo-160.png";
      }
      this.bio = data.bio;
      this.email = data.email;
      this.first_name = data.first_name;
      this.last_name = data.last_name;
      this.authorUUID = data.uuid;
      this.user_id = data.id;
    },

    async removeBaneUser() {
      await axios({
        url: "/posts/remove-bane-user/",
        method: "post",
        data: {
          user_id: this.user_id,
        },
      })
        .then((response) => {
          this.userBan = false;
          this.$store.dispatch("showToast", {
            message: response.data.message,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    goToProfile() {
      $("body").removeClass("adm-overlay");
      this.$router.push({
        name: "Profile",
        params: {
          uuid: this.authorUUID,
        },
      });
    },
  },
};
</script>