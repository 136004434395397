var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('perfect-scrollbar',{ref:"app",class:_vm.parentClass,attrs:{"id":"app"},on:{"scroll":_vm.scrolling}},[(_vm.showExpiredModal)?_c('VoteExpiredModal',{on:{"colseModal":_vm.colseModal}}):_vm._e(),(_vm.showSignUpConfirmationModal)?_c('SignUpConfirmationModal'):_vm._e(),(_vm.parentClass != 'leaderboard')?_c('ZeroHero'):_vm._e(),(_vm.adminClass != 'adminOverlay')?_c('MyOzone',{attrs:{"isLoginPannelOpen":_vm.isLoginPannelOpen},on:{"closeLoginPanel":_vm.closeLoginPanel}}):_vm._e(),(_vm.adminClass != 'adminOverlay')?_c('IconMenu',{attrs:{"isLoginPannelOpen":_vm.isLoginPannelOpen},on:{"changeIsLoginPannelOpen":_vm.changeIsLoginPannel}}):_vm._e(),(_vm.showToast)?_c('Toaster',{attrs:{"type":_vm.toastMsg.type,"toastMsg":_vm.toastMsg.message}}):_vm._e(),_c('div',{staticClass:"skip-link screen-reader-text"},[_c('a',{attrs:{"title":"Skip to content","href":"#content"}},[_vm._v("Skip to content")])]),_c('div',{staticClass:"shell no-ws",on:{"click":_vm.changeMyOzone}},[_c('div',{staticClass:"main-column"},[_c('router-link',{class:this.overlayClass === 'opened' ||
          this.sharedOverlayClass === 'opened' ||
          this.editPostOverlayClass === 'opened'
            ? ''
            : 'homeHeaderLink',attrs:{"to":"/"}}),(_vm.selectedItem)?_c('div',{staticClass:"domainName"},[_vm._v(_vm._s(_vm.selectedItem))]):_c('div',{staticClass:"domainName"},[_vm._v("Positive")]),(this.selectedTheme !== 'o2' && _vm.isLogin)?_c('div',{staticClass:"rewardsText",class:_vm.isLogin ? '' : 'not-login'},[_c('span'),_c('div')]):_vm._e(),_c('div',{staticClass:"content Hellow custom-screen",class:{
          active: _vm.isSearching,
          abc: _vm.sharedOverlayClass || _vm.editPostOverlayClass,
        },attrs:{"id":"screen"}},[_c('div',{staticClass:"overlay-content",on:{"click":_vm.toggleSearch}}),_c('SiteHeader'),(_vm.sharedOverlayClass === 'opened')?_c('Overlay'):_vm._e(),_c('router-view')],1)],1)]),_c('a',{staticClass:"icon-up-open goup",class:_vm.goUpClass,attrs:{"title":"Scroll to top"},on:{"click":_vm.scrollToTop}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }