import * as api from "../Services/api.js";
import axios from "axios";

export const state = {
  posts: [],
  pageNumber: 0,
  totalPosts: 0,
  orderBySort: null,
};

export const mutations = {
  VOTE_CHANGE(state, data) {
    const index = state.posts.findIndex((post) => post.post_id === data.postID);
    state.posts[index].votes += data.vote;
  },
  SET_OREDERBY_SORT(state, orderBy) {
    state.orderBySort = orderBy;
  },
  SET_POST(state, payload) {
    state.posts = payload.post_data;
    state.totalPosts = payload.post_count;
    state.pageNumber = 1;
  },
  SET_LOADED_POST(state, payload) {
    state.posts = state.posts.concat(payload.post_data);
    state.totalPosts = payload.post_count;
    state.pageNumber += 1;
  },
  CHANGE_PRESSURE(state, payload) {
    const index = state.posts.findIndex(
      ({ post_id }) => post_id === payload.post_id
    );
    state.posts[index].votes += payload.amount;
  },
  EDIT_POST(state, payload) {
    const index = state.posts.findIndex(
      ({ post_id }) => post_id === payload.post_id
    );
    state.posts.splice(index, 1, { ...payload });
  },
  REMOVE_POST(state, payload) {
    const index = state.posts.findIndex(({ uuid }) => uuid === payload);
    state.posts.splice(index, 1);
  },
};

export const getters = {
  getOrderBySort(state) {
    return state.orderBySort;
  },
};

export const actions = {
  async getAllAvailablePosts({ commit }, data) {
    return await api
      .get(
        "/posts/home-post/?page_num=" +
          data.pageNumber +
          "&active_tab=" +
          data.activeTab +
          "&category=" +
          data.selectedCategory
      )
      .then((response) => {
        commit("SET_POST", response);
        commit("SET_OREDERBY_SORT", response.order_by);
        return response;
      });
  },
  async loadMorePosts({ commit, dispatch, getters }, data) {
    dispatch("setStatus", true, { root: true });
    const tmp = await api
      .get(
        "/posts/home-post/?page_num=" +
          data.pageNumber +
          "&active_tab=" +
          data.activeTab +
          "&order_by=" +
          getters.getOrderBySort +
          "&category=" +
          data.selectedCategory
      )
      .then((response) => {
        dispatch("setStatus", false, { root: true });
        commit("SET_LOADED_POST", response);
        return response;
      });
    return tmp;
  },
  async changePressure({ commit }, data) {
    return await api
      .get(
        "/posts/change-vote/?post_id=" + data.post_id + "&amount=" + data.amount
      )
      .then((response) => {
        commit("CHANGE_PRESSURE", data);
        return response;
      });
  },
  async voteRecord(_, data) {
    axios({
      url: "posts/vote-record/",
      data: {
        post_id: data,
        vote_count: 1,
      },
      method: "POST",
    }).then((response) => {
      console.log(response);
    });
  },
  async editPost({ commit }, data) {
    return await api.post("/posts/update/", data).then((response) => {
      if (response.status === 200) {
        commit("EDIT_POST", data);
      }
    });
  },
  async removePost({ commit }, data) {
    return await api.get("/posts/remove-post?uuid=" + data).then((response) => {
      if (response.status === 200) {
        commit("REMOVE_POST", data);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
