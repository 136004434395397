<template>
  <div id="tabs-5">
    <div class="tabWrap">
      <div class="tx-c">
        <h2 v-if="myComments" class="akrobat c-999">
          <i class="icon-commenting"></i> : {{ totalCommentsCount }}
        </h2>
        <h2 v-else class="akrobat c-999 noCaps">
          <i class="icon-commenting"></i>No Comments yet
        </h2>
      </div>
      <div class="my-comments-wrap">
        <div v-for="(myComment, index) in myComments" :key="index">
          <div
            class="my-comments cs_commentBox"
            :class="{ read_comment: myComment.unread_count > 0 }"
          >
            <div @click="getUnreadComments(index)">
              <span
                class="click_area view-comment"
                :class="{ opened: opendComment === index }"
                title="Open/Close comments"
                @click="toggleAccordion(index)"
              ></span>
              <div>
                <a :href="`${postURL(index)}`" target="_blank">
                  <div v-if="myComment.post_image">
                    <img
                      class="pho-circs pho-2"
                      :title="`View ${myComment.post_title}`"
                      :src="myComment.post_image"
                      :alt="myComment.poster_fullname"
                    />
                  </div>
                  <div v-else>
                    <iframe
                      :title="`View ${myComment.post_title}`"
                      :src="myComment.post_video"
                      :alt="myComment.poster_fullname"
                    ></iframe>
                  </div>

                  <h3>
                    <span
                      class="show-panel"
                      title="View Post"
                      @click="openOverlay(myComment.post_uuid)"
                    >
                      {{ myComment.post_title }}
                    </span>
                  </h3>
                </a>
              </div>
              <div>
                {{ postTimeAgo(myComment.posted_date) }}
              </div>
              <a
                class="view-comment"
                :class="{ opened: opendComment === index }"
                title="Open/Close comments"
                @click="toggleAccordion(index)"
              >
                {{ opendComment === index ? "X" : "View" }}
              </a>
            </div>
          </div>

          <div class="comment-feed" :class="{ opened: opendComment === index }">
            <div
              class="comment_view_box"
              v-for="(comm, idx) in myComment.comments"
              :key="idx"
            >
              <div class="main_comment_child">
                <i
                  class="icon-trash com-trash"
                  title="Remove comment"
                  @click="removeComment(index, idx)"
                ></i>
                <div class="uName_time">
                  <span
                    class="all-black mr-10 user_name"
                    @click="
                      goToCommentProfile(
                        comm.cmt_user_uuid,
                        comm.cmt_user_email
                      )
                    "
                    >{{ comm.cmt_user_fullname }}</span
                  >
                  <div class="time">{{ timeAgo(comm.cmmented_at) }}</div>
                </div>
                <div class="comment_text">
                  <i>"</i>
                  {{ comm.comment_text }}
                  <i>"</i>
                </div>
              </div>
              <div class="r_count_view_btn">
                <p class="reply_count" v-if="comm.total_sub_comm > 0">
                  {{ comm.total_sub_comm }} Replies
                </p>
                <a
                  v-if="comm.total_sub_comm > 0"
                  class="view-sub-comment"
                  :class="{ opened: openReply === index }"
                  title="Open/Close comments"
                  @click="toggleReply(index)"
                >
                  {{ openReply === index ? "Close Replies" : "View Replies" }}
                </a>
              </div>
              <div
                v-for="(sub, sub_idx) in comm.sub_comm"
                :key="sub_idx"
                class="comment_view_box sub_box"
                :class="{ opened: openReply === index }"
              >
                <div title="Remove SubComment">
                  <i
                    class="icon-trash com-trash"
                    @click="removeSubComment(index, idx, sub_idx)"
                  ></i>
                  <div class="uName_time">
                    <span
                      class="blue_text"
                      @click="
                        goToCommentProfile(
                          sub.sub_cmt_user_uuid,
                          sub.sub_cmt_user_email
                        )
                      "
                    >
                      {{ sub.sub_cmt_user_fullname }}
                    </span>
                    <div class="time">
                      {{ subTimeAgo(sub.sub_cmmented_at) }}
                    </div>
                  </div>
                  <div class="comment_text">
                    <i>"</i>{{ sub.sub_comment }}<i>"</i>
                  </div>
                </div>
              </div>
              <div
                class="btn_center_sec"
                :class="{ opened: openReply === index }"
              >
                <a
                  class="load_more_btn"
                  v-if="
                    comm.sub_comm && comm.sub_comm.length < comm.total_sub_comm
                  "
                  @click="
                    loadMoreSubComments(
                      comm.comment_id,
                      comm.curr_page,
                      myComment.post_id
                    )
                  "
                >
                  Show more <span class="icon-down-open"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn_center_sec">
      <a class="load_more_btn" v-if="loadMoreToggle" @click="loadMoreComments">
        Show more <span class="icon-down-open"></span>
      </a>
    </div>
    <LoaderOverlay v-if="isLoading" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import CommentTimeMixin from "@/mixins/CommentTimeMixin";
import LoaderOverlay from "../shared/overlay/LoaderOverlay.vue";
import $ from "jquery";

export default {
  name: "Comments",
  components: {
    LoaderOverlay,
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      myComments: (state) => state.common.myComments,
      mySubComments: (state) => state.common.mySubComments,
      pageNumber: (state) => state.common.pageNumber,
      totalComments: (state) => state.common.totalComments,
      totalSubComment: (state) => state.common.totalSubComment,
      totalCommentsCount: (state) => state.common.totalCommentsCount,
      isLoading: (state) => state.auth.isLoading,
      unreadCount: (state) => state.userModule.unreadCount,
    }),
    loadMoreToggle() {
      const limit = 10;
      return Math.ceil(this.totalComments / limit) > this.pageNumber
        ? true
        : false;
    },
  },

  data() {
    return {
      openReply: -1,
      opendComment: -1,
      subCommentText: [],
      comm_id: "",
      read: false,
    };
  },

  mixins: [CommentTimeMixin],
  created: function () {
    this.timeAgo();
    this.subTimeAgo();
    this.postTimeAgo();
  },

  async mounted() {
    let $this = this;
    await $this.$store.dispatch("getMyComments");
    await $this.$store.dispatch("userModule/getUnreadCount");
  },

  methods: {
    async getUnreadComments(index) {
      if (this.myComments[index].unread_count > 0) {
        for (let i in this.myComments[index].comments) {
          let comm_id = this.myComments[index].comments[i].comment_id;
          const commData = {
            post_id: this.myComments[index].post_id,
            comm_id: comm_id,
          };
          this.$store.dispatch("getUnreadComments", commData);
        }
        if (this.myComments[index].unread_count > 0) {
          this.$store.commit(
            "userModule/SET_UNREAD_COMMENT_COUNT",
            this.myComments[index].unread_count
          );
        }
        this.myComments[index].unread_count = 0;
      }
    },
    goToCommentProfile(uuid, email) {
      let $this = this;
      if ($this.userEmail === email) {
        if ($this.$route.name !== "User") $this.$router.push({ name: "User" });
      } else {
        if ($this.$route.name !== "Profile") {
          $this.$router.push({
            name: "Profile",
            params: {
              uuid: uuid,
            },
          });
        }
      }
      $this.hideOverlay();
    },
    postURL(index) {
      return (
        process.env.VUE_APP_PREFIX + "/post/" + this.myComments[index].post_uuid
      );
    },
    toggleReply(index) {
      if (index === this.openReply) {
        this.openReply = -1;
      } else {
        this.openReply = index;
      }
    },
    loadMoreComments() {
      this.$store.dispatch("loadMoreComments", { pageNumber: this.pageNumber });
    },
    loadMoreSubComments(comm_id, subPageNum, post_id) {
      const data = {
        comm_id: comm_id,
        subPageNum: subPageNum,
        post_id: post_id,
      };
      this.$store.dispatch("loadMoreSubComments", data);
    },
    toggleAccordion(index) {
      if (index === this.opendComment) {
        this.opendComment = -1;
      } else {
        this.opendComment = index;
      }
    },
    async removeComment(index1, index2) {
      let comment_id = this.myComments[index1].comments[index2].comment_id;
      let subCommentCount =
        this.myComments[index1].comments[index2].total_sub_comm;
      subCommentCount = subCommentCount + 1;
      let data = {
        index1: index1,
        index2: index2,
        comment_id: comment_id,
      };
      const response = await this.$store.dispatch("removeComment", data);
      if (this.myComments[index1].comments.length < 2) {
        $(".click_area view-comment").removeClass("opened");
        $(".view-comment").removeClass("opened");
        $(".comment-feed").removeClass("opened");
      }
      if (response.status === 200) {
        await this.$store.commit("SET_TOTAL_COMMENTS_COUNT", -subCommentCount);
        if (this.myComments.length < 2) {
          await this.$store.dispatch("getMyComments");
        }
      }
    },
    async removeSubComment(index, idx, sub_idx) {
      let sub_comment_id =
        this.myComments[index].comments[idx].sub_comm[sub_idx].sub_comm_id;
      let data = {
        index: index,
        idx: idx,
        sub_idx: sub_idx,
        sub_comment_id: sub_comment_id,
      };
      const response = await this.$store.dispatch("removeSubComment", data);
      if (response.status === 200) {
        this.$store.commit("SET_TOTAL_COMMENTS_COUNT", -1);
        if (this.myComments[index].comments[idx].sub_comm.length < 2) {
          await this.$store.dispatch("getMyComments");
        }
      }
    },
    async openOverlay(post_uuid) {
      await this.$store.dispatch("setOverlayPostByUUID", post_uuid);
      const $body = document.body;
      $body.classList.add("overlay-open");
      this.$store.dispatch("changeState", ["overlayClass", "opened"]);
    },
  },
};
</script>